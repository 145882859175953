import React, { useState } from "react"
import { useGet } from "../../../../custom-hooks/useApi"
import styles from "./documents.module.css"
import dayjs from 'dayjs';
import SearchCompo from "../../../vacancies/searchComponent";
import { DeleteDocs, UpdateDocs } from "../../Action/action";
import UploadIcon from "../../../../assets/uploadIconWhite.svg"
import DeleteIcon from "../../../../assets/deleteIcon.svg"
import EditIcon from "../../../../assets/EditIcon.svg"
// import Download from "../../../../assets/d"
import { EllipsisOutlined } from "@ant-design/icons";
import Api from "../../../../service/Api";
import {Popover,Modal,Tooltip } from 'antd';
import Nodata2 from "../../../../assets/nodata2.svg"
import { useParams } from 'react-router-dom';
import ReadMoreText from "../../../../components/readMoreText";
const DocumentApplication = ({viewMode=false}) =>{
  const { jobId, id:appliationId } = useParams();
  console.log("pipeline,Application",jobId,appliationId)
    const [toggler,setToggler]=useState(false)
    const [hovered, setHovered] = useState(false);
    const [openModal,setOpenModal]=useState(false)
    const [fileData,setFileData]=useState({})
    const [search,setSearch]=useState()
  const {data}=useGet("/api/candidate/getDocuments",{applicationId:appliationId,search:search},toggler)
  const handleClick = (event,ele) => {
    const clickedId = event.target.id; // Get the id of the clicked child element
    console.log('Clicked ID:', clickedId,ele);
    
    // Perform actions based on the clicked id
    if (clickedId === "1") {
      DeleteDocs({applicationId:appliationId,key:ele?.key},((res,err) =>{
        console.log("success")
        if(res){
            setToggler(prev =>!prev)
        }
      }))
    } else if (clickedId === "2") {
        setFileData(ele)
        setOpenModal(true)
      
      console.log('Rename action triggered');
    }
  };
  const content=(ele)  => (
    <div style={{display:"flex",gap:10,flexDirection:"column"}}  onClick={(e) =>handleClick(e,ele)}>
    
      <div style={{cursor:"pointer"}} id="2"> <img src={EditIcon}/>  Rename</div>
      <div style={{cursor:"pointer"}} id="3"> <img src={EditIcon}/>  Download</div>
      <div style={{cursor:"pointer",color:"red"}} id="1"><img src={DeleteIcon}/>  Remove</div>
    </div>
  );
  const handleFileChange = (event) => {
    const file = event.target.files[0]; 
    // Get the selected file
    console.log('Selected file:', file);
    if (file) {
        Api.uploadAndPost(`/api/candidate/uploadDocuments?applicationId=${appliationId}`,{files:file},(per,res) =>{
            console.log(per,res)
            setToggler(prev =>!prev)
        })
       // Store the file name
      // Optionally, you can handle file upload here, e.g., by sending it to a server
      console.log('Selected file:', file);
    }
  };
  const getClassForFileType = (fileType) => {
    switch (fileType) {
      case "pdf":
        return styles.pdf;
      case "jpg":
        return styles.jpg;
      case "txt":
        return styles.txt;
      case "png":
        return styles.png;
      case "xlsx":
        return styles.xlsx;
        case "docx":
        return styles.doc;
      default:
        return "";
    }
  };


  return (<div style={{display:"flex",flexDirection:"column",padding:24,height:"90vh"}}>
    {((data?.records?.length>0 || data?.records)||search)&&<div style={{display:"flex",justifyContent:"space-between",marginBottom:20}}>
    <SearchCompo onSearch={(val) =>{console.log(val)
     setSearch(val)
     setToggler(prev =>!prev)

    }}/>
  <div style={{marginTop:8}}>
      <input
       onChange={handleFileChange}
        type="file"
        id="file-upload"
        
        style={{ display: 'none', }} // Hide the default file input
      />
      {!viewMode?<label htmlFor="file-upload" className={styles.uploadButton} style={{ color: 'white',cursor:"pointer" }}>
        Upload <img src={UploadIcon} alt="Upload Icon" />
      </label>:null}
    </div>
    </div>}
    {(data?.records?.length==0 || !data?.records)?
       <div style={{
        border: '2px dashed #DCDCDC',
        padding: '20px',
        width: '100%',
        height: '95%',
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        justifyContent:"center"
        
      }}>
        
       <div style={{  display:"flex",justifyContent: "space-evenly",flexDirection:"column",
        alignItems: 'center',height:"50%",marginBottom:40}}>
        <img src={Nodata2}/>
        <div style={{fontSize:20,fontWeight:600,marginTop:8}}>Nothing here yet</div>
        <div style={{marginTop:8}}>Upload Important files to keep everything you need in one place</div>
        {!search &&
        <div style={{marginTop:30}}>
       <input
       onChange={handleFileChange}
        type="file"
        id="file-upload"
        
        style={{ display: 'none', }} // Hide the default file input
      />
      <label htmlFor="file-upload" className={styles.uploadButton} style={{ color: 'white',cursor:"pointer" }}>
        Upload <img src={UploadIcon} alt="Upload Icon" />
      </label>
      </div>}
    </div>
    <div style={{color:"#7A7A7A",position:"absolute",bottom:1}}>Supported files: PDF, XLS, DOC, PPT, PNG, JPG and TXT.</div>
      </div>
    :
    <div className={styles.gridStyle} >
    
    {data?.records?.map((card, index) => (
      <div key={index} className={styles.cardStyle} style={{position: "relative" , boxShadow: hovered
        ? "0px 4px 12px rgba(0, 0, 0, 0.2)" // Shadow on hover
        : "0px 1px 2px rgba(0, 0, 0, 0.1)", // Default shadow
      transition: "box-shadow 0.3s ease",}} onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      >
        <div style={{marginBottom:40}}>
        {hovered && (
          <Popover placement="bottomRight" content={content(card)}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "absolute",
                top: 10,
                right: 10,
              }}
            >
              <EllipsisOutlined style={{ fontSize: "24px", cursor: "pointer" }} />
            </div>
          </Popover>
        )}
  </div>
  <div style={{marginBottom:40}}>
        <div onClick={() =>{
         window.open(card.file, "_blank");
        }} className={`${styles.sprite} ${getClassForFileType(card.fileName.split(".")[1])}`} style={{cursor:"pointer"}}></div>
        </div>
        <div  style={{width:"100%",gap:5,display:"flex",textAlign:"left",flexDirection:"column",}}>
        {/* <ReadMoreText text={card.fileName} maxLength={50} style={{fontWeight:500}} readBtnStyle={{fontWeight:200,color:"#1890FF", cursor: "pointer"}}/> */}
        <Tooltip title={card.fileName}>
        <div style={{fontWeight:500}} >{card.fileName.slice(0,30)}{card.fileName.length>30?"...":""}</div>
        </Tooltip>
        <div>{card.size} KB</div>
        <div>{dayjs(card.uploadedDate).format('DD/MM/YYYY')}</div>
        </div>
      </div>
    ))}
  </div>}
  {data?.records?.length>0&&<div style={{color:"#7A7A7A",position:"absolute",bottom:0}}>Supported files: PDF, XLS, DOC, PPT, PNG, JPG and TXT.</div>}
  <Modal 
      onCancel={() =>{
        setFileData({})
        setOpenModal(false)}}
      onClose={() =>{
        setFileData({})
        setOpenModal(false)}}
      onOk={() =>{
        UpdateDocs({applicationId:appliationId,key:fileData?.key,fileName:fileData?.fileName},((res,err) =>{
            console.log(res)
            if(res){
                setOpenModal(false)
                setToggler(prev =>!prev)
            }
        }))
      }
      }
      okText="Save data"
      width={"40%"}
      open={openModal} title="Edit Information">
        <div style={{ paddingBottom: 20, }}>
            {/* <div style={{ marginBottom: 8 }}>Provide a unique name to easily identify this template later.</div> */}
            <input  defaultValue={fileData.fileName} onChange={(e) =>{
               setFileData(prev =>({
                 ...prev,
                 fileName:e.target.value
               })) 
                
            }} className={styles.inputStyle} style={{marginTop:20}} />
          </div>
      </Modal>
  </div>
  )
}
export default DocumentApplication