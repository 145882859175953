import React, { useState } from "react";
import { Quill } from "react-quill";
import { CopyOutlined,CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import undo from '../../assets/icons/undo.svg'
import redo from '../../assets/icons/redo.svg'
import './styles.css'
import { useStore } from "../../pages/hiring/store/store";
// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}
function copyToClipboard() {
  const quillEditor = this.quill.root.innerText; // Get the editor's HTML content
  navigator.clipboard.writeText(quillEditor)
    .then(() => {
      // alert('Content copied to clipboard!');
      
    })
    .catch(err => {
      // console.error('Failed to copy: ', err);
    });
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      copy: copyToClipboard
    }
  },
  clipboard: {
            matchVisual: false
        },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
export const QuillToolbar = ({data}) => {
  const [isCopied,setIsCopied]=useState(false);
 console.log("quill data",data)
  const handleCopy=()=>{
    setIsCopied(true);
    setTimeout(()=>{
   setIsCopied(false)
    },3000)
  }
  return (
  <div id="toolbar">
    <div className="ql-formats">
 
    <button className="ql-undo tooltip" data-tooltip="Undo">
    
        <img src={undo}/>
       
      </button>
    
      <button className="ql-redo tooltip" data-tooltip="Redo">
   
        <img src={redo}/>
      </button>
   
    </div>
    <span className="ql-formats">
      {/* <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="extra-small">Size 1</option>
        <option value="small">Size 2</option>
        <option value="medium">Size 3</option>
        <option value="large">Size 4</option>
      </select> */}
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
    <button className="ql-bold  tooltip" data-tooltip="Bold" />
     <button className="ql-italic   tooltip" data-tooltip="Italic" />
      <button className="ql-underline  tooltip" data-tooltip="Underline" />
   <button className="ql-strike  tooltip" data-tooltip="Strikethrough" />
    </span>
    <span className="ql-formats">
   <button className="ql-list  tooltip" data-tooltip="Ordered list" value="ordered" />
    <button className="ql-list  tooltip" data-tooltip="Unordered list" value="bullet" />
   <button className="ql-indent  tooltip" data-tooltip="Indent left" value="-1" />
   <button className="ql-indent  tooltip" data-tooltip="Indent right" value="+1" />
    </span>
    {/* <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span> */}
    {/* <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-video" />
    </span> */}
    {/* <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span> */}
     {data?
     
    <span className="ql-formats" style={{float:'right'}}>
    <button className="ql-copy"  style={{cursor:'pointer',width:'max-content',
      display:'flex',flexDirection:'row',justifyContent:'center',
      alignItems:'center',fontWeight:'700',color:'#1677ff',gap:2}} onClick={handleCopy}>
      {isCopied? 
      <>
      <CheckOutlined  style={{color:"#1677ff"}}/>
        <p>Copied!</p>
       
      </> :
      <>
      <CopyOutlined />
      <p>Copy</p>
  
      </>
      }
    
      </button>
    </span>
    :null}

  </div>)
};

export default QuillToolbar;