import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import Select from 'react-select';
import "./createRequests.css";
import { useVacancyStore } from './store/store';



const SearchableSelect = ({options, placeholder,title,onChange,inputVal,isRequired=false}) => {
  console.log(inputVal,"APPROVEROPTIONS")

  const getDefaultOption = (inputVal) => {
    
    if(title=="Reason for vacancy" || title==="Priority" || title==="Currency" || title==="Compensation structure" || title==="Preferred Candidate"){
     console.log("REASONVACANCY",inputVal,title)
        return options.find(option => option.value.toLowerCase() == inputVal?.toLowerCase()) || null;
    }
    else{
      console.log("COMPENSATION",inputVal)
    return options.find(option => option.value === inputVal) || null; // Use null if no match is found
    }
  };

  

  const [selectedOption, setSelectedOption] = useState(getDefaultOption(inputVal));

   // const [selectedOption, setSelectedOption] = useState(inputVal??'');
    const {viewFormModal} = useVacancyStore(state=>state);

  // const handleChange = (event) => {
  //   console.log(event.value,"comp")
  //   setSelectedOption(event);
  //   onChange(event.value); // Pass the value to parent component
  // };

  const handleChange = (event) => {
    if (event) {
    
      setSelectedOption(event);
      if (onChange) {
        onChange(event.value); // Pass the selected value to parent component
      }
    } else {
      
      setSelectedOption(null);
      if (onChange) {
        onChange(null); // Notify parent component of cleared state
      }
    }
  };

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuToggle = (isOpen) => {
    setMenuIsOpen(isOpen);
  };
 
  return (
    <div className='input-group'>
      <label htmlFor="searchable-select">
        {title} {(title==="Approver" || title==="Department/Category" || title ==="Recruitment type")&&<span style={{ color: '#F5222D' }}>*</span>}
      </label>
      {viewFormModal === false && (
        <Select
          id="searchable-select"
          value={selectedOption} // This now shows the correct label
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          isClearable
          onMenuOpen={() => handleMenuToggle(true)}
          onMenuClose={() => handleMenuToggle(false)}
          isSearchable
          menuPlacement= {title==='Approver'?'top':'bottom'}
          //required = {title !== "Currency" && title!=="Compensation structure"}
          styles={{
            control: (provided) => ({
              ...provided,
              borderRadius: '4px',
              borderColor: '#d9d9d9',
            }),
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isSelected ? '#e6f7ff' : 'white',
              color: '#000',
              '&:hover': { backgroundColor: '#f2f2f2' },
           
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#d0d0d0', // Set your custom placeholder color here
              height: '17px',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease',
            }),
            
          }}
        />
        
      )}
      {viewFormModal === true && (
        <div style={{ color: '#1f1f1f' }}>
          {selectedOption ? selectedOption.label : '-'}
        
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
