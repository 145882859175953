import React, { useEffect, useState } from 'react';
import searchIcon from "../../assets/searchIcon.svg"
import clearIcon from "../../assets/clearIcon.svg"
import { useVacancyStore } from './store/store';

const SearchCompo = ({ onSearch, placeholder = 'Search',value="" }) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const {vacancyCreatorFlag} = useVacancyStore (state=>state);

  const handleSearch = () => {
    if(searchTerm.trim())
      onSearch(searchTerm.trim());
  };
  const clearSearch = () => {
    setSearchTerm('');
  };

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  useEffect(()=>{
    setSearchTerm('');
  },[vacancyCreatorFlag])


  useEffect(()=>{
    setSearchTerm(value)
  },[value])
  // Debounce the search term to reduce API calls
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // Adjust debounce delay as needed

    return () => {
      clearTimeout(handler); // Cleanup previous timeout
    };
  }, [searchTerm]);

  // API call on debounced search term
  useEffect(() => {
    if (debouncedSearchTerm) {
      onSearch(searchTerm);
    }else{
      onSearch('');
    }
  }, [debouncedSearchTerm]);

  return (
    <div style={styles.searchContainer}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => { 
          const trimmedValue = e.target.value.trimStart(); // Trim leading spaces only
          setSearchTerm(trimmedValue);
   
        }}
        onBlur={() => {
          // Remove trailing spaces on input blur
          setSearchTerm((prev) => prev.trim());
     
        }}
        placeholder={placeholder}
        style={{...styles.input,
          // borderRight: searchTerm? 'none':'',
          borderRight: 'none'
        }}
      />
       {searchTerm && (
        <button onClick={clearSearch} style={styles.clearButton}>
          <img src={clearIcon} alt="clear" />
        </button>
      )}
      <button onClick={handleSearch} style={styles.button}>
        <img src={searchIcon} alt="searchIcon"/>
      </button>
    </div>
  );
};

const styles = {
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    // border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
    minWidth: '200px',
    width:'100%',
    maxWidth:'300px',
    minHeight: '30px',
    border: '1px solid #D0D0D0',


  },
  input: {
    border: 'none',
    borderRadius: '4px 0px 0px 4px',
    padding: '10px',
    flex: 1,
    color: 'var(--Texts-disabled, #000)',

    height:'100%',
    fontFamily: 'Figtree',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'normal',
    outline: 'none',
  },
  clearButton: {
    backgroundColor:'white',
    border: 'none',
    position: 'relative',
    right: '0px', // Positioning clear button inside the input
    cursor: 'pointer',
    padding: '0 8px',
    height: '100%',
    
  },
  button: {
    backgroundColor: '#1890FF',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '0px 4px 4px 0px',
    width: '37px',

    height:'100%',
  },

};

export default SearchCompo;

