import React from 'react';
import { Select } from 'antd';

const SelectFilter = ({options,handleChange}) => (
  <Select
    defaultValue={options[0]?.value}
    style={{
      width: 200,
    }}
    onChange={(value)=>{handleChange(value)}}
    options={options}
  />
);
export default SelectFilter;