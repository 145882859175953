import React, { useEffect, useState } from "react";
import "./analytics.css"
import CustomTitle from "./customTitle";
import VacancyAnalysis from "./vacancyAnalysis";
import VacancySummary from "./vacancySummary";
import { getAnalytics } from "../Action/action";
import { useAnalyticsStore } from "../store/store";
import { DatePicker, Select, message } from 'antd';
import dayjs from 'dayjs';


const { RangePicker } = DatePicker;
const { YearPicker } = DatePicker;


const Analytics = ()=>{

  const currentDay = dayjs(); // Get the current date

const endOfWeek = currentDay.startOf('day'); // Start of today
const startOfWeek = currentDay.subtract(6, 'day').endOf('day'); // End of the 7th day from today

const currentMonth = currentDay.format("MM"); // Month in "01", "02" format
const currentYear = currentDay.format("YYYY"); // Year in "2024" format


  const [startDate, setStartDate] = useState(startOfWeek);
  const [endDate, setEndDate] = useState(endOfWeek);
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(currentMonth);

  const {analyticsData, setAnalyticsData, rangeType, setRangeType} = useAnalyticsStore(state=>state);

     const vacancyAnalysisData = [
    { name: 'Full-time', value: 32, index: 0 },
    { name: 'Part-time', value: 43 , index: 1  },
    { name: 'Contract', value: 14, index: 2 },
    { name: 'Freelance', value: 28, index: 3 },
    { name: 'Internship', value: 6, index: 4  },
    { name: 'Undetermined', value: 6, index: 5  }
  ];
//   const departmentData = Object.entries(data?.departmentCounts??{})?.map(([key, value],index) => ({
//     name: key,
//     value: value,
//     index: index
//   }));
  const totalVCount = vacancyAnalysisData?.reduce((sum, item) => sum + item.value, 0);
  const colors = ['#BAE7FF',  '#91D5FF', '#69C0FF','#40A9FF', '#1890FF',"#D0D0D0"];

  const activeColors = ["#13C2C2","#5CDBD3", "#B5F5EC"];
  const inactiveColors = ["#95DE64", "#FF7875", "#FF9C6E"];


    const successCardData = [
   { name: 'Recruited', value: 24, index: 0 },
   { name: 'Active', value: 104 , index: 1  },
   { name: 'On-hold', value: 256, index: 2 },
  
 ];
  const successCardColors=  ["#95DE64","#B37FEB", "#FFC069"];
  const totalCount = successCardData?.reduce((sum, item) => sum + item.value, 0);

  const summaryColors=["#B37FEB","#EFDBFF"];
  // const data = [
  //   { name: '18-25', Male: 75, Female: 80, maleCount:75, femaleCount:80 },
  //   { name: '26-35', Male: 70, Female: 75, maleCount:70, femaleCount:75 },
  //   { name: '36-45', Male: 70, Female: 80, maleCount:70, femaleCount:80 },
  //   { name: '46-55', Male: 65, Female: 70, maleCount:65, femaleCount:70 },
  //   { name: '56-65', Male: 70, Female: 75, maleCount:70, femaleCount:75 }
  // ];
  const data = analyticsData?.summary?.vacancyData?.map(item => {
    const date = item.date;
    // Reverse the last two characters in the date string.split("").reverse().join("")
    const reversedDay = date.slice(-5).split("-").reverse().join("-");
    return {
        name: rangeType==="Week"?reversedDay:date,
        activeCount: item.activeCount,
        inactiveCount: item.inactiveCount,
    };
});
  const dataActive = [
    { name: 'Pre-Interview Stage', value: analyticsData?.stageType?.preInterviewStageCount},
    { name: 'Interview Stage', value: analyticsData?.stageType?.interviewStageCount },
    { name: 'Post-Interview Stage', value: analyticsData?.stageType?.postInterviewStageCount },
  
  ];
  const months = Array.from({ length: 12 }, (_, i) => ({
    value: String(i + 1).padStart(2, '0'),
    label: new Date(0, i).toLocaleString('default', { month: 'long' }),
  }));
  
  const dataInactive = [
    { name: 'Recruited', value: 32 },
    { name: 'Force Closed', value: 14 },
    { name: 'On-hold', value: 4},
    
  ];
  const onChange = (value) => {
    setRangeType(value)
    console.log(`selected ${value}`);
  };

  const onYearChange = (value)=>{
    setYear(value);

  }
  const onMonthChange = (value)=>{
    const [yearData, monthData] = value.split('-');
    console.log(value, "MONTH")
    setMonth(monthData)
    setYear(yearData)
  }
  const onSearch = (value) => {
    
    console.log('search:', value);
  };


  const handleRangeChange = (dates) => {
   
    console.log("DATES",dates);
    if (dates) {
      
      if (dates[1].diff(dates[0], "day") !== 6) {
        message.error("You must select exactly 7 days.");
      }else{
        setStartDate(dates[0]);
        setEndDate(dates[1]);
      }
      
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const disabledDate = (current) => {
    const today = dayjs().startOf("day");
    const maxDate = today.add(6, "day");
    // Disable dates before today and after the 7th day
    return (current > today );
  };

  useEffect(()=>{
    console.log(startDate,endDate,"DATES")

  },[startDate,endDate])


  useEffect(()=>{

    if (rangeType === "Week" && (!startDate || !endDate)) {
    
      return; // Exit early if the condition is met
    }
    
    let params={
      rangeType: rangeType,
      startDate: rangeType==="Week"?dayjs(startDate).format("YYYY-MM-DD"):null,
      endDate: rangeType==="Week"?dayjs(endDate).format("YYYY-MM-DD"):null,
      year: rangeType!=="Week"?year:null,
      month: rangeType==="Month"?month:null,

    };
    getAnalytics(params,(response,error)=>{
      if (error) {
        console.error("Error :", error);
      } else {
      
        setAnalyticsData(response)
        
        
        console.log("Analytics check :",response?.summary);
      }
    })
  },[rangeType,startDate,endDate,year, month])

 

    return (
        <div className="analytics-container">
          <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
            <div className="analytics-header">
                <div>
                    Analytics & Reports
                </div>
                <div>
                     {rangeType==="Month"?"Displaying current month's data against previous month's data."
                     :rangeType==="Year"?"Displaying current year's data against previous year's data."
                     :"Displaying 7 days data against the previous 7 days data."}
                </div>
            </div>
     
            <div style={{display:'flex', gap:'16px', alignItems:'center'}}>
            <Select
                showSearch
                placeholder="Select"
                optionFilterProp="label"
                onChange={onChange}
                onSearch={onSearch}
                value={rangeType}
                options={[
                  {
                    value: 'Week',
                    label: 'Week',
                  },
                  {
                    value: 'Month',
                    label: 'Month',
                  },
                  {
                    value: 'Year',
                    label: 'Year',
                  },
                ]}
                className="Select-an"
            />
              {rangeType==="Week"?
              <RangePicker
                defaultValue={[startDate, endDate]}
                format="DD-MM-YYYY"
                style={{ borderRadius: '4px' }}
                disabledDate={disabledDate}
                onChange={handleRangeChange}
              />
              :
              rangeType==="Year"?
              <DatePicker
                picker="year" // Enables year-only selection
                placeholder="Select Year" // Placeholder similar to your Select component
                value={year ? dayjs(year, 'YYYY') : null} // Ensure the value is in moment format
                onChange={(date, dateString) => onYearChange(dateString)} // Pass the selected year
                className="Select-an" // Custom class for styling
              />
              :
              <>
             <DatePicker
              picker="month" // Enables month-only selection
              placeholder="Select Month" // Placeholder text
              value={year && month ? dayjs(`${year}-${month}`, 'YYYY-MM') : null} // Sets the selected month
              onChange={(date, dateString) => onMonthChange(dateString)} // Handles the selected month
              className="Select-an" // Custom styling class
            />
              {/* <DatePicker
                picker="year" // Enables year-only selection
                placeholder="Select Year" // Placeholder similar to your Select component
                value={year ? dayjs(year, 'YYYY') : null} // Ensure the value is in moment format
                onChange={(date, dateString) => onYearChange(dateString)} // Pass the selected year
                className="Select-an" // Custom class for styling
              /> */}
              
              </>
              }
            </div>
          </div>
            <div >
            <CustomTitle 
                title="Vacancy Overview" styles={{width:'4px', height:'24px', backgroundColor:'#2337C6'}} />
            <div className="analytics-subcontainer1" >
                    <VacancySummary 
                        type="vacancysummary" 
                        title="Vacancy Summary" 
                        content="The ratio of active to inactive job vacancies."
                        colors={summaryColors}
                        data={data}
                    />
                    <VacancySummary 
                        type="active"
                        title="Active Vacancies" 
                        content="Monitor all active vacancies."
                        colors={activeColors}
                        data={dataActive}
                    />
            </div>
            <div className="analytics-subcontainer2">
                    <VacancySummary 
                        type="inactive"
                        title="In-active Vacancies" 
                        content="View details on in-active job vacancies."
                        colors={inactiveColors}
                        data={dataInactive}
                    />
                    <VacancyAnalysis
                        data={vacancyAnalysisData} 
                        colors={colors} 
                        totalCount={totalVCount} 
                        title="Vacancy Analysis by Core Criteria" 
                        content="Analyze vacancies by key factors to enhance your hiring strategy."
                       
                    />
            </div>
            </div>
          

        </div>
    );

}

export default Analytics;