import React, { useState } from 'react';
import { Card, Col, Row , Avatar, Button, Flex, Dropdown} from 'antd';
import deleteIcon from "../../../assets/deleteIcon.svg"
import editIcon from "../../../assets/EditIcon.svg"
import { EllipsisOutlined } from '@ant-design/icons';
import parse, { htmlToDOM } from 'html-react-parser';
import "./cardComponent.css"

const cardData = [
    {
      title: "Note by Me",
      date: "Mar 30, 2024 13:52:41",
      avatar: "https://cdn.pixabay.com/photo/2023/03/05/10/15/ai-generated-7831038_1280.jpg",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa 
                qui officia deserunt mollit anim id est laborum.`,
    },
    {
      title: "Note by Me",
      date: "Mar 30, 2024 13:52:41",
      avatar: "https://cdn.pixabay.com/photo/2023/03/05/10/15/ai-generated-7831038_1280.jpg",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa 
                qui officia deserunt mollit anim id est laborum.`,
    },
    {
      title: "Note by Me",
      date: "Mar 30, 2024 13:52:41",
      avatar: "https://cdn.pixabay.com/photo/2023/03/05/10/15/ai-generated-7831038_1280.jpg",
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore 
                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi 
                ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse 
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa 
                qui officia deserunt mollit anim id est laborum.`,
    },
  ];
const CardComponent = ({data, handleEditNotes}) => {
    const [loading, setLoading] = useState(true);
    const [noteId, setNoteId] = useState();
    const [note,setNote]=useState('');
    console.log(data,"cccc")
    const onMenuClick = (e) => {
      console.log('click', e);
    };
    const items = [
      {
        key: '1',
        label:<div style={{display:'flex', flexDirection:'row', gap:'4px'}}  onClick={()=>{handleItemsClick("Edit",noteId, note)}}>
          <img src={editIcon}/>
          <div>Edit</div>
        </div>,
      },
      {
        key: '2',
        label:<div style={{display:'flex', flexDirection:'row', gap:'4px'}}  onClick={()=>{handleItemsClick("Delete",noteId)}}>
          <img src={deleteIcon}/>
          <div>Delete</div>
        </div>,
      },
    
    ];
    const handleItemsClick = (value,noteId,note)=>{
      handleEditNotes(value,noteId,note)
    }
    const handleNoteClick = (id,note)=>{
      console.log(id,note,"checkeditnotes")
      setNoteId(id);
      setNote(note);
    }
    return(
  <Row gutter={[16, 16]} style={{ flexDirection: 'column', gap:'16px' ,marginTop:'24px', marginBottom:'24px'}}>
    {data?.map((card, index) => (
      <Col key={index}>
        <Card
          bordered={true}
          className='candidate-notes-card'
          style={{cursor:'pointer'}}
          title={
            <div className='card-title-candidates' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between' }}>
              <div className='card-title-left' style={{display:'flex', flexDirection:'row', alignItems:'center', gap:'16px'}}>
              <Avatar src="https://cdn.pixabay.com/photo/2023/03/05/10/15/ai-generated-7831038_1280.jpg" />
              <div className="card-header">
                <div>Note by{card?.createdByName}</div>
                <div>{card?.createdTime}</div>
              </div>
              </div>
              <div className='card-title-right'>
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomLeft"
                  arrow
                  trigger={['click']}
                  onClick={()=>{handleNoteClick(card?.id, card?.note)}}
                >
                  <EllipsisOutlined style={{ fontSize: '20px', cursor: 'pointer' }} />
                </Dropdown>
              </div>
            </div>
          }
        >
          {parse(card?.note)}
        </Card>
      </Col>
    ))}
  </Row>
    ); 
};

export default CardComponent;
