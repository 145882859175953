import React, { useEffect, useState ,useRef} from 'react';
import Increment from "../../assets/Increment.svg";
import Decrement from "../../assets/decrement.svg";
import './NumberInput.css'; // Import your custom styles
import "./createRequests.css";
import { useVacancyStore } from './store/store';

const NumberInputExample = ({ title, min, max, width, inputVal, onChange,}) => {


    const { viewFormModal } = useVacancyStore(state => state);
    const [value, setValue] = useState(inputVal|| title=="Number of positions open"?1:0);
    console.log("invoked",typeof inputVal)

    useEffect(() => {
        setValue(inputVal || 0);
    }, [inputVal]);
    useEffect(() => {
        // Prevent scroll on all number inputs
        const inputs = document.querySelectorAll('input[type="number"]');
        
        if (inputs.length) {
            const preventScroll = (e) => e.preventDefault();
    
            inputs.forEach((input) => {
                input.addEventListener('wheel', preventScroll);
            });
    
            // Cleanup: Remove the event listener when the component unmounts
            return () => {
                inputs.forEach((input) => {
                    input.removeEventListener('wheel', preventScroll);
                });
            };
        }
    }, []);
    


    const handleChange = (e) => {
        e.preventDefault();
        const newValue = parseInt(e.target.value, 10); // Parse the value from input
        setValue(newValue);
        onChange(newValue); // Pass the new value to the parent component
    };
   


    const increment = (e) => {
        e.preventDefault();
     
        const newValue = Math.min(value + 1, max);
        setValue(newValue);
        onChange(newValue); // Call onChange with new value
    };

    const decrement = (e) => {
        e.preventDefault();
        const newValue = Math.max(value - 1, min);
        setValue(newValue);
        onChange(newValue); // Call onChange with new value
    };

    return (
        <div 
            className="input-group"
           
        >
            <label>
                {title}
                 {/* {(["Minimum range", "Maximum range","Maximum Years of Experience"].includes(title))&&<span style={{ color: '#F5222D' }}>*</span>} */}
            </label>
            {viewFormModal === false && (
                <>
                    <div className='custom-number-input'>
                        <input
                            type='Number'
                            min={min} 
                            max={max} 
                            value={value} 
                            onChange={(e)=>{handleChange(e)}} 
                            required
                        />
                        <div className="buttons">
                            <button onClick={(e)=>{increment(e)}} className="increment" id='incr-btn' >
                                <img src={Increment} alt="Increment" />
                            </button>
                            <button onClick={(e)=>{decrement(e)}} className="decrement" id='decr-btn' style={{cursor: value===0?'not-allowed':title==='Number of positions open'&& value===1?'not-allowed':'pointer'}}>
                                <img src={Decrement} alt="Decrement" />
                            </button>
                        </div>
                    </div>
                </>
            )}
            {viewFormModal === true && (
                <div style={{color:'#1f1f1f'}}>
                    {(inputVal !== undefined && inputVal!=='' )? inputVal : inputVal===''?0:'-'}
                    
                </div>
            )}
        </div>
    );
};

export default NumberInputExample;
