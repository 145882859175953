
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from 'react-router-dom';
import Home from '../landing/home';
import Vacancies from '../pages/vacancies/vacancies';

import JobDescription from '../pages/hiring/jobDescription';
//import TextEditor from './pages/hiring/textEditor';
import CreateJD from '../pages/hiring/createJD';
import Dashboard from '../pages/dashboard/dashboard';
// import SideBar from '../sidebar/sidebar';
import SideBar from "../sidebar/sidebar"
import Header from '../header/header';
import '../App.css'
import RecruitmentOverview from '../pages/vacancies/vacancy_details/recruitmentOverview/recruitmentOverview';
import ActivitiesPage from '../pages/vacancies/vacancy_details/activities/activity';
import VacancyDetails from '../pages/vacancies/vacancy_details/vacancyDetails';
import VacancyWrapper from '../pages/vacancies/vacancyWrapper';
import Configuration from '../pages/settings/configuration';
import CandidateInfo from "../pages/applicationManagement/candidate_profile/candidateProfile"
import ResumeScreening from '../pages/demoPages/resumeScreening/mainPage';
import ResumeScreeningResults from '../pages/demoPages/resumeScreening/resumeScreeningResults';
import PipelineView from '../pages/applicationManagement/pipeline/pipeline';
import ApplicationManagement from '../pages/applicationManagement/applicationManagement';
import ApplicationManagementWrapper from '../pages/applicationManagement/applicationManagementwrapper';
import ApplicationDetails from '../pages/applicationManagement/applicationDetails/applicationDetails';

function MainRouter() {
  return (

<Routes>
  <Route path="/" element={<Home />} />
  <Route path="/createJd" element={<CreateJD />} />
  <Route path="generatedJd" element={<JobDescription />} />
  <Route path="/resumeScreening" element={<ResumeScreening />} />
  <Route path="/results" element={<ResumeScreeningResults />} />
  {/* Shared Layout Route for Dashboard, Vacancies, and Job Description */}
  <Route
    path="/"
    element={
      <div className="app">
        <div className="app-sidebar">
          <SideBar />
        </div>
        <div className="app-main">
          <Header />
          <Outlet  /> 
        </div>
      </div>
    }
  >
    <Route path="dashboard" element={<Dashboard />} />

      <Route path="vacancies" element={<VacancyWrapper/>} >
        <Route path="" element={<Vacancies tab={"Requests"}/>}/>
        <Route path="active" element={<Vacancies tab={"Active"}/>}/>
        <Route path="in-active" element={<Vacancies tab={"In-active"}/>}/>
        <Route path="analytics" element={<Vacancies tab={"Analytics & Reports"}/>}/>
        <Route  path=":id" element={<VacancyWrapper/>} >
            <Route path='' element={<VacancyDetails tab={"Job Overview"} />}/>
            <Route path='recruitment-overview' element={<VacancyDetails tab={"Recruitment Overview"} />}/>
            <Route path='activities' element={<VacancyDetails tab={"Activities"} />}/>
        </Route>
        <Route path="activities" element={<ActivitiesPage/>}/>
        <Route path='recruitment_overview' element={<RecruitmentOverview/>}/>
      </Route>
      <Route path="application-management" element={<ApplicationManagementWrapper/>} >
           <Route path="" element={<ApplicationManagement tab={"Active"}/>} />
           <Route path="active" element={<Navigate to="/application-management" />} />
           <Route path="in-active" element={<ApplicationManagement tab={"In-active"}/>}/>
           <Route path="analytics" element={<ApplicationManagement tab={"Analytics & Reports"}/>}/>
          <Route path="pipeline/:jobId"  element={<ApplicationManagementWrapper/>}>
          <Route path='' element={<PipelineView/>}/>
          <Route  path="application/:id" element={<ApplicationManagementWrapper/>} >
             <Route path='' element={<ApplicationDetails tab={"Application"} />}/>
             <Route path='documents' element={<ApplicationDetails tab={"Documents"} />}/>
             <Route path='hiring-stages' element={<ApplicationDetails tab={"Hiring Stages"} />}/>
             <Route path='notes' element={<ApplicationDetails tab={"Notes"} />}/>
             <Route path='activities' element={<ApplicationDetails tab={"Activities"} />}/>
          </Route>
          </Route>

          
          <Route path='candidate-profile/:id' element={<CandidateInfo/>}/>
      </Route>

    <Route  path='configuration' element={<Configuration/>}/>

 
  </Route>
</Routes>
  );
}

export default MainRouter;
