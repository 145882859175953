import React, { useEffect, useState, useMemo } from "react";
import "./activities.css"
import SummaryCard from "./summaryCard";
import axios from "axios";
import activitiesNotes from "../../../../assets/activitiesNotes.svg"
import { Select } from "antd";
import { Timeline } from "antd/lib";
import CustomTimeline from "./timelineItem";
import CustomCarousel from "./customCarousel";
import NewCard from "./newCard";
import ResumeCandidate from "./resumeCandidates";
import CustomBarChart from "./customBarChart";
import { getVacancyActivities } from "../../Action/action";
import { useActivitiesStore } from "../../store/store";
import { useParams, useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from 'dayjs'

const ActivitiesPage = ()=>{

  const {id} = useParams();
  const location = useLocation();

    //const {activitiesData, setActivitiesData}  = useActivitiesStore(state=>state);
    const [activitiesData,setActivitiesData] = useState({});
    const [selectedOption, setSelectedOption] = useState({ month: "", year: "" });
    const [page,setPage] = useState();
    const [limit,setLimit] = useState();
    const [loadmore,setLoadmore] = useState(false);
    const [dateValue, setDateValue] = useState(dayjs().format("YYYY-MM"));
    const [tempActivitiesData, setTempActivitiesData] = useState({});
    const [type,setType] = useState('');

    const handleChange = (date) => {
      console.log(date?.month,"datepicker")
      if (date) {
        console.log(1,"datepicker")
        setSelectedOption({
          month: date?.month() +1, // Month is 0-indexed
          year: date?.year(),
        });
        
       
      } else {
        console.log(2,"datepicker")
        setSelectedOption((prev) => ({
          ...prev, // Spread to create a new object reference
          month: "",
          year: "",
        }));
        setDateValue('')
      }
    };

  const slides= [
       <NewCard/>,<ResumeCandidate/>
  ];

  const getVacancyActivitiesFunc = (params)=>{
    console.log(params,activitiesData,"checkactivities")
    getVacancyActivities(params,(response,error)=>{
      if (error) {
        console.error("Error geting activities:", error);
      } else {
        console.log("acticall")
        const resultKey = Object.keys(response.result)[0]; // Assuming "1-2025" is dynamic
        const newActivities = response.result[resultKey]?.activities || [];
        const loadMore = response.result[resultKey]?.loadMore || false;
        const page = response.result[resultKey]?.page || 1;
        setActivitiesData({
          ...activitiesData,
         ... response?.result,
          [resultKey]: {
            activities: [
              ...(activitiesData[resultKey]?.activities || []), // Preserve existing activities
              ...newActivities, // Append new activities
            ],
            loadMore, // Update loadMore
            page, // Update page
          },

        })
       
      }
    })
  }


  
  useEffect(()=>{
    
    console.log("checkactivities",typeof selectedOption?.year)
    console.log("inuseffact",selectedOption)
    if(selectedOption?.year !==""){
      console.log("inuseffact")
      console.log("checkactivities",typeof selectedOption?.year)
      setDateValue(`${selectedOption?.year}-${String(Number(selectedOption?.month)).padStart(2, '0')}`);
    }
    let params = {
        jobId: id,
        year: selectedOption?.year,
        month: selectedOption?.month,
        page: page,
        limit: limit,
    }
    console.log("checkactivities", params)
    getVacancyActivitiesFunc(params)
  },[selectedOption?.year,page, selectedOption?.month])

  const formattedData = useMemo(() => {
   
    const data = {};
    for (const [key, value] of Object.entries(activitiesData)) {
      const [month, year] = key.split('-');
      const date = new Date(year, month - 1);
      const formattedDate = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
      data[formattedDate] = value || [];
    }
    console.log(data,"datepicker")
    return data;
  }, [activitiesData]);
  

  const sections = useMemo(() => {
    if ((!selectedOption.year || !selectedOption.month) && Object.keys(formattedData).length > 0) {
    
      return Object.entries(formattedData).map(([key, value]) => ({
        label: key,
        data: value,
      }));
    }
  
    const formattedKey = new Date(selectedOption.year, selectedOption.month - 1).toLocaleString('en-US', {
      month: 'long',
      year: 'numeric',
    });
  
    return Object.entries(formattedData)
      .filter(([key]) => key === formattedKey) // Include only the matching entry
      .map(([key, value]) => ({
        label: key,
        data: value,
      }));
  }, [formattedData, selectedOption]);
  
  

  console.log(sections,"datepicker")
 
  
    return(
 
                <div className="activity-subcontainer">
                    <div className="activity-subcontainer-subcontainer">
                        <div>
                            <div>
                                <img src={activitiesNotes} alt="notesIcon"/>
                                <div >Activities</div>
                            </div>
                            <div>
                                Jump to:
                             
                                   <DatePicker
                                      picker="month"
                                      onChange={handleChange}
                                      //value={dateValue? dayjs(dateValue,"YYYY-MM"):null}
                                      //defaultValue={dateValue? dayjs(dateValue,"YYYY-MM"):null}
                                      placeholder="Select Month and Year"
                                      style={{width:'200px'}}
                                     
                                    />
                             
                            </div>
                        </div>

                    <div>
                        
                            {sections.map((section, index) => (
                                <div key={index} className="activities-timeline">
                                    <div>{section.label}</div>
                                    <CustomTimeline 
                                        options={section?.data}
                                        onLoadMore={async (nextPage) => {
                                          
                                              const date = new Date(section?.label);
                                              console.log("checkactivities")
                                              setLoadmore(true)
                                              setTempActivitiesData((prev) => ({
                                                // ...prev,           // Preserve existing `tempActivitiesData`
                                                ...activitiesData, // Merge `activitiesData` into `tempActivitiesData`
                                              }));
                                             
                                              console.log(activitiesData,tempActivitiesData,"checkingtempact")
                                              setSelectedOption((prev) => ({
                                                ...prev, // Spread to create a new object reference
                                                month: date.getMonth() + 1,
                                                year: date.getFullYear(),
                                              }));
                                              setPage(section?.data?.page+1)
                                              setLimit(5)
                                              
                                              
                                          }}
                                          type="activities"
                                    />
                                </div>
                            ))}
                            {/* </>
                            } */}
                     </div>  
                        
                    </div>
                    <div>
                        <SummaryCard/>
                       <div style={{width:'400px'}}> <CustomCarousel slides={slides}/></div>
                    
                    </div>
                  
                </div>
    
    );

}

export default ActivitiesPage