import { DownloadOutlined, DownOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Spin,Select, Progress } from "antd";
import { useEffect, useRef, useState } from "react"
import { DropDownCell } from "../../../components/Table/Table";
import Divider from "../../../components/divider/Divider";
import uploadFile from '../../../assets/icons/uploadFile.svg'
import './styles.css'
import { message, Upload } from 'antd';
import xlsImg from '../../../assets/icons/excel.svg'
import { emailCheck, getActiveJobs, getExcel, postBulkCandidateApplications } from "../Action/action";
import { useApplicationManagementStore } from "../store/store";
import { useParams } from "react-router-dom";
import backendUrl from "../../../service/credentials";
const { Dragger } = Upload;

const { Search } = Input;

const BulkUploadModal=()=>{

    const [step,setStep]=useState(-1);
    const [isLoading,setIsLoading]=useState(false);
    const [isValid,setIsValid]=useState(false);
    const [formData,setFormData]=useState({});
    const [isUploading,setIsUploading]=useState(false);
    const [resume,setResume]=useState();
    const [fileList, setFileList] = useState([]);
    const [progress,setProgress]=useState(-1)
    const [jobOptions,setJobOptions]=useState([])
    const [form] = Form.useForm();
    const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering options
   const {jobId}=useParams();

  const {openBulkUploadModal,setOpenBulkUploadModal,setRefresh,refresh}=useApplicationManagementStore(state=>state)
    const handleAddCandidate=()=>{

        setIsLoading(true);
        form.submit();
    }


    useEffect(()=>{
      getActiveJobs({},(res,err)=>{
        if(res?.job){
          console.log("jobs",res)
          setJobOptions(res?.job?.filter((job)=>job?.id&&job?.name)?.map((job)=>{
            return {
              key:job?.name,
              value:job?.id,
              jobCode:job?.jobCode
            }
          }))
          const findItem=res?.job?.find(item=>item.id==jobId);
          if(findItem){
          form.setFieldsValue({"jobId":{label:findItem?.name+""+(findItem?.jobCode||'-'),value:findItem?.id}})
          }
        }
  
      })
    },[jobId,openBulkUploadModal])

    const handleSearch = (term) => {
        setSearchTerm(term);
      };

      useEffect(()=>{
        if(progress==100){
            setTimeout(()=>{
                setProgress(-1)
            },500)
          
        }

      },[progress])

      // const handleOnChange=async (changedValues, allValues)=>{
      //   const fieldName = Object.keys(changedValues)[0]; // Get the first key (name of the field)
      //   const fieldValue = changedValues[fieldName]; // Get the value of the changed field
    
      //   try {
      //       // Validate all fields
      //       await form.validateFields();
      //       setIsValid(true)
          
      //     } catch (error) {
      //       console.log('There are invalid inputs:', error);
      //       if(error?.errorFields?.length==0)
      //       setIsValid(true)
      //       else
      //       setIsValid(false)
      //       // Optional: Display errors if needed
      //     }
    
      //   // Log all current form values
      //   console.log('All Form Values:', allValues);

      // }
      const handleOnChange = async (changedValues, allValues) => {
        const fieldName = Object.keys(changedValues)[0]; // Get the first key (name of the field)
        const fieldValue = changedValues[fieldName]; // Get the value of the changed field
    
        try {
            // Validate only the changed field
            await form.validateFields([fieldName]);
            // setIsValid(true);
        } catch (error) {
            console.log('Error in the changed field:', error);
            if(error?.errorFields?.length!=0){
              setIsValid(false);
              return 
            }
             
                
         
            // Optionally handle specific field errors here if needed
        }
    
        // Validate other fields silently without marking them in the form UI
        const fieldsToCheck = Object.keys(allValues).filter((key) => key !== fieldName);
        const errors = form.getFieldsError(fieldsToCheck).filter(({ errors }) => errors.length > 0);
          if(fileList.length==0 || !allValues.jobId || !allValues.file ){
            setIsValid(false);
            return 
          }
     
        if (errors.length === 0) {
          
            setIsValid(true);
        } else {
            console.log("Errors in other fields:", errors);
            setIsValid(false);
        }
        // Log all current form values
        console.log('All Form Values:', allValues, fileList);
    };
    


    const filteredOptions = jobOptions.filter((option) =>
        option?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase())||option?.jobCode?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );

      const onPreview = async (file) => {
        let src = file.url;
        const fileName = file.name || file.originFileObj?.name; // Get the original file name
        console.log("file",file,fileList)
        // If no URL is provided, create one from the file object
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
          });
        }
      
        // Determine file type
        const fileType = file.type || file.originFileObj?.type || '';
      
        const fileWindow = window.open();
        
        if (fileType.startsWith('image/')) {
          // Handle image preview
          const image = new Image();
          image.src = src;
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(image.outerHTML);
        } else if (fileType === 'application/pdf') {
          // Handle PDF preview
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<iframe src="${src}" width="100%" height="100%"></iframe>`);
        } else if (fileType.startsWith('text/')) {
          // Handle text file preview
          const response = await fetch(src);
          const textContent = await response.text();
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<pre>${textContent}</pre>`);
        } else {
          // Handle other document types (fallback)
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<a href="${src}" target="_blank">Download File</a>`);
        }
      };
      
      


      const props = {
        name: 'file',
        multiple: false,
        onPreview:onPreview,
         action:backendUrl+'/api/hiring/upload',
         
        onChange(info) {
          
     
          const { status } = info.file;

          const newProgress=progress+Math.floor(Math.random()*5);
          if(newProgress<=98){
           setProgress(newProgress)
         }else{
           setProgress(progress)
         }

          if (status !== 'uploading') {
           
          
          }
       
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            info.file?setResume(info.file): setResume()
            setIsUploading(false)
            setProgress(100)
            
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setResume();
            setIsUploading(false);
            setFileList([]);
            setProgress(-1);
           
          }

        },
        beforeUpload(file,fileList){
            const allowedFormats = [
                'application/vnd.ms-excel', // XLS
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      
            const isAllowed = allowedFormats.includes(file.type);
            console.log("file--list",file,fileList)
            const isAllowedSize = file.size / 1024 / 1024 <= 10; // Convert size to MB
            
            if (!isAllowed || !isAllowedSize) {
            
              !isAllowed?message.error('You can only upload XLS or XLSX files!')
               : message.error(`${file.name} is larger than 10MB. Please select a smaller file.`);
               setResume();
               setFileList([]);
               setIsValid(false);
            }
            else{
              setIsUploading(true);
              setFileList(fileList)
            }
            setProgress(0)
            return isAllowed || Upload.LIST_IGNORE;
            },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };
   
      const SearchComp=()=>{
      return   <Search
        allowClear
         placeholder="Search"
         value={searchTerm}
         onChange={(e)=>{
           e.preventDefault()
           if(e.target.value===' '){
             handleSearch("");
           }else
             handleSearch(e.target.value)
         }}
         onClear={()=>{
           handleSearch("")
         }}
         onSearch={(value)=>{
           handleSearch(value?.trim())
         }}
       style={{width:'100%',padding:'10px'}}
   
       />
      }

      const normFile = (e) => {
        console.log("after upload",fileList.length)
        if (Array.isArray(e)) {
           
             if(isAllowed)
                return e
            return [];
        }
     
        const allowedFormats = [
            'application/vnd.ms-excel', // XLS
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','video/mp4'];
     const  isAllowed = allowedFormats.includes(e?.fileList?.[0]?.type) && fileList.length!==0;
     
           
        return isAllowed?e&&e.fileList:[] ;
    };
    
    

    return(
        <Modal
         className="bulk-upload-modal"
         centered
        title={<div>
              <p className="modal-title">Upload in bulk</p>
            <p className="modal-sub">Save time by uploading candidate data from an Excel file.</p>
        </div>}
        footer={
            <div className="footer-wrapper">
            <div>
           
          </div>
          <div className="footer-submit">
          <button onClick={()=>{
                setOpenBulkUploadModal(false)
            }} className="modal-cancel">
            Cancel
          </button>

          {
          !isLoading?  <button onClick={()=>{
            if(!isUploading && isValid){
                handleAddCandidate()
            }
          }} className={`modal-done ${!isUploading && isValid?'':'diable'}`}  >
          Update
          </button>:
            <button className="modal-done" style={{color:'#ffffff',background:'#1890FF'}}>  Update  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          }

          </div>
         

            </div>

          

        }
       
        open={openBulkUploadModal}
        onCancel={() =>  setOpenBulkUploadModal(false)}
      >
       
       <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onValuesChange={handleOnChange}
       onFinish={values=>{
       
            if(values.file){
              values.file=values?.file?.[0]?.originFileObj

            }
            if(values?.jobId?.label){
             values.jobId=values.jobId.value
             }
         
            setIsLoading(true);
            postBulkCandidateApplications(values,(res,err)=>{
                  setIsLoading(false);
                  setOpenBulkUploadModal(false)
                  setRefresh(!refresh);
                })
              
       }}
       >
     
 
      <Form.Item
        name="jobId"
        label="Position applied for"
        rules={[
          {
            required: true,
            message:'Please choose a related job!'
          },
        ]}
      
        initialValue={jobId}
      >

<Select
  allowClear
     
      placeholder="Select position applied for"
      for="jobId"
      disabled={jobId}
      dropdownRender={(menu) => (
        <>
             
            <div className="search-input"> 
            { (
              <>
     <Form.Item style={{margin:0}}>
        {SearchComp()}
        </Form.Item>
          <Divider/>
          </>
                )}
            </div>
    
             {menu}

           
           
        </>
      )}
      options={filteredOptions.map((item) => ({
        label: item.key+" - "+(item?.jobCode||'NA'),
        value: item.value,
      }))}

   

      />
     
      
   
      </Form.Item>
      <Form.Item
        name="file"
        valuePropName="list"
        getValueFromEvent={normFile}
        style={{marginBottom:0}}
        rules={[
          {
            required: true,
            validator: async (_, value) => {
        if (!value ||(value && Array.isArray(value) && value.length==0)|| fileList.length===0) {
          return Promise.reject(new Error("Please upload Excel Template!"));
        }
      }
          }
        ]}
      
      >
      <>
      <Dragger {...props} 
      className="upload-resume-drag"
       maxCount={1}
      fileList={fileList}
       onRemove={()=>{
         setIsUploading(false)
            setResume();
            setFileList([]);
          
      }}
      
      >
    <p className="upload-drag-icon">
     <img src={uploadFile}></img>
    </p>
    <p className="upload-text">Drag and Drop file here or <span>Choose file</span></p>
    <p className="upload-hint">
    Supported formats: XLS, XLSX (maximum size: 10MB)
    </p>
  </Dragger>

  </>
  </Form.Item>
  {fileList?.length>0 && progress>=0?
    <Progress percent={progress}  />:
    null}

    </Form>

    <div className="download-template" style={{maxHeight:'60px',maxWidth:'fit-content',marginTop:'30px'}}>
                  <div className="excel-div">
                    <div>
                    <img src={xlsImg}></img> 
                  <span>Excel Template</span>
                  </div>
                    <div>
                    Download and edit attached template as a foundation for your file.
                    </div>
                  </div>
                  <div className="template-div" onClick={()=>{
                    getExcel({},(res,err)=>{
                   
                      if (res.file) {
                        // Create a temporary anchor element
                        const link = document.createElement('a');
                        link.href = res.file; // Create a download URL
                        // Append the anchor to the document, trigger the download, and then remove it
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        message.success('Template downloaded successfully!');
                        // Revoke the object URL to free up memory
                        URL.revokeObjectURL(link.href);
                    } else {
                        console.error("No file found in the response");
                        message.error('Unable to download template!');
                        return
                    }

                    })
                    
                  }}>
                    <DownloadOutlined/>
                    <span>Download</span>
                  </div>

                 </div>
      

    
      </Modal>
    )
}

export default BulkUploadModal