import React from "react";
import "./details.css"

const Details = ({name,imgSrc})=>{
    return(
        <div className="details-header">
            <img src={imgSrc}/>
            <div>{name}</div>
        </div>
    );

};

export default Details