import React, { useEffect, useState } from "react";
import { Typography } from "antd";

const { Paragraph } = Typography;

const ReadMoreText = ({ text, maxLength = 100,style={}, readBtnStyle={} }) => {
  
    const [expanded, setExpanded] = useState(false);
    useEffect(()=>{
     setExpanded(text.length<maxLength?true:false)
    },[text])

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <div style={{display:"flex",gap:0,minHeight:'10px'}}>
        <div>
      {expanded ? (
        <Paragraph style={{ ...style, marginBottom: 0 }}>{text}</Paragraph>
      ) : (
        <Paragraph style={{ ...style, marginBottom: 0 }} ellipsis={{ rows: 2, expandable: false }}>{text.slice(0, maxLength)}...</Paragraph>
      )}
      {text.length>maxLength&&<div onClick={toggleExpanded} style={readBtnStyle?readBtnStyle:{ color: "#1890FF", cursor: "pointer",fontWeight:600,lineHeight:1.5 }}>
        {expanded ? "Read Less" : "Read More"}
      </div>
}
      </div>
      
    </div>
  );
};
export default ReadMoreText