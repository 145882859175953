import React, { useEffect, useState } from "react";
import CustomModal from "../../hiring/customModal.js"
import "../../hiring/createJD.css"
import CustomButton from "../../hiring/customButton.js";
import Header from "../../../landing/header.js";
import design from "../../../assets/backgrounddesign.svg"
import Generate from "../../../assets/generateIcon.svg"
import { Input, Select, AutoComplete } from "antd";
import TextArea from "antd/lib/input/TextArea.js";
import { useResumeScreeningStore, useStore } from "../../hiring/store/store.js";
import { useNavigate } from 'react-router-dom';
import "./mainPage.css"
import ClickToUpload from "./clickToUpload.js";
import { generateJd } from "../../vacancies/Action/action.js";
import parse from 'html-react-parser';
import { useVacancyStore } from "../../vacancies/store/store.js";


const ResumeScreening = () =>{

    const {analyseModal, setAnalyseModal,
      jobTitle,setJobTitle,
      uploadedFile,setUploadedFile,
      jobDescription,setJobDescription,
      yearsOfExperience,setYearsOfExperience,
      qualification,setQualification,
    } = useResumeScreeningStore(state=>state)
    const {setProgress, setShowExperince} = useStore(state=>state);
    // const [jobTitle, setJobTitle] = useState("");
    // const [yearsOfExperience, setYearsOfExperience] = useState("");
    // const [uploadedFile, setUploadedFile] = useState(null);
    // const [jobDescription, setJobDescription] = useState("");
    const navigate = useNavigate();

    const jobTitles = ['Assistant Manager', 'Accountant', 'Associate', 'Associate Consultant', 'Analyst', 'Area Sales Manager', 'Account Assistant', 'Assistant Professor', 'Assistant Consultant', 'Associate Manager', 'Associate Software Engineer', 'Assistant Engineer', 'Administration Executive', 'Assistant Vice President', 
      'Area Manager', 'Automation Test Engineer', 'Assistant General Manager', 'Application Development Analyst', 'Assistant Sales Manager', 'Assistant System Engineer', 'Accounts Officer', 'Account Executive', 'Associate Vice President', 'Architect',
      'Associate Engineer', 'Associate Director', 'Advocate', 'Area Business Manager', 'Administration Manager', 'Assistant Store Manager', 'Application Developer', 'Automation Engineer', 'Area Sales Executive', 'Associate Analyst',
      'Assistant Teacher', 'Associate Technical Consultant', 'Assistant Accountant', 'Application Support Engineer', 'Android Developer', 'Accounting Staff', 'Account Manager', 'Audit Assistant', 'Advisor', 'Application Engineer', 'Assistant Project Manager', 'Associate Professor', 'Assistant Branch Manager', 'Administrator', 'Audit Executive', 'Article Assistant', 'Business Development Manager', 'Business Analyst', 'Branch Manager', 'Business Development Executive', 
      'Back Office Executive', 'Business Development Associate', 'Branch Head', 'Business Manager', 'Branch Sales Manager', 'Billing Executive', 'Business Head', 'Back Office Staff', 'Banking Officer', 'BPO Executive', 'Business Process Lead', 'Business Associate', 'Back Office Assistant', 'Branch Credit Manager', 'Business Executive',
      'Business Development Officer', 'Business Consultant', 'Brand Manager', 'Branch Relationship Executive', 'Back Office Computer Operator', 'Backup Administrator', 'Billing Engineer', 'Banking Executive', 'Branch Incharge', 'Big Data Engineer', 'Back Office Coordinator', 'Biomedical Engineer', 'B Tech Student', 'Beautician', 'Business Process Associate', 'Big Data Developer', 'Beauty Advisor', 'Banker', 'Business System Analyst',
      'Buyer', 'Business Owner', 'BPO Associate', 'Branch Relationship Manager', 'Bartender', 'B Tech Fresher', 'Biology Teacher', 'Branch Operations Manager', 'Business Partner', 'Business Operations Associate', 'Bank Teller', 'Bench Sales Recruiter', 'Consultant', 'Civil Site Engineer', 'Customer Service Executive', 
      'Customer Care Executive', 'Computer Operator', 'Civil Engineer', 'Customer Service Associate', 'Customer Support Executive', 'Car Driver', 'Cashier', 'Credit Manager', 'Chemist', 'Content Writer', 'Collection Executive', 'Company Secretary', 'Customer Support Engineer', 'Customer Relationship Manager', 'CNC Operator', 'Cloud Engineer', 'Chartered Accountant', 'Collection Officer', 'Chief Manager', 'Coordinator', 
      'Cluster Manager', 'Customer Service Representative', 'Computer Teacher', 'Civil Supervisor', 'Credit Officer', 'Customer Service Manager', 'Counsellor', 'Customer Service Officer', 'Clerk', 'CNC Machine Operator', 'Construction Manager', 'Center Manager', 'Commis Chef', 'Chief Executive Officer', 'Claims Associate', 'Chief Financial Officer', 'Call Center Executive', 'Customer Support Associate', 'Chef de Partie', 'Cluster Head', 
      'Customer Service Advisor', 'Commercial Executive', 'Cyber Security Analyst', 'Credit Analyst', 'CRM Executive', 'Civil Foreman', 'Civil Site Supervisor', 'Deputy Manager', 'Data Entry Operator', 'Design Engineer', 'Data Analyst', 'DevOps Engineer', 'Driver', 'Desktop Support Engineer', 
      'Deputy General Manager', 'Data Engineer', 'Director', 'Data Scientist', 'Digital Marketing Executive', 'Developer', 'Diploma Mechanical Engineer', 'Diploma Engineer Trainee', 'Department Manager', 'Data Entry Executive', 'Digital Marketing Manager', 'Delivery Manager', 'Doctor', 'Delivery Boy', 'Diploma Electrical Engineer', 'Deputy Branch Manager', 'Diploma Civil Engineer', 'Designer', 'Dispatch Executive', 'Dentist', 'Dot Net Developer',
      'Document Controller', 'Database Administrator', 'Dental Surgeon', 'Documentation Executive', 'Dialysis Technician', 'Data Operator', 'Drug Safety Associate', 'Dietician', 'Deputy Project Manager', 'Duty Doctor', 'Duty Manager', 'Digital Marketing Analyst', 'Deputy Vice President', 'Digital Marketer', 'Deputy Engineer', 'Deputy Manager Grade 2', 'Direct Marketing Executive', 'Digital Marketing Specialist', 'Dispatch Officer', 'Divisional Manager',
      'Deputy Manager Grade 1', 'District Manager', 'Executive', 'Engineer', 'Electrical Engineer', 'Electrician', 'Executive Assistant', 'Electrical Technician', 'Electrical Supervisor', 'Executive Engineer', 'Engineering Manager', 'English Teacher', 'Electrical Site Engineer', 'Embedded Software Engineer', 'Electrical Design Engineer', 'ETL Developer', 'ETL Tester', 'Electrical Project Engineer', 'Electronics Engineer', 'Export Executive', 'Education Counsellor', 
      'Executive Manager', 'Executive Chef', 'Engineering Lead', 'Engineering Student', 'Entrepreneur', 'E Commerce Manager', 'Employee Relations Executive', 'Equity Dealer', 'Executive Secretary', 'Editor', 'Electrical Maintenance Technician', 'Executive Officer', 'Executive Trainee', 'Embedded Engineer', 'Embedded Software Developer', 'ETL Test Engineer', 'Educator', 'Estimation Engineer', 'Engineer Trainee', 'Export Manager', 'Executive Director', 'Electrical Maintenance Supervisor', 
      'Email Support Executive', 'English Lecturer', 'Equity Research Analyst', 'Engagement Manager', 'Executive Relationship Manager', 'Executive Army', 'Employee Relations Manager', 'Electrical Foreman', 'Embedded Developer', 'Fresher', 'Financial Analyst', 'Finance Executive', 'Front Office Executive', 'Front End Developer', 'Finance Manager', 'Freelancer', 'Field Executive', 'Field Officer', 'Field Engineer', 'Front Office Receptionist', 'Fashion Designer', 'Functional Consultant', 
      'Facility Manager', 'Field Service Engineer', 'Fitter', 'Floor Manager', 'Financial Controller', 'Facility Executive', 'Fashion Consultant', 'Front Office Manager', 'Freelance Content Writer', 'Faculty', 'Founder', 'Fireman', 'Financial Associate', 'Financial Accountant', 'Factory Manager', 'Forklift Operator', 'Financial Advisor', 'Fresher Graduate', 'Freelance Consultant', 'Field Sales Executive', 'Finance Assistant', 'Field Sales Officer', 'Fraud Analyst', 'Field Operator', 
      'Floor Supervisor', 'Foreman', 'Financial Consultant', 'Front Office Associate', 'Freelance Graphic Designer', 'Fire Officer', 'Front Office Assistant', 'Field Boy', 'Flutter Developer', 'Finance Head', 'Finance Officer', 'Finance Specialist', 'Field Supervisor', 'Graphic Designer', 'General Manager', 'General Manager Operations', 'GIS Engineer', 'Guest Service Associate', 'Graduate Engineer', 'Group Manager', 'Graduate Trainee', 'General Manager Sales', 'Guest Relation Executive',
      'General Dentist', 'GNM Staff Nurse', 'GIS Analyst', 'Graduate Apprenticeship Trainee', 'General Manager Business Development', 'Guest Lecturer', 'Gold Loan Officer', 'Good Worker', 'General Accounting Ops Associate', 'General Manager Marketing', 'Growth Officer', 'General Practitioner', 'Guest Faculty', 'Garment Merchandiser', 'Ground Staff', 'General Manager Finance', 'Gym Trainer', 'GIS Executive', 'Gold Loan Executive', 'Godown Incharge', 'Group Product Manager', 'Geologist', 
      'Graduate Apprentice', 'General Manager Administration', 'Gold Appraiser', 'Group Project Manager', 'General Manager Manufacturing', 'General Manager Engineering', 'General Physician', 'General Manager Project', 'Group Head', 'German Language Expert', 'Game Developer', 'Graphic Designer Trainee', 'General Manager Production', 'Government Apprentice', 'Group Lead', 'Group Coordinator', 'Group Chief Financial Officer', 'General Accountant', 'HR Assistant', 'Hospital Pharmacist', 'Housekeeping Supervisor', 
      'Head Operations', 'Helper', 'HR Executive and Recruiter', 'Head Cashier', 'HVAC Technician', 'Home Tutor', 'Hindi Teacher', 'Highway Engineer', 'Hotel Manager', 'HSE Officer', 'Hardware Engineer', 'Heavy Driver', 'HVAC Engineer', 'HR Executive and Generalist', 'Hadoop Administrator', 'Human Resource Executive', 'Housekeeping Executive', 'Human Resource Manager', 'Hub Incharge', 'HR and Administration Team Member', 'Hardware Design Engineer', 'Hub Manager', 'HSE Engineer', 'Hospital Administrator', 
      'Help Desk Executive', 'HSE Manager', 'Hadoop Developer', 'Hotel Supervisor', 'HR Manager 1', 'HVAC Supervisor', 'Head Business Development', 'Head Chef', 'Hostel Warden', 'HVAC Design Engineer', 'Housekeeping Manager', 'Housekeeping Assistant', 'Homeopathic Doctor', 'HVAC Site Engineer', 'HR Executive and Team Lead', 'Head', 'Housekeeper', 'Heavy Truck Driver', 'Housekeeping Incharge', 'Home Maker', 'Home Loan Executive', 'HK Supervisor', 'Hospitality Manager', 'IT Recruiter', 'Interior Designer', 
      'ITI Fitter', 'Intern', 'IT Executive', 'IT Manager', 'ITI Electrician', 'Instrumentation Engineer', 'Internal Auditor', 'Internship Trainee', 'IT Support Engineer', 'IT Analyst C2', 'Informatica Developer', 'Information Security Analyst', 'Information Technology Analyst', 'Inspection Engineer', 'Inside Sales Executive', 'ITI Welder', 'Insurance Advisor', 'Industrial Engineer', 'IT Administrator', 'iOS Developer', 'IT Head', 'Incident Manager', 'Inventory Executive', 'Incharge', 'Insurance Executive',
      'Inventory Manager', 'Independent Consultant', 'Instructional Designer', 'Inside Sales Manager', 'IT Officer', 'Informatica Administrator', 'Indian Cook', 'Implementation Engineer', 'Import Executive', 'IT Support Executive', 'iOS Application Developer', 'IELTS Trainer', 'Instrumentation Technician', 'Implementation Consultant', 'IT Assistant', 'Instructor', 'Infrastructure Engineer', 'Indian Chef', 'Information Process Enabler', 'Inside Sales Specialist', 'Image Editor', 'ICU Staff Nurse', 'IT Fresher',
      'Junior Engineer', 'Java Developer', 'Junior Executive', 'Junior Accountant', 'Junior Officer', 'Junior Software Developer', 'Junior Manager', 'Junior Software Engineer', 'Junior Architect', 'Junior Associate', 'Junior Resident Doctor', 'Junior Advocate', 'Junior Assistant', 'Junior Engineer 1','Junior Relationship Executive', 'Junior Design Engineer','Junior Accounts Executive', 'Junior Data Analyst', 'Junior Research Fellow','Junior Analyst', 'Junior Consultant', 'Junior Developer', 'Junior Chemist', 
      'Junior Accounts Officer', 'Junior Supervisor', 'Junior Quality Engineer','JIRA Administrator', 'Junior Technician', 'Job Searcher', 'Junior Research Associate', 'Job Trainee', 'Junior Test Engineer', 'Junior Web Developer', 'Java Full Stack Developer', 'Junior Java Developer', 'Junior Merchandiser', 'Junior Process Associate', 'Junior Accounts Assistant', 'Junior Shop Manager', 'Junior Site Engineer', 'Java Software Developer', 'Junior Production Officer', 'Junior Clerk', 
      'Java Fresher', 'Junior Executive Officer', 'Journalist', 'Java and J2EE Developer', 'Junior Lecturer', 'Junior Research Analyst', 'Junior Software Tester', 'Key Accounts Manager', 'KYC Analyst', 'Key Accounts Executive', 'Key Relationship Manager', 'KYC Executive', 'Kindergarten Teacher', 'KPO Officer', 'KYC Specialist', 'KYC Associate', 'Kannada Teacher', 'Kitchen Helper', 'Kitchen Staff', 'Key Accounts Officer', 'Kitchen Supervisor', 'Knowledge Manager',
      'Key Accounts Business Manager', 'Kitchen Manager', 'Knowledge Management Associate', 'Kendra Manager', 'Knowledge Associate', 'Key Accounts and Client Servicing Manager', 'Knowledge Specialist', 'Kitchen Assistant','Kitchen Executive', 'Kg Teacher', 'Knowledge Analyst', 'Kitchen Incharge', 'Key Accounts Specialist', 'Knowledge Engineer', 'Kubernetes Administrator', 'Kafka Administrator', 'Key Accounts and Ecommerce Manager', 'Key Operator', 'Knowledge Management Analyst', 'Kronos Consultant', 'Kitchen Designer', 
      'Kitchen Stewarding Supervisor', 'Key Client Manager', 'Knowledge Executive', 'Key Business Manager', 'Knowledge Management Specialist', 'Kofax Developer', 'KST Supervisor', 'Kitchen Stewarding Manager', 'Kitchen Stewarding Executive', 'Key Accounts Lead', 'Key Operations Manager', 'Knowledge Management Consultant', 'Knitting Manager', 'Kitchen Steward', 'Lead Engineer', 'Lab Technician', 'Lead Consultant', 'Lecturer', 'Logistics Executive', 'Lead Software Engineer', 'Linux Administrator', 'Linux System Administrator',
       'Legal Associate', 'Lead', 'Lab Assistant', 'Logistics Manager', 'Legal Manager', 'Land Surveyor', 'Lawyer', 'Librarian', 'Lead Administrator', 'Legal Executive', 'Logistics Officer', 'Line Leader', 'Loan Officer', 'Lead Developer', 'Legal Advisor', 'Legal Officer', 'Lab Incharge', 'Laboratory Chemist', 'Laboratory Technologist', 'Legal Consultant', 'Lead Operations', 'Logistics Supervisor', 'Line Supervisor', 'Logistics Incharge', 'Lead Design Engineer', 'LMV Driver', 'Line Engineer', 'Legal Assistant', 'Lead Data Scientist', 
       'Lead Recruiter', 'Linux Server Administrator', 'Lead Generation Executive', 'Lead Data Engineer', 'Lift Technician', 'Loan Executive', 'Light Driver', 'Lead Quality Engineer', 'Lab Technician Incharge', 'Line Incharge', 'Line Operator', 'Law Officer', 'Logistics Associate', 'Manager', 'Mechanical Engineer', 'Marketing Executive', 'Marketing Manager', 'MIS Executive', 'Maintenance Engineer', 'Management Trainee', 'Medical Representative', 'Module Lead', 'Machine Operator', 'Medical Officer', 'Mechanical Maintenance Engineer', 
       'Merchandiser', 'Medical Coder', 'Mechanical Technician', 'Manual Test Engineer', 'Maintenance Fitter', 'Maintenance Manager', 'Marketing Officer', 'Maths Teacher', 'Maintenance Supervisor', 'Member Technical Staff', 'Managing Director', 'Microbiologist', 'Manual Tester', 'Marketing Head', 'Mainframe Developer', 'Mechanical Supervisor', 'Manufacturing Engineer', 'Medical Sales Representative', 'Mechanical Design Engineer', 'Managing Partner', 'Manager M1', 'MEP Engineer', 'Management Executive', 'Mulesoft Developer', 'Mechanical Fitter',
        'Management Consultant', 'Makeup Artist', 'Middleware Administrator', 'Marketing Specialist', 'Machine Learning Engineer', 'Mobile Application Developer', 'MIS Analyst', 'Music Teacher', 'Maintenance Electrician', 'Maintenance Executive', 'Medical Billing Executive', 'Market Research Analyst', 'MIS Coordinator', 'Network Engineer', 'Network Administrator', 'Nursing Staff', 'Network Support Engineer', 'Network Security Engineer', 'NOC Engineer', 'Nurse', 'National Sales Manager', 'New Product Development Engineer', 'Nursing Officer',
         'Nursery Teacher', 'Nursing Tutor', 'NPD Engineer', 'Nursing Assistant', 'Nutritionist', 'Netwrok Engineer', 'Network Analyst', 'Nursing Supervisor', 'Neem Trainee', 'NDT Technician', 'Network Specialist', 'Network Engineer L1 L2', 'Nursing Superintendent', 'Network Manager', 'Network Field Engineer', 'Non IT Recruiter', 'Nursing Incharge', 'NDT Engineer', 'NDT Inspector', 'Network Consultant', 'News Reporter', 'Nurse 1', 'National Key Account Manager', 'Nodal Officer', 'Network Associate', 'Network Operations Engineer', 
         'Non Commissioned Officer', 'News Anchor', 'Network Engineer L2', 'Network Planning Engineer', 'Network Lead', 'Nutrition Officer', 'NRI Relationship Manager', 'Network Security Administrator', 'Node Js Developer', 'National Account Manager', 'New Associate', 'NDT Multi Technician', 'Network Engineer 2', 'National Manager', 'Operations Manager', 'Officer', 'Office Assistant', 'Operator', 'Office Boy', 'Oracle Database Administrator', 'Owner', 'Office Executive', 'Operations Analyst', 'Optometrist', 'Operations Executive', 'Office Administrator', 
         'Oracle Financial Functional Consultant', 'Office Coordinator', 'Online Tutor', 'Operating Engineer', 'Office Assistant & Receptionist', 'Office Manager', 'Office Clerk', 'Operations Associate', 'Operations Specialist', 'Operating Manager', 'Oracle Technical Consultant', 'Office Staff', 'Office Assistant & Accountant', 'Operations Team Member', 'Officer Worker', 'Operation Theatre Technician', 'Online Data Entry Operator', 'Office Supervisor', 'Office Peon', 'Oracle Application Database Administrator', 'Order Management Specialist', 'Operations Coordinator', 
         'Oracle SCM Functional Consultant', 'Oracle Functional Consultant', 'Oracle Application Technical Consultant', 'Operating Engineer Trainee', 'Online Marketing Executive', 'Oracle Developer', 'Office Incharge', 'Operations Lead', 'Office Worker', 'Operations Officer', 'Operations Administrator', 'Onboarding Executive', 'Outbound Executive', 'Office Superintendent', 'Officer Grade 1', 'Onboarding Specialist', 'Project Manager', 'Process Associate', 'Project Engineer', 'Production Engineer', 'Production Supervisor', 'Production Manager', 'Programmer Analyst', 
         'Product Manager', 'Pharmacist', 'Purchase Executive', 'Project Coordinator', 'Process Developer', 'Process Executive', 'Program Manager', 'Production Executive', 'Production Officer', 'Power BI Developer', 'Process Specialist', 'Purchase Manager', 'Principal Consultant', 'Process Engineer', 'Primary Teacher', 'Production Incharge', 'Planning Engineer', 'Project Lead', 'Proprietor', 'PLSQL Developer', 'Process Analyst', 'Purchase Engineer', 'Performance Test Engineer', 'Principal', 'Project Associate', 'Process Lead', 'Purchase Officer', 'Plant Operator', 
         'Principal Software Engineer', 'Partner', 'PHP Developer', 'Production Chemist', 'Product Owner', 'Project Manager Civil', 'Physiotherapist', 'Plant Head', 'Programmer', 'Process Expert', 'Programmer Analyst Trainee', 'Principal Engineer', 'Production Operator', 'Procurement Manager', 'Plant Manager', 'Quality Engineer', 'Quality Analyst', 'Quality Inspector', 'QA Engineer', 'Quality Controller', 'QA QC Engineer', 'QC Chemist', 'Quality Manager', 'Quality Executive', 'Quantity Surveyor', 'QA Analyst', 'QA Executive', 'QA Lead', 'QC Executive', 
         'Quality Assurance Officer', 'Quality Control Incharge', 'QA Tester', 'Quality Control Officer', 'QA Test Engineer', 'Quality Head', 'Quality Specialist', 'Quality Supervisor', 'Quality Assurance Analyst', 'Quality Officer', 'QC Inspector', 'QA QC Manager', 'Quality Control Engineer', 'Quality Checker', 'Quality Auditor', 'Quality Incharge', 'Quality Control Analyst', 'Quality Lead', 'Quality Associate', 'Quality Expert', 'Quality Control Executive', 'Quality Analyst Tester', 'QA QC Executive', 'QC Microbiologist', 'QlikView Developer', 'Quality Control Inspector',
        'Quality Test Engineer', 'QC Manager', 'QC Analyst', 'QA QC Inspector', 'Quality Assurance Tester', 'QA QC Chemist', 'Quality Assurance Lead', 'Quality Assurance and Quality Control Engineer', 'QA Test Lead', 'Quality Analyst 2', 'Relationship Manager', 'Relationship Officer', 'Regional Sales Manager', 'Research Associate', 'Receptionist', 'Research Analyst', 'Regional Manager', 'Relationship Executive', 'Restaurant Manager', 'Resident Medical Officer', 'Research Scientist', 'Retail Sales Executive', 'RPA Developer', 'Regional Business Manager', 'Radiographer',
        'Regional Head', 'Recruiter', 'Risk Analyst', 'Research Assistant', 'Retail Pharmacist', 'RF Engineer', 'Resident Engineer', 'Regional Head Sales', 'Research Development Engineer', 'Research Scholar', 'Restaurant General Manager', 'Retail Manager', 'Restaurant Captain', 'Retail Sales Officer', 'Researcher', 'Reporting Analyst', 'Recovery Officer', 'React Developer', 'Relationship Associate', 'Retail Sales Associate', 'Regional Credit Manager', 'R&D Engineer', 'Research Engineer', 'Real Estate Consultant', 'Retail Associate', 'Recovery Executive', 'Registered Nurse',
        'React Native Developer', 'Research Executive', 'Resource Manager', 'React Js Developer', 'Regional Service Manager', 'Risk Manager', 'Regional Business Head', 'Reservation Executive', 'Software Engineer', 'Senior Software Engineer', 'Software Developer', 'Sales Executive', 'Senior Executive', 'Software Test Engineer', 'Senior Associate', 'Sales Manager', 'Senior Manager', 'Senior Engineer', 'Senior Consultant', 'Senior Analyst', 'Sales Officer', 'System Engineer', 'Supervisor', 'Service Engineer', 'Site Engineer', 'Staff Nurse', 'Senior Software Developer', 'Senior Accountant',
        'Store Manager', 'System Administrator', 'School Teacher', 'Senior Process Associate', 'Senior Accounts Executive', 'Subject Matter Expert', 'Senior Test Engineer', 'Senior Sales Executive', 'Senior Officer', 'Safety Officer', 'Specialist', 'Store Incharge', 'Senior Design Engineer', 'Senior Process Executive', 'Senior Business Analyst', 'Sales Coordinator', 'Senior Project Engineer', 'Salesforce Developer', 'Senior Sales Officer', 'Site Supervisor', 'Student', 'Senior Project Manager', 'Software Tester', 'Store Executive', 'Shift Incharge', 'Senior Technical Lead', 'Senior Associate Consultant',
        'SAP FICO Consultant', 'Store Keeper', 'System Analyst', 'Technical Lead', 'Teacher', 'Test Engineer', 'Team Lead', 'Technical Support Engineer', 'Technology Analyst', 'Trainee', 'Tele Caller', 'Territory Sales Manager', 'Technician', 'Technology Lead', 'Test Analyst', 'Technical Specialist', 'Test Lead', 'Territory Manager', 'Technical Architect', 'Team Member', 'Team Manager', 'Technical Manager', 'Technical Consultant', 'Technical Support Executive', 'Telecalling Executive', 'Trainer', 'Teaching Staff', 'Technical Recruiter', 'Tableau Developer', 'Talent Acquisition Specialist', 'Technical Analyst',
        'Technical Assistant', 'Teacher Educator', 'Transaction Processing Associate', 'Territory Sales Incharge', 'Technical Project Manager', 'Talent Acquisition Executive', 'Territory Sales Officer', 'Technical Associate', 'Territory Sales Executive', 'Technology Specialist', 'Tele Sales Executive', 'Training Manager', 'Technical Test Lead', 'Technical Support Associate', 'Technical Writer', 'Tutor', 'Technical Officer', 'Tool Room Engineer', 'Tax Consultant', 'Territory Business Manager', 'Test Specialist', 'Tele Marketing Executive', 'UI Developer', 'US IT Recruiter', 'Unit Sales Manager', 'Unit Head',
        'Underwriter', 'Utility Operator', 'Utility Engineer', 'US Recruiter', 'UI UX Developer', 'User Experience Designer', 'UNIX Administrator', 'US Technical Recruiter', 'UiPath Developer', 'Unit Head Operations', 'UNIX System Administrator', 'US IT Bench Sales Recruiter', 'Utility Supervisor', 'Utility Manager', 'Urban Planner', 'Utility Executive', 'Underwriting Executive', 'UI UX and Graphic Designer', 'Underwriting Analyst', 'US Mortgage Process Associate', 'Underwriting Manager', 'UPS Service Engineer', 'Unity Developer', 'UX and UI Designer', 'UI and UX Lead', 'Upper Division Clerk', 'Unit Manager Bancassurance',
        'Undergraduate Student', 'Unit Incharge', 'Utility Incharge', 'Underwriting Associate', 'US Healthcare Recruiter', 'Unity Game Developer', 'Utility Officer', 'US Mortgage Analyst', 'UI Design Engineer', 'Urdu Teacher', 'Unit Agency Manager', 'Unit Relationship Manager', 'UI UX Designer Consultant', 'Underwriting Assistant', 'Utilities Operations Engineer', 'Unit Marketing Manager', 'UI UX Design Intern', 'Underwriting Specialist', 'UI and UX Design Lead', 'Vice President', 'Video Editor', 'VMC Operator', 'Validation Engineer', 'Vice President Operations', 'Vice Principal', 'Visual Merchandiser', 'VMware Administrator', 
        'Voice Process Executive', 'VMC Programmer', 'Value Banker', 'Virtual Relationship Manager', 'VMC Setter', 'VMC Machine Operator', 'Vice President Sales', 'Visual Designer', 'Volunteer', 'Verification Executive', 'Vice President Finance', 'Visiting Faculty', 'Verification Engineer', 'Vendor Manager', 'Veterinary Doctor', 'Virtual Assistant', 'Visa Executive', 'Vocational Trainer', 'Verifier', 'VBA Developer', 'Venue Manager', 'Visualiser', 'VFX Artist', 'Visa Counsellor', 'Valuation Engineer', 'Vendor Management Executive', 'Veterinary Officer', 'Vice President Information Technology', 'Verification and Validation Engineer',
        'VMware System Administrator', 'Vendor Development Engineer', 'Visa Officer', 'Valuation Analyst', 'Valve Technician', 'Veterinary Assistant', 'Visa Consultant', 'Verification Specialist', 'Vice President Business Development', 'Vehicle Testing Engineer', 'Vice President Technology', 'Vendor Development', 'Veritas NetBackup Administrator', 'Web Developer', 'Web Designer', 'Warehouse Executive', 'Warehouse Manager', 'Warehouse Incharge', 'Welder', 'Warehouse Supervisor', 'Workshop Manager', 'Worker', 'Warehouse Assistant', 'Workman', 'Workday Consultant', 'Waiter', 'Wordpress Developer', 'Wealth Manager', 'Windows Administrator',
        'Warehouse Officer', 'Wintel Administrator', 'Water Treatment Plant Operator', 'Windows VMware Administrator', 'Workshop Supervisor', 'Welding Inspector', 'WebLogic Administrator', 'Work Manager', 'Welding Engineer', 'Writer', 'Web Application Developer', 'Wireman', 'Welfare Officer', 'Warranty Incharge', 'Warranty Manager', 'Warehouse Associate', 'Ward Boy', 'Welding Foreman', 'Web Developer Trainee', 'Work Flow Specialist', 'Werehouse Incharge', 'Workshop Incharge', 'Welding Supervisor', 'Websphere Administrator', 'Working Partner', 'Web Developer Lead', 'Weigh Bridge Operator', 'Warehouse Operator', 'Websphere MQ Administrator', 
        'Web Analyst', 'Workday Integration Consultant', 'Warehouse Coordinator', 'Welding Technician', 'Webmethods Developer', 'X Ray Technician', 'XML Operator', 'Xamarin Developer', 'XML Executive', 'XML Programmer', 'Xamarin Forms Developer', 'Xerox Machine Operator', 'XML Analyst', 'X Ray and CT Scan and MRI Technician', 'XML Associate', 'Xerox Operator', 'XML Conversion Specialist', 'XML Specialist', 'XBRL Analyst', 'XSLT Developer', 'XML Data Analyst', 'XBRL Specialist', 'XML Coder', 'Xamarin Mobile Application Developer', 'Xamarin Android Developer', 'XML Trainee', 'XBRL Financial Analyst', 'XML Integrator', 'Xpression Developer',
        'X-Ray Welder', 'XML Lead', 'XI Consultant', 'XHTML Developer', 'XML and HTML Developer', 'XML and HTML Programmer', 'XML Processor', 'XML Production Associate', 'Yoga Instructor', 'Yoga Teacher', 'Young Professional', 'Yes Premier Relationship Manager', 'Yoga Trainer', 'Yoga Therapist', 'Yard Manager', 'Youtube Channel Manager', 'Young Scientist', 'Youtube Manager', 'Youth Mentor', 'Yoga Instructor Freelance', 'Youtube Executive', 'Yarn Supervisor', 'Yard Operations Manager', 'Yoga Coach', 'Young Leader', 'Yarn Store Incharge', 'Yarn Dyeing Manager', 'Yoga and Meditation Teacher', 'Yoga Expert', 'Yarn Dyeing Incharge',
        'Yoga and Fitness Instructor', 'Yoga Consultant', 'Yield Analyst', 'Youtube Administrator', 'Youth Development Officer', 'Yield Manager', 'Yoga and Pilates Instructor', 'Yoga Master', 'Youth Program Manager', 'Zonal Sales Manager', 'Zonal Manager', 'Zonal Business Manager', 'Zonal Head', 'Zonal Credit Manager', 'Zonal Coordinator', 'Zonal Collection Manager', 'Zoology Lecturer', 'Zonal Marketing Manager', 'Zonal Training Manager', 'Zonal Service Manager', 'Zonal Head Sales', 'Zonal Incharge', 'Zonal Lead', 'Zonal Technical Manager', 'Zonal Operations Manager', 'Zone Manager', 'Zoho Developer', 'Zonal Project Manager', 
        'Zonal Sales Coordinator', 'Zoology Faculty', 'Zonal Accountant', 'Zonal Trainer', 'Zonal Sales Trainer', 'Zonal Legal Manager', 'Zonal Head Business', 'Zonal Area Manager', 'zOS System Programmer', 'Zonal Commercial Manager', 'Zonal Debt Manager', 'Zonal Relationship Manager', 'Zonal Sales Officer', 'Zonal Engineer', 'Zonal MIS Executive', 'Zonal Officer', 'Zonal Distribution Manager', 'Zonal Accounts Manager', 'Zumba Fitness Instructor', 'Zonal Head Retail', 'Zonal Retail Manager', 'Zonal Business Development Manager', 'Zonal HR Partner', 'Zonal Key Account Manager', 'Zonal Marcom Executive', 'Zonal Head Service', 
        'Zonal Security Officer', 'Zonal Marketing Executive', 'Zonal Administration Manager', 'Zonal Head Operations', 'Zonal Risk Manager']
    

    const [options, setOptions] = useState([]);

    const handleSearch = (value) => {
      const sanitize = (str) => str.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
    
      if (value) {
        const sanitizedValue = sanitize(value);
    
        let filteredOptions = jobTitles?.filter((title) => sanitize(title).includes(sanitizedValue))
          .map((title) => ({ value: title, label: title }));
    
        // Add a custom option for creating a new job title
        if (filteredOptions.length === 0 || !filteredOptions.some(option => option.value === value)) {
          filteredOptions = [
            
            {
              value: value,
              label: `${value}`,
              isCustom: true,
            },
            ...filteredOptions,
          ];
        }
    
        setOptions(filteredOptions);
        
       
      } else {
        setOptions([]);
      }
    };
    const handleSelect = (value) => {
      
      setJobTitle(value)
    };

    const [qualificationOptions,setQualificationOptions] = useState([]);

    const handleQualificationsSearch = (value) => {
      const sanitize = (str) => str.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
    
      if (value) {
        const sanitizedValue = sanitize(value);
    
        let filteredOptions = qualifications
          .filter((title) => sanitize(title).includes(sanitizedValue))
          .map((title) => ({ value: title, label: title }));
    
        // Add a custom option for creating a new qualification
        if (filteredOptions.length === 0 || !filteredOptions.some(option => option.value === value)) {
          filteredOptions = [
          
            {
              value: value,
              label: `${value}`,
              isCustom: true,
            },
            ...filteredOptions,
          ];
        }
    
        setQualificationOptions(filteredOptions);
        
      } else {
        setQualificationOptions([]);
      }
    };

    const handleQualificationsSelect = (value) => {
      setQualification(value);
      callApi(value)
     
    };
    const qualifications = [
      // IT Industry
      'Bachelor’s Degree in Computer Science',
      'Bachelor’s Degree in Technology(BTech)',
      'Master’s Degree in Information Technology',
      'PhD in Computer Science',
      'Diploma in Software Engineering',
      'Certified Information Systems Security Professional (CISSP)',
      'AWS Certified Solutions Architect',
      'Microsoft Certified: Azure Solutions Architect',
      'Google Cloud Professional Certification',
      'Certified Scrum Master (CSM)',
      'Cisco Certified Network Associate (CCNA)',
      'CompTIA A+ Certification',
      'Certified Ethical Hacker (CEH)',
      'Oracle Certified Professional (OCP)',
      'Red Hat Certified Engineer (RHCE)',
      'Data Science Certification (e.g., Coursera, edX)',
      'Machine Learning Certification',
      'Blockchain Certification',
      'Artificial Intelligence Certification',
      'Cybersecurity Certification',
      'Full Stack Development Certification',
    
      // Automobile Industry
      'Bachelor’s Degree in Mechanical Engineering',
      'Master’s Degree in Automotive Engineering',
      'Diploma in Automobile Engineering',
      'PhD in Automotive Technology',
      'Certified Automotive Engineer (SAE)',
      'Automotive Service Excellence (ASE) Certification',
      'Certified CAD Designer (AutoCAD, CATIA)',
      'Electric Vehicle (EV) Design and Manufacturing Certification',
      'Mechatronics Certification',
      'Vehicle Dynamics Certification',
      'Certified Automotive Technician',
      'Hybrid & Electric Vehicle Certification',
      'Automotive Quality Management Certification',
      'Automotive Embedded Systems Certification',
      '3D Printing & Additive Manufacturing Certification',
    
      // Basic Degrees
      'High School Diploma',
      'Associate Degree',
      'Bachelor’s Degree in Arts (BA)',
      'Bachelor’s Degree in Science (BSc)',
      'Bachelor’s Degree in Commerce (BCom)',
      'Master’s Degree in Arts (MA)',
      'Master’s Degree in Science (MSc)',
      'Master’s Degree in Commerce (MCom)',
      'PhD in any discipline',
      'Diploma in Business Administration',
      'Teaching Certification',
      'Vocational Training Certificate',
    ];




      const analyseResume = async () => {
        if (
          !jobTitle || 
          (yearsOfExperience === null || yearsOfExperience === undefined || yearsOfExperience === "") || 
          !uploadedFile
        ) {
          alert(`Please fill in all fields and upload a resume.${uploadedFile}`);
          return;
        }
        console.log( typeof yearsOfExperience.toString(),"checkyears")
        setShowExperince(false);
        setProgress(0);
        setAnalyseModal(true);
    
        const formData = new FormData();
        formData.append("cv_file", uploadedFile); // Attach the uploaded file
        formData.append("role_name", "Software Engineer"); // Attach the job title
        formData.append("years_of_experience", yearsOfExperience); // Attach years of experience
        formData.append("job_description", jobDescription); // Attach the job description
    
        try {
          const response = await fetch("https://jd.aulas.in/ats-score", {
            method: "POST",
            body: formData,
          });
    
          if (!response.ok) {
            throw new Error("Failed to analyze the resume.");
          }
    
          const result = await response.json();
          console.log("API Response:", result);
    
          // Simulate progress and navigation
          setTimeout(() => {
            setProgress(100);
            setAnalyseModal(false);
            navigate("/results", { state: result }); // Pass the result to the results page
          }, 500);
        } catch (error) {
          console.error("Error:", error);
          alert("An error occurred while analyzing the resume.");
          setAnalyseModal(false);
        }
      };


      const convertToPlainText = (data) => {
        console.log(data,"checkdata")
        if (!data || data.length === 0) {
            return ""; // Return an empty string if no data
        }
        return data?.map((item) => {
                if (item.type === "p") {
                    return item.props.children; // Extract paragraph text
                } else if (item.type === "br") {
                    return "\n"; // Line break
                } else if (item.type === "h3") {
                    return `### ${item.props.children}`; // Markdown header
                } else if (item.type === "ul") {
                    return item.props.children
                        .map((li) => `- ${li.props.children}`)
                        .join("\n"); // List items
                }
                return "";
            })
            .join("\n\n");
    };
    
    const parseToStructure = (text) => {
        if (!text || text.trim() === "") {
            return []; // Return an empty array for empty input
        }
        return text.split("\n\n").map((para, index) => ({
            type: "p",
            key: index.toString(),
            props: { children: para.trim() },
        }));
    };
            

    const handleCloseModal = ()=>{
        setAnalyseModal(!analyseModal)
    }
    const handleFileUpload = (files) => {
        setUploadedFile(files)
        console.log('Uploaded files:', files);
        // Perform upload or further processing here
      };

    const handleChange = (e) => {
        //const plainText = e.target.value;
        //setJobDescription(parseToStructure(plainText));
        setJobDescription(e.target.value)
    };


    const callApi = (value)=>{
      console.log(yearsOfExperience,"checkyears")
      const payload = {
        "role":jobTitle,
        "must_have_skills":"C++,python,javascript,react",
        "good_to_have_skills": "java",
        "years_of_experience": yearsOfExperience,
        "degree_requirements": value,
        "industry":""
      };
    
      generateJd(payload,(response,error)=>{
        const div=document.createElement('div');
        div.innerHTML=response?.response||'';

        setJobDescription(div?.innerText);
        // setIsModalOpen(false);
        // setJdEditModal(true);
        // setJobExperience();
        // setShowExperince("no")
      })
    }
    useEffect(()=>{
      console.log(jobDescription,"checkingjd")
    },[jobDescription])

    // useEffect(()=>{
    //   if(jobTitle!=="" && (!jobDescription || !yearsOfExperience || !qualification)){
    //     callApi()
    //   }
    
    // },[jobTitle,jobDescription,yearsOfExperience,qualification])

    return(
    <div className="createJD-container" >
           
        <Header color={'#2337C6'} />
        <div className="job-description" style={{padding:'0px'}}>
         <div class="flex-overlay-container" style={{left:'0'}}>
         <img src={design} class="image2" alt="Second Image"/>
        </div>
        {<div  className="createJD-child" style={{transform:'translateY(50px)', width:'740px'}}>
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}> 
                 <div className="heading-content">AI-Powered Resume Screening</div>
                  <div className="paragraph-content">Save time with AI-driven resume analysis. Focus on the candidates most aligned with your hiring needs.</div>
            </div>
            <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between', marginTop:'40px'}}>
                <div className="input-resumeScreening" style={{display:'flex', flexDirection:'column', width:'45%',textAlign:'left'}}>
                 
                            <label htmlFor="jobTitle">Job Title <span style={{color:'#F5222D'}}>*</span></label>
                            
                            <AutoComplete
                              options={options}
                              onSearch={handleSearch}
                              onSelect={handleSelect}
                              placeholder="Enter a Job Title"
                              style={{ width: "100%" }}
                              value={jobTitle}
                              onChange={(e)=>{
                                setJobTitle(e)
                                setOptions(prevOptions => [
                                  ...prevOptions,
                                  { e }, // Add the custom value to options
                                ]);
                              }}
                              dropdownStyle={{ zIndex: 1000000,
                                
                               }} 
                               getPopupContainer={(triggerNode) => triggerNode.parentNode} 
                            >
                              <Input required/>
                            </AutoComplete>
                       
                </div>
                <div className="input-resumeScreening" style={{display:'flex', flexDirection:'column', width:'45%',textAlign:'left'}}>
                <label>Years of experience</label>
                <Select
                    showSearch
                    placeholder="Select years of experience"
                    value={yearsOfExperience || undefined}
                    onChange={(value) =>{ 
                        setYearsOfExperience(value)
                       
                    }}
                    filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={[
                    {
                        value: 0,
                        label: 'Fresher (0-2years)',
                    },
                    {
                        value: 2,
                        label: 'Mid Senior (2-3years)',
                    },
                    {
                        value: 3,
                        label: 'Experience (3-5years)',
                    },
                    {
                        value: 5,
                        label: 'Lead (5-8years)',
                    },
                    {
                        value: 8,
                        label: 'Manager (8+years)',
                    },
                    ]}
                />
                </div>
            </div>
            <div  className="input-resumeScreening" style={{display:'flex', flexDirection:'column',width:'100%',marginTop:'1.5rem', marginBottom:'1.5rem',textAlign:'left'}}>
                                <label htmlFor="qualifications">
                                  Qualification 
                                </label>

                                <AutoComplete
                                  options={qualificationOptions} // List of qualification options
                                  onSearch={handleQualificationsSearch} // Function to filter/search options
                                  onSelect={handleQualificationsSelect} // Function to handle selection
                                  placeholder="Enter a Qualification"
                                  style={{ width: "100%" }}
                                  value={qualification} // Assuming you are tracking in formData
                                  onChange={(e)=>{
                                    setQualification(e)
                                  
                                  }} // Function to update state
                                  dropdownStyle={{ zIndex: 1000000 }} 
                                >
                                  <Input  />
                                </AutoComplete>
                              </div>
            <div className="input-resumeScreening" style={{display:'flex', flexDirection:'column',width:'100%',marginTop:'1.5rem', marginBottom:'1.5rem'}}>
                <label>Job Description</label>
                <TextArea
                    placeholder="Enter a job description"
                    //value={convertToPlainText(jobDescription)}
                    value={jobDescription}
                    onChange={handleChange}
                    autoSize={{ minRows: 8, maxRows: 15 }}
                />

                {/* <div
                    style={{
                    marginTop: '20px',
                    padding: '10px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    backgroundColor: '#fff',
                    textAlign:'left'
                    }}
                    dangerouslySetInnerHTML={{ __html: jobDescription }}
                /> */}
 
            </div>
            <div className="input-resumeScreening" style={{display:'flex', flexDirection:'column',width:'100%'}}>
                <label>Resume</label>
                {/* <UploadResume/> */}
                <ClickToUpload onUpload={handleFileUpload}/>
            </div>
            
           <div style={{marginTop:'10px'}}><CustomButton text="Analysis" img={Generate} onClick={analyseResume} variant="contained"/></div>
         </div>}

         {analyseModal && (<CustomModal show={analyseModal} handleClose={handleCloseModal} title="Analyzing Resume..." subtitle="This won’t take long—please hold tight!" />)}
        </div>
         
     
    </div>
    );
}

export default ResumeScreening;