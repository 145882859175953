import React,{useEffect,useRef,useState} from "react";
import './styles.css'
import { Col, Dropdown, Form, Input, Modal, Row, Spin, Upload ,message,DatePicker, Checkbox, Button, InputNumber, Select} from "antd";
import uploadFile from '../../../../assets/icons/uploadFile.svg'
import { useNavigate } from "react-router-dom";
import eye from '../../../../assets/icons/eye.svg'
import resumeIcon from '../../../../assets/icons/resume.svg'
import nodata from '../../../../assets/icons/no-job.svg'
import dayjs from 'dayjs';
import {  CloseOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, LoadingOutlined, MoreOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import additionlIcon from '../../../../assets/icons/additionalIcon.svg'
import referenceIcon from '../../../../assets/icons/reference.svg'
import { updateApplication } from "../../Action/action";
import { useApplicationManagementStore } from "../../store/store";
import emailIcon from '../../../../assets/icons/emailIcon.svg';
import phoneIcon from '../../../../assets/icons/phoneIcon.svg';
import { adjustColorBrightness, getInitials, stringToColor } from "../../../notification/notification";
const {TextArea} = Input;
const { RangePicker } = DatePicker;
export const inputNumberValidation=(event)=>{
    if (
        !(
          /[0-9]/.test(event.key) || // Numbers
          event.key === "Backspace" || // Backspace
          event.key === "Delete" || // Delete
          event.key === "ArrowLeft" || // Left arrow
          event.key === "ArrowRight" || // Right arrow
          event.key === "Tab" // Tab key
        )
      ) {
        event.preventDefault();
      }
}

export const emailValidation= async (_, value) => {
        if (!value) {
          return Promise.resolve()
        }
        if (! /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
          return Promise.reject(new Error("Please enter a valid email address!"));
        }
}

export const phoneNumberValidation=async (_, value) => {
    // Example Regex: Validates a 10-digit number starting with 6, 7, 8, or 9 (for India)
    const regex = /^[6-9]\d{9}$/;

    if (!value) {
        return Promise.resolve()
      }

    if (!regex.test(value)) {
   
      return Promise.reject(new Error("Please enter a valid mobile number!"));
    } 
  };

function AdditionalInfo({getApplicationData}){

  const {applicationData}=useApplicationManagementStore(state=>state)
    const [form] = Form.useForm();

    const [form2] = Form.useForm();

    const[additonalInfoModal,setAdditionalInfoModal]=useState(false);
    const [additionalInfo,setAdditionalInfo]=useState({
        noticePeriod:null,
        currency:'',
        compensationStructure:'',
        currentSalary:'',
        expectedSalary:'',
        preferredWorkLocation:'',
        travelPreference:''

    });

    const currencyDropDown=["USD ($)", "EUR (€)", "GBP (£)", "INR (₹)", "AUD (A$)", "CAD (C$)", "SGD (S$)", "CHF (CHF)", "CNY (¥)", "JPY (¥)", "KRW (₩)", "RUB (₽)", "BRL (R$)", "ZAR (R)", "NZD (NZ$)", "MXN ($)", "HKD (HK$)", "TRY (₺)", "AED (د.إ)", "SAR (ر.س)", "MYR (RM)", "THB (฿)", "PHP (₱)", "IDR (Rp)", "VND (₫)", "KWD (د.ك)", "EGP (ج.م)", "ILS (₪)", "PKR (₨)", "BDT (৳)", "LKR (රු)", "QAR (ر.ق)", "OMR (﷼)", "BHD (.د.ب)", "NGN (₦)", "TZS (TSh)", "KES (KSh)"];
    const travelDropDown=["Yes","No","Maybe"]
    const compensationDropDown=  ["Per annum","Per hour","Per project","Per task","Per milestone","Per diem","Per unit"];
 
    const [isModalLoding,setIsModalLoading]=useState(false);

    const [referenceModal,setReferenceModal]=useState(false);

   
    const [references, setReferences] = useState([
      {

        name: "",
        relation: "",
        phone: "",
        email:""
   
      },
    ]);
  
    // Synchronize form values with the state
    const handleReferenceValuesChange = (_, allValues) => {

      console.log("referecne",allValues)
     
      setReferences(allValues?.references);
    };

    const handleAdditionalValuesChange = (_, allValues) => {
      
        console.log("addtional value",allValues)
        setAdditionalInfo(allValues);
      };
  
    const handleAddReference = () => {
      const newReference = {
        name: "",
        relation: "",
        phone: "",
        email:""
      };
      setReferences((prev) => [...prev, newReference]);
      form2.setFieldsValue({ references: [...references, newReference] });
    };
  
    const handleRemoveReference = (index) => {
      if (references.length === 1) {
        const newReference = {
            name: "",
            relation: "",
            phone: "",
            email:""
        };
        setReferences([ newReference]);
        form2.setFieldsValue({ references: [ newReference] });
        return;
      }
      const updatedReference = references.filter((_, i) => i !== index);
      setReferences(updatedReference);
      form2.setFieldsValue({ references: updatedReference });
    };





    const updateAdditionalInfo=()=>{


      const params={
        id:applicationData?.application?.id,
        ...additionalInfo

      }
      setIsModalLoading(true)
      updateApplication(params,()=>{
        setIsModalLoading(false)
        getApplicationData();
        setAdditionalInfoModal(false);
  
      })
   

    }
    const updateReference=()=>{
      const params={
        id:applicationData?.application?.id,
         references:[...references]

      }
      setIsModalLoading(true)
      updateApplication(params,()=>{
        setIsModalLoading(false)
        getApplicationData();
        setReferenceModal(false);
  
      })

    }

    
        useEffect(()=>{
          if(applicationData){
        
          resetAdditionalInfo(applicationData)
          resetReferences(applicationData)
       
          }
    
        },[applicationData])


   const resetAdditionalInfo=(applicationData)=>{
    
    const data={
      noticePeriod:applicationData?.application?.noticePeriod,
      currency:applicationData?.application?.currency,
      compensationStructure:applicationData?.application?.compensationStructure,
      currentSalary:applicationData?.application?.currentSalary,
      expectedSalary:applicationData?.application?.expectedSalary,
      preferredWorkLocation:applicationData?.application?.preferredWorkLocation,
      travelPreference:applicationData?.application?.travelPreference,
     

  }
    setAdditionalInfo(data);
  form.setFieldsValue(data)
}
   


const resetReferences=(applicationData)=>{
  const data=applicationData?.application?.references||[
    {

      name: "",
      relation: "",
      phone: "",
      email:""
 
    },
  ]
  setReferences(data);

  form2.setFieldsValue({references:data})
}


const compareValues=(type)=>{
  let param1,param2
  if(type=='additonalInfo'){
    param1=additionalInfo
    param2={
      noticePeriod:applicationData?.application?.noticePeriod,
      currency:applicationData?.application?.currency,
      compensationStructure:applicationData?.application?.compensationStructure,
      currentSalary:applicationData?.application?.currentSalary,
      expectedSalary:applicationData?.application?.expectedSalary,
      preferredWorkLocation:applicationData?.application?.preferredWorkLocation,
      travelPreference:applicationData?.application?.travelPreference,
     
  }


  if(Object.values(param1)?.every(e=>e==null||e==undefined||e?.trim()=='') && Object.values(param2)?.every(e=>e==null||e==undefined||e?.trim()=='')){
    return true;
  }


  }else if(type=='references'){
 param1=references
 param2=applicationData?.application?.references

 

 if(param1?.every(p=>Object.values(p)?.every(e=>e==null||e==undefined||e?.trim()=="")) && !param2){
  return true;
}


  }
  const newValues=JSON.stringify(param1);
  const oldValues=JSON.stringify(param2);

 
  return newValues==oldValues
}
   




 

    return (
        <>


                <div className="candidate-additional-info">
         <div className="job-info-header-1" style={{marginBottom:'20px'}}>
                <div className="job-info-title">
                <img src={additionlIcon}></img>
                <div style={{cursor:'default'}}>Additional Information</div>
                </div>
                <div className="job-info-edit" onClick={()=>{
                   setAdditionalInfoModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>
                  
        </div>
        <div className="job-info-data">
          <div className="data-field">
           <div className="data-name">Notice period</div>
           <div className="data-value">{applicationData?.application?.noticePeriod??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Currency</div>
           <div className="data-value">{applicationData?.application?.currency??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Compensation structure</div>
           <div className="data-value">{applicationData?.application?.compensationStructure??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Current salary</div>
           <div className="data-value">{applicationData?.application?.currentSalary??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Expected salary</div>
           <div className="data-value">{applicationData?.application?.expectedSalary??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Preferred work location</div>
           <div className="data-value">{applicationData?.application?.preferredWorkLocation??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Travel preference</div>
           <div className="data-value">{applicationData?.application?.travelPreference??'-'}</div>
          </div>
        </div>
                </div>

    
                <div className="candidate-reference-info" style={{minHeight:applicationData?.application?.references?.length>0?'max-content':'400px'}}>
             <div className="job-info-header-1" style={{marginBottom:'20px'}}>
                <div className="job-info-title">
                <img src={referenceIcon}></img>
                <div style={{cursor:'default'}}>References</div>
                </div>
               
                  {applicationData?.application?.references?.length>0?
                                <div className="keywords-edit" onClick={()=>{
                                    setReferenceModal(true)
                                }}>
                                  <EditOutlined style={{ color: "#1890FF"}} />
                                   <div> Edit</div>
                                </div>:null}
                  
                 </div>

                 { applicationData?.application?.references?.length>0?
                <div className="reference-cards">
                  {applicationData?.application?.references?.map(reference=>{
              
                    return <div className="reference-card">
                  
                      <div className="reference-profile">
                          <div className="candidate-profile-icon" style={{backgroundColor:adjustColorBrightness(stringToColor(reference?.name||''),70),color:adjustColorBrightness(stringToColor(reference?.name||''),-70)}}>{getInitials(reference?.name||'')}
                         </div> 

                      </div>
                    
                
                    <div className="referenc-header">
                    <div className="reference-title">
                        <h3>{reference?.name||"-"}</h3>
                        <span>{reference?.relation||'-'}</span>
                      </div>
                    <div className="reference-contact">

                    <span><img src={emailIcon}/> {reference?.email||'-'}</span>
                    <span><img src={phoneIcon}/> {reference?.phone||'-'}</span>


                    </div>
                    </div>




                    </div>

                  })}
                </div>:
                <div className="keywords-nodata" style={{height:'80%',padding:'0'}} >
                    <img src={nodata}></img>
                    <h3>No references added</h3>
                    <p>Add reference contacts to help complete the candidate's profile and background.</p>
                    <button  onClick={()=>{
                    setReferenceModal(true)
                }}><PlusCircleOutlined/> Add references</button>
                </div>
               }
    
                </div>

  
           

   
    {/* additional info modal */}
    <Modal
        title={<p className="keyword-modal-title">Edit Information</p>}
        centered
    
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
                setAdditionalInfoModal(false);
                resetAdditionalInfo(applicationData);
              
            }} className="keyword-modal-done">
            Cancel
          </button>
        {!isModalLoding?  <button onClick={()=>{


          if(!compareValues("additonalInfo")){
            updateAdditionalInfo()
          }else{
    message.error("Please make changes before updating!")
    return 
          }

          }} className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none',
               cursor:compareValues("additionalInfo")?'not-allowed':'pointer',
          opacity:compareValues("additionalInfo")?'0.6':'1'
          }}>
            Save Data
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8}}> Save Data  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>}
          </div>

        }
       onClose={()=>{
        resetAdditionalInfo(applicationData);
    
       }}
        open={additonalInfoModal}
        onCancel={() => {setAdditionalInfoModal(false); resetAdditionalInfo(applicationData)}}
        width={"80%"}
      
      >
       
       <Form
      form={form}
      layout="vertical"
      className="job-overview-edit-modal"
      initialValues={additionalInfo}
      onValuesChange={handleAdditionalValuesChange}
    >
     
    

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="noticePeriod"
                label="Notice period (In days)"
                rules={[{ required: false, message: "Please enter Notice period" }]}
               
              >
                <InputNumber min={0} placeholder="Enter notice period"  style={{width:'100%'}}

        onKeyDown={inputNumberValidation}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: false, message: "Please select currency" }]}
              >
       

                <Select for="currency" placeholder="Select currency" allowClear>
                                                {currencyDropDown?.map(e => (
                                                    <Select.Option value={e}>{e}</Select.Option>
                                                ))}

                                            </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="compensationStructure"
                label="Compensation structure"
                rules={[{ required: false, message: "Please select Compensation structure" }]}
              >
         
                <Select for="compensationStructure" placeholder="Select compensation structure" allowClear>
                                                { compensationDropDown?.map(e => (
                                                    <Select.Option value={e}>{e}</Select.Option>
                                                ))}

                                            </Select>
              </Form.Item>
            </Col>
           </Row>
           <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="currentSalary"
                label="Current salary"
                rules={[
                  {
                    type: "number",
                    required: false,
                    message: "Please enter Current salary",
                  },
                ]}
              >
               <InputNumber placeholder="Enter current salary" onKeyDown={inputNumberValidation} min={0} style={{width:'100%'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="expectedSalary"
                label="Expected salary"
                rules={[
                  {
                    type: "number",
                    required: false,
                    message: "Please enter Expected salary",
                  },
                ]}
              >
               <InputNumber placeholder="Enter expected salary" min={0} onKeyDown={inputNumberValidation}  style={{width:'100%'}} />
              </Form.Item>
            </Col>
      
            <Col span={8}>
              <Form.Item
                name="preferredWorkLocation"
                label="Preferred work location"
                rules={[{ required: false, message: "Please enter preferred work location" }]}
              >
                <Input placeholder="Enter preferred work location "/>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="travelPreference"
                label="Travel preference"
                rules={[{ required: false, message: "Please select travel preference" }]}
              >
             
                <Select for="travelPreference" placeholder="Select travel preference" allowClear>
                                                { travelDropDown?.map(e => (
                                                    <Select.Option value={e}>{e}</Select.Option>
                                                ))}

                                            </Select>
              </Form.Item>
            </Col>
          </Row>
  
    

     
    </Form>

   

    
         </Modal>

   {/* reference modal */}

   <Modal
        title={<p className="keyword-modal-title">Edit Information</p>}
        centered
        onClose={()=>{
          resetReferences(applicationData)
        }}
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
               resetReferences(applicationData)
               setReferenceModal(false);
              
            }} className="keyword-modal-done">
            Cancel
          </button>
        {!isModalLoding?  <button onClick={()=>{
               if(!compareValues("references")){
            updateReference()
          }else{
    message.error("Please make changes before updating!")
    return 
          }
        }} className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none',
          cursor:compareValues("references")?'not-allowed':'pointer',
          opacity:compareValues("references")?'0.6':'1'}}>
            Save Data
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8}}> Save Data  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>}
          </div>

        }
       
        open={referenceModal}
        onCancel={() =>{ setReferenceModal(false);  resetReferences(applicationData)}}
        width={"60%"}
      >
       
       <Form
      form={form2}
      layout="vertical"
      className="job-overview-edit-modal"
      initialValues={{ references }}
      onValuesChange={handleReferenceValuesChange}
    >
      {references.map((reference, index) => (
        <div key={index} style={{ marginBottom: 20 }}>
          <Row gutter={16}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <div style={{
                display:'flex',
                flexDirection:'row',
                gap:'2px',
                alignItems:'center'

                
              }}>
                <div
                  style={{
                    height: 20,
                    width: 4,
                    backgroundColor: "#2337C6",
                    marginRight: 8,
                  }}
                ></div>
                <div>   Reference {index + 1}</div>
             
              </div>
              {index!=0 ?
              <Button
                type="text"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleRemoveReference(index)}
                disabled={index==0 && (!references[index] || Object.values(references[index]).every(value => !value))} 
              >
                Remove
              </Button>:null}
            </div>
         
          </Row>

          <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                name={["references", index, "name"]}
                label="Name"
                rules={[{ required: false, message: "Please enter name " }]}
              >
                <Input placeholder="Enter name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["references", index, "relation"]}
                label="Relation"
                rules={[{ required: false, message: "Please enter relation" }]}
              >
                <Input placeholder="Enter relation" />
              </Form.Item>
            </Col>
            </Row>
            <Row gutter={32}>
            <Col span={12}>
              <Form.Item
                name={["references", index, "phone"]}
                label="Mobile number"
                rules={[{ required: false,  validator: phoneNumberValidation}]}
              >
                <Input placeholder="Enter mobile number" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["references", index, "email"]}
                label="Email Id"
                rules={[{ required: false,  validator: emailValidation }]}
              >
                <Input placeholder="Enter email id" />
              </Form.Item>
            </Col>
            </Row>



        </div>
      ))}

      <Row>
        <span  className="add-more" onClick={handleAddReference} block>
          <PlusCircleOutlined /> Add reference
        </span>
      </Row>
    </Form>

   

    
         </Modal>
   

 

        </>
    )


}
export default AdditionalInfo