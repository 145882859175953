import React from "react";
import "./customButton.css";
import { useResumeScreeningStore, useStore } from "./store/store";

const CustomButton = ({ text, img, onClick, variant }) => {
  const { progress,jobDescription } = useResumeScreeningStore((state) => state);
 
  const isButtonEnabled = progress === 100 && jobDescription !== ""; // Mostly used for Demo Pages
  const enabled = text==="Add Notes"; // Used while developing the web app

  return (
    <button
      className={`button-container-${variant}`}
      // onClick={() => {
      //   console.log("addnotes",enabled)
    
      //   if (enabled || isButtonEnabled ) {
         
         
      //     onClick()
      //   }
      // }}
      onClick={onClick}
      disabled={!isButtonEnabled ||!enabled} // Disable the button if conditions are not met
      style={{
        background: text === "Set Weightage" ? "transparent" : "",
        cursor: isButtonEnabled || enabled ? "pointer" : "not-allowed", // Optional styling for disabled state
        opacity: isButtonEnabled || enabled ? 1 : 0.6, // Optional styling for disabled state
      }}
    >
      <img src={img} alt="Icon" />
      {text }
    </button>
  );
};

export default CustomButton;
