import { Divider } from "antd";
import React from "react";
import DynamicBoxes from "./dynamicBoxes";
import "./applicationStatusCard.css"

const ApplicationStatusCard= ()=>{

    return(
        <div className="applicationcard-container">
            <div>Application Status</div>
            <div>Application is actively moving through the hiring process.</div>
            <Divider/>
            <div style={{display:'flex', flexDirection:'row', gap:'4px', alignItems:'center'}}>
                <div style={{width:'9px', height:'9px', backgroundColor:'#13C2C2', borderRadius:'50%'}}></div>
                <div style={{color:'#13C2C2', fontSize:'14px', fontWeight:'600'}}>Hiring Stages</div>
            </div>
            <DynamicBoxes/>
            <div className="applicationcard-footer">Resume Screening</div>
            <div className="applicationcard-footer1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</div>
        </div>
    );

}
export default ApplicationStatusCard;