import React, { useEffect, useState } from "react";
import { Select ,Form, Input, Tooltip} from "antd";
//import "./createRequests.css";
import "./multiSearchSelect.css";

const { Option } = Select;

const MultiSearchSelect = ({onChange, inputValues}) => {
  const [selectedValues, setSelectedValues] = useState(
    inputValues ? inputValues.split(",").map((item) => item.trim()) : []
  );

  useEffect(()=>{
    console.log(selectedValues,"checkingspecial")
  },[selectedValues])

  const handleChange = (value) => {
    setSelectedValues(value);
    onChange(value)
    console.log("Selected values: ", value);
  };

  const options = [
    { value: "Software Engineering", label: "Software Engineering" },
    { value: "Computer Science", label: "Computer Science" },
    { value: "Electrical", label: "Electrical" },
    { value: "Electronics", label: "Electronics" },
    { value: "Signal Processing", label: "Signal Processing" },
    { value: "Data Science", label: "Data Science" },
    { value: "Artificial Intelligence", label: "Artificial Intelligence" },
    { value: "Machine Learning", label: "Machine Learning" },
    { value: "Deep Learning", label: "Deep Learning" },
    { value: "Cybersecurity", label: "Cybersecurity" },
    { value: "Cloud Computing", label: "Cloud Computing" },
    { value: "DevOps", label: "DevOps" },
    { value: "Blockchain", label: "Blockchain" },
    { value: "Big Data", label: "Big Data" },
    { value: "Internet of Things (IoT)", label: "Internet of Things (IoT)" },
    { value: "Web Development", label: "Web Development" },
    { value: "Mobile Development", label: "Mobile Development" },
    { value: "Game Development", label: "Game Development" },
    { value: "UI/UX Design", label: "UI/UX Design" },
    { value: "Full Stack Development", label: "Full Stack Development" },
    { value: "Database Administration", label: "Database Administration" },
    { value: "Network Administration", label: "Network Administration" },
    { value: "Quality Assurance", label: "Quality Assurance" },
    { value: "Business Intelligence", label: "Business Intelligence" },
    { value: "Cloud Architecture", label: "Cloud Architecture" },
    { value: "Data Engineering", label: "Data Engineering" },
    { value: "Embedded Systems", label: "Embedded Systems" },
    { value: "Augmented Reality (AR)", label: "Augmented Reality (AR)" },
    { value: "Virtual Reality (VR)", label: "Virtual Reality (VR)" },
    { value: "Natural Language Processing (NLP)", label: "Natural Language Processing (NLP)" },
    { value: "Robotic Process Automation (RPA)", label: "Robotic Process Automation (RPA)" },
    { value: "IT Support", label: "IT Support" },
    { value: "Technical Support", label: "Technical Support" },
    { value: "Network Security", label: "Network Security" },
    { value: "Server Management", label: "Server Management" },
    { value: "Systems Analysis", label: "Systems Analysis" },
    { value: "Information Technology Management", label: "Information Technology Management" },
    { value: "Virtualization", label: "Virtualization" },
    { value: "IT Consulting", label: "IT Consulting" },
    { value: "Software Testing", label: "Software Testing" },
    { value: "IT Project Management", label: "IT Project Management" },
    { value: "Technical Writing", label: "Technical Writing" },
    { value: "IT Sales", label: "IT Sales" },
    { value: "Product Management", label: "Product Management" },
    { value: "Data Analysis", label: "Data Analysis" },
    { value: "E-commerce Development", label: "E-commerce Development" },
    { value: "API Development", label: "API Development" },
    { value: "Automation Testing", label: "Automation Testing" },
    { value: "IT Audit", label: "IT Audit" },
    { value: "Software Architecture", label: "Software Architecture" },
    { value: "System Integration", label: "System Integration" },
    { value: "Enterprise Resource Planning (ERP)", label: "Enterprise Resource Planning (ERP)" },
    { value: "Digital Marketing", label: "Digital Marketing" },
    { value: "Tech Entrepreneurship", label: "Tech Entrepreneurship" },
    { value: "SaaS Development", label: "SaaS Development" },
    { value: "Business Automation", label: "Business Automation" },
    { value: "Information Security", label: "Information Security" },
    { value: "Cloud Security", label: "Cloud Security" },
  ];
 
  

  return (
  
    <div className="input-group">
      <label htmlFor="searchable-select">
        Specializations 
      </label>
      {/* <Form.Item
    name="specializations"
    rules={[
      { required: true, message: "Please select at least one specialization." },
    ]}
  > */}
    
      <Select
        mode="tags"
        allowClear
        showSearch
        placeholder="e.g. Electrical, Automobile, etc."
        defaultValue={selectedValues}
        onChange={handleChange}
        style={{ width: "100%" }}
        dropdownStyle={{ zIndex: 1000000 }}
        maxTagCount="responsive" // Limits visible tags
        className="custom-multi-select"
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        options={options}
      />
    
       {/* </Form.Item> */}
    </div>
 
  );
};

export default MultiSearchSelect;
