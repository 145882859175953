import React, { useRef,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AutoComplete, Input,Modal,Button, Tooltip,Flex, Spin } from "antd";
import "./createRequests.css"
import RequestsIcon from "../../assets/requestsIcon.svg"
import { useState } from "react";
import addButtonIcon from "../../assets/addButtonIcon.svg"
// import { DatePicker } from "antd";
import dayjs from 'dayjs';
// import 'react-datepicker/dist/react-datepicker.css';
import NumberInputExample from "./NumberInput";
import SearchableSelect from "./searchableSelect";
import SearchCompo from "./searchComponent";
import MyDatePicker from "./myDatePicker.js";
import OverviewCard from "./overviewCard";
import editTable from "../../assets/editTable.svg"
import StatusCard from "./statusCard";
import { useVacancyStore } from "./store/store";
import Pagination from "./customPagination";
import EditIcon from "../../assets/EditIcon.svg"
import deleteIcon from "../../assets/deleteIcon.svg"
import viewIcon from "../../assets/viewIcon.svg"
import {postVacancyRequests, getVacancyRequests, updateVacancyRequests,deleteVacancyRequest,updateVacancyState} from "../vacancies/Action/action.js"
import Table from "../../components/Table/Table.js";
import CustomTimeline from "./vacancy_details/activities/timelineItem.js";
import closeIcon from "../../assets/closeIcon.svg"
import MultiSearchSelect from "./multiSearchSelect.js";
import infoIconJobCode from "../../assets/icons/infoIconJobCode.svg"
import confirmationIcon from "../../assets/confirmationIcon.svg"
import { useStore } from "../../sidebar/store/store.js";



const CreateRequests =  ()=>{

  const {TextArea} = Input

    const [startDate,setStartDate] = useState('');
    const [formData,setFormData] = useState({
      jobTitle: '',
      department: '',
      qualification:'',
      specializations:'',
      vacancyReason:null,
      priority:null,
      anticipatedJoinDate:null,
      approver: '',
      minCompensation:0,
      maxCompensation:0,
      minExperience:0,
      maxExperience:0,
      // approverName:'',
      // vacancyStatus: '',
    });
 
    const navigate = useNavigate();
    const [submitAction, setSubmitAction] = useState('');
    // const [currentPage,setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    // const [limit,setLimit] = useState(8);
    // const [dropdownOptionsName, setDropdownOptionsName] = useState(null);
    const [currentStatus,  setCurrentStatus] = useState('');
    const [dropdown, setDropdown] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editFormData,setEditFormData] = useState(false);
    const [editFormModal, setEditFormModal] = useState(''); //has the data related to that job
    
    const [editModalPosition, setEditModalPosition] = useState({ top: 0, left: 0 });
    const [filterModalPosition, setFilterModalPosition] = useState({top: 0 ,let: 0});
    const totalPages = 5;
    const[loading,setLoading]=useState(true);
    const dateFormatList = ['DD/MM/YYYY'];
    const [jobTitle,setJobTitle] = useState('');
    const [jobTitlesFilter, setJobTitlesFilter] = useState([]);
    const [isReasonModalVisible, setIsReasonModalVisible] = useState(false);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [reason,setReason] = useState({jobId:null, value:null});
    const [showYearsOfExperience, setShowYearsOfExperience] = useState();

    const [tooltipVisibility, setTooltipVisibility] = useState({jobTitle:false,
                                                                department:false,
                                                                approver:false,                                   
                                                                recruitmentType: false
                                                                //approverName:false
                                                              });
    const {creatorFormModal,setCreatorFormModal,
            submittedData,setSubmittedData,
            filtersList,setFiltersList,
            viewFormModal,setViewFormModal,
            vacancyCreatorFlag,
            responseData,setResponseData,
            responderFormModal,setResponderFormModal,
            sortJobTitle,setSortJobTitle,
            sortDateTime,setSortDateTime,
            jobCode, setJobCode,
            jobTitlesFinal,setJobTitles,resetFilters} = useVacancyStore(state=>state);


    const resetForm = (title) => {

      
      if(title==="tooltip"){
        setTooltipVisibility((prevData)=>
          Object.keys(prevData).reduce((acc,key)=>({ ...acc, [key]: false }),{})
        )
      }
      else{
        setFormData((prevFormData) =>
          Object.keys(prevFormData).reduce((acc, key) => ({ ...acc, [key]: '' }), {})
        );
      }
    };
            

    const toggleForm = () =>{
    
        resetForm("") // Reset form
        resetForm("tooltip")
        setOptions([]);
        setShowYearsOfExperience(false)
        setQualificationOptions([]);
        setEditFormData(false);
        setCreatorFormModal(false);
        setViewFormModal(false);
    }

    
    const onGoBack = ()=>{
      setViewFormModal(false)
    }

    const toggleReqForm = ()=>{
      setCreatorFormModal(!creatorFormModal);
    }

    const toggleFormResponder = ()=>{
      //setFormData({ jobTitle: '', jobCode:'',department: '', approver: '',vacancyStatus:'' })
      resetForm("")
      resetForm("tooltip")
      setResponderFormModal(false);
    }

    const handleOutsideClick = (e) => {
        if (e.target.className === "modal-overlay") {
          // setIsVisible(false);

        }
      };

    const handleViewVacancyClick = (id)=>{
      localStorage.setItem('previousUrl', window.location.pathname);
      navigate(`/vacancies/${id}`)
    }

    const handleChange = (e) => {
      e.preventDefault();
      console.log("fdc",e)
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const dropdownRef = useRef(null);

  const handleScrollReset = () => {
    if (dropdownRef.current) {
      dropdownRef.current.scrollTop = 0; // Reset scroll to top
    }
  };
    const [options, setOptions] = useState([]);

    const handleSearch = (value) => {
      console.log("jobb")
      const sanitize = (str) => str.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
    
      if (value) {
        const sanitizedValue = sanitize(value);
    
        let filteredOptions = jobTitles
          .filter((title) => sanitize(title).includes(sanitizedValue))
          .map((title) => ({ value: title, label: title }));
    
        // Add a custom option for creating a new job title
        if (filteredOptions.length === 0 || !filteredOptions.some(option => option.value === value)) {
          filteredOptions = [
            {
              value: value,
              label: `${value}`,
              isCustom: true,
            },
            ...filteredOptions,
          ];
        }
        console.log("jobb")
        setOptions(filteredOptions);
        // setFormData({
        //   ...formData,
        //   jobTitle: value,
        // });
       handleScrollReset(); // Reset the scroll
      } else {
        console.log("jobb")
        setOptions([]);
      }
    };
    const handleSelect = (value) => {
      setFormData((prev) => ({ ...prev, jobTitle: value }));
    };


    const [qualificationOptions,setQualificationOptions] = useState([]);

    const handleQualificationsSearch = (value) => {
      const sanitize = (str) => str.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();
    
      if (value) {
        const sanitizedValue = sanitize(value);
    
        let filteredOptions = qualifications
          .filter((title) => sanitize(title).includes(sanitizedValue))
          .map((title) => ({ value: title, label: title }));
    
        // Add a custom option for creating a new qualification
        if (filteredOptions.length === 0 || !filteredOptions.some(option => option.value === value)) {
          filteredOptions = [
          
            {
              value: value,
              label: `${value}`,
              isCustom: true,
            },
            ...filteredOptions,
          ];
        }
    
        setQualificationOptions(filteredOptions);
        setFormData({
          ...formData,
          qualification: value,
        });
      } else {
        setQualificationOptions([]);
      }
    };
    
    
  
    const handleQualificationsSelect = (value) => {
      setFormData((prev) => ({ ...prev, qualification: value }));
    };

    
    const validateForm = () => {
      // if(submitAction==="draft"){
      //   return true;
      // }
      console.log("responder")
      let formKeys = [
        'jobTitle',
        'department',
        'approver',
        
      ];
      if(submitAction==="draft"){
        formKeys = ['jobTitle'];
      }
      
        const updatedFormKeys = !vacancyCreatorFlag
        ? formKeys.filter(key => key === 'jobTitle' || key === 'department').concat('recruitmentType')
        : formKeys;
      console.log("responder",formData['recruitmentType'])
      for (const key of updatedFormKeys) {
        console.log("validatefields",key)
        if (
          key !== 'reasonDescription' && 
          (typeof formData[key] === 'string' && formData[key].trim() === '') || // Check for empty strings
          (typeof formData[key] === 'number' && formData[key] <= 0) || // Check for invalid numbers (e.g., zero or negative)
          (!vacancyCreatorFlag && formData[key]===undefined &&!editFormData ) ||
          (formData[key]===null)
        ){
          console.log("validatefields",key)
          setTooltipVisibility((prev) => ({
            ...prev,
            [key]: true, // Show tooltip for the current empty field
          }));

          fieldRefs[key]?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          return false; // Stop validation on the first empty field
        }
      }
      console.log("validatefieldfinal")
      resetForm("tooltip") // Reset tooltips if all fields are valid
      return true;
    };
 


    const handleSubmit = (e) =>{

      console.log("ITS HERE AFTERSAVE")
      
      e.preventDefault();

      const now = new Date();

      // Convert to IST by adding the IST offset (5 hours 30 minutes) from UTC
      const offsetIST = 5.5 * 60 * 60 * 1000;
      const istTime = new Date(now.getTime() + offsetIST);
      
      // Extract day, month, year, hours, minutes, and seconds
      const day = String(istTime.getUTCDate()).padStart(2, '0');
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const month = monthNames[istTime.getUTCMonth()]; // Get the month name
      const year = istTime.getUTCFullYear();
      const hours = String(istTime.getUTCHours() + 5).padStart(2, '0'); // Convert to IST (UTC+5)
      const minutes = String(istTime.getUTCMinutes()).padStart(2, '0');
      
      // Format the date as "OCT DD YYYY IST TIME"
      const formattedDate = `${month} ${day} ${year} ${hours}:${minutes} IST`;
      
      console.log("Current IST Date and Time:", formattedDate);

      

    if(validateForm()){
    
          if(vacancyCreatorFlag){
            console.log("ITS HERE")
            
              setCreatorFormModal(!creatorFormModal);
            
          }
          else{
            setResponderFormModal(false);
            if(creatorFormModal===true){
              setCreatorFormModal(false)
            }
            //setResponderFormModal(!responderFormModal);
          }


          let newEntry={...formData};
          let prevStatus = newEntry?.vacancyStatus;
          console.log(newEntry,"checkcheckcheck")

          if(submitAction==='raiseRequest'){

            console.log("here")
          
          newEntry = {
            ...formData,
            
            vacancyStatus: "Pending",
            draft : false,
            approverRole: "Creator",
            vacancies: formData?.vacancies?formData?.vacancies:1,
            compensation: {
            
              currency: formData?.compensation?.currency? formData?.compensation?.currency:"INR (₹)",
              compensationStructure: formData?.compensation?.compensationStructure?formData?.compensation?.compensationStructure:"Per Annum", // Update or set `compensationStructure`
            },
            
          };
          setFormData(newEntry);
          
        
          console.log(formData,"FORMDATACHECK");
          }
        
          else if(submitAction==='draft'){
            newEntry = {
              ...formData,
              //dateTime: formattedDate, 
              vacancyStatus: "Draft",
              draft: true,
              approverRole: "Creator",
              vacancies: formData?.vacancies?formData?.vacancies:1,
              compensation: {
                
                currency: formData?.compensation?.currency? formData?.compensation?.currency:"INR (₹)",
                compensationStructure: formData?.compensation?.compensationStructure?formData?.compensation?.compensationStructure:"Per Annum", // Update or set `compensationStructure`
              },
              
            };
            setFormData(newEntry)
            
            console.log(formData,submittedData,Date.now(),"FORMDATACHECK");
          }

          else if(submitAction==='createRequest'){
            newEntry = {
              ...formData,
          
              approverRole: "Responder",
              jobCode: jobCode?.isEnabled===true?jobCode?.jobCode:formData?.jobCode,
              vacancies: formData?.vacancies?formData?.vacancies:1,
              compensation: {           
                currency: formData?.compensation?.currency? formData?.compensation?.currency:"INR (₹)",
                compensationStructure: formData?.compensation?.compensationStructure?formData?.compensation?.compensationStructure:"Per Annum", // Update or set `compensationStructure`
              },
              
            };
            setFormData(newEntry)
            
            console.log(formData,submittedData,Date.now(),"FORMDATACHECK");
          }


          console.log(newEntry,"POSTCHECK")

          const processedEntry = Object.fromEntries(
            Object.entries(newEntry).map(([key, value]) => [key, value === "" ? null : value])
          );
          console.log(processedEntry,"POSTCHECK")

        if(editFormData===false){
          let updatedFormEntry = (submitAction==="createRequest"&&!vacancyCreatorFlag)?
          {
            jobTitle: processedEntry?.jobTitle,
            department: processedEntry?.department,
            jobCode: processedEntry?.jobCode,
            recruitmentType: processedEntry?.recruitmentType,
            approverRole:"Responder",
            vacancies: processedEntry?.vacancies,
            compensation: processedEntry?.compensation
          }
          :
          {...processedEntry}
          if(submitAction==="createRequest"){

          }
          
            postVacancyRequests(updatedFormEntry, (response, error) => {
              if (error) {
                console.error("Error posting vacancy:", error);
              } else {
                console.log("Vacancy posted successfully:", response);
                
                
                //setSearch('');
                
                getRequests()
              
              }
            });
        }
        if(editFormData===true){
          console.log(processedEntry,"CHECKINGUPDATE")
          const updatedEntry = {
            ...processedEntry,
            jobId: formData?.id, // Add jobId from formData
          };
        
          console.log(updatedEntry, "CHECKINGUPDATE");
                if(((prevStatus==="Draft"|| prevStatus==="Denied")&& submitAction==="raiseRequest") ){
                  postVacancyRequests(updatedEntry, (response, error) => {
                    if (error) {
                      console.error("Error posting vacancy:", error);
                    } else {
                      console.log("Vacancy posted successfully:", response);
                      
                      
                      //setSearch('');
                      
                      getRequests()
                    
                    }
                  });
                }
                //pending save update
                //draft save as update
                //draft raise post
                //denied save as update
                //denied raise post
                else{
                  if(vacancyCreatorFlag){
                    updatedEntry.approverRole = "Creator"
                  }
                  else{
                    updatedEntry.approverRole = "Responder"
                  }
                  updateVacancyRequests(updatedEntry,(response,error)=>{
                    if (error) {
                      console.error("Error posting vacancy:", error);
                    } else {
                      console.log("Vacancy updated successfully:", response);
                      
                      //setSearch('');
                      getRequests()
                    
                    }
                  });
                }
                setEditFormData(false);
        }
        resetForm("") // Reset form
        setOptions([])
       // setSearch('')
        setQualificationOptions([])
      }
   
      else{
        console.log("validatefieldfinal")
      }
}


    const getRequests = ()=>{
      const params = {
        page: responseData?.meta?.currentPage,
        limit: responseData?.meta?.itemsPerPage,
        sortTitle: sortJobTitle,
        sortDate: sortDateTime,
        status: filtersList?filtersList?.status:null,
        approverId: filtersList?filtersList?.approverId:null,
        department: filtersList?filtersList?.department:null,
        search: search,
        role: vacancyCreatorFlag===true?"Creator":"Responder",
        jobTitle: jobTitle?jobTitle:null,
        

      }
      setLoading(true);
      getVacancyRequests(params,(response,error)=>{
         setLoading(false)
        if (error) {
          console.error("Error posting vacancy:", error);
        } else {
        
          setResponseData(response)
          setJobTitlesFilter(response?.dropDown?.jobTitles)
          const jobTitleOptions = combinedJobTitles?.map((title) => ({
            key: title,
            value: title,
          }));

          console.log(jobTitleOptions,"JOBJOB")
          if(submitAction==='createRequest' && !vacancyCreatorFlag){
            handleViewVacancyClick(response?.vacancies[0]?.id)
          }
          
          
          // setPageMeta(response?.meta)
          
          console.log("Vacancy check :",response?.vacancies[0]);
        }
      })
    }

    useEffect(() => {
      console.log("Updated formData:", formData);
    }, [formData]);

    useEffect(() => {
      console.log("CHECKINGPENDING", formData)
      setShowYearsOfExperience(formData?.experience);
      console.log(showYearsOfExperience,"CHECKINGPENDING")
    }, [formData?.experience]);

    useEffect(()=>{
      resetFilters()
      setSearch('')
      setJobTitle('')

    },[vacancyCreatorFlag])

    useEffect(()=>{
      console.log(responseData?.meta?.currentPage,"PAGINATIONCHECK")
      getRequests()
    },[filtersList,responseData?.meta?.currentPage,responseData?.meta?.itemsPerPage,search,sortDateTime,sortJobTitle,vacancyCreatorFlag,jobTitle])

    const priorityOptions =[
      {value:"Low",label:'Low'},
      {value:"Medium",label:'Medium'},
      {value:"High",label:'High'},
    ];
    const responderOptions = responseData?.dropDown?.responders.map(responder => ({
      value: responder?.id,
      label: responder?.userName
    })) || [];
  
    const experienceOptions = [
      {value:'Fresher', label:'Fresher'},
      {value:'Experience', label:'Experienced'}
    ]
    const reasonOptions =[
      {value:'Business Expansion',label:'Bussiness Expansion'},
      {value:'Replacement',label:'Replacement'},
      {value:'New Project',label:'New Project'},
      {value:'Skills gap',label:'Skills gap'},
      {value:'Others',label:'Others'},
    ];
    const currencies = [
      { label: "USD ($)", value: "USD ($)" },
      { label: "EUR (€)", value: "EUR (€)" },
      { label: "GBP (£)", value: "GBP (£)" },
      { label: "INR (₹)", value: "INR (₹)" },
      { label: "AUD (A$)", value: "AUD (A$)" },
      { label: "CAD (C$)", value: "CAD (C$)" },
      { label: "SGD (S$)", value: "SGD (S$)" },
      { label: "CHF (CHF)", value: "CHF (CHF)" },
      { label: "CNY (¥)", value: "CNY (¥)" },
      { label: "JPY (¥)", value: "JPY (¥)" },
      { label: "KRW (₩)", value: "KRW (₩)" },
      { label: "RUB (₽)", value: "RUB (₽)" },
      { label: "BRL (R$)", value: "BRL (R$)" },
      { label: "ZAR (R)", value: "ZAR (R)" },
      { label: "NZD (NZ$)", value: "NZD (NZ$)" },
      { label: "MXN ($)", value: "MXN ($)" },
      { label: "HKD (HK$)", value: "HKD (HK$)" },
      { label: "TRY (₺)", value: "TRY (₺)" },
      { label: "AED (د.إ)", value: "AED (د.إ)" },
      { label: "SAR (ر.س)", value: "SAR (ر.س)" },
      { label: "MYR (RM)", value: "MYR (RM)" },
      { label: "THB (฿)", value: "THB (฿)" },
      { label: "PHP (₱)", value: "PHP (₱)" },
      { label: "IDR (Rp)", value: "IDR (Rp)" },
      { label: "VND (₫)", value: "VND (₫)" },
      { label: "KWD (د.ك)", value: "KWD (د.ك)" },
      { label: "EGP (ج.م)", value: "EGP (ج.م)" },
      { label: "ILS (₪)", value: "ILS (₪)" },
      { label: "PKR (₨)", value: "PKR (₨)" },
      { label: "BDT (৳)", value: "BDT (৳)" },
      { label: "LKR (රු)", value: "LKR (රු)" },
      { label: "QAR (ر.ق)", value: "QAR (ر.ق)" },
      { label: "OMR (﷼)", value: "OMR (﷼)" },
      { label: "BHD (.د.ب)", value: "BHD (.د.ب)" },
      { label: "NGN (₦)", value: "NGN (₦)" },
      { label: "TZS (TSh)", value: "TZS (TSh)" },
      { label: "KES (KSh)", value: "KES (KSh)" },
    ];    
    
    const departments = [
      { label: "Human Resources", value: "Human Resources" },
      { label: "Finance and Accounting", value: "Finance and Accounting" },
      { label: "Marketing", value: "Marketing" },
      { label: "Sales", value: "Sales" },
      { label: "Operations", value: "Operations" },
      { label: "Information Technology", value: "Information Technology" },
      { label: "Research and Development", value: "Research and Development" },
      { label: "Customer Service and Support", value: "Customer Service and Support" },
      { label: "Legal", value: "Legal" },
      { label: "Administration", value: "Administration" },
      { label: "Strategy and Planning", value: "Strategy and Planning" },
      { label: "Procurement and Purchasing", value: "Procurement and Purchasing" },
      { label: "Health, Safety, and Environment", value: "Health, Safety, and Environment" },
      { label: "Quality Assurance", value: "Quality Assurance" },
      { label: "Public Relations and Corporate Communications", value: "Public Relations and Corporate Communications" },
    ];
    
    
    const compensationStructureOptions = [
      {value:'Per Annum',label:'Per Annum'},
      {value:'Per hour',label:'Per hour'},
      {value:'Per project',label:'Per project'},
      {value:'Per diem',label:'Per diem'},
      {value:'Per unit',label:'Per unit'},
      {value:'Per milestone',label:'Per milestone'},
      {value:'Per task',label:'Per task'},
     
    ];

    const recruitmentTypeOptions = [
      {value: 'Campus Recruitment', label: 'Campus Recruitment'},
      {value: 'Lateral Recruitment', label: 'Lateral Recruitment'},
    ];

    const handlePageChange = (page) => {
      setResponseData({
        ...responseData,   // Spread the previous state to keep other properties
        meta: {
          ...responseData.meta,  // Spread the existing meta to keep other meta properties
          currentPage: page      // Update only the currentPage
        }
      });
      //setPageMeta({currentPage:page})
    
    };
    const handleItemsPerPageChange = (size)=>{
      setResponseData({
        ...responseData,   // Spread the previous state to keep other properties
        meta: {
          ...responseData.meta,  // Spread the existing meta to keep other meta properties
          currentPage: 1 ,     // Update only the currentPage
          itemsPerPage: size,
          totalPage:0,
          totalItems:0,
          itemCount:0 
        }
      });
    };

    const [suggestions, setSuggestions] = useState([]);
    const jobTitles = ['Assistant Manager', 'Accountant', 'Associate', 'Associate Consultant', 'Analyst', 'Area Sales Manager', 'Account Assistant', 'Assistant Professor', 'Assistant Consultant', 'Associate Manager', 'Associate Software Engineer', 'Assistant Engineer', 'Administration Executive', 'Assistant Vice President', 
      'Area Manager', 'Automation Test Engineer', 'Assistant General Manager', 'Application Development Analyst', 'Assistant Sales Manager', 'Assistant System Engineer', 'Accounts Officer', 'Account Executive', 'Associate Vice President', 'Architect',
      'Associate Engineer', 'Associate Director', 'Advocate', 'Area Business Manager', 'Administration Manager', 'Assistant Store Manager', 'Application Developer', 'Automation Engineer', 'Area Sales Executive', 'Associate Analyst',
      'Assistant Teacher', 'Associate Technical Consultant', 'Assistant Accountant', 'Application Support Engineer', 'Android Developer', 'Accounting Staff', 'Account Manager', 'Audit Assistant', 'Advisor', 'Application Engineer', 'Assistant Project Manager', 'Associate Professor', 'Assistant Branch Manager', 'Administrator', 'Audit Executive', 'Article Assistant', 'Business Development Manager', 'Business Analyst', 'Branch Manager', 'Business Development Executive', 
      'Back Office Executive', 'Business Development Associate', 'Branch Head', 'Business Manager', 'Branch Sales Manager', 'Billing Executive', 'Business Head', 'Back Office Staff', 'Banking Officer', 'BPO Executive', 'Business Process Lead', 'Business Associate', 'Back Office Assistant', 'Branch Credit Manager', 'Business Executive',
      'Business Development Officer', 'Business Consultant', 'Brand Manager', 'Branch Relationship Executive', 'Back Office Computer Operator', 'Backup Administrator', 'Billing Engineer', 'Banking Executive', 'Branch Incharge', 'Big Data Engineer', 'Back Office Coordinator', 'Biomedical Engineer', 'B Tech Student', 'Beautician', 'Business Process Associate', 'Big Data Developer', 'Beauty Advisor', 'Banker', 'Business System Analyst',
      'Buyer', 'Business Owner', 'BPO Associate', 'Branch Relationship Manager', 'Bartender', 'B Tech Fresher', 'Biology Teacher', 'Branch Operations Manager', 'Business Partner', 'Business Operations Associate', 'Bank Teller', 'Bench Sales Recruiter', 'Consultant', 'Civil Site Engineer', 'Customer Service Executive', 
      'Customer Care Executive', 'Computer Operator', 'Civil Engineer', 'Customer Service Associate', 'Customer Support Executive', 'Car Driver', 'Cashier', 'Credit Manager', 'Chemist', 'Content Writer', 'Collection Executive', 'Company Secretary', 'Customer Support Engineer', 'Customer Relationship Manager', 'CNC Operator', 'Cloud Engineer', 'Chartered Accountant', 'Collection Officer', 'Chief Manager', 'Coordinator', 
      'Cluster Manager', 'Customer Service Representative', 'Computer Teacher', 'Civil Supervisor', 'Credit Officer', 'Customer Service Manager', 'Counsellor', 'Customer Service Officer', 'Clerk', 'CNC Machine Operator', 'Construction Manager', 'Center Manager', 'Commis Chef', 'Chief Executive Officer', 'Claims Associate', 'Chief Financial Officer', 'Call Center Executive', 'Customer Support Associate', 'Chef de Partie', 'Cluster Head', 
      'Customer Service Advisor', 'Commercial Executive', 'Cyber Security Analyst', 'Credit Analyst', 'CRM Executive', 'Civil Foreman', 'Civil Site Supervisor', 'Deputy Manager', 'Data Entry Operator', 'Design Engineer', 'Data Analyst', 'DevOps Engineer', 'Driver', 'Desktop Support Engineer', 
      'Deputy General Manager', 'Data Engineer', 'Director', 'Data Scientist', 'Digital Marketing Executive', 'Developer', 'Diploma Mechanical Engineer', 'Diploma Engineer Trainee', 'Department Manager', 'Data Entry Executive', 'Digital Marketing Manager', 'Delivery Manager', 'Doctor', 'Delivery Boy', 'Diploma Electrical Engineer', 'Deputy Branch Manager', 'Diploma Civil Engineer', 'Designer', 'Dispatch Executive', 'Dentist', 'Dot Net Developer',
      'Document Controller', 'Database Administrator', 'Dental Surgeon', 'Documentation Executive', 'Dialysis Technician', 'Data Operator', 'Drug Safety Associate', 'Dietician', 'Deputy Project Manager', 'Duty Doctor', 'Duty Manager', 'Digital Marketing Analyst', 'Deputy Vice President', 'Digital Marketer', 'Deputy Engineer', 'Deputy Manager Grade 2', 'Direct Marketing Executive', 'Digital Marketing Specialist', 'Dispatch Officer', 'Divisional Manager',
      'Deputy Manager Grade 1', 'District Manager', 'Executive', 'Engineer', 'Electrical Engineer', 'Electrician', 'Executive Assistant', 'Electrical Technician', 'Electrical Supervisor', 'Executive Engineer', 'Engineering Manager', 'English Teacher', 'Electrical Site Engineer', 'Embedded Software Engineer', 'Electrical Design Engineer', 'ETL Developer', 'ETL Tester', 'Electrical Project Engineer', 'Electronics Engineer', 'Export Executive', 'Education Counsellor', 
      'Executive Manager', 'Executive Chef', 'Engineering Lead', 'Engineering Student', 'Entrepreneur', 'E Commerce Manager', 'Employee Relations Executive', 'Equity Dealer', 'Executive Secretary', 'Editor', 'Electrical Maintenance Technician', 'Executive Officer', 'Executive Trainee', 'Embedded Engineer', 'Embedded Software Developer', 'ETL Test Engineer', 'Educator', 'Estimation Engineer', 'Engineer Trainee', 'Export Manager', 'Executive Director', 'Electrical Maintenance Supervisor', 
      'Email Support Executive', 'English Lecturer', 'Equity Research Analyst', 'Engagement Manager', 'Executive Relationship Manager', 'Executive Army', 'Employee Relations Manager', 'Electrical Foreman', 'Embedded Developer', 'Fresher', 'Financial Analyst', 'Finance Executive', 'Front Office Executive', 'Front End Developer', 'Finance Manager', 'Freelancer', 'Field Executive', 'Field Officer', 'Field Engineer', 'Front Office Receptionist', 'Fashion Designer', 'Functional Consultant', 
      'Facility Manager', 'Field Service Engineer', 'Fitter', 'Floor Manager', 'Financial Controller', 'Facility Executive', 'Fashion Consultant', 'Front Office Manager', 'Freelance Content Writer', 'Faculty', 'Founder', 'Fireman', 'Financial Associate', 'Financial Accountant', 'Factory Manager', 'Forklift Operator', 'Financial Advisor', 'Fresher Graduate', 'Freelance Consultant', 'Field Sales Executive', 'Finance Assistant', 'Field Sales Officer', 'Fraud Analyst', 'Field Operator', 
      'Floor Supervisor', 'Foreman', 'Financial Consultant', 'Front Office Associate', 'Freelance Graphic Designer', 'Fire Officer', 'Front Office Assistant', 'Field Boy', 'Flutter Developer', 'Finance Head', 'Finance Officer', 'Finance Specialist', 'Field Supervisor', 'Graphic Designer', 'General Manager', 'General Manager Operations', 'GIS Engineer', 'Guest Service Associate', 'Graduate Engineer', 'Group Manager', 'Graduate Trainee', 'General Manager Sales', 'Guest Relation Executive',
      'General Dentist', 'GNM Staff Nurse', 'GIS Analyst', 'Graduate Apprenticeship Trainee', 'General Manager Business Development', 'Guest Lecturer', 'Gold Loan Officer', 'Good Worker', 'General Accounting Ops Associate', 'General Manager Marketing', 'Growth Officer', 'General Practitioner', 'Guest Faculty', 'Garment Merchandiser', 'Ground Staff', 'General Manager Finance', 'Gym Trainer', 'GIS Executive', 'Gold Loan Executive', 'Godown Incharge', 'Group Product Manager', 'Geologist', 
      'Graduate Apprentice', 'General Manager Administration', 'Gold Appraiser', 'Group Project Manager', 'General Manager Manufacturing', 'General Manager Engineering', 'General Physician', 'General Manager Project', 'Group Head', 'German Language Expert', 'Game Developer', 'Graphic Designer Trainee', 'General Manager Production', 'Government Apprentice', 'Group Lead', 'Group Coordinator', 'Group Chief Financial Officer', 'General Accountant', 'HR Assistant', 'Hospital Pharmacist', 'Housekeeping Supervisor', 
      'Head Operations', 'Helper', 'HR Executive and Recruiter', 'Head Cashier', 'HVAC Technician', 'Home Tutor', 'Hindi Teacher', 'Highway Engineer', 'Hotel Manager', 'HSE Officer', 'Hardware Engineer', 'Heavy Driver', 'HVAC Engineer', 'HR Executive and Generalist', 'Hadoop Administrator', 'Human Resource Executive', 'Housekeeping Executive', 'Human Resource Manager', 'Hub Incharge', 'HR and Administration Team Member', 'Hardware Design Engineer', 'Hub Manager', 'HSE Engineer', 'Hospital Administrator', 
      'Help Desk Executive', 'HSE Manager', 'Hadoop Developer', 'Hotel Supervisor', 'HR Manager 1', 'HVAC Supervisor', 'Head Business Development', 'Head Chef', 'Hostel Warden', 'HVAC Design Engineer', 'Housekeeping Manager', 'Housekeeping Assistant', 'Homeopathic Doctor', 'HVAC Site Engineer', 'HR Executive and Team Lead', 'Head', 'Housekeeper', 'Heavy Truck Driver', 'Housekeeping Incharge', 'Home Maker', 'Home Loan Executive', 'HK Supervisor', 'Hospitality Manager', 'IT Recruiter', 'Interior Designer', 
      'ITI Fitter', 'Intern', 'IT Executive', 'IT Manager', 'ITI Electrician', 'Instrumentation Engineer', 'Internal Auditor', 'Internship Trainee', 'IT Support Engineer', 'IT Analyst C2', 'Informatica Developer', 'Information Security Analyst', 'Information Technology Analyst', 'Inspection Engineer', 'Inside Sales Executive', 'ITI Welder', 'Insurance Advisor', 'Industrial Engineer', 'IT Administrator', 'iOS Developer', 'IT Head', 'Incident Manager', 'Inventory Executive', 'Incharge', 'Insurance Executive',
      'Inventory Manager', 'Independent Consultant', 'Instructional Designer', 'Inside Sales Manager', 'IT Officer', 'Informatica Administrator', 'Indian Cook', 'Implementation Engineer', 'Import Executive', 'IT Support Executive', 'iOS Application Developer', 'IELTS Trainer', 'Instrumentation Technician', 'Implementation Consultant', 'IT Assistant', 'Instructor', 'Infrastructure Engineer', 'Indian Chef', 'Information Process Enabler', 'Inside Sales Specialist', 'Image Editor', 'ICU Staff Nurse', 'IT Fresher',
      'Junior Engineer', 'Java Developer', 'Junior Executive', 'Junior Accountant', 'Junior Officer', 'Junior Software Developer', 'Junior Manager', 'Junior Software Engineer', 'Junior Architect', 'Junior Associate', 'Junior Resident Doctor', 'Junior Advocate', 'Junior Assistant', 'Junior Engineer 1','Junior Relationship Executive', 'Junior Design Engineer','Junior Accounts Executive', 'Junior Data Analyst', 'Junior Research Fellow','Junior Analyst', 'Junior Consultant', 'Junior Developer', 'Junior Chemist', 
      'Junior Accounts Officer', 'Junior Supervisor', 'Junior Quality Engineer','JIRA Administrator', 'Junior Technician', 'Job Searcher', 'Junior Research Associate', 'Job Trainee', 'Junior Test Engineer', 'Junior Web Developer', 'Java Full Stack Developer', 'Junior Java Developer', 'Junior Merchandiser', 'Junior Process Associate', 'Junior Accounts Assistant', 'Junior Shop Manager', 'Junior Site Engineer', 'Java Software Developer', 'Junior Production Officer', 'Junior Clerk', 
      'Java Fresher', 'Junior Executive Officer', 'Journalist', 'Java and J2EE Developer', 'Junior Lecturer', 'Junior Research Analyst', 'Junior Software Tester', 'Key Accounts Manager', 'KYC Analyst', 'Key Accounts Executive', 'Key Relationship Manager', 'KYC Executive', 'Kindergarten Teacher', 'KPO Officer', 'KYC Specialist', 'KYC Associate', 'Kannada Teacher', 'Kitchen Helper', 'Kitchen Staff', 'Key Accounts Officer', 'Kitchen Supervisor', 'Knowledge Manager',
      'Key Accounts Business Manager', 'Kitchen Manager', 'Knowledge Management Associate', 'Kendra Manager', 'Knowledge Associate', 'Key Accounts and Client Servicing Manager', 'Knowledge Specialist', 'Kitchen Assistant','Kitchen Executive', 'Kg Teacher', 'Knowledge Analyst', 'Kitchen Incharge', 'Key Accounts Specialist', 'Knowledge Engineer', 'Kubernetes Administrator', 'Kafka Administrator', 'Key Accounts and Ecommerce Manager', 'Key Operator', 'Knowledge Management Analyst', 'Kronos Consultant', 'Kitchen Designer', 
      'Kitchen Stewarding Supervisor', 'Key Client Manager', 'Knowledge Executive', 'Key Business Manager', 'Knowledge Management Specialist', 'Kofax Developer', 'KST Supervisor', 'Kitchen Stewarding Manager', 'Kitchen Stewarding Executive', 'Key Accounts Lead', 'Key Operations Manager', 'Knowledge Management Consultant', 'Knitting Manager', 'Kitchen Steward', 'Lead Engineer', 'Lab Technician', 'Lead Consultant', 'Lecturer', 'Logistics Executive', 'Lead Software Engineer', 'Linux Administrator', 'Linux System Administrator',
       'Legal Associate', 'Lead', 'Lab Assistant', 'Logistics Manager', 'Legal Manager', 'Land Surveyor', 'Lawyer', 'Librarian', 'Lead Administrator', 'Legal Executive', 'Logistics Officer', 'Line Leader', 'Loan Officer', 'Lead Developer', 'Legal Advisor', 'Legal Officer', 'Lab Incharge', 'Laboratory Chemist', 'Laboratory Technologist', 'Legal Consultant', 'Lead Operations', 'Logistics Supervisor', 'Line Supervisor', 'Logistics Incharge', 'Lead Design Engineer', 'LMV Driver', 'Line Engineer', 'Legal Assistant', 'Lead Data Scientist', 
       'Lead Recruiter', 'Linux Server Administrator', 'Lead Generation Executive', 'Lead Data Engineer', 'Lift Technician', 'Loan Executive', 'Light Driver', 'Lead Quality Engineer', 'Lab Technician Incharge', 'Line Incharge', 'Line Operator', 'Law Officer', 'Logistics Associate', 'Manager', 'Mechanical Engineer', 'Marketing Executive', 'Marketing Manager', 'MIS Executive', 'Maintenance Engineer', 'Management Trainee', 'Medical Representative', 'Module Lead', 'Machine Operator', 'Medical Officer', 'Mechanical Maintenance Engineer', 
       'Merchandiser', 'Medical Coder', 'Mechanical Technician', 'Manual Test Engineer', 'Maintenance Fitter', 'Maintenance Manager', 'Marketing Officer', 'Maths Teacher', 'Maintenance Supervisor', 'Member Technical Staff', 'Managing Director', 'Microbiologist', 'Manual Tester', 'Marketing Head', 'Mainframe Developer', 'Mechanical Supervisor', 'Manufacturing Engineer', 'Medical Sales Representative', 'Mechanical Design Engineer', 'Managing Partner', 'Manager M1', 'MEP Engineer', 'Management Executive', 'Mulesoft Developer', 'Mechanical Fitter',
        'Management Consultant', 'Makeup Artist', 'Middleware Administrator', 'Marketing Specialist', 'Machine Learning Engineer', 'Mobile Application Developer', 'MIS Analyst', 'Music Teacher', 'Maintenance Electrician', 'Maintenance Executive', 'Medical Billing Executive', 'Market Research Analyst', 'MIS Coordinator', 'Network Engineer', 'Network Administrator', 'Nursing Staff', 'Network Support Engineer', 'Network Security Engineer', 'NOC Engineer', 'Nurse', 'National Sales Manager', 'New Product Development Engineer', 'Nursing Officer',
         'Nursery Teacher', 'Nursing Tutor', 'NPD Engineer', 'Nursing Assistant', 'Nutritionist', 'Netwrok Engineer', 'Network Analyst', 'Nursing Supervisor', 'Neem Trainee', 'NDT Technician', 'Network Specialist', 'Network Engineer L1 L2', 'Nursing Superintendent', 'Network Manager', 'Network Field Engineer', 'Non IT Recruiter', 'Nursing Incharge', 'NDT Engineer', 'NDT Inspector', 'Network Consultant', 'News Reporter', 'Nurse 1', 'National Key Account Manager', 'Nodal Officer', 'Network Associate', 'Network Operations Engineer', 
         'Non Commissioned Officer', 'News Anchor', 'Network Engineer L2', 'Network Planning Engineer', 'Network Lead', 'Nutrition Officer', 'NRI Relationship Manager', 'Network Security Administrator', 'Node Js Developer', 'National Account Manager', 'New Associate', 'NDT Multi Technician', 'Network Engineer 2', 'National Manager', 'Operations Manager', 'Officer', 'Office Assistant', 'Operator', 'Office Boy', 'Oracle Database Administrator', 'Owner', 'Office Executive', 'Operations Analyst', 'Optometrist', 'Operations Executive', 'Office Administrator', 
         'Oracle Financial Functional Consultant', 'Office Coordinator', 'Online Tutor', 'Operating Engineer', 'Office Assistant & Receptionist', 'Office Manager', 'Office Clerk', 'Operations Associate', 'Operations Specialist', 'Operating Manager', 'Oracle Technical Consultant', 'Office Staff', 'Office Assistant & Accountant', 'Operations Team Member', 'Officer Worker', 'Operation Theatre Technician', 'Online Data Entry Operator', 'Office Supervisor', 'Office Peon', 'Oracle Application Database Administrator', 'Order Management Specialist', 'Operations Coordinator', 
         'Oracle SCM Functional Consultant', 'Oracle Functional Consultant', 'Oracle Application Technical Consultant', 'Operating Engineer Trainee', 'Online Marketing Executive', 'Oracle Developer', 'Office Incharge', 'Operations Lead', 'Office Worker', 'Operations Officer', 'Operations Administrator', 'Onboarding Executive', 'Outbound Executive', 'Office Superintendent', 'Officer Grade 1', 'Onboarding Specialist', 'Project Manager', 'Process Associate', 'Project Engineer', 'Production Engineer', 'Production Supervisor', 'Production Manager', 'Programmer Analyst', 
         'Product Manager', 'Pharmacist', 'Purchase Executive', 'Project Coordinator', 'Process Developer', 'Process Executive', 'Program Manager', 'Production Executive', 'Production Officer', 'Power BI Developer', 'Process Specialist', 'Purchase Manager', 'Principal Consultant', 'Process Engineer', 'Primary Teacher', 'Production Incharge', 'Planning Engineer', 'Project Lead', 'Proprietor', 'PLSQL Developer', 'Process Analyst', 'Purchase Engineer', 'Performance Test Engineer', 'Principal', 'Project Associate', 'Process Lead', 'Purchase Officer', 'Plant Operator', 
         'Principal Software Engineer', 'Partner', 'PHP Developer', 'Production Chemist', 'Product Owner', 'Project Manager Civil', 'Physiotherapist', 'Plant Head', 'Programmer', 'Process Expert', 'Programmer Analyst Trainee', 'Principal Engineer', 'Production Operator', 'Procurement Manager', 'Plant Manager', 'Quality Engineer', 'Quality Analyst', 'Quality Inspector', 'QA Engineer', 'Quality Controller', 'QA QC Engineer', 'QC Chemist', 'Quality Manager', 'Quality Executive', 'Quantity Surveyor', 'QA Analyst', 'QA Executive', 'QA Lead', 'QC Executive', 
         'Quality Assurance Officer', 'Quality Control Incharge', 'QA Tester', 'Quality Control Officer', 'QA Test Engineer', 'Quality Head', 'Quality Specialist', 'Quality Supervisor', 'Quality Assurance Analyst', 'Quality Officer', 'QC Inspector', 'QA QC Manager', 'Quality Control Engineer', 'Quality Checker', 'Quality Auditor', 'Quality Incharge', 'Quality Control Analyst', 'Quality Lead', 'Quality Associate', 'Quality Expert', 'Quality Control Executive', 'Quality Analyst Tester', 'QA QC Executive', 'QC Microbiologist', 'QlikView Developer', 'Quality Control Inspector',
        'Quality Test Engineer', 'QC Manager', 'QC Analyst', 'QA QC Inspector', 'Quality Assurance Tester', 'QA QC Chemist', 'Quality Assurance Lead', 'Quality Assurance and Quality Control Engineer', 'QA Test Lead', 'Quality Analyst 2', 'Relationship Manager', 'Relationship Officer', 'Regional Sales Manager', 'Research Associate', 'Receptionist', 'Research Analyst', 'Regional Manager', 'Relationship Executive', 'Restaurant Manager', 'Resident Medical Officer', 'Research Scientist', 'Retail Sales Executive', 'RPA Developer', 'Regional Business Manager', 'Radiographer',
        'Regional Head', 'Recruiter', 'Risk Analyst', 'Research Assistant', 'Retail Pharmacist', 'RF Engineer', 'Resident Engineer', 'Regional Head Sales', 'Research Development Engineer', 'Research Scholar', 'Restaurant General Manager', 'Retail Manager', 'Restaurant Captain', 'Retail Sales Officer', 'Researcher', 'Reporting Analyst', 'Recovery Officer', 'React Developer', 'Relationship Associate', 'Retail Sales Associate', 'Regional Credit Manager', 'R&D Engineer', 'Research Engineer', 'Real Estate Consultant', 'Retail Associate', 'Recovery Executive', 'Registered Nurse',
        'React Native Developer', 'Research Executive', 'Resource Manager', 'React Js Developer', 'Regional Service Manager', 'Risk Manager', 'Regional Business Head', 'Reservation Executive', 'Software Engineer', 'Senior Software Engineer', 'Software Developer', 'Sales Executive', 'Senior Executive', 'Software Test Engineer', 'Senior Associate', 'Sales Manager', 'Senior Manager', 'Senior Engineer', 'Senior Consultant', 'Senior Analyst', 'Sales Officer', 'System Engineer', 'Supervisor', 'Service Engineer', 'Site Engineer', 'Staff Nurse', 'Senior Software Developer', 'Senior Accountant',
        'Store Manager', 'System Administrator', 'School Teacher', 'Senior Process Associate', 'Senior Accounts Executive', 'Subject Matter Expert', 'Senior Test Engineer', 'Senior Sales Executive', 'Senior Officer', 'Safety Officer', 'Specialist', 'Store Incharge', 'Senior Design Engineer', 'Senior Process Executive', 'Senior Business Analyst', 'Sales Coordinator', 'Senior Project Engineer', 'Salesforce Developer', 'Senior Sales Officer', 'Site Supervisor', 'Student', 'Senior Project Manager', 'Software Tester', 'Store Executive', 'Shift Incharge', 'Senior Technical Lead', 'Senior Associate Consultant',
        'SAP FICO Consultant', 'Store Keeper', 'System Analyst', 'Technical Lead', 'Teacher', 'Test Engineer', 'Team Lead', 'Technical Support Engineer', 'Technology Analyst', 'Trainee', 'Tele Caller', 'Territory Sales Manager', 'Technician', 'Technology Lead', 'Test Analyst', 'Technical Specialist', 'Test Lead', 'Territory Manager', 'Technical Architect', 'Team Member', 'Team Manager', 'Technical Manager', 'Technical Consultant', 'Technical Support Executive', 'Telecalling Executive', 'Trainer', 'Teaching Staff', 'Technical Recruiter', 'Tableau Developer', 'Talent Acquisition Specialist', 'Technical Analyst',
        'Technical Assistant', 'Teacher Educator', 'Transaction Processing Associate', 'Territory Sales Incharge', 'Technical Project Manager', 'Talent Acquisition Executive', 'Territory Sales Officer', 'Technical Associate', 'Territory Sales Executive', 'Technology Specialist', 'Tele Sales Executive', 'Training Manager', 'Technical Test Lead', 'Technical Support Associate', 'Technical Writer', 'Tutor', 'Technical Officer', 'Tool Room Engineer', 'Tax Consultant', 'Territory Business Manager', 'Test Specialist', 'Tele Marketing Executive', 'UI Developer', 'US IT Recruiter', 'Unit Sales Manager', 'Unit Head',
        'Underwriter', 'Utility Operator', 'Utility Engineer', 'US Recruiter', 'UI UX Developer', 'User Experience Designer', 'UNIX Administrator', 'US Technical Recruiter', 'UiPath Developer', 'Unit Head Operations', 'UNIX System Administrator', 'US IT Bench Sales Recruiter', 'Utility Supervisor', 'Utility Manager', 'Urban Planner', 'Utility Executive', 'Underwriting Executive', 'UI UX and Graphic Designer', 'Underwriting Analyst', 'US Mortgage Process Associate', 'Underwriting Manager', 'UPS Service Engineer', 'Unity Developer', 'UX and UI Designer', 'UI and UX Lead', 'Upper Division Clerk', 'Unit Manager Bancassurance',
        'Undergraduate Student', 'Unit Incharge', 'Utility Incharge', 'Underwriting Associate', 'US Healthcare Recruiter', 'Unity Game Developer', 'Utility Officer', 'US Mortgage Analyst', 'UI Design Engineer', 'Urdu Teacher', 'Unit Agency Manager', 'Unit Relationship Manager', 'UI UX Designer Consultant', 'Underwriting Assistant', 'Utilities Operations Engineer', 'Unit Marketing Manager', 'UI UX Design Intern', 'Underwriting Specialist', 'UI and UX Design Lead', 'Vice President', 'Video Editor', 'VMC Operator', 'Validation Engineer', 'Vice President Operations', 'Vice Principal', 'Visual Merchandiser', 'VMware Administrator', 
        'Voice Process Executive', 'VMC Programmer', 'Value Banker', 'Virtual Relationship Manager', 'VMC Setter', 'VMC Machine Operator', 'Vice President Sales', 'Visual Designer', 'Volunteer', 'Verification Executive', 'Vice President Finance', 'Visiting Faculty', 'Verification Engineer', 'Vendor Manager', 'Veterinary Doctor', 'Virtual Assistant', 'Visa Executive', 'Vocational Trainer', 'Verifier', 'VBA Developer', 'Venue Manager', 'Visualiser', 'VFX Artist', 'Visa Counsellor', 'Valuation Engineer', 'Vendor Management Executive', 'Veterinary Officer', 'Vice President Information Technology', 'Verification and Validation Engineer',
        'VMware System Administrator', 'Vendor Development Engineer', 'Visa Officer', 'Valuation Analyst', 'Valve Technician', 'Veterinary Assistant', 'Visa Consultant', 'Verification Specialist', 'Vice President Business Development', 'Vehicle Testing Engineer', 'Vice President Technology', 'Vendor Development', 'Veritas NetBackup Administrator', 'Web Developer', 'Web Designer', 'Warehouse Executive', 'Warehouse Manager', 'Warehouse Incharge', 'Welder', 'Warehouse Supervisor', 'Workshop Manager', 'Worker', 'Warehouse Assistant', 'Workman', 'Workday Consultant', 'Waiter', 'Wordpress Developer', 'Wealth Manager', 'Windows Administrator',
        'Warehouse Officer', 'Wintel Administrator', 'Water Treatment Plant Operator', 'Windows VMware Administrator', 'Workshop Supervisor', 'Welding Inspector', 'WebLogic Administrator', 'Work Manager', 'Welding Engineer', 'Writer', 'Web Application Developer', 'Wireman', 'Welfare Officer', 'Warranty Incharge', 'Warranty Manager', 'Warehouse Associate', 'Ward Boy', 'Welding Foreman', 'Web Developer Trainee', 'Work Flow Specialist', 'Werehouse Incharge', 'Workshop Incharge', 'Welding Supervisor', 'Websphere Administrator', 'Working Partner', 'Web Developer Lead', 'Weigh Bridge Operator', 'Warehouse Operator', 'Websphere MQ Administrator', 
        'Web Analyst', 'Workday Integration Consultant', 'Warehouse Coordinator', 'Welding Technician', 'Webmethods Developer', 'X Ray Technician', 'XML Operator', 'Xamarin Developer', 'XML Executive', 'XML Programmer', 'Xamarin Forms Developer', 'Xerox Machine Operator', 'XML Analyst', 'X Ray and CT Scan and MRI Technician', 'XML Associate', 'Xerox Operator', 'XML Conversion Specialist', 'XML Specialist', 'XBRL Analyst', 'XSLT Developer', 'XML Data Analyst', 'XBRL Specialist', 'XML Coder', 'Xamarin Mobile Application Developer', 'Xamarin Android Developer', 'XML Trainee', 'XBRL Financial Analyst', 'XML Integrator', 'Xpression Developer',
        'X-Ray Welder', 'XML Lead', 'XI Consultant', 'XHTML Developer', 'XML and HTML Developer', 'XML and HTML Programmer', 'XML Processor', 'XML Production Associate', 'Yoga Instructor', 'Yoga Teacher', 'Young Professional', 'Yes Premier Relationship Manager', 'Yoga Trainer', 'Yoga Therapist', 'Yard Manager', 'Youtube Channel Manager', 'Young Scientist', 'Youtube Manager', 'Youth Mentor', 'Yoga Instructor Freelance', 'Youtube Executive', 'Yarn Supervisor', 'Yard Operations Manager', 'Yoga Coach', 'Young Leader', 'Yarn Store Incharge', 'Yarn Dyeing Manager', 'Yoga and Meditation Teacher', 'Yoga Expert', 'Yarn Dyeing Incharge',
        'Yoga and Fitness Instructor', 'Yoga Consultant', 'Yield Analyst', 'Youtube Administrator', 'Youth Development Officer', 'Yield Manager', 'Yoga and Pilates Instructor', 'Yoga Master', 'Youth Program Manager', 'Zonal Sales Manager', 'Zonal Manager', 'Zonal Business Manager', 'Zonal Head', 'Zonal Credit Manager', 'Zonal Coordinator', 'Zonal Collection Manager', 'Zoology Lecturer', 'Zonal Marketing Manager', 'Zonal Training Manager', 'Zonal Service Manager', 'Zonal Head Sales', 'Zonal Incharge', 'Zonal Lead', 'Zonal Technical Manager', 'Zonal Operations Manager', 'Zone Manager', 'Zoho Developer', 'Zonal Project Manager', 
        'Zonal Sales Coordinator', 'Zoology Faculty', 'Zonal Accountant', 'Zonal Trainer', 'Zonal Sales Trainer', 'Zonal Legal Manager', 'Zonal Head Business', 'Zonal Area Manager', 'zOS System Programmer', 'Zonal Commercial Manager', 'Zonal Debt Manager', 'Zonal Relationship Manager', 'Zonal Sales Officer', 'Zonal Engineer', 'Zonal MIS Executive', 'Zonal Officer', 'Zonal Distribution Manager', 'Zonal Accounts Manager', 'Zumba Fitness Instructor', 'Zonal Head Retail', 'Zonal Retail Manager', 'Zonal Business Development Manager', 'Zonal HR Partner', 'Zonal Key Account Manager', 'Zonal Marcom Executive', 'Zonal Head Service', 
        'Zonal Security Officer', 'Zonal Marketing Executive', 'Zonal Administration Manager', 'Zonal Head Operations', 'Zonal Risk Manager']
    
       
        //const combinedJobTitles = [...jobTitles, ...(jobTitlesFilter || [])];
        const combinedJobTitles = [...jobTitles];
        const jobTitleOptions = jobTitlesFilter?.map((title) => ({
      key: title,
      value: title,
    }));
    const qualifications = [
      // IT Industry
      'Bachelor’s Degree in Computer Science',
      'Bachelor’s Degree in Technology(BTech)',
      'Master’s Degree in Information Technology',
      'PhD in Computer Science',
      'Diploma in Software Engineering',
      'Certified Information Systems Security Professional (CISSP)',
      'AWS Certified Solutions Architect',
      'Microsoft Certified: Azure Solutions Architect',
      'Google Cloud Professional Certification',
      'Certified Scrum Master (CSM)',
      'Cisco Certified Network Associate (CCNA)',
      'CompTIA A+ Certification',
      'Certified Ethical Hacker (CEH)',
      'Oracle Certified Professional (OCP)',
      'Red Hat Certified Engineer (RHCE)',
      'Data Science Certification (e.g., Coursera, edX)',
      'Machine Learning Certification',
      'Blockchain Certification',
      'Artificial Intelligence Certification',
      'Cybersecurity Certification',
      'Full Stack Development Certification',
    
      // Automobile Industry
      'Bachelor’s Degree in Mechanical Engineering',
      'Master’s Degree in Automotive Engineering',
      'Diploma in Automobile Engineering',
      'PhD in Automotive Technology',
      'Certified Automotive Engineer (SAE)',
      'Automotive Service Excellence (ASE) Certification',
      'Certified CAD Designer (AutoCAD, CATIA)',
      'Electric Vehicle (EV) Design and Manufacturing Certification',
      'Mechatronics Certification',
      'Vehicle Dynamics Certification',
      'Certified Automotive Technician',
      'Hybrid & Electric Vehicle Certification',
      'Automotive Quality Management Certification',
      'Automotive Embedded Systems Certification',
      '3D Printing & Additive Manufacturing Certification',
    
      // Basic Degrees
      'High School Diploma',
      'Associate Degree',
      'Bachelor’s Degree in Arts (BA)',
      'Bachelor’s Degree in Science (BSc)',
      'Bachelor’s Degree in Commerce (BCom)',
      'Master’s Degree in Arts (MA)',
      'Master’s Degree in Science (MSc)',
      'Master’s Degree in Commerce (MCom)',
      'PhD in any discipline',
      'Diploma in Business Administration',
      'Teaching Certification',
      'Vocational Training Certificate',
    ];
    
    const handleInputChange = (e) => {
      const value = e.target.value;
      setFormData((prev) => ({ ...prev, jobTitle: value }));
  
      // Filter suggestions
      if (value.length > 0) {
        const filteredSuggestions = jobTitles.filter((title) =>
          title.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredSuggestions);
      } else {
        setSuggestions([]);
      }
    };

    const handleSuggestionClick = (suggestion) => {
      setFormData((prev) => ({ ...prev, jobTitle: suggestion }));
      setSuggestions([]); // Clear suggestions after selection
    };

  const modalRef = useRef(null);
  const handleEditClick = (event) =>{
  
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const modalHeight = 76; // Adjust based on your modal size
    const modalWidth = 140;

    // Calculate the top and left positions based on click coordinates
    const calculatedTop = Math.min(event.clientY, viewportHeight - modalHeight);
    const calculatedLeft = Math.min(event.clientX, viewportWidth - modalWidth);

    // Update position state
    setEditModalPosition({ top: calculatedTop, left: calculatedLeft });
    setEditModal(!editModal);
  }


  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setEditModal(false); // Close modal when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const statusOptions = [
    { key: "Draft", value: "Draft" },
    { key: "Pending", value: "Pending" },
    { key: "Activated", value: "Activated" },
    { key: "Denied", value: "Denied" },
 
  ];
  const approverOptions = responseData?.dropDown?.responders.map(responder => ({
    value: responder?.id,
    key: responder?.userName
  })) || [];

 
  const departmentOptions = [
   
      { key: "Human Resources", value: "Human Resources" },
      { key: "Finance and Accounting", value: "Finance and Accounting" },
      { key: "Marketing", value: "Marketing" },
      { key: "Sales", value: "Sales" },
      { key: "Operations", value: "Operations" },
      { key: "Information Technology", value: "Information Technology" },
      { key: "Research and Development", value: "Research and Development" },
      { key: "Customer Service and Support", value: "Customer Service and Support" },
      { key: "Legal", value: "Legal" },
      { key: "Administration", value: "Administration" },
      { key: "Strategy and Planning", value: "Strategy and Planning" },
      { key: "Procurement and Purchasing", value: "Procurement and Purchasing" },
      { key: "Health, Safety, and Environment", value: "Health, Safety, and Environment" },
      { key: "Quality Assurance", value: "Quality Assurance" },
      { key: "Public Relations and Corporate Communications", value: "Public Relations and Corporate Communications" },


    // "Software Development","IT","Finance"
  ];
  
  const handleFilter = (event) =>{
    setDropdown(!dropdown);
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    const modalHeight = 100; // Adjust based on your modal size
    //const modalWidth = 140;

    // Calculate the top and left positions based on click coordinates
    const calculatedTop = Math.min(event.clientY, viewportHeight - modalHeight);
    const calculatedLeft = Math.min(event.clientX, viewportWidth - 140);

    // Update position state
    setFilterModalPosition({ top: calculatedTop, left: calculatedLeft });
  }

  const handleFilterSubmit = (key,value)=>{
    
    setDropdown(false)
    setResponseData({
      ...responseData,   // Spread the previous state to keep other properties
      meta: {
        ...responseData.meta,  // Spread the existing meta to keep other meta properties
        currentPage: 1      // Update only the currentPage
       
      }
    });
  
 
    setFiltersList({
      ...filtersList,
      [key]:value?.join(',')
    });
  }

  const fieldRefs = {
    jobTitle: useRef(null),
    department: useRef(null),
    approver:useRef(null),
    recruitmentType:useRef(null),
 
  };


  const handleTitleClick = (data)=>{

    setEditFormModal(data)
    console.log(data,"CHECKINGPENDING")
  
    console.log(currentStatus,"4567")

    setFormData({
      jobTitle: data?.jobTitle || '',
      department: data?.department || '',
      qualification: data?.qualification || '',
      dateTime: data?.dateTime ? data?.dateTime : '',
      approver: data?.approver ?? '',
      minExperience: data?.minExperience || '',
      maxExperience: data?.maxExperience || '',
      minCompensation: data?.minCompensation || '',
      maxCompensation: data?.maxCompensation || '',
      approverName: data?.approverName || '-',
      reasonDescription: data?.reasonDescription || '',
      priority: data?.priority ?? '',
      vacancies: data?.vacancies || '',
      vacancyStatus: data?.vacancyStatus || '',
      draft : data?.draft || null,
      id: data?.id || null,
      vacancyReason: data?.vacancyReason || null,
      activities: data?.activities || null,
      anticipatedJoinDate: data?.anticipatedJoinDate? data?.anticipatedJoinDate : '',
      specializations: data?.specializations || '',
      compensation: {
        currency: data?.compensation?.currency || '',
        compensationStructure: data?.compensation?.compensationStructure || ''
      },
      experience: data?.experience,
    });


    if(data?.vacancyStatus==='Draft'){
      console.log("ITS HERE 1")
      setCreatorFormModal(true)
    }
    else{
      setViewFormModal(true)
    }
    
  }

const handleEditFormClick = ()=>{
  console.log(formData,"CHECKINGEDIT")
  console.log("ITS HERE 2")
  setViewFormModal(false)
  setCreatorFormModal(true)
}
const validateField = (key, value) => {
  console.log(key,value,"validatefield")
  if (typeof value === 'string') {
    return value.trim() !== ''; // For strings, check if trimmed value is non-empty
  }
  if (typeof value === 'number') {
    return value > 0; // Example validation: number must be greater than 0
  }
  return value !== null && value !== undefined; // For non-string values, ensure they are not null/undefined
};

const handleJobTitleChange = (key,value)=>{
  console.log("jobb")
  // if (!value) {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     jobTitle: "",
  //   }));
  //   return;
  // }
  // else{
  // if (!options.some(option => option.value === value)) {
  //   setOptions(prevOptions => [
  //     ...prevOptions,
  //     { value }, // Add the custom value to options
  //   ]);
   
  // }
  setFormData((prevFormData) => ({
    ...prevFormData,
    jobTitle: value
  }));
  setTooltipVisibility((prev) => ({
    ...prev,
    [key]: !validateField(key, value), // Hide tooltip if valid
  }));
  // }
}

const handleQualificationChange = (key,value)=>{
  setFormData((prevFormData) => ({
    ...prevFormData,
    qualification: value
  }));
  setTooltipVisibility((prev) => ({
    ...prev,
    [key]: !validateField(key, value), // Hide tooltip if valid
  }));
}
  const handleApproverChange = (key,value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      approver: value
    }));
    setTooltipVisibility((prev) => ({
      ...prev,
      [key]: !validateField(key, value), // Hide tooltip if valid
    }));
  };
  const handleReasonChange = (key,value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      vacancyReason: value
    }));
    setTooltipVisibility((prev) => ({
      ...prev,
      [key]: !validateField(key, value), // Hide tooltip if valid
    }));
  };
  const handleExperienceChange = (key,value)=>{
    console.log(value,"Updated formData")
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value==="Experience",
      maxExperience: value==="Experience"?1:0,
      minExperience: 0,
    }));
    if(value){
      setShowYearsOfExperience(true)
    }
    else{
      setShowYearsOfExperience(false)
    }
    
  }
  const handleDepartmentChange = (key,value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      department: value
    }));
    setTooltipVisibility((prev) => ({
      ...prev,
      [key]: !validateField(key, value), // Hide tooltip if valid
    }));
  };

  const handleSpecializationChanges = (key,value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      specializations: value?.join(',')
    }));
    setTooltipVisibility((prev) => ({
      ...prev,
      [key]: !validateField(key, value), // Hide tooltip if valid
    }));
  }

  const handleCompensationChange = (value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      compensation: {
        ...prevFormData?.compensation, // Preserve other properties inside `compensation`
        compensationStructure: value, // Update or set `compensationStructure`
      },
    }));
  };
  const handleCurrencyChange = (value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      compensation: {
        ...prevFormData?.compensation, // Preserve other properties inside `compensation`
        currency: value, // Update or set `compensationStructure`
      },
    }));
  }
  const handlePriorityChange = (key,value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      priority: value
    }));
    setTooltipVisibility((prev) => ({
      ...prev,
      [key]: !validateField(key, value), // Hide tooltip if valid
    }));
  };
  

  const handleRecruitmentChange = (key,value)=>{
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value
    }));
    setTooltipVisibility((prev) => ({
      ...prev,
      [key]: !validateField(key, value), // Hide tooltip if valid
    }));
  }

  const handleNumberInputChange = (value, type)=>{
    console.log("called ",value)

    switch(type) {
      case 'minExperience':
        
        setFormData((prevState) => {
          let newState = { ...prevState, [type]: value };
      
          // If the minimum experience is being updated, also update the maximum experience if necessary
         
            if (!prevState.maxExperience || prevState.maxExperience < value) {
              // Automatically set maxExperience to match minExperience
              newState.maxExperience = value;
            }
          
      
          return newState;
        });
        // setTooltipVisibility((prev) => ({
        //   ...prev,
        //   "minExperience": !validateField("minExperience", value), // Hide tooltip if valid
        // }));
   
        break;
        
      case 'maxExperience':
        setFormData(prevState => ({
          ...prevState,
          maxExperience: value, 
        }));
        setTooltipVisibility((prev) => ({
          ...prev,
          "maxExperience": !validateField("maxExperience", value), // Hide tooltip if valid
        }));
        break;
  
      case 'minCompensation':
        setFormData((prevState) => {
          let newState = { ...prevState, [type]: value };
      
          // If the minimum experience is being updated, also update the maximum experience if necessary
         
            if (!prevState.maxCompensation || prevState.maxCompensation < value) {
              // Automatically set maxExperience to match minExperience
              newState.maxCompensation = value;
            }
          
      
          return newState;
        });
        setTooltipVisibility((prev) => ({
          ...prev,
          "minCompensation": !validateField("minCompensation", value), // Hide tooltip if valid
        }));
        break;
        case 'vacancies':
        setFormData(prevState => ({
          ...prevState,
          vacancies: value,
        }));

        break;
  
      case 'maxCompensation':
        setFormData(prevState => ({
          ...prevState,
          maxCompensation: value, 
        }));
        setTooltipVisibility((prev) => ({
          ...prev,
          "maxCompensation": !validateField("maxCompensation", value), // Hide tooltip if valid
        }));
        break;
        
      default:
        console.log('Unknown type:', type); // Handle unrecognized types
    }
  }

  const handleDelete = ()=>{
    setDeleteConfirmationModal(false)
   
    console.log("DELETE")
    
    deleteVacancyRequest(editFormModal?.id,(response,error)=>{
      if (error) {
        console.error("Error deleting vacancy:", error);
      } else {
        console.log("Vacancy deleted successfully:", response);
  
        // setCount(response?.count)
        // setSearch('');
        getRequests()
      
      }
    });
  }

  const handleOk = (value,name) => {
    // After user confirms reason, proceed with the original functionality
    console.log("REASON FOR DENIAL",value?.jobId)
    setFormData({
      ...formData,
      [name]: value?.value,
      });
    let params = {
      
      active: false,
      jobId: value?.jobId,
      reasonForDenial: value?.value,
    }
    updateVacancyState(params,(response,error)=>{
      if(error){
        console.error();
      }
      else{
        console.log(response,"Vacancy request updated");
        getRequests();
      }
    })
    setIsReasonModalVisible(false);
    setViewFormModal(false); // Close the form modal
    setReason((prevState) => ({
      ...prevState, // preserve existing state
      jobId: null, // update jobId
      value:null, // reset or update reason
    }));
   
  }
  const handleCancel = () => {
    setIsReasonModalVisible(false);
    setReason((prevState) => ({
      ...prevState, // preserve existing state
      jobId: null, // update jobId
      value:null, // reset or update reason
    }));
  };

  const handleStatusCardClick = (value,name)=>{
    if(name==="Deny"){
      console.log("Reason for denialll", value)
      setReason((prevState) => ({
        ...prevState, // preserve existing state
        jobId: value, // update jobId
        value: '', // reset or update reason
      }));
      setIsReasonModalVisible(true)
    
    }
    else{
      let params = {
        jobId: value,
        active: name==="Activate"?true: name==="Deny"?false:null,
      }
      updateVacancyState(params,(response,error)=>{
        if(error){
          console.error();
        }
        else{
          console.log(response,"Vacancy request updated");
          getRequests();
        }
      })
      setViewFormModal(false)
    
    }
    
  }

  useEffect(()=>{
    console.log(formData,'EXPCHECK')
  },[formData])

  useEffect(()=>{
    setJobTitles(jobTitles);
    console.log(jobTitlesFinal,"checkingjobtitles")
  },[])


  const handleDept=(v)=>{
  console.log(v)
  }

{console.log("selected",jobTitle)}
  const columns=[
    {
      meta:{
        title:'Job Title',
        type:'dropdown',
        styles:{
           width:'200px',
           
        },
        options: jobTitleOptions||[],
        isMultiSelect: true,
        selectAll:false
     
      },
      handler:(e)=>{
        console.log(e,"filter applied")     
        // if(e!==undefined){
          setResponseData({
            ...responseData,
            meta:{
              currentPage:1,
              itemsPerPage:10
            }
          })          
          setJobTitle(e)
        // }
      },
    
     
    },
   
    {
      meta:{
        title:'Date & Time',
        type:'sort',
        styles:{
          width:'180px'
        },
      },
      handler: (e)=>{setSortDateTime(e)}
    },
    {
      meta:{
        title:'Department/Category',
        type:'dropdown',
        styles:{
          width:'160px'
        },
        options: departmentOptions,
        isMultiSelect: true,
        selectAll:false
        
      },
      handler:(a)=>{handleFilterSubmit("department",a)},
      
    },
    {
      meta:{
        title:vacancyCreatorFlag?'Approver':'Requested by',
        type:'dropdown',
        styles:{
          width:'160px'
        },
        options: approverOptions,
        isMultiSelect: true
      },
      handler:(a)=>{handleFilterSubmit("approverId",a)},
     

    },
    {
      meta:{
        title:'Status',
        type:'dropdown',
        styles:{
          width:'180px'
        },
        options: statusOptions,
        isMultiSelect: true
      },
      handler:(a)=>{handleFilterSubmit("status",a)},
    

    },
    {
      meta:{
        title:'Actions',
        type:'default',
        styles:{
          width:'50px',
          maxWidth:'50px',
        },
      }
    },
    
  ]
  const newColumns = [...columns]
  if (!vacancyCreatorFlag) {
    
    columns.splice(
      columns.length - 2, // Start from the last two elements
      2, // Remove the last two entries
      {
        meta: {
          title: 'Actions',
          type: 'default',
          styles: {
            width: '280px'
          }
        }
      },
      
    );
  }
  
  
  const data= responseData?.vacancies?.map(data=>{
     const row =[
        {renderItem: <div style={{color:'#1890FF',cursor:'pointer'}} onClick={()=>{
                          
          setCurrentStatus(data?.vacancyStatus);
          console.log(data,"KEYCHECK")
          setEditFormData(true)
          handleTitleClick(data);
       
        }} title={data?.jobTitle}>{data?.jobTitle}</div>},
        {renderItem: <div>
          <div>
            <div>{data?.vacancyStatus==='Draft'?'Created on':data?.vacancyStatus==='Pending'?vacancyCreatorFlag?'Requested on':'Received on':data?.vacancyStatus==='Denied'?'Denied on':'Activated on'}</div>
            <div>{data?.dateTime}</div>
          </div>
        </div>},
        {renderItem:<div>
          {/* <div>{data?.department}</div> */}
          <div title={data?.department}>{data?.department?data?.department:'-'}</div>
        </div>},
        {renderItem:<div >
          <div>{data?.approverName?data?.approverName:'-'}</div>
        </div>},
   
        {renderItem: <div><StatusCard name={data?.vacancyStatus} index={data?.id} type=""/></div>},
        {renderItem:<div onClick={(event)=>{
          setCurrentStatus(data?.vacancyStatus)
          // setEditFormData(data);
          setEditFormModal(data)
          handleEditClick(event)}}
          style={{cursor:'pointer', width:'25px'}}   
          >
          <img src={editTable} alt="editOptions" />
          </div>},
      ];
      
       
       if (!vacancyCreatorFlag) {
        // Remove the last two items and replace them with a different item
        row.splice(
          -2, // Start from the second-to-last item
          2,  // Remove two items
          {
            renderItem: (
              <div>
              
                {(data?.vacancyStatus==="Pending")&&(
        <div className="table-cellR" style={{display:'flex', flexDirection:'row', padding:'0px',borderBottom:'none',width:'260px',overflow:'inherit',gap:'8px'}}>
            <StatusCard name="Activate" index={data?.id} type="Responder" onClick={(value,name)=>{handleStatusCardClick(value,name)}}/>
            <StatusCard name="Deny" index={data?.id} type="Responder" onClick={(value,name)=>{handleStatusCardClick(value,name)}} />
          </div>)}
         {(data?.vacancyStatus==="Denied")&&(<StatusCard name="Denied" index={data?.id} type="Responder"/>)}
         {(data?.vacancyStatus==="Activated")&&(<StatusCard name="Activated" index={data?.id} type="Responder"/>)}
              </div>
            ),
          }
        );
      }
    
      return row;
    })


    return (
      <>
      
          {(vacancyCreatorFlag===true && responseData?.vacancies?.length>0 || (loading && search==='' &&Object.values(filtersList)?.filter(e=>e!==undefined)?.length==0) )?
          <div className="requests-table-container">
            <div className="requests-table-subcontainer" >
              <SearchCompo 
            //  value={search}
              onSearch={(value)=>{
                setResponseData( {
                  ...responseData,   // Spread the previous state to keep other properties
                  meta: {
                    currentPage: 1 ,
                    itemsPerPage:10     // Update only the currentPage
                  }
                });
  
                setSearch(value)
              }}  /> 
              <div className="table-subcontainer" >
              <Table columns={columns} rowData={data} loading={loading} filterApplied={Object(filtersList)?.values?.length===0?false:true && jobTitle!==''?true:false}/>
              
              </div>
              {responseData?.meta?.totalItems!==0 ? <Pagination 
              totalPages={responseData?.meta?.totalPages}
              currentPage={responseData?.meta?.currentPage}
              onPageChange={handlePageChange} 
              itemsPerPage={responseData?.meta?.itemsPerPage}
              onItemsPerPageChange={handleItemsPerPageChange}
             />:null}
            </div>
            <div>
               <OverviewCard/>
            </div>
      
          </div>
          :
          vacancyCreatorFlag===true&& responseData?.vacancies===undefined && search==='' &&Object.values(filtersList)?.filter(e=>e!==undefined)?.length==0 ?
          <div className="dashed-box" style={{backgroundColor:'#fff',border: '1px dashed #D0D0D0',margin:'24px', marginRight:'48px'}}>
           
             
              {/* Show the content when loading is false */}
              <>
               <div className="dashed-box-child"  style={{}}> 
                <div><img src={RequestsIcon} alt="icon"/></div>
                <div style={{color:"#1F1F1F",fontSize:'18px',fontWeight:600}}>It's time to find the right talent</div>
                <div style={{color:'#4D4D4D',fontSize:'14px',fontWeight:400}}>Click 'Raise a Request' to start hiring and manage the hiring process from here</div>
            </div>
            <button id="raiseReqForm" type="button" style={{cursor:'pointer'}}  onClick={toggleReqForm} className="dashed-box-button"> 
              <img src={addButtonIcon} />
              <div>Raise a request</div>
            </button>
              </>
            
           
            
          </div>
          :
          (!vacancyCreatorFlag && responseData?.vacancies?.length>0 || (loading && search==='' &&Object.values(filtersList)?.filter(e=>e!==undefined)?.length==0)) ?
           <div className="requests-table-container">
           <div className="requests-table-subcontainer" >
             <SearchCompo onSearch={(value)=>{
              setResponseData({
                ...responseData,
                meta:{
                  currentPage:1,
                  itemsPerPage:10
                }
              })
           
               setSearch(value)
             }}/> 
             <div className="table-subcontainer">
             <Table columns={columns} rowData={data} loading={loading}  filterApplied={Object(filtersList)?.values?.length===0?false:true && jobTitle!==''?true:false}/>
           
             </div>
             {responseData?.meta?.totalItems!==0?<Pagination       
             totalPages={responseData?.meta?.totalPages}
             currentPage={responseData?.meta?.currentPage}
             onPageChange={handlePageChange} 
             itemsPerPage={responseData?.meta?.itemsPerPage}
             onItemsPerPageChange={handleItemsPerPageChange}
            />:null}
           </div>
           <div>
              <OverviewCard/>
           </div>
     
           </div>
          :!vacancyCreatorFlag && responseData?.vacancies===undefined && search==='' &&Object.values(filtersList)?.filter(e=>e!==undefined)?.length==0?
            <div className="dashed-box" style={{backgroundColor:'#fff',border: '1px dashed #D0D0D0',margin:'24px', marginRight:'96px'}}>
           
            <div className="dashed-box-child" > 
                <div><img src={RequestsIcon} alt="icon"/></div>
                <div>It’s empty in here!</div>
                <div>No request has been submitted at this time.</div>
            </div>
            
            </div>
            :
            <div className="requests-table-container">
              <div className="requests-table-subcontainer">
                <SearchCompo onSearch={(value)=>{
                    setResponseData({
                      ...responseData,
                      meta:{
                        currentPage:1,
                        itemsPerPage:10
                      }
                    })
                  
                    setSearch(value)
                  }}  
                /> 
              
                  <div className="table-subcontainer" >
                  <Table columns={newColumns} rowData={data} loading={loading}  filterApplied={Object(filtersList)?.values?.length===0?false:true && jobTitle!==''?true:false}/>
                  {responseData?.meta?.totalItems!==0?<Pagination 
                    totalPages={responseData?.meta?.totalPages}
                    currentPage={responseData?.meta?.currentPage}
                    onPageChange={handlePageChange} 
                    itemsPerPage={responseData?.meta?.itemsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                  />:null}
                  </div>
                 
              </div>
              <div>
                <OverviewCard/>
              </div>
            </div>
            
          }
        
        
        {(creatorFormModal) && (
          <div className="req-modal-overlay" onClick={handleOutsideClick}>
              <div className="req-modal-content">
                {(editFormData===false )?
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div>
                      <div>Raise a Request</div>
                      <div>Provide necessary information to create a request and forward it for approval inorder to activate the job vacancy</div>
                    </div>
                    <div><img src={closeIcon} alt="closeIcon" style={{cursor:'pointer'}} onClick={toggleForm}/></div>
                  </div>
                  :
                  editFormData===true ?
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div>Edit Request</div>
                    <div style={{cursor:'pointer'}} onClick={toggleForm}> <img src={closeIcon} alt="closeIcon"/></div>
                  </div>
                  :
                  null

                }
                <form className="req-modal-form" onSubmit={handleSubmit}>
                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Basic Job Info</div>
                      </div>
                      

                      <div className="form-group">
                      <Tooltip title="Please fill this field" placement="bottom" open={tooltipVisibility.jobTitle} >
                          <div className="input-group"  ref={fieldRefs.jobTitle}>
                            <label htmlFor="jobTitle">Job Title <span style={{color:'#F5222D'}}>*</span></label>
                            
                            <AutoComplete
                              options={options}
                              onSearch={handleSearch}
                              onSelect={handleSelect}
                              placeholder="Enter a Job Title"
                              style={{ width: "100%" }}
                              value={formData?.jobTitle}
                              onChange={(e)=>{handleJobTitleChange('jobTitle',e)}}
                              //dropdownStyle={{ zIndex: 1000000 }} 
                              dropdownStyle={{ overflowY: "auto", zIndex: 1000000 }}
                              allowClear
                            >
                              <Input   />
                            </AutoComplete>
                          </div>
                        </Tooltip> 
                        <Tooltip title="Please fill this field" placement="bottom" open={tooltipVisibility.department}  >
                         <SearchableSelect options={departments}  placeholder="Select Department/Category" onChange={(e)=>{handleDepartmentChange("department",e)}} title="Department/Category" inputVal={formData?.department||'-'}/>
                      </Tooltip>
                      </div>

                      <div className="form-group">
                          
                    
                              <div className="input-group" >
                                <label htmlFor="qualifications">
                                  Qualifications 
                                </label>

                                <AutoComplete
                                  options={qualificationOptions} // List of qualification options
                                  onSearch={handleQualificationsSearch} // Function to filter/search options
                                  onSelect={handleQualificationsSelect} // Function to handle selection
                                  placeholder="Enter a Qualification"
                                  style={{ width: "100%" }}
                                  value={formData?.qualification} // Assuming you are tracking in formData
                                  onChange={(e)=>{handleQualificationChange('qualification',e)}} // Function to update state
                                  dropdownStyle={{ zIndex: 1000000 }} 
                                  allowClear
                                >
                                  <Input    />
                                </AutoComplete>
                              </div>
                           
                      </div>
                      
                      <div className="form-group">
                   
                          <MultiSearchSelect onChange={(e)=>{handleSpecializationChanges('specializations',e)}} inputValues={formData?.specializations} />
                     
                      </div>
                      <div className="form-group">
                        <SearchableSelect options={experienceOptions} placeholder="Please select a preferred candidate" onChange={(e)=>{handleExperienceChange('experience',e)}} title="Preferred Candidate" inputVal={formData?.experience===true?'Experience':formData?.experience===false?'Fresher':''}/>
                      </div>

                      {(formData?.experience!==undefined && formData?.experience!=='') && (
                        <div className="form-group">
                          <NumberInputExample
                            title="Minimum years of experience"
                            min={0}
                            max={100}
                            onChange={(value) => handleNumberInputChange(value, "minExperience")}
                            inputVal={formData?.minExperience}
                          />

                          <Tooltip title="Please fill this field" placement="bottom">
                            <NumberInputExample
                              title="Maximum Years of Experience"
                              min={formData?.minExperience}
                              max={100}
                              onChange={(value) => handleNumberInputChange(value, "maxExperience")}
                              inputVal={formData?.maxExperience?formData?.maxExperience:formData?.experience===true?1:''}
                            />
                          </Tooltip>
                        </div>
                      )}

                          <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                          <div className="req-form-subtitle">
                              <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                              <div className="form-title">Justification</div>
                          </div>

                          <div className="form-group">
                       
                            <SearchableSelect options={reasonOptions} placeholder="Select reason for vacancy" onChange={(e)=>{handleReasonChange('vacancyReason',e)}} title="Reason for vacancy" inputVal={formData?.vacancyReason}/> 
                         
                          </div>
                          <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="description">Description</label>
                                {/* <input
                                    type="text"
                                    id="description"
                                    name="reasonDescription"
                                    placeholder="Expand on the reason"
                                    className="description"
                                    value={formData?.reasonDescription}
                                    onChange={handleChange}
                                    style={{alignItems:'flex-start', boxSizing:"border-box",height:'4rem'}}
                                    AutoComplete="off"
                                /> */}
                                <TextArea
                                  id="description"
                                  name="reasonDescription"
                                  placeholder="Expand on the reason"
                                  className="description"
                                  value={formData?.reasonDescription}
                                  onChange={handleChange}
                                  style={{
                                    alignItems: 'flex-start',
                                    boxSizing: 'border-box',
                                    height: '120px', // Matches the 4rem height
                                    resize: 'none',  // Disable resizing
                                  }}
                                  autoComplete="off"
                                  //showCount
                                  maxLength={600}
                                />
                                 <div style={{ textAlign: "right", marginTop: 5, color:'#D0D0D0' }}>
                                  {formData?.reasonDescription? formData?.reasonDescription?.length:0} / {600}
                                </div>
                              </div>
                          </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>
                      

                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Compensation</div>
                      </div>
                    
                    <div className="form-group">
                    <SearchableSelect options={currencies} placeholder="INR (₹)" onChange={(e)=>{handleCurrencyChange(e)}} title="Currency" inputVal={formData?.compensation?.currency? formData?.compensation?.currency : "INR (₹)"}/>
                    <SearchableSelect options={compensationStructureOptions} placeholder="Per Annum" onChange={(e)=>{handleCompensationChange(e)}} title="Compensation structure" inputVal={formData?.compensation?.compensationStructure?formData?.compensation?.compensationStructure:"Per Annum"}/>
                    </div>
                  

                      <div className="form-group" >
                    
                          <NumberInputExample title="Minimum range" min={0} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"minCompensation")}} inputVal={formData?.minCompensation} />
                          <NumberInputExample title="Maximum range" min={formData?.minCompensation} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"maxCompensation")}} inputVal={formData?.maxCompensation}/>
                      
                      </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Preferences</div>
                      </div>
                      

                      <div className="form-group" >
                   
                          <SearchableSelect options={priorityOptions} placeholder="Select a priority" onChange={(value)=>{handlePriorityChange("priority",value)}} title="Priority" inputVal={formData?.priority??''}/>

                          <NumberInputExample title="Number of positions open" min={1} max={100} onChange={(value)=>{handleNumberInputChange(value,"vacancies")}} inputVal={formData?.vacancies?formData?.vacancies:1}/>
                      </div>
        
                      
                      <div className="form-group">
                    
                        <div className="input-group" >
                          <label>Anticipated joining date</label>
                          <MyDatePicker
                            date={
                              editFormData
                                ? formData?.anticipatedJoinDate
                                ? formData?.anticipatedJoinDate
                                : null
                              :null
                            }
                            handleAnticipatedDate={(value)=>{  
                              if (!value) {
                                // Handle clearing of the date

                                setFormData(prevState => ({
                                  ...prevState,
                                  anticipatedJoinDate: null, // Set the date field to null
                                }));
                              
                                
                               
                                return;
                              }                            
                              const date = new Date(value);
                              console.log(value,"checkdate")
                              const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
                              console.log(formattedDate,"checkdate")
                              setFormData(prevState => ({
                                ...prevState,
                                anticipatedJoinDate: formattedDate, 
                              }));
                              setTooltipVisibility((prev) => ({
                                ...prev,
                                anticipatedJoinDate: !validateField("anticipatedJoinDate", value), // Hide tooltip if valid
                              }));
                            }}
                            
                          />  
                        </div>
                       
                        <div className="input-group"></div>
                      </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                      <div className="req-form-subtitle">
                        <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                        <div className="form-title">Approval Chain</div>
                      </div>
                      

                      <div className="form-group"  ref={fieldRefs.approver}>     
                      <Tooltip title="Please fill this field" placement="top" open={tooltipVisibility.approver} >               
                        <SearchableSelect options={responderOptions} placeholder="Anyone" title="Approver"  onChange={(e)=>{handleApproverChange("approver",e)}} inputVal={formData?.approver??''}/>
                      </Tooltip>
                        <div className="input-group"></div>
                      </div>

                      {
                        <div className="all-buttons">
                          {(
                            (editFormData===false ||formData?.vacancyStatus==='Draft')
                            && 
                          (formData?.vacancyStatus!=="Pending" || formData?.vacancyStatus!=="Denied" ))?
                          <div>
                            <button className="close-btn" onClick={toggleForm}>Close</button>
                          </div>
                          :
                          <div>
                            <button className="close-btn" onClick={()=>{
                              console.log("ITS HERE 3")
                              setCreatorFormModal(false)
                              setResponderFormModal(false)
                              handleTitleClick(editFormModal)
                            }}>Go back</button>
                          </div>
                          }
                          {editFormData===false || formData?.vacancyStatus ==="Draft" || formData?.vacancyStatus==="Denied"?
                            <div>
                            <button type="submit" className="draft-button" onClick={()=>{
                              setSubmitAction('draft')
                              setEditFormModal('')
                            
                            }}>Save as draft
                            </button>
                            <button type="submit" className="submit-button" onClick={()=>{
                              setSubmitAction('raiseRequest')
                              setEditFormModal('')
                              }}>
                                Raise a request
                            </button>
                            </div>
                            :editFormData===true && formData?.vacancyStatus === "Pending"?
                            <div>
                              <button  type="submit" className="submit-button">Save</button>
                            </div>
                            :null 
                          }
                        </div>
                       
                      }
                </form>
              </div>
          </div>
        )}
        {(responderFormModal) && (
          <div className="req-modal-overlay-responder" onClick={handleOutsideClick}>
              <div className="req-modal-content-responder">
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                    <div>
                      <div>Create Job Vacancy</div>
                      <div>Complete the fields to create a job vacancy and begin your search for ideal hire</div>
                    </div>
                    <div>
                      <img src={closeIcon} alt="closeIcon" onClick={toggleFormResponder} style={{cursor:'pointer'}}/>
                    </div>
                </div>
              
                <form className="req-modal-form-responder" onSubmit={handleSubmit}>              

                      <div className="form-group-responder">
                         <Tooltip title="Please fill this field" placement="bottom" open={tooltipVisibility.jobTitle} >
                          <div className="input-group"  ref={fieldRefs.jobTitle}>
                            <label htmlFor="jobTitle">Job Title<span style={{color:'#F5222D'}}>*</span></label>
                            <AutoComplete
                              options={options}
                              onSearch={handleSearch}
                              onSelect={handleSelect}
                              placeholder="Enter a Job Title"
                              style={{ width: "100%" }}
                              value={formData?.jobTitle}
                              onChange={(e)=>{handleJobTitleChange('jobTitle',e)}}
                              dropdownStyle={{ zIndex: 1000000 }} 
                            >
                              <Input />
                            </AutoComplete>
                          </div>
                          </Tooltip>
                      </div>
                      <div className="form-group-responder">
                          <div className="input-group-responder">
                              <label htmlFor="JobCode">Job Code</label>
                              <input
                                  type="text"
                                  id="jobcode"
                                  name="jobCode"
                                  value={formData?.jobCode}
                                  placeholder={jobCode?.jobCode}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  disabled={jobCode?.isEnabled}
                                  style={{cursor:jobCode?.isEnabled===true?'not-allowed':'cursor'}}
                              />
                              { !jobCode?.isEnabled&&<div style={{display:'flex', flexDirection:'row', gap:'4px', marginTop:'4px'}}>
                                <div><img src={infoIconJobCode} alt="infoIcon"/></div>
                                <div style={{color:'#1890FF', fontSize:'12px'}}>Last job code used is {jobCode?.jobCodeLastOne}</div>

                              </div>}
                          </div>
                      </div>
                      <div className="form-group-responder"  ref={fieldRefs.department}>
                      <Tooltip title="Please fill this field" placement="bottom" open={tooltipVisibility.department}  >
                        <SearchableSelect options={departments}  placeholder="Select Department/Category" onChange={(e)=>{handleDepartmentChange("department",e)}} title="Department/Category" inputVal={formData?.department||'-'}/>
                      </Tooltip>
                      </div>

                          <div className="form-group-responder" ref={fieldRefs.recruitmentType}>
                          <Tooltip title="Please fill this field" placement="bottom" open={tooltipVisibility.recruitmentType}  >
                            <SearchableSelect options={recruitmentTypeOptions} placeholder="Select recruitment type"  title="Recruitment type" inputVal='' onChange={(e)=>{handleRecruitmentChange("recruitmentType",e)}} isRequired={true}/>
                          </Tooltip>
                          </div>
                      <div className="all-buttons-responder">
                        <button className="close-btn-responder" onClick={toggleFormResponder}>Close</button>
                        <button type="submit" className="submit-button-responder" onClick={()=>{
                          setSubmitAction('createRequest')                      
                          }}>Create
                        </button>                   
                      </div>
  
                </form>
              </div>
          </div>
        )}
        {editModal && (
            <div 
              ref={modalRef}
              className="editModal-container" 
              style={{
                top: `${editModalPosition.top + 10}px`, 
                left: `${editModalPosition.left + 10}px`,
              }}
            >
              {currentStatus === 'Draft'||  currentStatus === 'Pending' ? (
                <>
                  <div  className="hover-item" onClick={()=>{
                      setEditModal(false)
                      setEditFormData(true)
                      handleTitleClick(editFormModal);
                    }} >
                    <img src={EditIcon} alt="EditIcon" />
                    Edit
                  </div>
                  <div  className="hover-item" onClick={()=>{ 
                    setEditModal(false)
                    setDeleteConfirmationModal(true)}} 
                  >
                    <img src={deleteIcon} alt="DeleteIcon" />Delete
                  </div>
                </>
              ) :  currentStatus === 'Denied' ? (
                <div  className="hover-item" onClick={()=>{
                  setEditModal(false)
                  setEditFormData(true)
                  handleTitleClick(editFormModal);
                }}>
                  <img src={EditIcon} alt="EditIcon"  />
                  Edit
                </div>
              ) : currentStatus === 'Activated' ? (
                <div  className="hover-item" onClick={()=>{
                  setEditModal(false);
                  handleViewVacancyClick(editFormModal?.id)
                  
                }}>
                  <img src={viewIcon} alt="viewIcon" />View vacancy
                </div>
              ) : null}
            </div>
        )}
        {(viewFormModal) && (
          <div className="req-modal-overlay" onClick={handleOutsideClick}>
          <div className="req-modal-content" >
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
              <div>Request</div>
              <div style={{cursor:'pointer'}}><img src={closeIcon} alt="closeIcon" onClick={toggleForm}/></div>
            </div>
          
            <form className="req-modal-form" onSubmit={handleSubmit}>
                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Basic Job Info</div>
                  </div>
                  

                  <div className="form-group">
                      <div className="input-group">
                          <label htmlFor="jobTitle">Job Title<span style={{color:'#F5222D'}}>*</span></label>
                          
                          <div style={{color:'#1F1F1F'}}>{formData?.jobTitle||'-'}</div>
                      </div>

                      <div className="input-group">
                          <label htmlFor="department">Department/Category<span style={{color:'#F5222D'}}>*</span></label>
                         <div style={{color:'#1F1F1F'}}>{formData?.department||'-'}</div>
                      </div>
                  </div>

                  <div className="form-group">
                      <div className="input-group">
                          <label htmlFor="qualifications">Qualifications</label>
                          <div style={{color:'#1F1F1F'}}>{formData?.qualification||'-'}</div>
                      </div>
                      <div className="input-group">
                          <label htmlFor="Specializations">Specializations</label>
                          <div style={{color:'#1F1F1F', height:'40px', overflowY:'scroll', overflowX:'scroll', width:'100%'}}>
                          {formData?.specializations?formData?.specializations:'-'}
                          </div>
                      </div>       
                  </div>
                  <div className="form-group">
                      <div className="input-group">
                          <label htmlFor="preferredCandidate">Preferred Candidate</label>
                          <div style={{color:'#1F1F1F'}}>{formData?.experience===true?"Experienced":formData?.experience===false?"Fresher":'-'}</div>
                      </div>
                          
                  </div>


                  <div className="form-group">
              
                      <NumberInputExample title="Minimum years of experience" min={0} max={100} onChange={(value)=>{handleNumberInputChange(value,"minExperience")}} inputVal={formData?.minExperience}/>
                 
                      <NumberInputExample title="Maximum Years of Experience" min={formData?.minExperience} max={100} onChange={(value)=>{handleNumberInputChange(value,"maxExperience")}} inputVal={formData?.maxExperience}/>
                 
                      
                  </div>

                      <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                      <div className="req-form-subtitle">
                          <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                          <div className="form-title">Justification</div>
                      </div>

                      <div className="form-group">
                        <SearchableSelect options={reasonOptions} placeholder="Select reason for vacancy" onChange={(e)=>{handleReasonChange(e)}} title="Reason for vacancy" inputVal={formData?.vacancyReason||'-'}/>

                     
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                            <label htmlFor="description">Description</label>
                            
                             <div style={{color:'#1F1F1F'}}>{formData?.reasonDescription||'-'}</div>

                        </div>
                      </div>

                  <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>
                  

                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Compensation</div>
                  </div>
                  <div className="form-group">
                   <div className="input-group">
                    <label>Currency</label>
                    <div style={{ color: '#1f1f1f' }}> {formData?.compensation?.currency ? formData?.compensation?.currency:'-'}</div>
                   </div>
                   <div className="input-group">
                    <label>Compensation Structure</label>
                    <div style={{ color: '#1f1f1f' }}>{formData?.compensation?.compensationStructure?formData?.compensation?.compensationStructure:'-'}</div>
                   </div>
                  </div>

                  <div className="form-group">             
              
                      <NumberInputExample title="Minimum range" min={0} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"minCompensation")}} inputVal={formData?.minCompensation}/>
                    
                     
                      <NumberInputExample title="Maximum range" min={formData?.minCompensation} max={100000000} onChange={(value)=>{handleNumberInputChange(value,"maxCompensation")}} inputVal={formData?.maxCompensation}/>
                   
                  </div>

                  <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Preferences</div>
                  </div>

                  <div className="form-group">                     
                      <SearchableSelect options={priorityOptions} placeholder="Select a priority" title="Priority" onChange={(e)=>{handlePriorityChange("priority",e)}} inputVal={formData?.priority??'Low'}/>
                      <NumberInputExample title="Number of positions open" min={1} max={10} onChange={(value)=>{handleNumberInputChange(value,"vacancies")}} inputVal={formData?.vacancies}/>
                  </div>
                 
                  <div className="form-group">
                    <div className="input-group">
                    <label>Anticipated joining date</label>
                    {(formData?.anticipatedJoinDate?
                    <div style={{ color: '#1f1f1f' }}>
                    {formData?.anticipatedJoinDate.split("/").reverse().join("/")}
                  </div>
                  :<div style={{color:'#1f1f1f'}}>{"-"}</div>)}
                    </div>
                    <div></div>
                  </div>

                  <hr style={{ border: '1px solid #E9E9E9',marginBottom:'1.5rem'}}></hr>

                  {vacancyCreatorFlag?
                  <>
                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Approval Chain</div>
                  </div>
                  

                  <div className="form-group">
                    
                    <SearchableSelect options={responderOptions} placeholder="Anyone" title="Approver"  onChange={handleApproverChange} inputVal={formData?.approver??''}/>
                    <div className="input-group"></div>
                    
                  </div>
                  </>
                  :
                  <>
                  <div className="req-form-subtitle">
                    <div style={{width:'4px', height:'1.5rem',backgroundColor:'#2337C6'}}></div>
                    <div className="form-title">Activities</div>
                  </div>

                  <div className="form-group">                 
                    <CustomTimeline options={formData?.activities} onLoadMore={()=>{

                    }}
                    type="form-activities"
                    />
                    
                  </div>
                  </>
                  }

                  <div className="all-buttons">
                  {vacancyCreatorFlag?
                  <>
                  <div>
                    <button className="close-btn" onClick={toggleForm}>Close</button>
                  </div>
                  
                  <div>
                    {formData?.vacancyStatus==='Pending'||formData?.vacancyStatus==='Denied'?
                    <button type="submit" className="draft-button" onClick={()=>{
                      handleEditFormClick()
                      setEditFormData(true)
                      
                     }}>Edit
                     </button>: formData?.vacancyStatus==='Activated'?
                    <button type="submit" className="draft-button" onClick={()=>{
                     toggleForm()
                     handleViewVacancyClick(formData?.id)

                     
                    }}>View vacancy
                    </button>:null
}
                  </div>
                  </>
                  :
                  vacancyCreatorFlag===false && formData?.vacancyStatus==="Activated"?
                  <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                    <div><button  className="close-btn" onClick={toggleForm}>Close</button></div>
                    <div ><button className="draft-button"  onClick={() => {
                                toggleForm();
                                handleViewVacancyClick(formData?.id);
                              }}
                              >View vacancy</button></div>
                  </div>
                  :vacancyCreatorFlag===false && formData?.vacancyStatus === "Pending" ? (
                    <div
                      className="table-cellR"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: '0px',
                        borderBottom: 'none',
                        width: '100%',
                        overflow: 'inherit',
                        justifyContent:'space-between'
                      }}
                    >
                      <div>
                      
                          <button
                            type="submit"
                            className="draft-button"
                            onClick={handleEditFormClick}
                          >
                            Edit
                          </button>
                        
                      
                        </div >
                        <div style={{display:'flex', flexDirection:'row', gap:'16px'}}>
                      <StatusCard
                        name="Deny"
                        index={formData?.id}
                        type="Responder-edit"
                        onClick={(value, name) => handleStatusCardClick(value, name)}
                        
                      />
                      <StatusCard
                        name="Activate"
                        index={formData?.id}
                        type="Responder-edit"
                        onClick={(value, name) => handleStatusCardClick(value, name)}
                      />
                      </div>
                    </div>
                  ):vacancyCreatorFlag===false && formData?.vacancyStatus === "Denied"?(
                    <div>
                      <button className="close-btn" onClick={toggleForm}>Close</button>
                    </div>
                  ):null
                
                  }
              </div>

              

                  
            </form>
            
          
          </div>
      
          </div>
        )}
        {(isReasonModalVisible)&&(
          <Modal
          title="Reason "
          open={isReasonModalVisible}
          onOk={()=>{handleOk(reason,"reasonForDenial")}}
          onCancel={handleCancel}
          zIndex={10000000}
          footer={[
            <Button key="back"  onClick={handleCancel}>
            Cancel
          </Button>,
            <Button key="submit" type="primary" onClick={()=>{handleOk(reason,"reasonForDenial")}}>
              Send
            </Button>,
          ]}
          className="denialModal"
         
        >
          {/* <Input
            value={reason?.value}
            onChange={(e) => 
              {
                setReason((prevState) => ({
                  ...prevState, // preserve existing state
                
                  value: e.target.value, // reset or update reason
                }));
               
            
              }
          }
            placeholder="Please provide a reason"
          /> */}
          <TextArea
                                  id="description"
                                  name="reasonDescription"
                                  placeholder="Expand on the reason"
                                  className="description"
                                  value={reason?.value}
                                  onChange={(e) => 
                                    {
                                      setReason((prevState) => ({
                                        ...prevState, // preserve existing state
                                      
                                        value: e.target.value, // reset or update reason
                                      }));
                     
                                    }
                                  }
                                  style={{
                                    alignItems: 'flex-start',
                                    boxSizing: 'border-box',
                                    height: '300px', 
                                    resize: 'none',  // Disable resizing
                                  }}
                                  autoComplete="off"
                                  showCount
                                  maxLength={150}
          />
         </Modal>
        )}
        {(deleteConfirmationModal)&&(
          <Modal
          title={<div style={{display:'flex', flexDirection:'row', gap:'16px'}}>
            <img src={confirmationIcon} alt="ConfirmationIcon"/>
            <div style={{fontFamily:'Figtree', fontSize:'20px', fontWeight:'700'}}>Delete Confirmation</div>        
          </div>}
          open={deleteConfirmationModal}
          onOk={()=>{handleDelete()}}
          onCancel={()=>{setDeleteConfirmationModal(false)}}
          zIndex={10000000}
          footer={[
            <Button key="back"  onClick={()=>{setDeleteConfirmationModal(false)}}>
            No
          </Button>,
            <Button key="submit" type="primary" onClick={()=>{handleDelete()}}>
              Yes
            </Button>,
          ]}
          centered
        //   style={{  
        //     top: '50%', // Move it 50% down
        //     left: '50%', // Move it 50% right
        //     transform: 'translate(-50%, -50%)', // Center it
        //     position: 'absolute', // Ensure proper placement
        //  }}
         className="confirmationModal"
        >
         <div style={{fontFamily:'Figtree', fontSize:'14px', fontWeight:'400', marginLeft:'40px'}}>Are you sure you want to delete this? This action cannot be undone.</div>
         </Modal>
        )}
      </>
    );
  }

export default CreateRequests;