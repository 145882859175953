import React from "react";
import { useState } from "react";
import { Avatar, Badge, Card, Divider,Drawer,Button } from "antd";
import {  ClockCircleOutlined  } from "@ant-design/icons";
import fileNotesIcon from "../../../assets/fileNoteIcon.svg"
import Selectable from "./selectable";
import starIconGreen from "../../../assets/starIconGreen.svg"
import starIconRed from "../../../assets/starIconRed.svg"
import visitProfile from "../../../assets/visitIcon.svg"
import mailIcon from "../../../assets/email.svg"
import phoneIcon from "../../../assets/phoneIcon.svg"
import locationIcon from "../../../assets/locationIcon.svg"
import Details from "./details";
import ApplicationStatusCard from "./applicationStatusCard";
import ApplicationManagmentView from "./AMTTabs";
import "./candidateCard.css"
import CustomTabs from "../../../components/CustomTabs/CustomTabs";
import DocumentApplication from "../applicationDetails/documents/document";
import CandidateNotes from "../candidateNotesActivities/candidateNotes";
import activitiesIcon from "../../../assets/activitiesIcon.svg"
import activitiesIconInactive from "../../../assets/activitiesIconInactive.svg"
import RecruitactiveImage from "../../../assets/recruitOverview.svg"
import RecruitInactiveImage from "../../../assets/recruitInactive.svg"
import jobOverviewActive from '../../../assets/icons/job-overview-active.svg';
import jobOverviewInactive from '../../../assets/icons/job-overview-inactive.svg';
import ApplicationView from "./applicationView";

const CandidateCard = ({ name, avatar, score, date, status, title, color }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Notes')

  // Function to open the drawer
  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  // Function to close the drawer
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const tabs = [
    { 
      name: "Application", 
      content: <><ApplicationView/></>,
      url:"",
      style:{
        width:'160px',
        marginLeft:'0px',
      },
      iconActive:<img src={jobOverviewActive}/>,
      iconInactive: <img src={jobOverviewInactive}/>
    },
    { 
      name: "Documents", 
      content: <><DocumentApplication viewMode={true} /></>,
      url:"",
      style:{
        width:'160px',
        marginLeft:'320px',
      },
      iconActive:<img src={RecruitactiveImage}/>,
      iconInactive: <img src={RecruitInactiveImage}/>
    },
    { 
      name: "Hiring Stages", 
      content:   <></>,
     url:"",
      style:{
        width:'100px',
        marginLeft:'420px',
      },
      iconActive:<img src={activitiesIcon}/>,
      iconInactive: <img src={activitiesIconInactive}/>
    },
    { 
      name: "Notes", 
      content:   <CandidateNotes viewMode={true}/>,
      url:"",
      style:{
        width:'100px',
        marginLeft:'420px',
      },
      iconActive:<img src={activitiesIcon}/>,
      iconInactive: <img src={activitiesIconInactive}/>
    },
 
  ];



  const contactData = [
    { name: "john.doe@example.com", imgSrc: mailIcon },
    { name: "098-765-4321", imgSrc: phoneIcon },
    { name: "Buenos Aires, Argentina", imgSrc: locationIcon },
  ];
    return(
    <div>
      <Card
        bordered={false}
        style={{
          borderTop: `4px solid ${color}`,
          borderRadius: "8px",
          marginBottom: "16px",
          cursor:'pointer'
        }}

        bodyStyle={{ padding: "12px" }}
        onClick={showDrawer}

      >
        
          <div style={{ display: "flex", alignItems: "center", flexDirection:'column' }}>
            <div style={{display:'flex', flexDirection:'row',width:'100%', justifyContent:'space-between'}}>
            <div style={{ display:'flex', flexDirection:'row',alignItems:'center', gap:'8px'}}>
                <Avatar size="large" src={avatar} />
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>{name}</div>      
            </div>
            <Badge
                count={
                <span
                    style={{
                    color: "#000",
                    backgroundColor: status === "low" ? "#FFF1F0" : "#F6FFED",
                    padding: "4px 8px",
                    borderRadius: "999px",
                    border: status==="low"? '1px solid #FFCCC7':'1px solid #D9F7BE',
                    }}
                >
                    <img src={status==="low"?starIconRed:starIconGreen} style={{marginRight:'4px'}}/>{score}
                </span>
                }
            />
            </div>
            <Divider/>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
              <div style={{ display: "flex", alignItems: "center", color: "gray", marginTop: "0px" }}>   
                <img src={fileNotesIcon} alt="fileIcon"/>
                <span>3</span>
                <ClockCircleOutlined style={{ marginLeft: "12px", marginRight: "4px" }} />
                <span>{date}</span>
               
              </div>
             <div
                onClick={(e) => {
                e.stopPropagation(); // Prevent click event from propagating to the Card
              }}            
             >
                <Selectable option={title}/>
              </div>
              </div>
          </div>
         
       
      </Card>
      <Drawer
        title={<div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
          <div>Quick View</div>
          <Button color="default" variant="outlined" style={{border:'1px solid #1890FF'}}>
            <img src={visitProfile}/>
            <div style={{color:'#1890FF', fontWeight:500}}>Manage application</div>
          </Button>
        </div>}
        placement="right"
        onClose={closeDrawer}
        open={isDrawerOpen}
        width={800}
      >
        <div className="drawer-container" >
          <div >
            <Avatar size="large" src={avatar} style={{width:'80px', height:'80px'}}/>
          </div>
          <div >
                <div>Emily Raybon</div>
                <div>Android Developer</div>
            <div style={{display:'flex', flexDirection:'row',gap:'24px',marginTop:'16px'}}>
               {contactData.map((contact, index) => (
               
                 <Details name={contact.name} imgSrc={contact.imgSrc}/>
              
                ))}
                </div>
          </div>
          <div style={{display:'flex', flexDirection:'row', gap:'4px',alignItems:'flex-start', cursor:'pointer'}} onClick={()=>{}}>
            
              <img src={visitProfile} alt="visitprofile"/>
              <div style={{color:'#1890FF'}}>Visit profile</div>
      
           
          </div>
        </div>
        <div>
          <ApplicationStatusCard/>
        </div>
       <Divider/>
       <CustomTabs tabs={tabs} activeTab={activeTab} onClick={(e)=>{setActiveTab(e)}}/>
       {/* <ApplicationManagmentView/> */}
      </Drawer>
      </div>
    );
      
};
export default CandidateCard;