import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./pipeline.css"
import SearchCompo from "../../vacancies/searchComponent";
import SelectFilter from "./select";
import HiringStagesPipeline from "./hiringStagesPipeline";
import addIcon from "../../../assets/addIcon.svg"
import { Button, Skeleton } from "antd";
import visitIcon from "../../../assets/visitIcon.svg"
import { getPipelineViewData } from "./Action/action";
import { useApplicationManagementStore } from "../store/store";
import AddCandidateModal from "../listingPage/addCandidateModal";
import backIcon from "../../../assets/backIcon.svg"


const PipelineView = ()=>{
 
    const [search,setSearch] = useState('');
    const [pipelineData, setPipelineData] = useState(null);
    const [error, setError] = useState(null);
    const [filtersList, setFiltersList] = useState({"all": true, "qualified": false, "unqualified": false,"onHold":false});
    const{openAddCandidateModal,setOpenAddCandidateModal} = useApplicationManagementStore(state=>state)

    const {jobId} = useParams();
  
    const navigate = useNavigate();
    
    const handleRedirect = () => {
      navigate(`/vacancies/${jobId}`);
    };
  
    const fetchPipelineView = ()=>{
      let params = {
        //"jobId" : 482
        "jobId" : jobId
        //"jobId" : 554
      };
      getPipelineViewData(params,(res,err)=>{
        if(err){
          console.error("Error notes:", err);   
        }
        else{
         setPipelineData(res)
        }
      })
    }
   
    useEffect(()=>{
      fetchPipelineView()
    },[])

    useEffect(()=>{
        console.log(pipelineData?.response,"checkingpipeline")
    },[pipelineData]);
  
    if (error) {
      return <div>Error: {error}</div>;
    }

   


    const filterOptions = [
      
        {
          label: <span>All</span>,
          value: 'all',
        },
        {
          label: <span>Qualified</span>,
          value: 'qualified',
        },
        {
          label: <span>Unqualified</span>,
          value: 'unqualified',
        },
        {
          label: <span>On Hold</span>,
          value: 'onHold',
        },
   
  ];
  const matchOptions = [
      
        {
        label: <span>Newest to Oldest</span>,
        value: 'All',
        },
        {
        label: <span>Oldest to Newest</span>,
        value: 'Qualified',
        },
        {
        label: <span>Name(A-Z)</span>,
        value: 'Qualified',
        },
        {
        label: <span>Name(Z-A)</span>,
        value: 'Unqualified',
        },
        {
        label: <span>Best Match First</span>,
        value: 'Unqualified',
        },

    ]  ;
    // const hiringStages = [
    //     { key: '1', label: 'Candidate Sourcing' },
    //     { key: '2', label: 'Resume Screening' },
    //     { key: '3', label: 'Initial Contact' },
    //     { key: '4', label: 'Single Round Interview' },
    //     { key: '5', label: 'Decision Making' },
    //     { key: '6', label: 'Job Offer' },
    //     { key: '7', label: 'On Boarding' },
    //   ];
      const hiringStages = pipelineData?.response?.templateStages?.map((stage, index) => ({
        stagePosition: stage?.stagePosition, // Generate keys dynamically based on index
        label: stage?.stageName, // Use the stageName for the label
        total: stage?.totalApplications,
        totalQualified: stage?.candidates?.qualified?.length,
        totalUnqualified: stage?.candidates?.unqualified?.length,
        totalOnHold: stage?.candidates?.onHold?.length,
        qualifiedCandidates: stage?.candidates?.qualified?.map(candidate => ({
            name: candidate?.candidateName,
            avatar: candidate?.avatar || "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg",
            score: candidate?.score || "0%",
            date: candidate?.date||"Today", // Update dynamically if needed
            // status: candidate.score && parseInt(candidate.score) >= 50 ? "high" : "low",
            status: index+1%2 ? "high":"low",
            applicationId: candidate?.applicationId
          })),
          unQualifiedCandidates: stage?.candidates?.unqualified?.map(candidate => ({
            name: candidate.candidateName,
            avatar: candidate.avatar || "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg",
            score: candidate.score || "0%",
            date: "Today", // Update dynamically if needed
            // status: candidate.score && parseInt(candidate.score) >= 50 ? "high" : "low",
            status: index+1%2 ? "high":"low",
          })),
          onHoldCandidates: stage?.candidates?.onHold?.map(candidate => ({
            name: candidate.candidateName,
            avatar: candidate.avatar || "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg",
            score: candidate.score || "0%",
            date: "Today", // Update dynamically if needed
            // status: candidate.score && parseInt(candidate.score) >= 50 ? "high" : "low",
            status: index+1%2 ? "high":"low",
          })),
      }));
     
    
    return (
        <div style={{color:'#000', marginTop:'40px'}} className="pipelineview-container">
          
            <div className="pipelineview-container-header"> 
              <div style={{display:'flex', flexDirection:'row', gap:'8px'}}>
                <div ><img src={backIcon} style={{marginTop:'6px', cursor:'pointer'}} onClick={()=>{
                  navigate('/application-management')
                }} /></div>
                <div>
                <div>{pipelineData?.response?.jobTitle}</div>               
                {(pipelineData?.response?.jobTitle && pipelineData?.response?.jobCode) ?
                <ul className="breadcrumb">
                  <li>
                    <a href="/application-management" style={{ color: '#7A7A7A' }}>
                      Application Management
                    </a>
                  </li>
                  <li>
                    <a href="" style={{ color: '#7A7A7A' }}>
                      / PipelineView /
                    </a>
                  </li>
                  <li style={{ cursor: 'context-menu' }}>                   
                      {pipelineData.response.jobTitle} - {pipelineData.response.jobCode}
                  </li>
                </ul>
                  : <Skeleton.Input active size="small" style={{ width: 200 }} />
                }
              
                </div>
              </div>
              <div style={{display:'flex', flexDirection:'row'}}>
                <Button style={{width:'149px', height:'36px', borderRadius:'4px', color:'#1890FF', border:'1px solid #1890FF',marginRight:'24px'}}
                onClick={handleRedirect}>
                    <img src={visitIcon} alt="add" />
                    See details
                  </Button>
                  <Button type="primary" style={{width:'149px', height:'36px', borderRadius:'4px'}} onClick={()=>{setOpenAddCandidateModal(true)}}>
                    <img src={addIcon} alt="add" />
                    Add Candidate
                  </Button>
              </div>
            </div>
            <div className="pipelineview-subcontainer">
                <div>
                <SearchCompo
                    onSearch={(value)=>{
                        setSearch(value)
                    }} 
                />
                
                </div>
                <div>
                    <SelectFilter options={filterOptions} handleChange={(value)=>{
                      setFiltersList({[value]:true})
                    }}/>
                    <SelectFilter options={matchOptions}/>               
                </div>
               
            </div>
            <div className="pipelineview-panel" >
                {hiringStages?.map(stage => (
                     <HiringStagesPipeline
                     stagePosition={stage?.stagePosition}
                     title={stage?.label}
                     total={stage?.total}
                     totalQualified={stage?.totalQualified}
                     totalUnqualified={stage?.totalUnqualified}
                     totalOnHold={stage?.totalOnHold}
                     qualifiedCandidates={stage?.qualifiedCandidates}
                     unQualifiedCandidates={stage?.unQualifiedCandidates}
                     onHoldCandidates={stage?.onHoldCandidates}
                     filtersList={filtersList}
                   />
                ))}
            </div>
            {openAddCandidateModal && <AddCandidateModal openAddCandidateModal={openAddCandidateModal} setOpenAddCandidateModal={setOpenAddCandidateModal} />}
        </div>
    );

}

export default PipelineView