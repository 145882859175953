// NotificationsComponent.js
import React, { useEffect,useRef,useState } from 'react';
import backendUrl from '../../service/credentials';
import notification from "antd/lib/notification";
import './style.css'
import notifIcon from '../../assets/notifIcon.svg'
import { CloseOutlined, LoadingOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { getNotification, getNotifications, getNotificationsAlertsCount, notificationAction, readNotification, updateNotificationsAlertsCount } from './action/action';
import { useNotificationStore } from './store/store';
import { timeAgo } from '../vacancies/jobs/JobCard';
import { message, Spin } from 'antd';
import no_job from '../../assets/icons/no-job.svg'
import { useVacancyStore } from '../vacancies/store/store';
import parse from 'html-react-parser';
import { getVacancyRequests } from '../vacancies/Action/action';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import NotificationCard from './notificationCard';

  
export const getInitials=(name)=> {
  const words = name.trim().split(" ");
  if (words.length === 1) {
    return words[0].slice(0, 2).toUpperCase();
  } else {
    return (words[0][0] + words[words.length - 1][0]).toUpperCase();
  }
}

export const stringToColor=(name)=> {
  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to hexadecimal color
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += ('00' + value.toString(16)).slice(-2);
  }

  return color;
}

export const  adjustColorBrightness=(hex, percent)=> {
  let num = parseInt(hex.slice(1), 16); // Remove "#" and parse the hex
  let r = (num >> 16) + percent; // Adjust red channel
  let g = ((num >> 8) & 0x00FF) + percent; // Adjust green channel
  let b = (num & 0x0000FF) + percent; // Adjust blue channel

  r = Math.max(0, Math.min(255, r)); // Clamp value between 0-255
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // Convert back to hex
}


const NotificationsUtil = () => {
  const sseConnectionRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
 const [loading,setLoading]=useState(false);
 const [loadMore,setLoadMore]=useState(false);
  const[openNotificationBar,setOpenNotificationBar]=useState(false);
 const {notificationRes, setNotificationRes}=useNotificationStore(store=>store);
 const {vacancyCreatorFlag,setResponseData} = useVacancyStore(state=>state);
 const [count,setCount]=useState();
 const [reasonPayload,setReasonPayload]=useState({reasonFlag:false,payload:{},notificationId:null});

 const navigate = useNavigate();

  const success = (title, text)=> {
    notification.success({
       message: title,
      description: text,
      duration: 3,
      style: {
        borderRadius: 4,
        border: "#95DE64",
        backgroundColor: "#F6FFED",
        color:"#4D4D4D",
      },
    });
  }

  const error = (title, text) =>{
    notification.error({
      message:title==='error'?'Error!':title,
      description: text,
      duration: 5,
      style: {
        //borderRadius: 11,
        borderRadius: 4,
        border: "#95DE64",
        backgroundColor: "#ff9d9e",
        color:"#4D4D4D",
      },
    });
  }

  const warning = (title, text) =>{
    notification.warning({
      message: title,
      description: text,
      duration: 5,
      style: {
        borderRadius: 4,
      
      },
    });
  }

  const info=(title, text)=> {
    notification.info({
      message: title,
      description: text,
      duration: 6,
      style: {
        borderRadius: 11,
      },
    });
  }




 const sidebarRef = useRef(null);
  const openNotifications=()=>{
    setOpenNotificationBar(true);
    updateNotificationsAlertsCount({userId: vacancyCreatorFlag?3:4,},(res,err)=>{
      if(res)
      setCount()
    })

    getAllNotifications();
  }
  const closeNotifications=()=>{
    setOpenNotificationBar(false);
  }




  const handleOutsideClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      event.stopPropagation();
      setOpenNotificationBar(false); // Close the sidebar if the click is outside
      return;
    }
  };

  useEffect(() => {
    if (openNotificationBar) {
      document.body.style.pointerEvents='none';
      if(sidebarRef?.current)
        sidebarRef.current.style.pointerEvents='auto'

      sidebarRef?.current?.setAttribute('data-flag','active');
      
      // Add event listener when the sidebar is open
      document.addEventListener('mousedown', handleOutsideClick);

    } else {
      // Remove event listener when the sidebar is closed
      document.body.style.pointerEvents='auto';
      sidebarRef?.current?.setAttribute('data-flag','in-active');
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [openNotificationBar]);

  const connectSSE = (retryCount = 0) => {
    if (sseConnectionRef.current) {
      // Connection already exists, no need to create a new one
      return;
    }
    const userId=vacancyCreatorFlag?3:4;

    const sse = new EventSource(`${backendUrl}/api/hiring/notifications?userId=${userId}`);
    sseConnectionRef.current = sse;

    sse.onmessage = (event) => {
      // Handle incoming messages
      console.log("New notification:", event.data);
      const data = JSON.parse(event.data);
      setNotifications([data]);
    };

    sse.onerror = () => {
      console.error("SSE connection error, retrying...");
      sse.close();
      sseConnectionRef.current = null; // Reset the ref on error

      // Retry connection with exponential backoff, capped at 30 seconds
      const retryTimeout = Math.min(30000, Math.pow(2, retryCount) * 1000);
      setTimeout(() => connectSSE(retryCount + 1), retryTimeout);
    };
  };

  useEffect(() => {

    if (sseConnectionRef.current) {
      sseConnectionRef.current.close();
      sseConnectionRef.current = null; // Reset ref on cleanup
    }
    // Establish the SSE connection once when component mounts
    connectSSE(); // Initialize the connection

 
  }, [vacancyCreatorFlag]);

  // useEffect(() => {
  //   // Check if an SSE connection already exists
  //   if (!sseConnectionRef.current) {
  //     console.log("Notification sse connected")
  //     // Establish SSE connection only once
  //     const sse = new EventSource(backendUrl+'/api/hiring/notifications?userId=3');
  //     sseConnectionRef.current = sse;

  //     sse.onmessage = (event) => {
  //       // Handle incoming messages
  //       console.log("New notification:", event.data);
  //       const data = JSON.parse(event.data);
  //       setNotifications([data]);
  //     };

  //     sse.onerror = () => {
  //       sse.close();
  //       sseConnectionRef.current = null; // Reset the ref on error
  //     };
  //   }

  //   // Clean up the connection when component unmounts
  //   // return () => {
  //   //   if (sseConnectionRef.current) {
  //   //     sseConnectionRef.current.close();
  //   //   }
  //   // };
  // }, []);

  useEffect(()=>{

  const flag=sidebarRef?.current?.getAttribute('data-flag')
    if(flag=='in-active'){
      console.log("notification-data",notifications)
    notifications.map((ele, index) => {
      console.log("notification-data",notifications)
     info(ele?.notification?.title,<NotificationCard ele={ele?.notification} navigate={navigate} />);
    });
  }
  getNotificationsAlertsCount({userId: vacancyCreatorFlag?3:4},(res,err)=>{
    if(res)
    setCount(res?.count)
  })
    getAllNotifications();
  },[notifications, vacancyCreatorFlag])


  const getAllNotifications=(page,limit)=>{
    let params={
      userId:   vacancyCreatorFlag?3:4,
      limit:limit||10,
      page:page||1,
    
    }
    setLoading(true);
    getNotifications(params,(response,error)=>{
       setNotificationRes(response)
       setLoading(false);
    })
  }


  const handleActionPerfomed=(id,index)=>{
    let params={
      userId:   vacancyCreatorFlag?3:4,
      notificationId:id
    }
  
    getNotification(params,(response,error)=>{
      console.log("ressss",response,error)
       if(response?.notification){
      const updatedNotificationRes=notificationRes;
      if(updatedNotificationRes?.notifications?.[index]){
        updatedNotificationRes.notifications[index]=response?.notification
      }
      setNotificationRes(updatedNotificationRes);
    }
    })

 

  }
  
  
const loadMoreHandler=()=>{
  let params={
    userId: vacancyCreatorFlag?3:4,
    limit:10,
    page: (notificationRes?.page||0)+1,
  
  }
  setLoading(true);
  getNotifications(params,(response,error)=>{
     setNotificationRes({
      notifications:[...notificationRes?.notifications,...response?.notifications],
      page:response?.page,
      loadMore:response?.loadMore})
     setLoading(false);
  })
}


const invokeVacancyRequests=()=>{
  const params = {
    page: 1,
  limit: 10,
  role: vacancyCreatorFlag===true?"Creator":"Responder",
   }
    getVacancyRequests(params,(res,err)=>{

      setResponseData(res)
    });
}

const handleReadNotification=(id,index)=>{
  const params = {
   userId: vacancyCreatorFlag?3:4,
   notificationId:id

   }
  readNotification(params,(res,err)=>{
    handleActionPerfomed(id,index)
  })
}


const handleLinkClick = (event,data) => {
  const target = event.target;
  console.log("target", target,data)
  if (target.classList.contains("_notification_clickable_element")) {
    const index = target.getAttribute("data-index");
    const navigationData=data?.navigation?.[index];
   const link=navigationData?.link;
   const payload=navigationData?.payload||{}
    if(link) {
      navigate(link,{state:payload}); // Navigate using React Router
      setOpenNotificationBar(false)
    }
  }
};


  return (

    <>

<section class="banner"  ref={sidebarRef} data-flag="in-active">
<div style={{display:'flex',justifyContent:'center',alignItems:'center',position:'relative',cursor:'pointer'}} onClick={openNotifications}>
<img src={notifIcon} alt="NotificationIcon"  style={{width:'20px',height:'20px',cursor:'pointer'}} >

</img>
{
  count?<span className='notification-count'>{count}</span>:null
}

</div>


  <div class={`sidebar ${openNotificationBar?'active-notification-siderbar':''}`}>
  <div className='sidebar__header'>
  <CloseOutlined onClick={closeNotifications} for="menu-control" className="sidebar__close" />
  <h3 className='sidebar__heading'>Notifications</h3>
  </div>

<div className='sidebar__body'>
{notificationRes?.notifications?.map((ele,index)=>{
  let obj={};
try{
obj=JSON.parse(ele.info);

}catch(e){
  obj={}
}


  return <div className='sidebar__notification__card' style={{background: ele?.seenStatus==false?'#E6F7FF':"white"}} onClick={(e)=>{ if(ele?.seenStatus==false) handleReadNotification(ele?.id,index)}} id={index}>
<div className='notification__card__header'>
    <div className='notification__card__profile' style={{backgroundColor:adjustColorBrightness(stringToColor(ele?.requesterId==3?'Praveen Kumar':"Snehodeep"),70),color:adjustColorBrightness(stringToColor(ele?.requesterId==3?'Praveen Kumar':"Snehodeep"),-70)}}>{getInitials(ele?.requesterId==3?'Praveen Kumar':"Snehodeep")}</div>
    <div className='notification__card__content'>
          <div className='notification__card__title' onClick={e=>{handleLinkClick(e,obj)}}>{parse(ele?.message||"")} </div>
          <div className='notification__card__date'>{timeAgo(ele?.createdAt)}</div>

    </div>

</div>


{
 
  obj?.action==true?
  
  <div className='notification__card__actions'>
 
  { reasonPayload.reasonFlag && reasonPayload.notificationId==ele?.id ?
    <div  className='reason-actions'>
       <span className='reason-label'>Got suggestion? We'd love to here them! <span>(optional)</span></span>
       <TextArea rows={4}  placeholder="Reason for denial (0/150)" value={reasonPayload?.payload?.reasonForDenial} style={{marginTop:'8px'}} onChange={(e)=>{
           if(e.target.value.length>150){
          message.warning('Reason cannot exceed 150 characters..')
          return 
       
        }
        setReasonPayload({...reasonPayload,payload:{...reasonPayload?.payload,reasonForDenial:e.target.value}})}}  />
       <div className='reason-action-btns'>
       <button className='notification-ok'  onClick={()=>{
       notificationAction(
          obj?.actionType,
           obj?.endpoint,
           {...reasonPayload.payload}
          ,((response,error)=>{
          
              handleActionPerfomed( ele?.id,index)
               invokeVacancyRequests();
               setReasonPayload({reasonFlag:false,payload:{},notificationId:null})
              
            }))
       }}>Send</button>
       <button className='notification-cancel'  onClick={()=>{ setReasonPayload({reasonFlag:false,payload:{},notificationId:null})}}>Cancel</button>
       </div>
    </div>:
     obj?.buttons?.map(btn=>{
    
     if(btn?.type=='ok'){
     
      return <button className={obj?.endpoint?'notification-ok':'notification-ok notification-accepted'}  onClick={()=>{
      if( obj?.actionType && obj?.endpoint){
        notificationAction(
          obj?.actionType,
           obj?.endpoint,
           btn?.payload,((response,error)=>{
              console.log("error",error,response)
              
                handleActionPerfomed( ele?.id,index)
                invokeVacancyRequests();
                
              
            }))
      }
      }}>{btn?.title}</button>
     }else if(btn?.type=='cancel'){
      return <button className= {obj?.endpoint?'notification-cancel':' notification-cancel notification-canceled'}  onClick={()=>{
         if( obj?.actionType && obj?.endpoint){
        // notificationAction(
        //   obj?.actionType,
        //    obj?.endpoint,
        //    btn?.payload
        //   ,((response,error)=>{
          
        //       handleActionPerfomed( ele?.id,index)
        //        invokeVacancyRequests();
              
        //     }))

        setReasonPayload({reasonFlag:true,payload: {...btn?.payload,reasonForDenial:''},notificationId:ele.id})
      }
      }}>{btn?.title}</button>
     }
    })


  }

</div>
  
  
   :null

  
}


</div>
})}
{
  notificationRes?.loadMore? <div className='load-more-notifications' >
  {loading?<Spin indicator={<LoadingOutlined spin />}/>  :
   <div onClick={() =>{loadMoreHandler()}}>
                    Load More
                    </div>}
                </div>:null
}
{
  loading?
  [...new Array(5)].map((e,i)=>{
 return <><div className='sidebar__notification__card' >
<div className='notification__card__header'>
    <div className='notification__card__profile animated-bg animated-bg-text' ></div>
    <div className='notification__card__content'>
          <div className='notification__card__title animated-bg animated-bg-text' style={{width:'100%',height:'18px'}}> </div>
          <div className='notification__card__title animated-bg animated-bg-text' style={{width:'100%',height:'18px'}}> </div>
          <div className='notification__card__date animated-bg animated-bg-text' style={{width:'25%',height:'10px'}}></div>
    </div>
</div>
{i<3?<div className='notification__card__actions'>
 <button className='notification-ok animated-bg animated-bg-text' style={{width:'100px',height:'30px'}} ></button>
 
</div>:null
}
  
  {i==4?<div className='load-more-notifications'  style={{height:'10px'}}> <div className='animated-bg animated-bg-text' style={{width:'100px',height:'20px'}}></div></div>:null}
  
</div>
</> 
  })
  :  !notificationRes?.notifications?

  <div className='no-notification-data' >

 
                 <img src={no_job}/>
                 <h3>No Data Found!...</h3>
             
        
  </div>:
  null
}

</div>
    
   
    
 
  </div>


  
  
</section>
    </>
  )
};




export default NotificationsUtil;
