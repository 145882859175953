

export const personalInfo=[

    {
        id:1,
        label:"Name",
        type:"input",
        name:"name"
    },
    {
        id:2,
        label:"Job Title",
        type:"input",
        name:"jobTitle",
    },
    {
        id:3,
        label:"Primary Email Id",
        type:"input",
        name:"email"
    },
    {
        id:3,
        label:"Secondary Email Id",
        type:"input",
        name:"secondaryEmail"
    },
    {
        id:4,
        label:"Primary mobile Number",
        type:"input",
        name:"phone"
    },
    {
        id:5,
        label:"Secondary mobile number",
        type:"input",
        name:"secondaryPhone"
    },
    {
        id:6,
        label:"Gender",
        type:"dropdown",
        value:["Male","Female","Other"],
        name:"gender"
    },
    {
        id:7,
        label:"Date of Birth",
        type:"date",
         name:"dob"
    },
    {
        id:8,
        label:"Nationality/Citizenship",
        type:"dropdown",
        name:"nationality"
    },
    {
        id:9,
        label:"Marital Status",
        value:["Single/Unmarried","Married","Widowed","Divorced","Seperated","Others"],
        type:"dropdown",
        name:"maritalStatus"
    },
    {
        id:10,
        label:"Differently-abled",
        value:["Yes","No"],
        type:"dropdown",
        name:"disability"
    },
    {
        id:11,
        label:"Taken a career break",
        value:["Yes","No"],
        type:"dropdown",
        name:"careerBreak"
    }
]

export const AddressInfo=[
    {
        id:1,
        label:"Address line 1",
        type:"input",
        name:"address.currentAddress.addressLine1",
    },
    {
        id:2,
        label:"Address line 2",
        type:"input",
        name:"address.currentAddress.addressLine2",
    },
    {
        id:3,
        label:"Country/Region",
        type:"dropdown",
        name:"address.currentAddress.country",
    },
    {
        id:4,
        label:"State/Region/Province",
        type:"dropdown",
        name:"address.currentAddress.state",
    },
    
    {
        id:5,
        label:"City",
        type:"dropdown",
        name:"address.currentAddress.city",
    },
    {
        id:6,
        label:"Zip/Postal Code",
        type:"input",
        name:"address.currentAddress.zipCode",
    }
]
export const PermanentAddressInfo=[
    {
        id:1,
        label:"Address line 1",
        type:"input",
        name:"address.permanentAddress.addressLine1"
    },
    {
        id:2,
        label:"Address line 2",
        type:"input",
        name:"address.permanentAddress.addressLine2"
    },
    {
        id:3,
        label:"Country/Region",
        type:"dropdown",
        name:"address.permanentAddress.country"
    },
    {
        id:4,
        label:"State/Region/Province",
        type:"dropdown",
        name:"address.permanentAddress.state"
    },
    
    {
        id:5,
        label:"City",
        type:"dropdown",
        name:"address.permanentAddress.city"
    },
    {
        id:6,
        label:"Zip/Postal Code",
        type:"input",
        name:"address.permanentAddress.zipCode"
    }
]
export const Portfolio=[
    {
        id:1,
        label:"Portfolio",
        type:"input",
        name:"portfolio.portfolioLink"
    },
    {
        id:2,
        label:"LinkedIn",
        type:"input",
        name:"portfolio.linkedIn"
    },
    {
        id:3,
        label:"GitHub",
        type:"input",
        name:"portfolio.gitHub"
    },
    {
        id:4,
        label:"Youtube",
        type:"input",
        name:"portfolio.gitHub"
    },
    {
        id:5,
        label:"Instagram",
        type:"input",
        name:"portfolio.instagram"
    },
    {
        id:6,
        label:"Others",
        type:"input",
        name:"portfolio.others"
    },
]
export const Language = [
    {
        id:1,
        label:"Language",
        type:"input",
        name:"language"
        
    },
    {
        id:2,
        label:"Proficiency",
        type:"dropdown",
        value:["Beginner","Proficient","Expert"],
        name:"proficiency"
    },
    {
        id:3,
        label:"",
        type:"checkbox",
        name:"type",

        value:[
            {
              label: 'Read',
              value: 'read',
            },
            {
              label: 'Write',
              value: 'write',
            },
            {
              label: 'Speak',
              value: 'speak',
            },
          ]
    },

]
export function unflattenObj(obj) {

    let newObj = {}
    console.log("obj", obj)
    for (let key in obj) {
       
        let keys = key.split(".")

        keys.reduce((acc, cur, idx) => {
            console.log("85", acc, cur)
            if (idx === keys.length - 1) {
                acc[cur] = obj[key];

            }
            else if (!acc[cur]) {
                acc[cur] = isNaN(keys[idx + 1]) ? {} : [];

            }


            return acc[cur];
        }, newObj)


    }
    return newObj
}
export function flattenObj(obj) {


    let result = {}

    for (let i in obj) {

        if (typeof obj[i] === "object" && i !== "type") {

            let temp = flattenObj(obj[i])

            for (let j in temp) {
                result[i + "." + j] = temp[j]
            }
        }
        else {
            result[i] = obj[i]

        }
    }

    return result



}