import React, { useEffect, useRef, useState } from "react";
import { Form, DatePicker, Select, Input, Button, Row, Col, Divider, Space, Checkbox, Spin, Popover } from "antd"
import { personalInfo, AddressInfo, Portfolio, Language, PermanentAddressInfo, } from "./infoArray";
import CandidateImg from "../../../assets/CandidateInfo.svg"
import styles from "../../vacancies/vacancy_details/recruitmentOverview/recruit.module.css"
import styles1 from "./candidateProfile.module.css"
import ApllicationHistory from "./applicationHistory";

import { useFirstRender } from "../../../custom-hooks/useFirstRender";
import { useCountryStateCityList } from "../../../custom-hooks/useCountryList";
import { useGet } from "../../../custom-hooks/useApi";
import dayjs from 'dayjs';
import PlusIcon from "../../../assets/plusIcon.svg"
import Delete from "../../../assets/deleteIcon.svg"
import pencilIcon from "../../../assets/bluepen.svg"
import Upload from "../../../assets/UploadBlack.svg"
import { PostEditCandidate } from "./action";
import { useParams } from "react-router-dom";
import Api from "../../../service/Api";
import Notification from "../../../service/Notification";
import { flattenObj,unflattenObj } from "./infoArray";
const { RangePicker } = DatePicker;

const CandidateProfile = () => {
    const [toggler, setToggler] = useState(false)
    const { id } = useParams()
    const [checkeds,setChecked]=useState(false)
    console.log("id", id)
    const handleFileChange = (event) => {
        console.log("event", event.target.files)
        const fileList = event.target.files[0];
        if (fileList) {
            Api.uploadAndPost(`/api/candidate/add-candidate-image?applicationId=${id.split("_")[0]}`, { file: fileList }, (per, res) => {
                console.log(per, res)
                setToggler(prev => !prev)
            })
            // Store the file name
            // Optionally, you can handle file upload here, e.g., by sending it to a server
            console.log('Selected file:', fileList);
        }
        // fileList[0]
        // setSelectedFiles(Array.from(event.target.files));
    };
    const buttonref=useRef()
    const [form] = Form.useForm()
    const { countryName, getStatesByCountry, getCitiesByCountryandStates } = useCountryStateCityList()
    const { firstRender } = useFirstRender()
    const [selectedStateList, setSelectedStateList] = useState()
    const [selectedCityList, setSelectedCityList] = useState()
    const [LanguagesArr, setLangArr] = useState([Language])
  
    const [countrySel, setCountrySel] = useState("")
    const { data, isLoading } = useGet("/api/candidate/get-candidate", { applicationId: id.split("_")[0], jobId: id.split("_")[1] }, toggler)
    const handleClick = (event, ele) => {
        const clickedId = event.target.id; // Get the id of the clicked child element
        console.log('Clicked ID:', clickedId, ele);

        // Perform actions based on the clicked id
        if (clickedId === "1") {
            // DeleteDocs({applicationId:appliationId,key:ele?.key},((res,err) =>{
            //   console.log("success")
            //   if(res){
            //       setToggler(prev =>!prev)
            //   }
            // }))
        } else if (clickedId === "2") {
            //   setFileData(ele)
            //   setOpenModal(true)

            console.log('Rename action triggered');
        }
    };
    const onChangeCheck = (e) => {
        
      
       let data= unflattenObj(form.getFieldsValue())
       if(e.target.checked===true){
        if(!data.address.currentAddress.addressLine1){
            Notification.error("Error","Current address cannot be empty")
            return
        }
        setChecked(e.target.checked)
        data.address.permanentAddress=data.address.currentAddress
      
       }
       else{
        data.address.permanentAddress={
            addressLine1
            : 
            undefined,
            addressLine2
            : 
            undefined,
            city
            : 
            undefined,
            country
            : 
            undefined,
            state
            : 
            undefined,
            zipCode
            : 
            undefined}
            setChecked(e.target.checked)
            
       }
       let flatdata=flattenObj(data)
       form.setFieldsValue(flatdata)
       
       console.log(`checked = ${e.target.checked}`,data);
      };
    const content = (ele) => (
        <div style={{ display: "flex", gap: 10, flexDirection: "column" }} onClick={(e) => handleClick(e, ele)}>

            <div style={{ cursor: "pointer" }} id="2">
                <input
                    onChange={handleFileChange}
                    type="file"
                    id="file-upload"
                    style={{ display: 'none' }} // Hide the default file input
                />
                <label htmlFor="file-upload" className={styles.uploadButton} style={{ cursor: "pointer" }}>
                    <img src={Upload} alt="Upload Icon" style={{ height: 15, width: 15 }} /> Upload

                </label>
            </div>

            <div style={{ cursor: "pointer", color: "red" }} id="1"><img src={Delete} />  Remove</div>
        </div>
    );
    useEffect(() => {
        if (data) {

            if (data.languageProficiency?.length > 1
            ) {
                for (let i = 1; i < data.languageProficiency.length; i++) {
                    setLangArr((old) => [...old, Language])
                }
            }

            let newdata = { ...flattenObj(data) }
            newdata["dob"] =newdata["dob"]? dayjs(newdata["dob"], 'YYYY/MM/DD') : ""
            console.log("newData", newdata)
            form.setFieldsValue(newdata)
        }
    }, [data, form])
    if (firstRender) {

        let countryLabel = countryName.map(ele => {
            return ele?.name
        })
        personalInfo[7]["value"] = countryLabel
        AddressInfo[2]["value"] = countryLabel
        PermanentAddressInfo[2]["value"] = countryLabel

    }

    const onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
    };
    const Switch = (type, ele) => {

        switch (type) {
            case "input":
                return <Input placeholder={`Enter ${ele?.label}`} />;
            // This 'break' is redundant if you're using return, but included for your request.

            case "dropdown":
                return (
                    <Select onChange={(value) => {
                        form.setFieldValue({ [ele.name]: value })

                    }}
                        onDropdownVisibleChange={(value) => {
                            console.log("drowdon", value, ele)

                            if (value) {
                                if (ele?.name.includes("address.currentAddress.state") || ele?.name.includes("address.permanentAddress.state")) {

                                    let stateName = getStatesByCountry(ele?.name.includes("address.currentAddress.state") ? form.getFieldValue("address.currentAddress.country") : form.getFieldValue("address.permanentAddress.country")).map(ele => {
                                        return ele?.name
                                    })

                                    setSelectedStateList(stateName)
                                }
                                if (ele?.name.includes("address.currentAddress.city")) {

                                    let cityName = getCitiesByCountryandStates(form.getFieldValue("address.currentAddress.country"), form.getFieldValue("address.currentAddress.state")).map(ele => {
                                        return ele?.name
                                    })

                                    setSelectedCityList(cityName)
                                }
                                if (ele?.name.includes("address.permanentAddress.city")) {

                                    let cityName = getCitiesByCountryandStates(form.getFieldValue("address.permanentAddress.country"), form.getFieldValue("address.permanentAddress.state")).map(ele => {
                                        return ele?.name
                                    })

                                    setSelectedCityList(cityName)
                                }
                            }
                        }}
                        style={{ width: "100%" }}
                        showSearch placeholder={`Select ${ele?.label}`}>

                        {ele?.label?.includes("State") ? selectedStateList?.map((eles, index) => (
                            <Select.Option key={index} value={eles}>
                                {eles}
                            </Select.Option>
                        )) :
                            ele?.label?.includes("City") ? selectedCityList?.map((eles, index) => (
                                <Select.Option key={index} value={eles}>
                                    {eles}
                                </Select.Option>
                            ))

                                :

                                ele?.value?.map((eles, index) => (
                                    <Select.Option key={index} value={eles}>
                                        {eles}
                                    </Select.Option>
                                ))}
                    </Select>
                );


            case "date":
                return <DatePicker style={{ width: "100%" }} format="YYYY-MM-DD" />;


            case "dateDuration":
                return <RangePicker style={{ width: "100%" }} />;

            case "checkbox":
                return <Checkbox.Group options={ele?.value} defaultValue={["read"]} onChange={onChange} />
            default:
                return null; // Optionally handle the default case if type doesn't match any known type
        }

    }

    return (
<div style={{color:'#000', marginTop:'40px'}}  className={styles1.container}>
            <div className="pipelineview-container-header">
              <div>
                <div>{data?.name}</div>               
                <ul class="breadcrumb">
                  <li ><a  style={{color:'#7A7A7A'}}>Candidate's Profile</a></li>
                  {/* <li><a href="" style={{color:'#7A7A7A'}}> / PipelineView / </a></li> */}
                  {/* <li style={{cursor:'context-menu'}}>{`${pipelineData?.response?.jobTitle} - ${pipelineData?.response?.jobCode}`}</li> */}
                </ul>
              </div></div>
        {/* <div
            className={styles1.container}
            style={{
                gap: 20, padding: 24, display: "flex",
                flexDirection: "row", minHeight: "100vh", marginTop: 20, marginBottom: 20
            }}> */}
           <div style={{display:"flex",flexDirection:"row",width:"100%",gap:20,padding:24}}>
            <div
                className={styles1.card}

                style={{ width: "65%", padding: 16, }}>
                <div style={{ display: "flex", justifyContent: "center" }}>{isLoading && <Spin />}</div>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: 8 }}>

                    <img src={CandidateImg} alt="Loading" />
                    <div className={styles.modalTitle}>  Candidate Information</div>
                </div>
                <div className={styles1.center}>
                    <div className={styles1.candImageCircle}>
                        {!data?.
                            profileImage?.includes("null") ? <img src={data?.
                                profileImage} height={"100%"} width={"100%"} style={{ borderRadius: 50 }} /> :
                            data?.name.slice(0, 2).toUpperCase()}

                        <div style={{

                            position: "absolute",
                            bottom: -10,
                            left: "75%",
                            transform: "translateX(-50%)", // Center the icon horizontally
                            // Half the height of the icon to move it outside the circle
                        }}>

                            <Popover content={content("cand")}>

                                <div style={{ height: 30, width: 30, backgroundColor: "#E6F7FF", borderRadius: 15, justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer" }}>  <img src={pencilIcon} alt="Upload Icon" style={{ height: 15, width: 15 }} /></div>


                            </Popover>
                        </div>
                       
                    </div>

                    
                   
                </div>
                <div className={styles1.candName}>{data?.name}</div>
                <Divider />
                <Form form={form} layout="vertical"
                    onFinish={(values) => {
                        //    console.log(unflattenObj(values),values)
                        
                        let datas = unflattenObj(values)
                         datas["dob"]=datas["dob"]?dayjs(datas["dob"], 'YYYY/MM/DD').format('YYYY/MM/DD') : ""
                        //  if(datas.newlanguageProficiency){
                        //  datas={...datas,languageProficiency:datas.languageProficiency.concat(datas.newlanguageProficiency)}
                        //  delete(datas?.newlanguageProficiency)
                        //  }
                         
                         

                        PostEditCandidate({ ...datas, id: data?.id }, (res, err) => {

                        })
                    }}
                >
                    <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                        <div className={styles1.lineHeight}> </div>Personal Info</div>
                    <Row gutter={16} style={{marginTop:10}}>

                        {personalInfo?.map((ele, index) => (
                            <>

                                <Col key={ele?.id} span={12}  >

                                    <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                        {Switch(ele?.type, ele)}

                                    </Form.Item>
                                </Col>



                            </>
                        ))}
                    </Row>
                    <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                        <div className={styles1.lineHeight}> </div>Current Address</div>
                    <Row gutter={16}  style={{marginTop:10}}>

                        {AddressInfo?.map((ele, index) => (
                            <>
                                <Col key={ele?.id} span={12}  >

                                    <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                        {Switch(ele?.type, ele)}

                                    </Form.Item>
                                </Col>
                            </>
                        ))}
                    </Row>
                    
                    <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row",justifyContent:"space-between" }}>
                        <div style={{display:"flex",flexDirection:"row"}}><div className={styles1.lineHeight}> </div>Permanent Address</div> <Checkbox checked={checkeds} onChange={onChangeCheck} style={{color:"grey"}}>Permanent Address Same as current address</Checkbox></div>
                        
                    
                    <Row gutter={16}  style={{marginTop:10}}>

                        {PermanentAddressInfo?.map((ele, index) => (
                            <>

                                <Col key={ele?.id} span={12}  >

                                    <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                        {Switch(ele?.type, ele)}

                                    </Form.Item>
                                </Col>
                            </>
                        ))}
                    </Row>
                    <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>

                        <div className={styles1.lineHeight}> </div>Portfolio/Social Media</div>
                    <Row gutter={16} style={{marginTop:10}}>

                        {Portfolio?.map((ele, index) => (
                            <>

                                <Col key={ele?.id} span={12}  >

                                    <Form.Item key={ele?.id} label={ele?.label} name={ele?.name}>
                                        {Switch(ele?.type, ele)}

                                    </Form.Item>
                                </Col>
                            </>
                        ))}
                    </Row>
                    <div className={styles.modalTitle} style={{ display: "flex", flexDirection: "row" }}>
                        <div className={styles1.lineHeight}> </div>Language Proficiency</div>
                    <Row gutter={16} >
                        {LanguagesArr.map((eles, indexed) => <Col span={24}>
                            <Row gutter={16} style={{ marginTop: 8 }}>
                                {eles?.map((ele, index) => (


                                    <Col key={ele?.id} span={12} style={{ marginTop: 8 }}  >

                                        <Form.Item  key={ele?.name} label={ele?.label} name={`languageProficiency.${indexed}.${ele?.name}`}>
                                            {Switch(ele?.type, ele)}
                                        </Form.Item>

                                    </Col>


                                ))}<Button 
                                
                                
                              
                                onClick={() => {
                                    let data=unflattenObj(form.getFieldsValue())
                                    
                                    data["languageProficiency"]=data.languageProficiency.filter((ele,indexing) =>indexing!==indexed)
                                    form.setFieldsValue(flattenObj(data))

                                    setLangArr(LanguagesArr.filter((ele, Id) => Id !== indexed))
                                }} 
                                className={styles1.deleteBin} style={{ cursor: "pointer",border:"none" }}> <img src={Delete} alt="Delete" /> Remove </Button>

                            </Row></Col>)}
                          
                    </Row>
                    <div onClick={() => {
                        setLangArr((old) => [...old, Language])
                    }} style={{ display: "flex", color: "#1890FF", fontWeight: 600, gap: 5, cursor: "pointer", marginTop: 8 }}>
                        <div style={{ height: 20, width: 20, borderRadius: 10, border: "1px solid #1890FF", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={PlusIcon} alt="Plus +" />

                        </div>
                        Add another language</div>
                    <Row>
                        <Col span={21}></Col>
                        <Col span={3}>
                            <Space>

                                <Button htmlType="submit" type="primary">Save Data</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className={styles.card} style={{ width: "30%", height: "90vh", padding: 24 }}>
                <ApllicationHistory />

            </div>
            </div>
        </div>

    );

}
export default CandidateProfile