
import React, { useState,useEffect,useRef } from "react";
import { Input, Space, Tooltip } from 'antd';

import descArrow from "../../assets/descSort.svg"
import aescArrow from "../../assets/aescSort.svg"
import aescSelected from "../../assets/aescSelectedSort.svg"
import descSelected from "../../assets/descSelected.svg"
import filterIcon from "../../assets/filterIcon.svg"
import selectedFilterIcon from "../../assets/filterIconSelected.svg"
import no_data from '../../assets/icons/no-job.svg'
import './styles.css'
import Pagination from "../../pages/vacancies/customPagination";
import Divider from "../divider/Divider";

import { ReactComponent as ColumnFilterIcon } from '../../assets/icons/filterIcon.svg';
import { ArrowDownOutlined, CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
const { Search } = Input;

const Table=({columns,rowData,loading,scrollMore,selectedColumns,columnFilterFlag,filterApplied})=>{
  const filteredColumns = columnFilterFlag? columns.filter((col) => selectedColumns.includes(col?.meta?.title)):columns;
  const filteredRowData =columnFilterFlag? rowData?.map((row) =>
    row.filter((_, index) => selectedColumns.includes(columns[index]?.meta?.title))
  ):rowData;
  console.log("selected columns",selectedColumns)
  // useEffect(()=>{
  //   const tooltip = document.getElementById('tooltip');
  //   document.querySelectorAll('.table-cell').forEach(cell => {
  //     cell.querySelectorAll('*').forEach(child => {
  //       console.log("child",child,child.scrollWidth > child.clientWidth)
  //       if (child.scrollWidth > child.clientWidth) {
  //         cell.setAttribute('data-has-ellipsis', 'true');
  //         cell.setAttribute('data-tooltip', child.innerText); // Tooltip content
  //       }else{
  //         cell.removeAttribute('data-has-ellipsis');
  //         cell.removeAttribute('data-tooltip');
  //       }
  //     });


  //     cell.addEventListener('mouseenter', event => {
  //       const tooltipText = cell.getAttribute('data-tooltip');
  //       if (tooltipText) {
  //         tooltip.style.display = 'block';
  //         tooltip.textContent = tooltipText;
  //         const rect = cell.getBoundingClientRect();
  //         tooltip.style.top = `${rect.top  +50}px`; // Position above the cell
  //         tooltip.style.left = `${rect.left+100}px`; // Align with the cell
  //       }
  //     });
    
    
    
  //     cell.addEventListener('mouseleave', () => {
  //       tooltip.style.display = 'none';
  //     });
  //   });
    

  // },[columns,rowData,selectedColumns])

  useEffect(() => {
    const hideTooltip = () => {
      const tooltip = document.getElementById('tooltip');
      if (tooltip) {
        tooltip.style.display = 'none';
      }
    };

    window.addEventListener('beforeunload', hideTooltip);
    return () => {
      hideTooltip(); // Ensure tooltip is hidden on unmount
      window.removeEventListener('beforeunload', hideTooltip);
    };
  }, []);


  const tableRef = useRef(null); // Ref to access the table
  const [rowHeights, setRowHeights] = useState([]); // State to store max heights for each row

  useEffect(() => {
      if (tableRef.current) {
          // Calculate the max height for each row
          const rows = Array.from(tableRef.current.rows); // Get all rows in the table
          const heights = rows.map((row) => {
              const cellHeights = Array.from(row.cells).map(
                  (cell) => cell.offsetHeight
              );
              return Math.max(...cellHeights); // Find max height for each row
          });
          setRowHeights(heights); // Update state with calculated heights
      }
  }, [rowData]);

    return (
        <div className="table-wrapper">
        <div className="table-fixed-column-outter">
        <div className="table-fixed-column-inner" style={{minHeight: rowData?'max-content':'80vh',paddingLeft:filteredColumns?.[0]?.meta?.styles?.width}}>
        <table className="table custom-table" ref={tableRef}>
            <thead className="table-thead">
                <tr  style={{height:'61px'}} >
                {filteredColumns?.map((column,index)=>(

                
                   column?.meta?.type=='sort'?
                    <SortCell handler={column?.handler} meta={column?.meta} columns={columns}/>
                    :
                    column?.meta?.type=='dropdown'?
                    <DropDownCell handler={column?.handler} meta={column?.meta} columns={columns} />
                    :
                    column?.meta?.type=='default'?
                    <th className="table-headerCell"  style={{minWidth:column?.meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 111px 0px rgba(0, 0, 0, 0.1)':'none'}}>
                     <div  style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <div>{column?.meta?.title}</div>
                      </div> 
                      </th>
                    :
                    <th className="table-headerCell" style={{width:column?.meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}></th>
                   
                
             
                
                ))}
                </tr>
            </thead>
            <tbody >
            {

              !loading?
           
              filteredRowData?
              <>
                {filteredRowData?.map((data,index) => {
                    return <tr key={index}>
                {data?.map((item,i)=>(

                      <td className="table-cell" style={{
                        boxShadow: i==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none',
                        minWidth:filteredColumns[i]?.meta?.styles?.width,
                        height: rowHeights[index+1], // Apply max height for this row
                        verticalAlign: "top",
                        marginTop:i===0?0.5:0
                        
                        }}
                        
                         key={i}
>
                      <div style={{position:'relative'}}>   {item?.renderItem??'-'}</div>
                    
                      </td>
                     
              
                    ))}
                    </tr>
                })}</>
                :
                <>
               
                <tr  style={{height:'600px',zIndex:1}}>
                <td style={{width:'100%',height:'600px',maxWidth:'100vw',zIndex:1}} className="no-table-data">

            
                 <img src={no_data }/>
                 <h3 style={{maxWidth:'100%'}}>{filterApplied?'Oops! No results.':'Oops! Nothing matches your search.'}</h3>
                 <p style={{maxWidth:'100%'}}>{filterApplied?'We couldn\'t find any matches.':'Try adjusting your search terms or use different keywords for better results.'}</p>
       
             
                </td></tr>
                </>
           
                :
                [...new Array(10)].map(e=>{ 

                  
                return <tr style={{height:'61px'}} >
                {
                  [...new Array(columns?.length??3)].map((e,i)=>{ 
                  return   <td className="table-cell " style={{boxShadow:i==0  && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none',minWidth:filteredColumns[i]?.meta?.styles?.width}} >
                  <div className="animated-bg animated-bg-text" style={{width:'120px',height:'20px',borderRadius:'6px'}}></div>
                    </td>
                  })
                }
             
                </tr>
            
                })
               
                    
             
            

            }
         
                
            </tbody>
          
        </table>
        
       </div>
     
        </div>
        </div>
   
     
    )
}
export default Table;



export  const SortCell=({meta,handler,index,scrollMore,columns})=>{

    const [sort,setSort]=useState();

    return(
        <th className="table-headerCell"   style={{width:meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}>
        <div  style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
          <div className="table-filter-title"> {meta?.title}</div>
       
            <div style={{display:'flex',flexDirection:'column'}} className="table-filter-box">
            <Tooltip title={"Click to sort ascending"}>
                <img src={sort==='ASC'?aescSelected:aescArrow} onClick={()=>{
                  if(sort=='ASC'){
                    handler();
                    setSort();
                  }else{
                handler('ASC');
                setSort('ASC');
                  }
              }} style={{cursor:'pointer'}}/>
              </Tooltip>
              <Tooltip title={"Click to sort descending"} placement="bottom">
                <img src={sort==='DESC'?descSelected:descArrow} onClick={()=>{
               if(sort=='DESC'){
                    handler();
                    setSort();
                  }else{
                handler('DESC');
                setSort('DESC');
                  }
                }} style={{cursor:'pointer'}}/>
                   </Tooltip>
            </div>
            
          </div>
       
      </th>
    )

}

export const DropDownCell=({meta,handler,index,scrollMore,columns})=>{
    const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Tracks dropdown open state
    const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering options
    const [selectedOptions, setSelectedOptions] = useState([]); // Track selected options
    const dropdownRef = useRef(null); // Ref for dropdown to detect clicks outside
    const buttonRef = useRef(null);
    const inputRef=useRef(null);
    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };
  
    // Closes dropdown if clicked outside of it
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current &&
            !dropdownRef.current.contains(event.target) && // Click is outside dropdown
            buttonRef.current &&
            !buttonRef.current.contains(event.target) ) {

          setIsDropdownOpen(false);
          //setSelectedOptions([]);
          
        
        }else if(isDropdownOpen){
            setIsDropdownOpen(false);
            
    
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(()=>{
      if(!isDropdownOpen)
      handleSubmit();
    },[isDropdownOpen])
  
    const handleOptionSelect = (option) => {
      if (meta.isMultiSelect) {
        // Toggle selection in multi-select mode
        if (selectedOptions.includes(option)) {
          setSelectedOptions(selectedOptions.filter((o) => o !== option));
        } else {
          setSelectedOptions([...selectedOptions, option]);
        }
      } else {
        // Single select: only one option can be selected
        setSelectedOptions([option]);
      }
    };
  
    const handleSearch = (term) => {
      setSearchTerm(term);
    };
  
    const handleReset = () => {
      setSelectedOptions([]); // Reset selections
      handleSearch("");
      if( inputRef.current)
      inputRef.current.value=""
     
    };
  
    const handleSubmit = () => {
     
      setIsDropdownOpen(false); // Close the dropdown after submission
      if(selectedOptions?.length>0){
        console.log("TITLETITLE")
        handler(selectedOptions)
      }
      else
      handler()
    };
  
    const filteredOptions = meta.options.filter((option) =>
      option?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  
    return (
      <th className="table-headerCell" style={{width:meta?.styles?.width,boxShadow: index==0 && scrollMore? '3px 5px 13px 0px rgba(0, 0, 0, 0.1)':'none'}}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',position:'relative',gap:'10px',alignItems:"center",width:'100%' }}>
          <div className="table-filter-title">{meta?.title}</div>
          <div style={{display:'flex',flexDirection:'column'}} className={`table-filter-box ${isDropdownOpen? 'table-filter-box-active':''}`}>
          <img
            src={selectedOptions.length>0 ? selectedFilterIcon : filterIcon}
            alt="filterIcon"
            onClick={toggleDropdown} // Toggles dropdown on button click
            ref={buttonRef}
          />
          </div>
          {isDropdownOpen && (
            <div className="table-dropdown" ref={dropdownRef}>
            <div className="search-input"> 
            {meta.options.length > 5 && (
              <>
            <Search
               ref={inputRef}
                  placeholder="Search"
               value={searchTerm}
               allowClear
                  onChange={(e)=>{
                    console.log("drop search",e.target.value,searchTerm)
                    if(e.target.value===' '){
                      handleSearch("");
                    }else
                      handleSearch(e.target.value)
                  }}
                  onClear={()=>{
                    handleSearch("")
                  }}
                  onSearch={(value)=>{
                    handleSearch(value?.trim())
                  }}
              
                />

          <Divider/>
          </>
                )}
            </div>
       
            {meta?.selectAll &&
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedOptions(meta.options?.map(ele=>ele?.value)); // Select all
                        } else {
                          setSelectedOptions([]); // Deselect all
                        }
                      }}
                      checked={selectedOptions.length === meta.options.length}
                    />
                    <label>Select All</label>
                  </div>
                
                }
             
              {meta.isMultiSelect ? (
                <div className="multi-select-dropdown">
                  <div className="options-list">
                 { filteredOptions?.length>0?
                    filteredOptions.map((option) => (
                      <div key={option.value}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option.value)}
                          onChange={() => handleOptionSelect(option.value)}
                        />
                        <label>{option.key}</label>
                      </div>
                    )):
                    <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center',color:'#c8cacb'}}>no data</div>}
                  </div>
                  <Divider/>
                  <div className="actions">
                  <button onClick={(e)=>{
                   if( selectedOptions?.length!=0)
                       handleReset(e)
                    }} style={{color:selectedOptions?.length==0?'#c8cacb':'#F5222D',cursor:selectedOptions?.length==0?'not-allowed':'pointer'}}>Reset</button>
                  <button onClick={handleSubmit}>Apply</button>
                  </div>
              
                </div>
              ) : 
              (
                <div className="single-select-dropdown">
                <div className="options-list">
                { filteredOptions?.length>0?
                filteredOptions.map((option) => (
                <div
                key={option.value}
                className={`option-item ${selectedOptions[0] === option.value ? 'selected' : ''}`}
                onClick={() => handleOptionSelect(option.value)}
                >
                {option.key}
                </div>
                )): <div style={{display:'flex',width:'100%',justifyContent:'center',alignItems:'center',color:'#c8cacb'}}>no data</div>}
                </div>
                <Divider/>
                <div className="actions">
                <button onClick={(e)=>{
                   if( selectedOptions?.length!=0)
                       handleReset(e)
                    }} style={{color:selectedOptions?.length==0?'#c8cacb':'#F5222D',cursor:selectedOptions?.length==0?'not-allowed':'pointer'}}>Reset</button>
                <button onClick={handleSubmit}>Apply</button>
                </div>
              
                </div>

              )}
            </div>
          )}
        </div>
      </th>
    );
}


export const ColumnFilter=({ setSelectedColumns,selectedColumns,columns,meta})=>{
  const [columnFilter,setColumnFilter]=useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Tracks dropdown open state
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering options
  const [selectedOptions, setSelectedOptions] = useState([]); // Track selected options
  const dropdownRef = useRef(null); // Ref for dropdown to detect clicks outside
  const buttonRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  useEffect(()=>{
    if(!isDropdownOpen)
    handleSubmit();
  },[isDropdownOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current &&
          !dropdownRef.current.contains(event.target) && // Click is outside dropdown
          buttonRef.current &&
          !buttonRef.current.contains(event.target) ) {

        setIsDropdownOpen(false);
      }else if(isDropdownOpen){
          setIsDropdownOpen(false);
      }
    };

    setSelectedOptions(selectedColumns);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedColumns]);

    
  const handleOptionSelect = (option) => {
    if (meta.isMultiSelect) {
      // Toggle selection in multi-select mode
      
      if (selectedOptions.includes(option) && selectedOptions.length>1) {
        setSelectedOptions(selectedOptions.filter((o) => o !== option));
      } else if(!selectedOptions.includes(option)) {
        setSelectedOptions([...selectedOptions, option]);
      }
    } else {
      // Single select: only one option can be selected
      setSelectedOptions([option]);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleReset = () => {
   if(selectedColumns.length==0)
    setSelectedOptions([columns?.[0]?.meta?.title]); // Reset selections
   else
   setSelectedOptions(allOptions);
   
  };

  const handleSubmit = () => {
    setIsDropdownOpen(false); // Close the dropdown after submission

    if(selectedOptions?.length>0)
      setSelectedColumns(selectedOptions)
      else
      setSelectedColumns(selectedColumns)  
  };

  const filteredOptions = columns.filter((option) =>
    option?.meta?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );

  const allOptions=columns.map((option) =>option?.meta?.title);




  return (

    <div className='layout-btn column-filter' onClick={()=>{
      setColumnFilter(!columnFilter)
    }} style={{position:'relative'}} >

    <ColumnFilterIcon  className={`column-filter-icon-${selectedColumns?.length>=1?'active':'inacive'}`} 
          alt="filterIcon"
            onClick={toggleDropdown} // Toggles dropdown on button click
            ref={buttonRef} />

    {isDropdownOpen && (
            <div className="table-dropdown" ref={dropdownRef} >
            <div className="search-input"> 
            {columns?.length > 5 && (
              <>
            <Search
                  placeholder="Search"
               value={searchTerm}
               allowClear
                  onChange={(e)=>{
                    
                    console.log("drop search",e.target.value,searchTerm)
                    if(e.target.value==' '){
                      handleSearch("");
                    }else
                    handleSearch(e.target.value)
                  }}
                  onClear={()=>{
                    handleSearch("");
                  }}
                  onSearch={(value)=>{

                    handleSearch(value?.trim())
                  }}
              
                />

          <Divider/>
          </>
                )}
            </div>
       
            {meta?.selectAll &&
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedOptions(columns?.map(ele=>ele?.meta?.title)); // Select all
                        } else {
                          setSelectedOptions([columns?.[0]?.meta?.title]); // Deselect all
                        }
                      }}
                      checked={selectedOptions.length === columns.length}
                    />
                    <label>Select All</label>
                  </div>
                
                }
             
              {meta.isMultiSelect ? (
                <div className="multi-select-dropdown">
                  <div className="options-list">
                 { filteredOptions?.length>0?
                    filteredOptions.map((option,idx) => (
                      <div key={option?.meta?.title}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option?.meta?.title)}
                          onChange={() =>option?.meta?.title!=columns?.[0].meta?.title? handleOptionSelect(option?.meta?.title):null}
                        />
                        <label className={option?.meta?.title==columns?.[0].meta?.title?'editform-custom-label-after':''}>{option?.meta?.title}</label>
                      </div>
                    )):
                    <>nodata</>}
                  </div>
                  <Divider />
                  <div className="actions">
                  <button onClick={(e)=>{
                   if( JSON.stringify(selectedOptions)!=JSON.stringify( allOptions))
                       handleReset(e)
                    }} style={{color:JSON.stringify( allOptions)==JSON.stringify(selectedOptions)?'#c8cacb':'#F5222D',cursor:JSON.stringify(allOptions)==JSON.stringify(selectedOptions)?'not-allowed':'pointer'}}>Reset</button>
                  <button onClick={handleSubmit}>Apply</button>
                  </div>
              
                </div>
              ) : 
             null}
            </div>
          )}
          </div>
   
  )
}

export const ActivejobsFilter=({ setSelectedColumns,selectedColumns,columns,meta})=>{
  const [columnFilter,setColumnFilter]=useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Tracks dropdown open state
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering options
  const [selectedOptions, setSelectedOptions] = useState([]); // Track selected options
  const dropdownRef = useRef(null); // Ref for dropdown to detect clicks outside
  const buttonRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  useEffect(()=>{
    if(!isDropdownOpen)
    handleSubmit();
  },[isDropdownOpen])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current &&
          !dropdownRef.current.contains(event.target) && // Click is outside dropdown
          buttonRef.current &&
          !buttonRef.current.contains(event.target) ) {

        setIsDropdownOpen(false);
      }else if(isDropdownOpen){
          setIsDropdownOpen(false);
      }
    };

    setSelectedOptions(selectedColumns);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedColumns]);

    
  const handleOptionSelect = (option) => {
    if (meta.isMultiSelect) {
      // Toggle selection in multi-select mode
      
      if (selectedOptions.some(e=>e.key==option.key&&e.value==option.value) ) {
        setSelectedOptions(selectedOptions.filter((o) => o.key !== option.key && o.value!==option.value));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    } else {
      // Single select: only one option can be selected
      setSelectedOptions([option]);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleReset = () => {
  
    setSelectedOptions([]); // Reset selections

   
  };

  const handleSubmit = () => {
    setIsDropdownOpen(false); // Close the dropdown after submission
      setSelectedColumns(selectedOptions)

  };

  const filteredOptions = columns.filter((col) =>
   col?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase())
  );


  console.log("selected columns",selectedColumns,selectedOptions)





  return (

    <div className=' column-filter' onClick={()=>{
      setColumnFilter(!columnFilter)
    }} style={{position:'relative'}} >

    {/* <ColumnFilterIcon  className={`column-filter-icon-${selectedColumns?.length>=1?'active':'inacive'}`} 
          alt="filterIcon"
            onClick={toggleDropdown} // Toggles dropdown on button click
            ref={buttonRef} /> */}


            <div className={`active-job-filter-${selectedColumns?.length>0?'active':'inactive'} `} 
            style={{  border: isDropdownOpen|| selectedColumns?.length>0?'1px solid #1890FF':'1px solid #ddd',boxShadow:isDropdownOpen?'0 0 0 2px rgba(5, 145, 255, 0.1)':""}}
            onClick={toggleDropdown} // Toggles dropdown on button click
            ref={buttonRef}>
               <>
            { selectedColumns?.length>0?
           
            <div className="selected-job-tags">
                {selectedColumns?.map((e, index) => {
                      if (index < 2) {
                      return (
                          <div key={index} className="selected-job-tag">
                          <span className="tag-job-name" title={`${e?.key} - ${e?.jobCode||'NA'}`}>{e?.key} - {e?.jobCode||'NA'}</span><CloseOutlined className="tag-remove-icon" style={{fontSize:'8px'}} onClick={(_)=>{_.stopPropagation(); 
                          
                          
            if (meta.isMultiSelect) {
      // Toggle selection in multi-select mode
      
      if (selectedOptions.some(a=>a.key==e.key&&a.value==e.value) ) {
        setSelectedColumns(selectedOptions.filter((o) => o.key!==e.key&&o.value!==e.value));
        setSelectedOptions(selectedOptions.filter((o) => o.key!==e.key&&o.value!==e.value));
        
      } else {
        setSelectedColumns([...selectedOptions, e]);
        setSelectedOptions([...selectedOptions, e])
      }
    } else {
      // Single select: only one option can be selected
      setSelectedColumns([]);
      setSelectedOptions([])
    }
    
}} />
                          </div>
                      );
                      }
                })}

                {selectedColumns?.length > 2 && (
                <div className="select-job-tag">+ {selectedColumns.length - 2} others</div>
                )}
          
            </div>
            :
         
            <span>Select job title(s)</span>
          
            }
            <span style={{marginLeft:'5px'}}>{isDropdownOpen?<UpOutlined/>: <DownOutlined/>}</span>
            </>
            
              
            </div>

    {isDropdownOpen && (
            <div className="table-dropdown" ref={dropdownRef} >
            <div className="search-input"> 
            {columns?.length > 5 && (
              <>
            <Search
                  placeholder="Search"
               value={searchTerm}
               allowClear
                  onChange={(e)=>{
                    
                    console.log("drop search",e.target.value,searchTerm)
                    if(e.target.value==' '){
                      handleSearch("");
                    }else
                    handleSearch(e.target.value)
                  }}
                  onClear={()=>{
                    handleSearch("");
                  }}
                  onSearch={(value)=>{

                    handleSearch(value?.trim())
                  }}
              
                />

          <Divider/>
          </>
                )}
            </div>
       
            {meta?.selectAll &&
                  <div>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedOptions(columns?.map(ele=>ele?.meta?.title)); // Select all
                        } else {
                          setSelectedOptions([columns?.[0]?.meta?.title]); // Deselect all
                        }
                      }}
                      checked={selectedOptions.length === columns.length}
                    />
                    <label>Select All</label>
                  </div>
                
                }
             
              {meta.isMultiSelect ? (
                <div className="multi-select-dropdown">
                  <div className="options-list">
                 { filteredOptions?.length>0?
                    filteredOptions.map((option,idx) => (
                      <div key={idx}>
                        <input
                          type="checkbox"
                          checked={selectedOptions.some(e=>e.key==option.key&&e.value==option.value)}
                          onChange={() => handleOptionSelect(option)}
                        />
                       
                        <label className="option-label"><span>{option?.key}</span><span className="option-dot"></span><span>{option.jobCode||'-'}</span></label>
                      </div>
                    )):
                    <>nodata</>}
                  </div>
                  <Divider />
                  <div className="actions">
                  <button onClick={(e)=>{
                   if( selectedOptions?.length>0)
                       handleReset(e)
                    }} style={{color:selectedOptions.length==0?'#c8cacb':'#F5222D',cursor:selectedOptions.length==0?'not-allowed':'pointer'}}>Reset</button>
                  <button onClick={handleSubmit}>Apply</button>
                  </div>
              
                </div>
              ) : 
             null}
            </div>
          )}
          </div>
   
  )
}