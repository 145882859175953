import React, { useEffect,useRef,useState } from 'react';
import './style.css'
import {  notificationAction } from './action/action';

import { message, Spin } from 'antd';
import { useVacancyStore } from '../vacancies/store/store';
import parse from 'html-react-parser';
import { getVacancyRequests } from '../vacancies/Action/action';
import TextArea from 'antd/es/input/TextArea';


const  NotificationCard=({ele,navigate})=>{
    const {vacancyCreatorFlag,setResponseData} = useVacancyStore(state=>state);
    const [obj,setObj]=useState({});
    const [reasonPayload,setReasonPayload]=useState({reasonFlag:false,payload:{},notificationId:null});
    const invokeVacancyRequests=()=>{
      const params = {
        page: 1,
      limit: 10,
      role: vacancyCreatorFlag===true?"Creator":"Responder",
       }
        getVacancyRequests(params,(res,err)=>{
    
          setResponseData(res)
        });
    }
    // const navigate = useNavigate();
    
  
    
  
    useEffect(()=>{
      try{
        let parsedJson=JSON.parse(ele.info);
        setObj( parsedJson)
        }catch(e){
          setObj({})
        }
  
        invokeVacancyRequests();
    },[ele])
  
  
  
  
  
    const handleLinkClick = (event,data) => {
      const target = event.target;
      console.log("target", target)
      if (target.classList.contains("_notification_clickable_element")) {
        const index = target.getAttribute("data-index");
        const navigationData=data?.navigation?.[index];
       const link=navigationData?.link;
       const payload=navigationData?.payload||{}
        if (link) {
          navigate(link,{state:payload}); // Navigate using React Router
       
        }
      }
    };
  
  
    return (
      <div className='notification-body'>
    <div className='sidebar__notification__card' style={{border:'none',padding:0,height:'max-content !impotant',paddingBottom:'10px'}} >
  <div className='notification__card__header'>
      <div className='notification__card__content'>
            <div className='notification__card__title' onClick={e=>{handleLinkClick(e,obj)}}>{parse(ele?.message||"")} </div>
  
      </div>
  
  </div>
  
  
  {
   
    obj?.action==true?
    
    <div className='notification__card__actions'>
    {
  
       
     reasonPayload.reasonFlag && reasonPayload.notificationId==ele?.id ?
      <div  className='reason-actions'>
         <span className='reason-label'>Got suggestion? We'd love to here them! <span>(optional)</span></span>
         <TextArea rows={4}  placeholder="Reason for denial (0/150)"  value={reasonPayload?.payload?.reasonForDenial} style={{marginTop:'8px'}} onChange={(e)=>{
          if(e.target.value.length>150){
            message.warning('Reason cannot exceed 150 characters..')
            return
          }
          setReasonPayload({...reasonPayload,payload:{...reasonPayload?.payload,reasonForDenial:e.target.value}})
          
          
          }}  />
         <div className='reason-action-btns'>
         <button className='notification-ok'  onClick={()=>{
         notificationAction(
            obj?.actionType,
             obj?.endpoint,
             {...reasonPayload.payload}
            ,((response,error)=>{
              if(response){
            
              setObj({
                    "action":true,
                    "buttons":[ {
                        "title":"Denied",
                        "key":"active",
                        "value":true,
                        "type":'cancel'
                      }
                    ]
                  })
                  invokeVacancyRequests();
                }
  
              
                setReasonPayload({reasonFlag:false,payload:{},notificationId:null})
            })
  
         )}}>Send</button>
         <button className='notification-cancel'  onClick={()=>{ setReasonPayload({reasonFlag:false,payload:{},notificationId:null})}}>Cancel</button>
         </div>
      </div>:
       obj?.buttons?.map(btn=>{
      
       if(btn?.type=='ok'){
     
        return <button className='notification-ok'  onClick={()=>{
      
      notificationAction(
            obj?.actionType,
             obj?.endpoint,
             btn?.payload,((response,error)=>{
                console.log("error",error,response)
                if(response){
                  setObj({
                    "action":true,
            "buttons":[
             {
                "title":"Activated",
                "key":"active",
                "value":true,
                "type":'ok'
              }
            ]
                  })
                }
                invokeVacancyRequests();
              }))
  
        }}>{btn?.title}</button>
       }else if(btn?.type=='cancel'){
        return <button className='notification-cancel'  onClick={()=>{
  
          
  setReasonPayload({reasonFlag:true,payload: {...btn?.payload,reasonForDenial:''},notificationId:ele.id})
        //  notificationAction(
        //     obj?.actionType,
        //      obj?.endpoint,
        //      btn?.payload,((response,error)=>{
        //         console.log("error",error,response)
        //         if(response){
            
        //       setObj({
        //             "action":true,
        //             "buttons":[ {
        //                 "title":"Denied",
        //                 "key":"active",
        //                 "value":true,
        //                 "type":'cancel'
        //               }
        //             ]
        //           })
        //         }
  
        //         invokeVacancyRequests();
            
                
        //       }))
        }}>{btn?.title}</button>
       }
      })
    }
  
  </div>
    
    
     :null
  
    
  }
  
  
  </div>
  
  
      </div>
    )
  
  }

  export default NotificationCard;