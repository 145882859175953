import React, { useState } from 'react';
import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import "./createRequests.css";

const MyDatePicker = ({ date, handleAnticipatedDate }) => {
  const [showWarning, setShowWarning] = useState(false);

  const disablePastDates = (current) => {
    // Disable dates before today
    return current && current < dayjs().endOf('day');
  };

  const handleDateChange = (value) => {
    if (value) {
      setShowWarning(false); // Hide warning if a valid date is selected
      handleAnticipatedDate(value.format("YYYY/MM/DD")); // Send formatted date
    } else {
      // Handle clearing of the date
      handleAnticipatedDate(null);
    }
  };
  const handleKeyDown = (e) => {
    // Allow only digits, arrow keys, and control keys
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
    if (!/[0-9]/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();  // Prevent input of any non-numeric characters
    }
  };

  return (
    
      <DatePicker
        disabledDate={disablePastDates}
        defaultValue={date ? dayjs(date, "YYYY/MM/DD") : null}
        placeholder="Select Date"
        dropdownStyle={{ zIndex: 1000000 }}
        className={`requests-datepicker`}
        onChange={handleDateChange}
        onKeyDown={handleKeyDown}  // Add the keydown event handler
        allowClear
      />
    
  );
};

export default MyDatePicker;
