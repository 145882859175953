import React, { useEffect,useState } from "react"
import { adjustColorBrightness, getInitials, stringToColor } from "../../notification/notification"
import Divider from "../../../components/divider/Divider"
import { CheckOutlined, DownOutlined } from "@ant-design/icons"
import { Dropdown } from "antd"
import visitIcon from '../../../assets/icons/visit.svg'
import { useNavigate,useParams } from "react-router-dom"
import { useApplicationManagementStore } from "../store/store"
import { updateApplication } from "../Action/action"
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { ratingColor } from "../listingPage/candidateCard"
const  CandidateInfo=({getApplicationData})=>{
     const {id,jobId}=useParams()
  const {applicationData,} = useApplicationManagementStore(state=>state);
    
    
    

    const statusBtnColor=(status)=>{
     
        switch(status){
            case "Unqualified":return "#F5222D";
            case "On-hold":return "#FA8C16";
            case "Qualified":return "#52C41A";
            case "New": return "#1890FF";
            case "Joined":return "#1890FF";
            default: return "#E9E9E9";

        }
    }

    const navigate=useNavigate()
 
    const [statusList,setStatusList]=useState([])


        useEffect(()=>{
          setStatusList([
            {
                key:'New',
                label:<>New &nbsp; {applicationData?.application?.status=='New'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
            },
            {
                key:'Qualified',
                label:<>Qualified &nbsp; {applicationData?.application?.status=='Qualified'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
            },
            {
                key:'On-hold',
              
                label: <>On-hold  &nbsp; {applicationData?.application?.status=='On-hold'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
                  
              },
            {
                key:'Unqualified',
               
                label: <>Unqualified  &nbsp; {applicationData?.application?.status=='Unqualified'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
            },
            {
              key:'Joined',
             
              label: <>Joined &nbsp; {applicationData?.application?.status=='Joined'?<CheckOutlined style={{color:'#1890FF'}}/>:null}</>
          }
          
            ])
        

        },[applicationData])


         const updateStatus=(status)=>{
             
              let params={
                applicationStatus:status, id:applicationData?.application?.id};
              updateApplication(params,()=>{
                getApplicationData();
          
              })
            }


            const getValidatedLink = (link) => {
              if (!link) return null; // Return null if the link is not present
              // Ensure the link has a proper prefix
              return link.startsWith('http://') || link.startsWith('https://') 
                ? link 
                : `https://${link}`;
            };
    return(
        <>
   
        <div className="profile-section">
        {applicationData?.candidate?.profileImage?<div className="profile-icon">
<img src={applicationData?.candidate?.profileImage} style={{objectFit:'contain',width:'100%',height:'100%'}} />
</div>
:<div className="profile-icon" style={{backgroundColor:adjustColorBrightness(stringToColor(applicationData?.candidate?.name||""),70),color
         :adjustColorBrightness(stringToColor(applicationData?.candidate?.name||""),-70)}}>{getInitials(applicationData?.candidate?.name||"")}
        </div> 
} 
        
          <div className="rating-hug" style={{width:'max-content',padding:'4px 8px',
          
          border:!applicationData?.application?.ats?'1px solid #D9F7BE':`1px solid ${ratingColor(applicationData?.application?.ats)?.strokeColor}`,
          background:!applicationData?.application?.ats?'#F6FFED':ratingColor(applicationData?.application?.ats)?.fillColor
          }}>     <StarIcon  className="rating-star" style={{'--fillColor':!applicationData?.application?.ats?'#52C41A':ratingColor(applicationData?.application?.ats)?.starColor}}/>{applicationData?.application?.ats||'Processing ...'}</div>
          <div className="name-wrapper">
          <h3>{applicationData?.candidate?.name||'-'}</h3>
          <span>{applicationData?.candidate?.jobTitle||'-'}</span>
          <span>-</span>
          </div>
       
          </div>
          <div className="application-section">
      
          <div className="jobcard-header">
        <div>
        <h3 className="jobcard-title" style={{fontWeight:600}}>Application status</h3>
        <span className="jobcard-field">Application is actively moving through the hiring process.</span>
        </div>
       
        <Dropdown
        menu={{
            items:statusList,
          selectedKeys: [applicationData?.application?.applicationStatus],
        onClick:({key})=>{
    
         updateStatus(key);
        }
        }}
        trigger={['click']}
        placement="bottomRight"
        style={{padding:'0'}}
  
   
    
      >
            <button style={{background:statusBtnColor(applicationData?.application?.applicationStatus)}} className="joboverview-status"><p style={{width:'max-content'}}>{applicationData?.application?.applicationStatus?.toUpperCase()??'-'}</p> <DownOutlined/></button>
     
        </Dropdown>

       
        </div>

       
          <Divider/>
          <div className="hiring-stages">
          <div className="application-stage">
          <div className="stage-dot"></div><span>HIRING STAGE</span>
        </div>
        <div className="stage-box-wrapper">
        {applicationData?.application?.stages  ? applicationData?.application?.stages?.map((ele,index)=>{
             
             return <div className={`hiring-stage-box ${index<=((applicationData?.application?.stagePosition||0)-1)?'completed':index==(applicationData?.application?.stagePosition)?'active':''}`}>
                {index+1}
             </div>

            })
          :
          [...new Array(8)]?.map((ele,index)=>{
             
             return <div className={`hiring-stage-box`} style={{background:'rgb(233, 233, 233)'}} >
            
             </div>

            })
          }
        </div>

          </div>
          <div className="job-applied">
          <div className="jobcard-header">
        <div>
        <h3 className="jobcard-title" style={{fontWeight:600,width:'max-content'}}>{applicationData?.application?.stage||'-'}</h3>
       {applicationData?.job?.jobTitle || applicationData?.application?.createdAt ? 
       <span className="jobcard-field">Application created for 
       <span style={{color:'#1890FF',fontSize:'14px',fontWeight:'600',cursor:'pointer'}} onClick={()=>{
        navigate(`/application-management/pipeline/${applicationData?.job?.id}`)
       }}> {applicationData?.job?.jobTitle||'-'} </span> 
       role on <span style={{fontSize:'14px',fontWeight:'600'}}>{applicationData?.application?.createdAt||"-"}.</span>
        </span>:<span>
         -
        </span>}
        </div>
        </div>

          </div>



          </div>
        <div style={{width:'100%'}}>
        <Divider/>
        </div>
          <div className="personal-info-section">
       
          <div className="job-info-data">
          <div className="data-field">
           <div className="data-name">Name</div>
           <div className="data-value">{applicationData?.candidate?.name||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Job title</div>
           <div className="data-value">{applicationData?.candidate?.jobTitle||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Primary email ID</div>
           <div className="data-value">{applicationData?.candidate?.email||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Secondary email ID</div>
           <div className="data-value">{applicationData?.candidate?.secondaryEmail||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Primary mobile number </div>
           <div className="data-value">{applicationData?.candidate?.phone||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Secondary mobile number </div>
           <div className="data-value">{applicationData?.candidate?.secondaryPhone||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Gender</div>
           <div className="data-value">{applicationData?.candidate?.gender||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Date of birth</div>
           <div className="data-value">{applicationData?.candidate?.dob||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Nationality/Citizenship</div>
           <div className="data-value">{applicationData?.candidate?.nationality||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Marital status</div>
           <div className="data-value">{applicationData?.candidate?.maritalStatus||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Differently-abled</div>
           <div className="data-value">{applicationData?.candidate?.disability||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Taken a career break</div>
           <div className="data-value">{applicationData?.candidate?.careerBreak||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Current address</div>
           <div className="data-value">{Object.values(applicationData?.candidate?.address?.currentAddress||{})?.join(', ')||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Permanent address</div>
           <div className="data-value">{Object.values(applicationData?.candidate?.address?.permanentAddress||{})?.join(', ')||'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Portfolio</div>
           <div className="data-value">
           {getValidatedLink(applicationData?.candidate?.portfolio?.portfolioLink) ? (
        <a href={getValidatedLink(applicationData?.candidate?.portfolio?.portfolioLink)} target="_blank" rel="noopener noreferrer">
          {applicationData?.candidate?.portfolio?.portfolioLink}
        </a>
      ) : (
        '-'
      )}
           </div>
          </div>
          <div className="data-field">
           <div className="data-name">LinkedIn</div>
           <div className="data-value">

           {getValidatedLink(applicationData?.candidate?.portfolio?.linkedIn) ? (
        <a href={getValidatedLink(applicationData?.candidate?.portfolio?.linkedIn)} target="_blank" rel="noopener noreferrer">
          {applicationData?.candidate?.portfolio?.linkedIn}
        </a>
      ) : (
        '-'
      )}
           
           </div>
          </div>

          <div className="data-field">
           <div className="data-name">GitHub</div>
           <div className="data-value">

           {getValidatedLink(applicationData?.candidate?.portfolio?.gitHub) ? (
        <a href={getValidatedLink(applicationData?.candidate?.portfolio?.gitHub)} target="_blank" rel="noopener noreferrer">
          {applicationData?.candidate?.portfolio?.gitHub}
        </a>
      ) : (
        '-'
      )}
           </div>
          </div>
          <div className="data-field">
           <div className="data-name">YouTube</div>
           <div className="data-value">
           
           {getValidatedLink(applicationData?.candidate?.portfolio?.youtube) ? (
        <a href={getValidatedLink(applicationData?.candidate?.portfolio?.youtube)} target="_blank" rel="noopener noreferrer">
          {applicationData?.candidate?.portfolio?.youtube}
        </a>
      ) : (
        '-'
      )}
           </div>
          </div>
          <div className="data-field">
           <div className="data-name">Instagram</div>
           <div className="data-value">
           
           {getValidatedLink(applicationData?.candidate?.portfolio?.instagram) ? (
        <a href={getValidatedLink(applicationData?.candidate?.portfolio?.instagram)} target="_blank" rel="noopener noreferrer">
          {applicationData?.candidate?.portfolio?.instagram}
        </a>
      ) : (
        '-'
      )}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Others</div>
           <div className="data-value">{applicationData?.candidate?.portfolio?.others??'-'}</div>
          </div>
          <div className="data-field">
           <div className="data-name">Language</div>
           <div className="data-value">{applicationData?.candidate?.languageProficiency?.map((lang,i)=>{
            return (i+1)+'. '+(lang?.language||'')+" - "+(lang?.proficiency||'-')+" "+`(${lang?.type?.join(', ')||'-'})\n`

           })??'-'}</div>
          </div>
        </div>
        <div className="visit-profile">
      
            <span onClick={()=>{
              navigate(`/application-management/candidate-profile/${id}_${jobId}`)
            }} ><img src={visitIcon}></img>Visit profile</span>
        </div>
          </div>

   


        </>
    )
}
export default CandidateInfo