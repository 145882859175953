import React from 'react';
import { Dropdown, Space, Typography } from 'antd';
import threeDots from "../../../assets/threeDots.svg"

const items = [
  {
    key: '1',
    label: 'Candidate Sourcing',
  },
  {
    key: '2',
    label: 'Resume Screening',
  },
  {
    key: '3',
    label: 'Initial Contact',
  },
  {
    key: '4',
    label: 'Single Round Interview',
  },
  {
    key: '5',
    label: 'Decision Making',
  },
  {
    key: '6',
    label: 'Job Offer',
  },
  {
    key: '7',
    label: 'On Boarding',
  },
];

const Selectable = ({option}) => {
  console.log(option,"selectedtitle")
  const matchingItem = items.find(item => item.label === option);
  return(
 
  <Dropdown
    menu={{
      items,
      selectable: true,
      defaultSelectedKeys: matchingItem ? [matchingItem.key] : [],
    }}
    trigger={['click']}
  >
    <Typography.Link>
      

       <img src={threeDots} alt="threedots"/>
   
    </Typography.Link>
  </Dropdown>
);
};

export default Selectable;
