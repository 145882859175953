import ApplicationEmpty from "../../../assets/ApplicationEmpty.svg"
import ApplicationHistory from "../../../assets/ApplicationHistory.svg"
import styles from "../../vacancies/vacancy_details/recruitmentOverview/recruit.module.css"
import styles1 from "./candidateProfile.module.css"
import GreenStar from "../../../assets/greenStar.svg"
import { Divider,Spin } from "antd"
import { useGet } from "../../../custom-hooks/useApi"
 import dayjs from 'dayjs';
const arr = [{
  job: "Frontend Developer",
  id: "JV_2024/36",
  location: "Bengaluru/KA",
  jobType: "Full Time",
  type: "remote",
  stage: "Candidate Sourcing"
}, {
  job: "Frontend Developer",
  id: "JV_2024/36",
  location: "Bengaluru/KA",
  jobType: "Full Time",
  type: "remote",
  stage: "Interview Round 1"
},
]
const ApllicationHistory = () => {
  const {data,isLoading}=useGet("/api/candidate/applicationHistory",{candidateId:3})
  console.log(data)
  return (
    <div>
       <div style={{display:"flex",justifyContent:"center"}}>{isLoading&&<Spin/>}</div>
      <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center",gap:8}}>
     
      <img src={ApplicationHistory}/>
      <div className={styles.modalTitle}>Application History</div>
      </div>
      {data?.applications?.length>0&&
      <div style={{marginTop:16}}>
      {data?.applications?.map((ele,index) => {
        return (
          <div className={styles1.applHistoryBox} >
            <div style={{
              display: "flex",
              justifyContent:"space-between",
             width:"100%"
            }}>
              <div>
            <div style={{ color: "#1890FF", fontSize: "16px",marginBottom:2 }}>
              {ele?.jobTitle}
              
              </div>
              {ele?.id}
            </div>
            <div className={styles1.greenBox} style={{height:30}}>
            <img src={GreenStar}/>
            {ele.acs}%
            </div>
            </div>
            <div style={{
              display: "flex", gap: 10,flexWrap:"wrap"

            }}>
              <div className={`${styles1.appHistoryBox} ${styles1.boxAlign}`}>
                {ele?.jobType}

              </div>
              <div className={`${styles1.appHistoryBox} ${styles1.boxAlign}`}>
                {ele?.jobLocation}

              </div>
              <div className={`${styles1.appHistoryBox} ${styles1.boxAlign}`}>
                {ele?.workArrangements}

              </div>
            </div>
            <div style={{display:"flex",gap:4}}>
             <div style={{height:10,width:10,borderRadius:5,backgroundColor:index===0?"#13C2C2":"gray",alignSelf:"center"}}/> 
             <div style={{fontWeight:600,color:index===0?"#13C2C2":"gray"}}>
            {ele?.stage?.toUpperCase() || "Candidate Sourcing"}
            
            </div>
            <div style={{color:"#7A7A7A"}}>{`${dayjs().diff(dayjs(ele?.appliedOn), 'day')} days ago` }</div>
            </div>
           <Divider/>
          </div>
        )
      })}
      </div>}
      <div className={`${styles.dashedBox} ${styles1.center}`} style={{ marginTop: 24, height: "580px", }}>
        <div>
          <img src={ApplicationEmpty} style={{ height: "30%", width: "100%" }} />
          <div className={styles.modalTitle} style={{ textAlign: "center" }}>No Data to display</div>
          <div>This section is currently empty</div>
        </div>
      </div>

    </div>
  )
}

export default ApllicationHistory