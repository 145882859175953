import { DownOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Spin,Select, Progress } from "antd";
import { useEffect, useRef, useState } from "react"
import { DropDownCell } from "../../../components/Table/Table";
import Divider from "../../../components/divider/Divider";
import uploadFile from '../../../assets/icons/uploadFile.svg'
import './styles.css'
import { message, Upload } from 'antd';
import { emailCheck, getActiveJobs, postCandidateApplication } from "../Action/action";
import { useApplicationManagementStore } from "../store/store";
import { useNavigate, useParams } from "react-router-dom";
import backendUrl from "../../../service/credentials";

const { Dragger } = Upload;

const { Search } = Input;

const AddCandidateModal=()=>{
  const {openAddCandidateModal,setOpenAddCandidateModal,setRefresh,refresh}=useApplicationManagementStore(state=>state)

    const [step,setStep]=useState(-1);
    const [isLoading,setIsLoading]=useState(false);
    const [isValid,setIsValid]=useState(false);
    const [isUploading,setIsUploading]=useState(false);
    const [resume,setResume]=useState();
    const [jobOptions,setJobOptions]=useState([ ])
    const [fileList, setFileList] = useState([]);
    const [progress,setProgress]=useState(-1)
    const [form] = Form.useForm();
    const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering options
    const {jobId}=useParams();
    const navigate=useNavigate()

    useEffect(()=>{
      if(progress==100){
          setTimeout(()=>{
              setProgress(-1)
          },500)
        
      }

    },[progress])





    const handleAddCandidate=()=>{

        setIsLoading(true);
        form.submit();
    }

    const handleSearch = (term) => {
        setSearchTerm(term);
      };

      // const handleOnChange=async (changedValues, allValues)=>{
      //   const fieldName = Object.keys(changedValues)[0]; // Get the first key (name of the field)
      //   const fieldValue = changedValues[fieldName]; // Get the value of the changed field
    
      //   try {
      //       // Validate all fields
      //       await form.validateFields();
      //       setIsValid(true)
          
      //     } catch (error) {
      //       console.log('There are invalid inputs:', error);
      //       if(error?.errorFields?.length==0)
      //       setIsValid(true)
      //       else
      //       setIsValid(false)
      //       // Optional: Display errors if needed
      //     }
    
      //   // Log all current form values
      //   console.log('All Form Values:', allValues,fileList);

      // }

      const handleOnChange = async (changedValues, allValues) => {
        const fieldName = Object.keys(changedValues)[0]; // Get the first key (name of the field)
        const fieldValue = changedValues[fieldName]; // Get the value of the changed field
    
        try {
            // Validate only the changed field
            await form.validateFields([fieldName]);
            // setIsValid(true);
        } catch (error) {
            console.log('Error in the changed field:', error);
            if(error?.errorFields?.length!=0){
              setIsValid(false);
              return 
            }
             
                
         
            // Optionally handle specific field errors here if needed
        }
    
        // Validate other fields silently without marking them in the form UI
        const fieldsToCheck = Object.keys(allValues).filter((key) => key !== fieldName);
        const errors = form.getFieldsError(fieldsToCheck).filter(({ errors }) => errors.length > 0);
    
        console.log("error--",errors)
        if (errors.length === 0) {
      
            setIsValid(true);
        } else {
            console.log("Errors in other fields:", errors);
            setIsValid(false);
        }
        // Log all current form values
        console.log('All Form Values:', allValues, fileList);
    };
    

    const filteredOptions = jobOptions.filter((option) =>
        option?.key?.toLowerCase()?.includes(searchTerm?.toLowerCase())||option?.jobCode?.toLowerCase()?.includes(searchTerm?.toLowerCase())
      );

   

      const onPreview = async (file) => {
        let src = file.url;
        const fileName = file.name || file.originFileObj?.name; // Get the original file name
      
        // If no URL is provided, create one from the file object
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
          });
        }
      
        // Determine file type
        const fileType = file.type || file.originFileObj?.type || '';
      
        const fileWindow = window.open();
        
        if (fileType.startsWith('image/')) {
          // Handle image preview
          const image = new Image();
          image.src = src;
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(image.outerHTML);
        } else if (fileType === 'application/pdf') {
          // Handle PDF preview
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<iframe src="${src}" width="100%" height="100%"></iframe>`);
        } else if (fileType.startsWith('text/')) {
          // Handle text file preview
          const response = await fetch(src);
          const textContent = await response.text();
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<pre>${textContent}</pre>`);
        } else {
          // Handle other document types (fallback)
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<a href="${src}" target="_blank">Download File</a>`);
        }
      };
 

      const props = {
        name: 'file',
        multiple: false,
        onPreview:onPreview,
         action:backendUrl+'/api/hiring/upload',
         
        onChange(info) {
        
      
          const { status } = info.file;
         console.log('status',status)
         const newProgress=progress+Math.floor(Math.random()*5);
         if(newProgress<=98){
          setProgress(newProgress)
        }else{
          setProgress(progress)
        }


         if (status !== 'uploading') {
           
          
         }

          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            info.file?setResume(info.file): setResume()
            setIsUploading(false);
            setProgress(100)
         
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setResume();
            setIsUploading(false);
            setProgress(-1)
        
            
          }

         
        },
        beforeUpload(file,fileList){
          setProgress(0)
          const allowedFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
          
          const isAllowed = allowedFormats.includes(file.type);

           const isAllowedSize = file.size / 1024 / 1024 <= 2; // Convert size to MB
                  
                      
          if (!isAllowed || !isAllowedSize) {
            
            !isAllowed?message.error('You can only upload PDF or DOC files!')
             : message.error(`${file.name} is larger than 2MB. Please select a smaller file.`);
          setResume();
          setFileList([])
          }else{
            setIsUploading(true);
            setFileList(fileList)
          }
     
          return isAllowed && isAllowedSize|| Upload.LIST_IGNORE;
          },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };
   
      const SearchComp=()=>{
      return   <Search
        allowClear
         placeholder="Search"
         value={searchTerm}
         onChange={(e)=>{
           e.preventDefault()
           if(e.target.value===' '){
             handleSearch("");
           }else
             handleSearch(e.target.value)
         }}
         onClear={()=>{
           handleSearch("")
         }}
         onSearch={(value)=>{
           handleSearch(value?.trim())
         }}
       style={{width:'100%',padding:'10px'}}
   
       />
      }
    
      
      const validateEmail = async (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Please enter candidate\'s email!"));
        }
        if (! /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
          return Promise.reject(new Error("Please enter a valid email address!"));
        }
    //    try{
    //        await new Promise((resolve, reject) => { emailCheck({ email: value }, (err, res) => {
    //               if (err || !res||!res.data) {
                   
    //                 return reject(new Error("Validation failed!"));
    //               }
    //               else if (res?.data?.exists) {
                   
    //                 return reject(new Error("Candidate Email already exists!"));
    //               }else{
    //                 return resolve();
    //               }
                  
    //             })});

    //         }catch(e){
    //             return Promise.reject(new Error("Validation failed!")); 
    //         } 
              
         
           
         
      };

 

  useEffect(()=>{
    getActiveJobs({},(res,err)=>{
      if(res?.job){
        console.log("jobs",res)
        setJobOptions(res?.job?.filter((job)=>job?.id&&job?.name)?.map((job)=>{
          return {
            key:job?.name,
            value:job?.id,
            jobCode:job?.jobCode
          }
        }))
        const findItem=res?.job?.find(item=>item.id==jobId);
        if(findItem){
        form.setFieldsValue({"jobId":{label:findItem?.name+" - "+(findItem?.jobCode||'NA'),value:findItem?.id}})
        }
      }

    })
  },[jobId,openAddCandidateModal])




      
    return(
        <Modal
         className="add-candidate-modal"
         centered
        title={<div>
              <p className="modal-title">{step<=1?'Add Candidate':"Upload candidate’s resume"}</p>
            <p className="modal-sub">{step<=1?"Quickly create a candidate profile to track and manage applications.":"Simply upload a resume, and we'll handle the rest for easy autofill."}</p>
        </div>}
        footer={
            <div className="footer-wrapper">
            <div>
            {step>1? <button onClick={()=>{
                setStep(1);
            }} className="modal-back">
            Back
          </button>:null}
          </div>
          <div className="footer-submit">
          <button onClick={()=>{
                setOpenAddCandidateModal(false)
            }} className="modal-cancel">
            Cancel
          </button>

          {step>1?
          !isLoading?  <button onClick={()=>{
            if(!isUploading){
                handleAddCandidate()
            }
          }} className={`modal-done ${isUploading?'diable':''}`}  >
          {resume?'Create':'Skip & Create'}
          </button>:
            <button className="modal-done" style={{color:'#ffffff',background:'#1890FF'}}>Create  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
        :    
        <button onClick={()=>{
            if(isValid){
                setStep(2);
            }

        }} className={`modal-next ${isValid?'enable':''}`}>
            Next
          </button>
          }


          </div>
         

            </div>

          

        }
       
        open={openAddCandidateModal}
        onCancel={() =>  setOpenAddCandidateModal(false)}
      >
       
       <Form form={form} name="validateOnly" layout="vertical" autoComplete="off"
    
        onValuesChange={handleOnChange}
       onFinish={values=>{
        if(values.file){
              values.file=values?.file?.file?.originFileObj
       }
       if(values.jobId?.label){
        values.jobId=values.jobId.value
       }
       setIsLoading(true);
        postCandidateApplication(values,(res,err)=>{
          setIsLoading(false);
          if(res){
            setOpenAddCandidateModal(false)
            navigate(`pipeline/${values?.jobId}/application/${res.id}`)
          }
            setRefresh(!refresh);
        })
       }}
       >
       
      <Form.Item
        name="name"
        label="Candidate’s name"
        rules={[
          {
            required: true,
            message: 'Please enter candidate\'s name!'
          },
        ]}
        style={{opacity:step>1?0:1,display:step>1?'none':'block'}}
      
      >
        <Input placeholder="Enter candidate’s name" />
      </Form.Item>
      <Form.Item
        name="jobId"
        label="Position applied for"
        rules={[
          {
            required: true,
            message:'Please choose a related job!'
          },
        ]}
        style={{opacity:step>1?0:1,display:step>1?'none':'block'}}
   
      >

<Select
  allowClear
     disabled={jobId}
      placeholder="Select position applied for"
      for="jobId"
      dropdownRender={(menu) => (
        <>
             
            <div className="search-input"> 
            { (
              <>
              <Form.Item style={{margin:0}}>
              {SearchComp()}
              </Form.Item>
     
          <Divider/>
          </>
                )}
            </div>
    
             {menu}

           
           
        </>
      )}
      options={filteredOptions.map((item) => ({
        label:  item.key+" - "+(item?.jobCode||'NA'),
        value: item.value,
      }))}

      />
        {/* //   <Input type="text" placeholder="Select position applied for" onClick={toggleDropdown}  suffix={<div  ref={buttonRef}>{isDropdownOpen?<UpOutlined/>:<DownOutlined />}</div>}   /> */}
      
      
   
      </Form.Item>
      <Form.Item
        name="email"
        label="Candidate’s email"
        rules={[
       
       {   required: true,
        validator: validateEmail },
        ]}
        style={{opacity:step>1?0:1,display:step>1?'none':'block'}}
      
      >
        <Input  placeholder="Enter candidate’s email" />
      </Form.Item>

      <Form.Item
        name="file"
        style={{opacity:step>1?1:0,display:step>1?'block':'none',marginBottom:0}}
     
      >
      <Dragger {...props}
       className="upload-resume-drag"
        maxCount={1}   
        fileList={fileList}
    
      onRemove={()=>{
         setIsUploading(false)
            setResume();
            setFileList([])
      }}>
    <p className="upload-drag-icon">
     <img src={uploadFile}></img>
    </p>
    <p className="upload-text">Drag and Drop file here or <span>Choose file</span></p>
    <p className="upload-hint">
    Supported formats: PDF, DOC, DOCX (maximum size: 2MB)
    </p>
  </Dragger>
  </Form.Item>
  {fileList?.length>0 && progress>=0 && step>1?
    <Progress percent={progress}  />:
    null
    }
 
    </Form>
      

    
      </Modal>
    )
}

export default AddCandidateModal