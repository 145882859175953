import { useEffect, useState } from "react";
import { Modal, Form, DatePicker, Select, Input, Button, Row, Col, Divider, Space, InputNumber, message,AutoComplete, Spin } from "antd"
import styles from "../recruitmentOverview/recruit.module.css"
import { updateVacancyRequests } from "../../Action/action";
import { useVacancyStore } from "../../store/store";
import dayjs from 'dayjs';
import { LoadingOutlined } from "@ant-design/icons";
import { inputNumberValidation } from "../../../applicationManagement/applicationDetails/application/additionalInfo";
const { Option } = Select;

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const convertDate = (date) => {
    const jsDate = new Date(date);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = new Intl.DateTimeFormat('en-CA', options).format(jsDate);
    
    return formattedDate.replace(/-/g, '/'); // Replace dashes with slashes for the desired format
  };


const MultiAutoComplete = ({data,value = "", onChange }) => {
    console.log("split value",value)
    const [selectedValues, setSelectedValues] = useState(
        value ? value?.split(",").map((item) => item.trim()) : []
      );
      const [options, setOptions] = useState(
        data?.suggestions?.map((e) => ({ value: e, label: e })) || []
      );
 
    
      const handleChange = (value) => {
       // if(value?.length<=0) return 
        setSelectedValues(value);
        onChange(value?.join(','))
        console.log("Selected values: ", value);
      };

     
      
  
   
  
    return (
        <Select
        mode="tags"
        allowClear
        showSearch
        placeholder={"Enter "+data?.label}
        value={selectedValues}
        onChange={handleChange}
        style={{ width: "100%" }}
       
          maxTagCount="responsive" // Limits visible tags
        className="custom-multi-select"
        filterOption={(input, option) =>
          option.label.toLowerCase().startsWith(input.toLowerCase())
        }
        options={options}
      />
    );
  };

  const SingleAutoComplete = ({data,value = "", onChange  }) => {
    const [options, setOptions] = useState(
      data?.suggestions?.map((e) => ({ value: e, label: e })) || []
    );
  
    const handleChange = (value) => {
      // Check if the value already exists in the options
      const optionExists = options.some((option) => option.value === value);
       if (!optionExists && value.trim()) {
        // Add the new value to the options
        const newOption = { value, label: value };
        setOptions((prevOptions) => [...prevOptions, newOption]);
      }


    };
  
    return (
        <>
 <AutoComplete
   value={value}
      placeholder={"Enter "+data?.label}
        options={options}
        filterOption={(inputValue, option) =>
          option.value.toLowerCase().startsWith(inputValue.toLowerCase()) // Ensure case-insensitivity
        }
        onClear={()=>{setOptions(data?.suggestions?.map((e) => ({ value: e, label: e })))}}
        style={{ width: "100%" }}
        onChange={e=>{handleChange(e);onChange(e)}} // Dynamically add new options
   
       
      />
        </>
     
    );
  };
  

const JobOverviewEditModal = (props) => {
    const { vacancyCreatorFlag, jobTitlesSuggestions,  departmentsSuggestions, qualificationsSuggestions,  specializationSuggestions}=useVacancyStore(state=>state);
 
    const arr = [
        {
            id: 1,
            label: "Job title",
            type: "singleAutoComplete",
            name:"jobTitle",
            isRequired:true,
            suggestions:jobTitlesSuggestions,
            message:'Please enter the Job title!',
            
        },
        {   
            id: 2,
            label: "Job code",
            type: "input",
            name:"jobCode",
           
    
        },
        {
            id: 3,
            label: "Job type",
            type: "dropdown",
            value: ["Full-time", "Part-time", "Contract","Freelance","Internship"],
            name:"jobType"
        },
        {
            id: 3,
            label: "Department/Category",
            type:"dropdown",
            name:'department',
            value:departmentsSuggestions,
            isRequired:true,
            message:'Please select department!',
            
        },
        {
            id: 4,
            label: "Team",
            type: "input",
            name:"team"
        },
        {
            id: 5,
            label: "Job location",
            type: "input",
            name:"jobLocation",
          
        },
        {
            id: 6,
            label: "Work arrangements",
            type: "dropdown",
            value:["On-site","Remote","Hybrid"],
            name:"workArrangements",
          
           
        },
        {
            id: 7,
            label: "Qualifications",
            type: "singleAutoComplete",
            name:"qualification",
            suggestions:qualificationsSuggestions,
            // isRequired:true,
            message:'Please add qualifications!',
        },
        {
            id: 8,
            label: "Specializations",
            type: "multiAutoComplete",
            name:"specializations",
            multiSelect:true,
            suggestions:specializationSuggestions,
            // isRequired:true,
            message:'Please add specializations!',

    
        },
        {
            id: 9,
            label: "Certifications",
            type: "input",
            name:"certifications"
        },
        {
            id: 10,
            label: "Industry-specific experience",
            type: "dropdown",
            name:"industryExpertise",
            value:["IT"]
        },
        {
            id: 11,
            label: "Minimum years of experience",
            type: "number",
            name:"minExperience",
            min:0,
            message:'Please enter the minimum experience!',
            isRequired:true
            
    
    
        },
        {
            id: 12,
            label: "Maximum years of experience",
            type: "number",
            name:"maxExperience",
            min:0,
            message:'Please enter the maximum experience!',
            validation:'Maximum experience must be greater than or equal to minimum experience!',
            dependency:'minExperience',
            dependencyValue:0,
            isRequired:true,
            _validate:(a,b)=>a>=b
            
        },
        {
            id: 13,
            label: "Preferred notice period (In days)",
            type: "number",
            name:"noticePeriod",
            min:0,
    
        },
        {
            id:14,
            label:"Travel involvement",
            type:"dropdown",
            name:"travelInvolvement",
            value:["Yes","No","Maybe"]
        },
        {
            id:15,
            label:"Currency",
            type:"dropdown",
            name:"currency",
            value:["USD ($)", "EUR (€)", "GBP (£)", "INR (₹)", "AUD (A$)", "CAD (C$)", "SGD (S$)", "CHF (CHF)", "CNY (¥)", "JPY (¥)", "KRW (₩)", "RUB (₽)", "BRL (R$)", "ZAR (R)", "NZD (NZ$)", "MXN ($)", "HKD (HK$)", "TRY (₺)", "AED (د.إ)", "SAR (ر.س)", "MYR (RM)", "THB (฿)", "PHP (₱)", "IDR (Rp)", "VND (₫)", "KWD (د.ك)", "EGP (ج.م)", "ILS (₪)", "PKR (₨)", "BDT (৳)", "LKR (රු)", "QAR (ر.ق)", "OMR (﷼)", "BHD (.د.ب)", "NGN (₦)", "TZS (TSh)", "KES (KSh)"],
            isRequired:true,
            message:'Please select currency!',
            
        },
        {
            id:16,
            label:"Compensation structure",
            type:"dropdown",
            name:"compensationStructure",
            value:["Per annum","Per hour","Per project","Per task","Per milestone","Per diem","Per unit"],
            isRequired:true,
            message:'Please select compensation structure!',
            
        },
        {
            id: 17,
            label: "Minimum range",
            type: "number",
            name:"minCompensation",
            min:0,
            message:'Please enter the minimum compensation!',
            isRequired:true,
            
            
           
    
        },
        {
            id: 18,
            label: "Maximum range",
            type: "number",
            name:"maxCompensation",
            min:0,
            message:'Please enter the maximum compensation!',
            validation:'Maximum compensation must be greater than or equal to minimum compensation!',
            dependency:'minCompensation',
            dependencyValue:0,
            isRequired:true,
            _validate:(a,b)=>a>=b
            
        }
    
    ]
 
    const defaultKeys=["minCompensation","maxCompensation","minExperience","maxExperience"]

    let datastored={...props.data};


 const [loading,setLoading]=useState(false);
  
    return (
        <Modal centered={true} open={props?.editModal} onCancel={() => { props.setEditModal(false) }} onClose={() => props.setEditModal(false)} width={"80%"} style={{ top: 5 }} footer={false}>
            <div style={{padding:20}}>
                <div className={styles.modalTitle} style={{marginBottom:16,fontSize:24}}>Edit Information</div>
                <Form  layout="vertical" 
                 className="job-overview-edit-modal"
                onFinish={(values) => {

                    if(!datastored?.id){
                        message.warning("Vacancy not found!")
                        return 

                    }
                
                    setLoading(true);
                    let obj={
                        jobId:datastored?.id,
                         role:vacancyCreatorFlag?'Creator':"Responder",
                        ...values
                    }
                    console.log(" job overview values", obj, datastored)
          
                     updateVacancyRequests(obj,(res,err) =>{
                       setLoading(false)
                        props.fetchJobData({jobId:datastored?.id,  role:vacancyCreatorFlag?'Creator':"Responder"});
                        props.setEditModal(false);
            
                     })
                  
                }} >
                    <Row gutter={16}>

                        {arr?.map((ele, index) => (
                            <>
                             {index==7?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",marginBottom:10}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Job Requirements</div>
                                :index==15?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",marginBottom:10}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>  Compensation</div>
                                :index===0?<div className={styles.modalTitle} style={{marginLeft:8,width:"100%",display:"flex",flexDirection:"row",marginBottom:10}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Basic Job Information</div>
                            :""}
                                <Col key={ele?.id} span={ 8}  >

                                    <Form.Item   initialValue={defaultKeys?.includes(ele?.name)?datastored[ele?.name]||0:datastored[ele?.name]} key={ele?.id} label={
                                    // ele?.isRequired?
                                    // <div className="editform-custom-label-wrapper"><label for={ele?.label} className="editform-custom-label" title={ele?.label}>{ele?.label}</label></div>
                                    
                                    // :
                                    ele?.label
                                    } name={ele?.name}          
                                                          
                                   rules={ [
                                        {
                                        required:
                                        // datastored[ele?.name]
                                        ele?.isRequired
                                        ?true:false,
                                        message: ele?.message,
                                        },
                                        ({ getFieldValue,setFieldValue }) => ({
                                        validator(_, value) {

                                        



                                            if(ele?.dependency){
                                            let dValue= getFieldValue(ele?.dependency);
                                            if(ele?.type=='number'){
                                                   dValue=Number(dValue)
                                                   value=Number(value)
                                            }

                                           
                                            if(dValue==undefined||dValue==null){
                                                setFieldValue(ele?.dependency,ele?.dependencyValue)
                                            }
                                            if (value==undefined ||value==null || ele?._validate(value,dValue)) {
                                             
                                            return Promise.resolve();
                                            }
                                            return Promise.reject(
                                            new Error(ele?.validation)
                                            );
                                            }else{
                                                console.log("error message",ele?.message,datastored[ele?.name], ele?.isRequired)
                                                return Promise.resolve();
                                            }
                                        },
                                        }),
                                    ]}
                              
                                   
                                    >
                                        {ele?.type == "dropdown" ?
                                            <Select placeholder={`Select ${ele?.label}`} allowClear>
                                                {ele?.value?.map(eles => (
                                                    <Select.Option value={eles}>{eles}</Select.Option>
                                                ))}

                                            </Select> : ele?.type === "date" ?
                                                <DatePicker  style={{ width: "100%" }} format="YYYY-MM-DD"/>
                                                : ele?.type === "dateDuration" ?
                                                    <RangePicker style={{ width: "100%" }} />
                                                    :ele?.type==="number"?
                                                    <InputNumber   onKeyDown={inputNumberValidation}
    placeholder={`Enter ${ele?.label}`}  min={ele?.min}  style={{width: '100%'}} 


                                                    />:ele?.type==="singleAutoComplete"?<>
          
                                              <SingleAutoComplete data={ele}/>     
                                                    </>
                                                    :ele?.type==="multiAutoComplete"?
                                                   <MultiAutoComplete data={ele}/>
                                              
                              
     
                         
                                               
                                                    
                                                    : <Input type={ele?.type} placeholder={`Enter ${ele?.label}`} />}

                                    </Form.Item>
                                </Col>
                              
                                {(index === 6 || index === 14) && <Divider style={{marginBottom:32}}/>}
                               
                            </>
                        ))}
                    </Row>
                    <Row>
                        <Col span={18}></Col>
                        <Col span={6}>
                            <Space>
                 <Button onClick={()=>{
                      props.setEditModal(false);
                 }}>Cancel</Button>
                 {loading?
                    <Button  type="primary" style={{pointerEvents:'none'}}>Save Data <Spin indicator={<LoadingOutlined spin  style={{color:'#fff'}} />}></Spin></Button>:
                 
                 <Button htmlType="submit" type="primary">Save Data</Button>}
                 </Space>
                 </Col>
                 </Row>
                </Form>
                  {/* <CustomAutoComplete suggestions={ele?.suggestions}  multiSelect={ele?.multiSelect}/> */}
            </div>
        </Modal>
    )
}

export default JobOverviewEditModal






