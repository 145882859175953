import React, { useEffect, useState } from "react";
import CustomTabs from "../../../components/CustomTabs/CustomTabs";

import { useApplicationManagementStore } from "../store/store";

import { ArrowLeftOutlined, DeleteFilled, DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined, ExclamationCircleFilled, PlusCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import activitiesIcon from "../../../assets/activitiesIcon.svg"
import activitiesIconInactive from "../../../assets/activitiesIconInactive.svg"
import RecruitactiveImage from "../../../assets/recruitOverview.svg"
import RecruitInactiveImage from "../../../assets/recruitInactive.svg"
import applicationActive from '../../../assets/icons/activeApplicationIcon.svg';
import applicationInactive from '../../../assets/icons/inactiveApplicationIcon.svg';
import './styles.css'
import { Dropdown, message, Modal } from "antd";
import CandidateInfo from "./candidateInfo";

import Application from "./application/application";
import nodata from '../../../assets/icons/no-job.svg'
import CandidateNotes from "../candidateNotesActivities/candidateNotes";
import CandidateActivities from "../candidateNotesActivities/candidateActivities";

import DocumentApplication from "./documents/document";
import AdditionalInfo from "./application/additionalInfo";
import { deleteApplication, getApplication, getApplicationMetaCount, getCandidate } from "../Action/action";
function ApplicationDetails ({tab})
{

    const navigate=useNavigate();
    const {applicationData,setApplicationData,setApplicationLoading} = useApplicationManagementStore(state=>state);
   const previousUrl=localStorage.getItem('previousUrl')
    const {id,jobId}=useParams();
    const { confirm } = Modal;


    const [docsCount,setDocsCount]=useState(0);
    const[notesCount,setNotesCount]=useState(0);
    
        useEffect(()=>{
          
          if(applicationData?.application?.id){
            getApplicationMetaCount({applicationId:applicationData?.application?.id},(res,err)=>{
              
               setDocsCount(res?.docsCount||0);
              
              
                setNotesCount(res?.notesCount||0);
         
            })
          }
         
        },[applicationData?.application?.id,tab])

    const tabs = [
        { 
          name: "Application", 
          content: <><Application/></>,
          url:`/application-management/pipeline/${jobId}/application/${id}`,
          style:{
            width:'160px',
            marginLeft:'0px',
          },
          iconActive:<img src={applicationActive}/>,
          iconInactive: <img src={applicationInactive}/>
        },
        { 
          name: "Documents", 
          content: <><DocumentApplication /></>,
          url:`/application-management/pipeline/${jobId}/application/${id}/documents`,
          style:{
            width:'160px',
            marginLeft:'320px',
          },
          iconActive:<img src={RecruitactiveImage}/>,
          iconInactive: <img src={RecruitInactiveImage}/>,
          count:docsCount
        },
        { 
          name: "Hiring Stages", 
          content:   <></>,
         url:`/application-management/pipeline/${jobId}/application/${id}/hiring-stages`,
          style:{
            width:'100px',
            marginLeft:'420px',
          },
          iconActive:<img src={RecruitactiveImage}/>,
          iconInactive: <img src={RecruitInactiveImage}/>
        },
        { 
          name: "Notes", 
          content:   <CandidateNotes/>,
          url:`/application-management/pipeline/${jobId}/application/${id}/notes`,
          style:{
            width:'100px',
            marginLeft:'420px',
          },
          iconActive:<img src={activitiesIcon}/>,
          iconInactive: <img src={activitiesIconInactive}/>,
          count:notesCount
        },
        { 
          name: "Activities", 
          content:   <CandidateActivities/>,
          url:`/application-management/pipeline/${jobId}/application/${id}/activities`,
          style:{
            width:'100px',
            marginLeft:'420px',
          },
          iconActive:<img src={activitiesIcon}/>,
          iconInactive: <img src={activitiesIconInactive}/>
        },
     
      ];


      useEffect(()=>{

        getApplicationData();

      },[id])

      const getApplicationData=()=>{
        const params={
          applicationId:id,
       
        }
        setApplicationLoading(true);
        
          getApplication(params,(response,error)=>{
            if (error) {
              console.error("Error posting vacancy:", error);
            } else {
            
              setApplicationData(response);
              setApplicationLoading(false);
      
            }
        
          })
      }




  
      const handleDelete = ()=>{
    
        
        deleteApplication(id,(response,error)=>{
          if (error) {
            console.error("Error deleting job application:", error);
          } else {
            console.log("Job application deleted successfully:", response);
            navigate(`/application-management/pipeline/${jobId}`)
          
          }
        });
      }

      const showConfirm = () => {
        confirm({
          title: 'Delete Confirmation',
          icon: <ExclamationCircleFilled />,
          content: 'Are you sure you want to delete this? This action cannot be undone.',
          onOk() {
            handleDelete()
          },
          onCancel() {
            
          },
        });
      };
 

    return (
   
        <div className="vacancies-wrapper">
     
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:'20px',}}>
    
        <div style={{display:'flex',flexDirection:'row',gap:'10px'}} >
        <div style={{marginTop:'4px',width:'30px',cursor:'pointer'}} onClick={()=>{
            navigate(previousUrl||'/vacancies/active');
        }}>
            <ArrowLeftOutlined style={{fontSize:'18px'}}/>
        </div>
        <div>
        {!applicationData || Object.keys(applicationData||{})?.length==0?
        <>
        <h3 className="animated-bg animated-bg-text" style={{width:'220px',height:'18px'}}></h3>
        <br></br>
             <p className="animated-bg animated-bg-text" style={{width:'150px',height:'15px'}}></p>
        </>
          
          :      <>
          <h3 style={{margin:'3px 0px'}} >{applicationData?.candidate?.name||'-'}</h3>
          <p style={{margin:0}}><Link to={'/application-management'} style={{color:'black'}} className="vacancy-link"> Application Management </Link> 
          / <Link to={`/application-management/pipeline/${jobId}`} style={{color:'black'}} className="vacancy-link"> Pipeline View </Link> 
          / Application
          / {applicationData?.job?.jobTitle||'-'} - {applicationData?.job?.jobCode||'-'} </p>

          </> 

        }
    
        
        </div>
        </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',gap:'10px'}}>
      <div className="share-jd" onClick={()=>{
    const url=window.location.href;
     navigator.clipboard.writeText(url)
        .then(() => {
          // alert('Content copied to clipboard!');
     
          message.success("Application link copied to clipboard.")

        })
        .catch(err => {
          // console.error('Failed to copy: ', err);
        });
      }} ><ShareAltOutlined /></div>
      <div className="more-jd"> 
      <Dropdown
       
    menu={{
      items:[
        ['On-hold','New','Unqualified'].includes(applicationData?.application?.applicationStatus)? {
    label: <div className="delete-jd" onClick={showConfirm}> <DeleteFilled/> Delete</div>,
    key: '1',
  }:null,
  {
    label: <div className="export-jd"> <DownloadOutlined/> Export</div>,
    key: '2',
  },

]
   
    }}
    trigger={['click']}
  
  >
       <EllipsisOutlined  />
       </Dropdown>
       
       </div>
    
      </div>
      
            </div>
            <div className="application-wrapper">

              <div className="appliation-candidate-info">
              <CandidateInfo  getApplicationData={getApplicationData}/>

              </div>
              <div className="application-info">
              <div className="application-tabs">
              <CustomTabs tabs={tabs} activeTab={tab} onClick={()=>{}}/>
              </div>
           
              {tab=='Application'&& <AdditionalInfo applicationData={applicationData} getApplicationData={getApplicationData}/>}
           
          
            
              </div>

            </div>
     
         

    
        
    </div>
       
    );
}

export default ApplicationDetails;