import React, { useState,useEffect } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from 'dayjs'
import { useActivitiesStore } from "../../vacancies/store/store";
import CustomTimeline from "../../vacancies/vacancy_details/activities/timelineItem";
import { getVacancyActivities } from "../../vacancies/Action/action";
import { getCandidateActivities } from "../Action/action";
import "./candidateActivities.css"


const CandidateActivities = ()=>{
    const {id} = useParams();

    // const {activitiesData, setActivitiesData}  = useActivitiesStore(state=>state);
    const [activitiesData, setActivitiesData] = useState({})
    const [selectedOption, setSelectedOption] = useState({ month: "", year: "" });
    const [page, setPage] = useState(1);
    const [limit,setLimit] = useState(10);
    const [loadmore,setLoadmore] = useState(false);
    const [dateValue, setDateValue] = useState(dayjs().format("YYYY-MM"));
    const [tempActivitiesData, setTempActivitiesData] = useState({});

   
    const handleChange = (date) => {
      if (date) {
        setSelectedOption({
          month: date.month() + 1, // Month is 0-indexed
          year: date.year(),
        });
        

      } else {
        setSelectedOption((prev) => ({
          ...prev, // Spread to create a new object reference
          month: "",
          year: "",
        }));
        setDateValue('')
      }
     

    };

  const getVacancyActivitiesFunc = (params)=>{
    console.log(params,"checkactivities")
    
    getCandidateActivities(params,(response,error)=>{
      if (error) {
        console.error("Error geting activities:", error);
      } else {
        
        setActivitiesData(response?.result)
      
        // setActivitiesData({
        //   ...activitiesData,
        //   ...tempActivitiesData,
        // });
        setLimit();
        setPage();
        
        console.log("Activities check :",activitiesData);
      }
    })
  }
  useEffect(()=>{
    console.log("checkactivities",typeof selectedOption?.year)
    if(selectedOption?.year !==""){
      console.log("checkactivities",typeof selectedOption?.year)
    setDateValue(`${selectedOption?.year}-${String(Number(selectedOption?.month)).padStart(2, '0')}`);
    }
    let params = {
        applicationId: id,
        year: selectedOption?.year,
        month: selectedOption?.month,
        page: page,
        limit: limit,
    }
    getVacancyActivitiesFunc(params)
  },[selectedOption,page])

  // const formattedData = {};
  // for (const [key, value] of Object.entries(activitiesData)) {
  //   // Split the month and year
  //   const [month, year] = key.split('-');
    
  //   // Create a date string formatted as "Month Year"
  //   const date = new Date(year, month - 1);
  //   const formattedDate = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    
  //   // Assign the activities (or other data) to the new formatted key
  //   formattedData[formattedDate] = value || [];
  // }
  // console.log(activitiesData,"ACTIVITIES")

  // const sections = Object.entries(formattedData)?.map(([key, value]) => ({
  //   label: key,
  //   data: value
  // }));

   const formattedData = useMemo(() => {
     
      const data = {};
      for (const [key, value] of Object.entries(activitiesData)) {
        const [month, year] = key.split('-');
        const date = new Date(year, month - 1);
        const formattedDate = date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
        data[formattedDate] = value || [];
      }
      console.log(data,"datepicker")
      return data;
    }, [activitiesData]);
    
  
    const sections = useMemo(() => {
      if ((!selectedOption.year || !selectedOption.month) && Object.keys(formattedData).length > 0) {
      
        return Object.entries(formattedData).map(([key, value]) => ({
          label: key,
          data: value,
        }));
      }
    
      const formattedKey = new Date(selectedOption.year, selectedOption.month - 1).toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
      });
    
      return Object.entries(formattedData)
        .filter(([key]) => key === formattedKey) // Include only the matching entry
        .map(([key, value]) => ({
          label: key,
          data: value,
        }));
    }, [formattedData, selectedOption]);
  
  //console.log(sections?.data,"ACTIVITIES")

  const defaultDate = new Date(
    selectedOption?.year || new Date().getFullYear(),
    (selectedOption?.month ? selectedOption?.month - 1 : new Date().getMonth())
  );

    return(
 
                <div className="candidate-activities-container">
                    <div >
                        Jump to:
                        
                            <DatePicker
                                picker="month"
                                onChange={handleChange}
                                value={dateValue? dayjs(dateValue,"YYYY-MM"):null}
                                placeholder="Select Month and Year"
                                style={{width:'200px'}}
                                
                            />
                        
                    </div>
                    <div>
                        
                            {sections.map((section, index) => (
                                <div key={index} className="activities-timeline">
                                    <div>{section.label}</div>
                                    <CustomTimeline 
                                        options={section?.data}
                                        onLoadMore={async (nextPage) => {
                                          //handleLoadMore(section);
                                              const date = new Date(section?.label);
                                              console.log("checkactivities")
                                              setLoadmore(true)
                                              setTempActivitiesData((prev) => ({
                                                ...activitiesData,
                                              }))
                                              // setSelectedOption((prev) => ({
                                              //   ...prev, // Spread to create a new object reference
                                              //   month: date.getMonth() + 1,
                                              //   year: date.getFullYear(),
                                              // }));
                                              setPage(section?.data?.page+1)
                                              setLimit(5)
                                              
                                          }}
                                          type="activities"
                                    />
                                </div>
                            ))}
                            {/* </>
                            } */}
                    </div>  
                        
                   
                   
                </div>
    
    );
}

export default CandidateActivities