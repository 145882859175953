import React,{useEffect,useRef,useState} from "react";
import './styles.css'
import { Col, Dropdown, Form, Input, Modal, Row, Spin, Upload ,message,DatePicker, Checkbox, Button} from "antd";
import uploadFile from '../../../../assets/icons/uploadFile.svg'
import { useNavigate, useParams } from "react-router-dom";
import eye from '../../../../assets/icons/eye.svg'
import resumeIcon from '../../../../assets/icons/resume.svg'
import educationIcon from '../../../../assets/icons/educationIcon.svg'
import workExperienceIcon from '../../../../assets/icons/work-experience.svg'
import nodata from '../../../../assets/icons/no-job.svg'
import dayjs from 'dayjs';
import {  CloseOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, LeftCircleFilled, LeftCircleOutlined, LeftOutlined, LoadingOutlined, MoreOutlined, PlusCircleOutlined, RightCircleFilled, RightCircleOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons";
import moment from "moment";
import { deleteResume, getApplication, getCandidate, updateApplication } from "../../Action/action";
import { useApplicationManagementStore } from "../../store/store";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Document, Page,pdfjs } from "react-pdf";
import backendUrl from "../../../../service/credentials";
import Divider from "../../../../components/divider/Divider";

// Set the workerSrc to the PDF.js worker script
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const {TextArea} = Input;
const { RangePicker } = DatePicker;
const { Dragger } = Upload;

export const MoreActions=({data,setShowUpload,deleteApplicationResume})=>{

  

    const downloadPdf = async(url, fileName) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
    
        const blob = await response.blob();
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName+'_resume.pdf';
        document.body.appendChild(link);
        link.click();
        
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href); // Clean up blob URL
          // Show success message after successful download
      message.success('Resume downloaded successfully!');
      } catch (error) {
        console.error('Error downloading file:', error);
        message.error('Failed to download resume. Please try again.');
      }
    };
    
 
   
   const statusList=[
     {
         key:'1',
         label:<span onClick={()=>{
            setShowUpload(true)
         }}><UploadOutlined/> Upload new</span>
     },
     {
         key:'2',
       
         label: <span onClick={()=>{
          downloadPdf(data?.application?.resumeLink, (data?.candidate?.name||+'candidate'))
         }
         } ><DownloadOutlined/> Download</span>
           
       },
     {
         key:'3' ,
        
         label:<span className="delete-resume" onClick={()=>{
         deleteApplicationResume()

         }
         }><DeleteOutlined/> Remove</span> 
     }
   
     ]
  return ( <div 
   onClick={(e) => e.stopPropagation()} >
       <Dropdown
       menu={{
           items:statusList,
       onClick:({key,domEvent})=>{
    
        //  domEvent.stopPropagation();
 
        //  switch(key){
        //    case '1':navigate(`/pipeline/${data?.jobId}/application/${data?.applicationId}`);
        //              return ;
        //    case '2':navigate(`/pipeline/${data?.jobId}`);
        //             return ;
        //    case '3':navigate(`/candidate-profile/${data?.candidateId}`);
        //  }
     
       }
       }}
       trigger={['click']}
       placement="bottomRight"
       style={{padding:'0'}}
       className="dropdown-status"
    
  
   
     >
       <MoreOutlined  className="more-btn" style={{rotate:'90deg'}}/>
       </Dropdown>
       </div>);
 }

function Application(){
    const {applicationData, setApplicationData,applicationLoading,setApplicationLoading} = useApplicationManagementStore(state=>state);
    const [isUploading,setIsUploading]=useState(false);
    const [resume,setResume]=useState();
    const [showUpload,setShowUpload]=useState(false);
    const uploadRef=useRef()
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [skillModal,setSkillModal]=useState(false);
    const [aboutModal,setAboutModal]=useState(false);
    const [about,setAbout]=useState();
    const[workExperienceModal,setWorkExperienceModal]=useState(false);
    const [newSkill,setNewSkill]=useState();
    const [skills,setSkills]=useState([]);
    const [isModalLoding,setIsModalLoading]=useState(false);
    const {id,jobId}=useParams()
    const [workExperience, setWorkExperience] = useState([
      {
        current: false,
        company: "",
        jobTitle: "",
        location: "",
        workDuration :[],
        details: "",
      },
    ]);
  const [isResumeModalOpen,setResumeModal]=useState(false)
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
    const[educationModal,setEducationModal]=useState(false);
    const [pdfSize, setPdfSize] = useState('');

    const [educations, setEducations] = useState([
      {
        current:false,
        degree: '',
        area: "",
        university: "",
       courseDuration:[]
   
      },
    ]);

      // Handle page load
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

    
    const formatDate=(startDate,endDate)=>{
      return [startDate?moment(startDate, "YYYY-MM-DD"):null, endDate?moment(endDate, "YYYY-MM-DD"):null]

    }
  
    // Synchronize form values with the state
    const handleExperienceValuesChange = (_, allValues) => {
    
    
      const formattedWorkExperience = allValues?.workExperience?.map((experience) => ({
        ...experience,
        workDuration: [experience?.workDuration?.[0],experience.current?null:experience?.workDuration?.[1]],
      }));

      setWorkExperience(formattedWorkExperience);
      form1.setFieldsValue({ workExperience: formattedWorkExperience });
    };
  
    const handleAddExperience = () => {
      const newExperience = {
        current: false,
        company: "",
        jobTitle: "",
        location: "",
        startDate: null,
        endDate: null,
        details: "",
      };
      setWorkExperience((prev) => [...prev, newExperience]);
      form1.setFieldsValue({ workExperience: [...workExperience, newExperience] });
    };
  
    const handleRemoveExperience = (index) => {
      if (workExperience.length === 1) {
        const newExperience = {
          current: false,
          company: "",
          jobTitle: "",
          location: "",
          startDate: null,
          endDate: null,
          details: "",
        };
        setWorkExperience([ newExperience]);
        form1.setFieldsValue({ workExperience: [ newExperience] });
        return;
      }
      const updatedExperience = workExperience.filter((_, i) => i !== index);
      setWorkExperience(updatedExperience);
      form1.setFieldsValue({ workExperience: updatedExperience });
    };



    const handleEduactionValuesChange = (_, allValues) => {
      const formattedEducation = allValues?.educations?.map((education) => ({
        ...education,
        courseDuration:  [ education?.courseDuration?.[0],education.current?null: education?.courseDuration?.[1]],
      }));

      setEducations(formattedEducation);
      form2.setFieldsValue({ educations:formattedEducation });
    };
  
    const  handleAddEducation = () => {
      const newEducation = {
        current:false,
        degree: '',
          area: "",
        university: "",
        courseDuration:[]
      };
      setEducations((prev) => [...prev, newEducation]);
      form2.setFieldsValue({ educations: [...educations, newEducation] });
    };
  
    const handleRemoveEducation = (index) => {
      if (educations.length === 1) {
        const newEducation = {
          current:false,
          degree: '',
         area: "",
          university: "",
          courseDuration:[]
        };
        setEducations([ newEducation]);
        form2.setFieldsValue({ educations: [ newEducation] });
        return;
      }
      const updatedEducation = educations.filter((_, i) => i !== index);
      setEducations(updatedEducation);
      form2.setFieldsValue({ educations: updatedEducation });
    };


   
const deleteApplicationResume=()=>{

  if( !applicationData?.application?.id)return 
  setApplicationLoading(true)
  deleteResume(applicationData?.application?.id,(res,err)=>{
    setApplicationLoading(false)
    getApplicationData()
          
  })


}



   
    const onPreview = async (file) => {
        let src = file.url;
        const fileName = file.name || file.originFileObj?.name; // Get the original file name
         console.log("filllle",file)
        // If no URL is provided, create one from the file object
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file?.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
      
        // Determine file type
        const fileType = file.type || file.originFileObj?.type || '';
      
        const fileWindow = window.open();
        
        if (fileType.startsWith('image/')) {
          // Handle image preview
          const image = new Image();
          image.src = src;
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(image.outerHTML);
        } else if (fileType === 'application/pdf') {
          // Handle PDF preview
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<iframe src="${src}" width="100%" height="100%"></iframe>`);
        } else if (fileType.startsWith('text/')) {
          // Handle text file preview
          const response = await fetch(src);
          const textContent = await response.text();
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<pre>${textContent}</pre>`);
        } else {
          // Handle other document types (fallback)
          fileWindow?.document.write(`<h2>${fileName}</h2>`);
          fileWindow?.document.write(`<a href="${src}" target="_blank">Download File</a>`);
        }
      };

      const updateResume=()=>{
        if(!resume)return 
        const params={
          id:applicationData?.application?.id,
         file:resume
        }
        setIsModalLoading(true);
        updateApplication(params,(res,err)=>{
          if(res){
          setShowUpload(false);
          setResume();
          }
          setIsUploading(false);
          setIsModalLoading(false);
           getApplicationData();
         })
        
      }
    const props = {
        name: 'file',
        multiple: false,
        onPreview:onPreview,
         action:backendUrl+'/api/hiring/upload',
         
        onChange(info) {
          
          console.log("aborted",info)
          const { status } = info.file;

 
          if (status !== 'uploading') {
           
          
          }
       
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
            setResume(info?.file?.originFileObj);
            if(!showUpload){
           const params={
              id:applicationData?.application?.id,
             file:info?.file?.originFileObj
            }
           updateApplication(params,(res,err)=>{
            setShowUpload(false);
            setIsUploading(false);
             getApplicationData();
           })
          }else{
            setIsUploading(false);
          }
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setIsUploading(false);
           // setShowUpload(false);
            
          }

        },
        beforeUpload(file,fileList){
            const allowedFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
          
      
            const isAllowed = allowedFormats.includes(file.type);
            console.log("file--list",file,fileList)
            const isAllowedSize = file.size / 1024 / 1024 <= 2; // Convert size to MB
        
            
            if (!isAllowed) {
            message.error('You can only upload PDF or DOC files!');
            }else if (!isAllowedSize) {
              message.error(`${file.name} is larger than 2MB. Please select a smaller file.`);
            }
            else{
                setIsUploading(true);
               // setShowUpload(false);
            }
            return isAllowed && isAllowedSize ||Upload.LIST_IGNORE;
            },
            onRemove(e){
            
                setIsUploading(false);
             
            },
          
            
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };

      const updateAbout=()=>{
      
        const params={
          id:applicationData?.application?.id,
          headline:about
        }
        setIsModalLoading(true)
        updateApplication(params,(res,err)=>{
        
          setIsModalLoading(false);
          if(res){
            setAboutModal(false);
          }
          getApplicationData();
        
    
        })

      }

      const updateEducation=()=>{
      
        const params={
          id:applicationData?.application?.id,
          education:educations.map(ele=>{
            
            let obj={
              ...ele,
              startDate:ele?.courseDuration?.[0]?.format('YYYY-MM-DD'),
              endDate:ele?.courseDuration?.[1]?.format('YYYY-MM-DD')
            }
            if(obj.hasOwnProperty('courseDuration'))delete obj.courseDuration;
            return obj
          })
        }
        setIsModalLoading(true)
        updateApplication(params,(res,err)=>{
        
          setIsModalLoading(false);
          if(res){
            setEducationModal(false)
          }
          getApplicationData();
        
    
        })

      }

      const updateExperience=()=>{
      
        const params={
          id:applicationData?.application?.id,
          workExperience:workExperience?.map(ele=>{
        
            let obj={
              ...ele,
              startDate:ele?.workDuration?.[0]?.format('YYYY-MM-DD'),
              endDate:ele?.workDuration?.[1]?.format('YYYY-MM-DD')
            }
            if(obj.hasOwnProperty('workDuration'))delete obj.workDuration;
            return obj
          })
        }
        setIsModalLoading(true)
        updateApplication(params,(res,err)=>{
        
          setIsModalLoading(false);
          if(res){
           setWorkExperienceModal(false)
          }
          getApplicationData();
        
    
        })

      }

 
      const addSkills=()=>{
        if( newSkill?.trim()?.length>15){
          message.warning('keyword length cannot be  more than 15 characters!')
          return 
        }
        if(newSkill?.trim()){
        setSkills( [newSkill.trim(),...skills])
        setNewSkill();
        }else{
          setNewSkill();
        }
    }
     const removeSkills=(skill)=>{
      setSkills( skills?.filter(v => v !== skill))
  }

  const updateSkills=()=>{
    if(skills.length==0 && (!applicationData?.skills ||applicationData?.skills?.join(',').length==0)){
      message.warning("Please input skills")
      return;
    }
    const params={
      id:applicationData?.application?.id,
      skills:skills?.join(',')
    }
    setIsModalLoading(true)
    updateApplication(params,(res,err)=>{
      setIsModalLoading(false);
      if(res)
      setSkillModal(false)
      getApplicationData();

    })
  }
   

  useEffect(()=>{
    if(showUpload && uploadRef.current){
        console.log("query",uploadRef.current)
        const inputElement = uploadRef.current.nativeElement.querySelector('input[type="file"]');
        if (inputElement) {
          inputElement.click();
        }
    }

  },[showUpload])


      useEffect(()=>{
        getApplicationData();
      },[id])

  
    const getApplicationData=()=>{
      const params={
        applicationId:id,
      
      }
      setApplicationLoading(true);
        getApplication(params,(response,error)=>{
   
          if (error) {
            console.error("Error fetching application:", error);
          } else {
            setApplicationData(response);
            setApplicationLoading(false);
          }
      
       
       
        })
      
    }


    useEffect(()=>{
      if(applicationData){
      setAbout(applicationData?.application?.headline);
      resetSkills(applicationData);
      resetWorkExperince(applicationData);
      resetEducation(applicationData);
   
      }
    

    },[applicationData])

    const resetSkills=(data)=>{
      setSkills(data?.application?.skills?.split(',')||[]);
    }

    const resetWorkExperince=(data)=>{
      const resetData=data?.application?.workExperience?.map(ele=>{
        return {
          ...ele,
          workDuration:formatDate(ele?.startDate,ele?.endDate)
        }

      })||[      {
        current: false,
        company: "",
        jobTitle: "",
        location: "",
        workDuration :[],
        details: "",
      },]
      setWorkExperience(resetData);

      form1.setFieldsValue({workExperience:resetData})
    }
    const resetEducation=(data)=>{

      const resetData=data?.application?.education?.map(ele=>{
        return {
          ...ele,
          courseDuration:formatDate(ele?.startDate,ele?.endDate)
        }

      })||[  {
        current:false,
        degree: '',
        area: "",
        university: "",
       courseDuration:[]
   
      }]
      setEducations(resetData);

      form2.setFieldsValue({educations:resetData})
    }

    const getLastNameFromUrl = (url) => {
      return url?url?.substring(url?.lastIndexOf('/') + 1):null;
    };

    const getPdfSize = (url, callback) => {
      fetch(url, { method: 'HEAD' })
        .then((res) => {
          const contentLength = res.headers.get('Content-Length');
          if (contentLength) {
            callback(`${(contentLength / 1024).toFixed(2)} KB`);
          } else {
            console.log('Unable to retrieve PDF size');
            callback('');
          }
        })
        .catch((err) => {
          console.error('Error fetching PDF size:', err);
          callback('');
        });
    };
    
    useEffect(() => {
      if(applicationData?.application?.resumeLink){
      getPdfSize(applicationData?.application?.resumeLink, (size) => {
        setPdfSize(size); // Update the state with the PDF size
      });
    }
    }, [applicationData?.application?.resumeLink]);
  
  
  
    const compareValues=(param1,param2,remove)=>{
      if(remove ){
        param1?.map(ele=>{
          if(ele?.hasOwnProperty([remove])) delete ele[[remove]]
          return ele
        })
        if(param1?.every(p=>Object.values(p)?.every(e=>e==null||e==undefined||e==false||typeof e =='string' && e?.trim()=="")) && !param2){
          return true;
        }
      }else{
        param1=param1?.toString()?.trim()
        if(!param1 && !param2)
          return true
      }
      
    
      const newValues=JSON.stringify(param1);
      const oldValues=JSON.stringify(param2);
      return newValues==oldValues
    }
      
 

    return (
        <>
            <div className="job-application-container">

          {!applicationLoading?
            <div className="resume-section">
            {
               
            !applicationData?.application?.resumeLink|| (isUploading && !showUpload) ?
            <>
        {isUploading && !showUpload?<div className="resume-container">Uploading....</div>:null} 
           <Dragger {...props} 
   
     ref={uploadRef}
      className="drag-upload"
       maxCount={1}
       style={{display:isUploading?'none':'block'}}
      >
    
    <p className="upload-drag-icon">
     <img src={uploadFile}></img>
    </p>
    <p className="upload-text">Drag and Drop resume here or <span>Choose file</span></p>
    <p className="upload-hint">
    Supported formats: PDF, DOC, DOCX (maximum size: 2MB)
    </p>
          </Dragger>
 
            </>
          :

        <div className="resume-container">
      <div className="resume-data">
      <img src={resumeIcon}></img>
      <div className="resume-meta">
        <h3>Resume</h3>
        <span>{getLastNameFromUrl(applicationData?.application?.resumeLink)||'NA'}</span>
        <span>{ pdfSize||'-'}</span>
      </div>

      </div>
      <div className="resume-actions">
      <div className="view-resume" onClick={()=>{
        setResumeModal(true)
      }}>
        <img src={eye}></img>
        <span >View</span>
      </div>
      <div className="resume-more-action">
        <MoreActions data={applicationData} setShowUpload={setShowUpload} deleteApplicationResume={deleteApplicationResume}/>
      </div>

      </div>

        </div>}

            </div>:
       
            <div className="resume-section">
            <div className="resume-container">
            <div className="resume-data">
      <span  className="animated-bg animated-bg-text" style={{width:'100px',height:'120px',borderRadius:'6px'}}></span>
      <div className="resume-meta">
        <h3 className="animated-bg animated-bg-text"  style={{width:'200px',height:'16px'}}></h3>
        <span className="animated-bg animated-bg-text"  style={{width:'100px',height:'11px'}}></span>
        <span className="animated-bg animated-bg-text"  style={{width:'100px',height:'10px'}}></span>
      </div>

      </div>
      <div className="resume-actions">
      <div className="view-resume animated-bg animated-bg-text "  style={{width:'100px',height:'16px'}}>

      </div>
      <div className="resume-more-action animated-bg animated-bg-text" style={{width:'20px',height:'16px'}}>
  
      </div>

      </div>
            </div>
            </div>
          }

            <div className="headline-section">
            <div className="keywords-header">
                <div  className="keywords-title" style={{width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600,marginBottom:'20px'}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Headline</div>
              
                <div className="keywords-edit" onClick={()=>{
                    setAboutModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>

            </div>
            <span>{applicationData?.application?.headline||'-'}</span>
            </div>
            <Divider margin='15px 0px'/>


            <div className="experience-section">
            <div className="keywords-header">
                <div  className="keywords-title" style={{width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600,marginBottom:'20px'}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Work Experience</div>
                {applicationData?.application?.workExperience?.length>0?
                <div className="keywords-edit" onClick={()=>{
                    setWorkExperienceModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>:null}
            </div>
              
                { applicationData?.application?.workExperience?.length>0?
          
               

                <div className="workexperience-cards">
                  {applicationData?.application?.workExperience?.map(experience=>{
             
                 
                    return <div className="workexperience-card">
                  
                      <div className="workexperience-profile">
                      <div>
                      <img src={workExperienceIcon} />
                      </div>
                          

                      </div>
                    
                
                    <div className="workexperience-header">
                    <div className="workexperience-title">
                        <h3>{experience?.jobTitle||"-"}</h3>
                        <div >
                        <span>{experience?.company||'-'}</span>
                        <div className="block-dot"></div>
                        <span> 
                         {experience?.location||'-'}
                        </span>
                        </div>
                       
                      
                        <span style={{fontSize:'12px',fontWeight:'500',color:'#7A7A7A'}}>{moment(experience?.startDate).format('MMM YYYY')} - {experience?.current?'Present':moment(experience?.endDate).format('MMM YYYY')}</span>
                      </div>
                    <div className="workexperience-contact">
                    {experience?.details}
                    </div>
                    </div>




                    </div>

                  })}
                </div>
                
                
                
                :
                <div className="keywords-nodata" >
                    <img src={nodata}></img>
                    <h3>No data to display</h3>
                    <p>This section is currently empty. Add information to see it here.</p>
                    <button  onClick={()=>{
                    setWorkExperienceModal(true)
                }}><PlusCircleOutlined/> Add work experience</button>
                </div>
               }
            </div>

            <Divider margin='15px 0px'/>
            <div className="education-section">
            <div className="keywords-header">
                <div  className="keywords-title" style={{width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600,marginBottom:'20px'}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Education</div>
                {applicationData?.application?.education?.length>0?
                <div className="keywords-edit" onClick={()=>{
                    setEducationModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>:null}
            </div>
              
                { applicationData?.application?.education?.length>0?
                  <div className="workexperience-cards">
                  {applicationData?.application?.education?.map(education=>{
                
                 
                    return <div className="workexperience-card">
                  
                      <div className="workexperience-profile">
                      <div>
                      <img src={educationIcon} />
                      </div>
                          

                      </div>
                    
                
                    <div className="workexperience-header">
                    <div className="workexperience-title">
                        <h3>{education?.degree||"-"}</h3>
                        <div >
                        <span>{education?.university||'-'}</span>
                        <div className="block-dot"></div>
                        <span> 
                         {education?.area||'-'}
                        </span>
                        </div>
                       
                      
                        <span style={{fontSize:'12px',fontWeight:'500',color:'#7A7A7A'}}>{moment(education?.startDate).format('MMM YYYY')} - {education?.current?'Present':moment(education?.endDate).format('MMM YYYY')}</span>
                      </div>
                  
                    </div>




                    </div>

                  })}
                </div>:
                <div className="keywords-nodata" >
                    <img src={nodata}></img>
                    <h3>No data to display</h3>
                    <p>This section is currently empty. Add information to see it here.</p>
                    <button  onClick={()=>{
                  setEducationModal(true)
                }}><PlusCircleOutlined/> Add education</button>
                </div>
               }
            </div>
            <Divider margin='15px 0px'/>

            <div className="skill-section">
            <div className="keywords-header">
                <div  className="keywords-title" style={{width:"100%",display:"flex",flexDirection:"row",color:'#1F1F1F',fontSize:'16px',fontWeight:600,marginBottom:'20px'}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>Skills</div>
                {applicationData?.application?.skills?.length>0?
                <div className="keywords-edit" onClick={()=>{
                    setSkillModal(true)
                }}>
                  <EditOutlined style={{ color: "#1890FF"}} />
                   <div> Edit</div>
                </div>:null}
                </div>
              
                { applicationData?.application?.skills?.length>0?
                <div className="keywords-list">
                  {applicationData?.application?.skills?.split(',')?.map(keyword=>{
                    return <div>{keyword}</div>
                  })}
                </div>:
                <div className="keywords-nodata" >
                    <img src={nodata}></img>
                    <h3>No data to display</h3>
                    <p>This section is currently empty. Add information to see it here.</p>
                    <button  onClick={()=>{
                    setSkillModal(true)
                }}><PlusCircleOutlined/> Add skills</button>
                </div>
               }
            </div>
           

            </div>

{/* upload new resume */}
    <Modal
        title={<p className="keyword-modal-title">Upload resume</p>}
        centered
        destroyOnClose
     
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
                setShowUpload(false)
                setResume()
            }} className="keyword-modal-done">
            Cancel
          </button>
        {resume && !isModalLoding?  <button onClick={updateResume} className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none'}}>
            Upload
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8,border:'none',cursor:'not-allowed'}}> Upload </button>
          </>}
          </div>

        }
       
        open={showUpload}
        onCancel={() => {
          setShowUpload(false);
          setResume()
          }}
      >
        <div className="keyword-input-wrapper">
        {isUploading?<div className="resume-container">Uploading....</div>:null} 
      <Dragger {...props} 
   
     ref={uploadRef}
      className="drag-upload"
       maxCount={1}
       style={{display:isUploading?'none':'block'}}
      >
    
    <p className="upload-drag-icon">
     <img src={uploadFile}></img>
    </p>
    <p className="upload-text">Drag and Drop resume here or <span>Choose file</span></p>
    <p className="upload-hint">
    Supported formats: PDF, DOC, DOCX (maximum size: 2MB)
    </p>
      </Dragger>
 
   
        </div>
      

    
         </Modal>

    {/* about modal */}
            <Modal
        title={<p className="keyword-modal-title">About</p>}
        centered
        destroyOnClose
     
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
                setAbout(applicationData?.application?.headline)
                setAboutModal(false)
            }} className="keyword-modal-done">
            Cancel
          </button>
        {!isModalLoding?  <button onClick={()=>{
  
    if(!compareValues(about,applicationData?.application?.headline,)){
      updateAbout()
    }else{
      message.error("Please make changes before updating!")
      return 
    }
  
  
  }} className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none',
       cursor:compareValues(about,applicationData?.application?.headline,)?'not-allowed':'pointer',
          opacity:compareValues(about,applicationData?.application?.headline,)?'0.6':'1'
  }}>
            Save Data
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8}}> Save Data  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>}
          </div>

        }
       
        open={aboutModal}
        onCancel={() => {
          setAboutModal(false);
          setAbout(applicationData?.application?.headline)
          }}
      >
        <div className="keyword-input-wrapper">
            <div className="keyword-input">

            <TextArea rows={8} placeholder="About the candidate..." value={about} onChange={(e)=>{
             
              setAbout(e.target.value?.trim())
              }}/>
     
            </div>
   
        </div>
      

    
         </Modal>
    {/* experience modal */}
      <Modal
        title={<p className="keyword-modal-title">Work Experience</p>}
        centered
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
                setWorkExperienceModal(false);
                resetWorkExperince(applicationData)
            }} className="keyword-modal-done">
            Cancel
          </button>
        {!isModalLoding?  <button onClick={
          ()=>{
    

    if(!compareValues(workExperience,applicationData?.application?.workExperience,'workDuration')){
      updateExperience()
    }else{
      message.error("Please make changes before updating!")
      return 
    }
  
  
  }
        } className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none',
        cursor:compareValues(workExperience,applicationData?.application?.workExperience,'workDuration')?'not-allowed':'pointer',
        opacity:compareValues(workExperience,applicationData?.application?.workExperience,'workDuration')?'0.6':'1'}}>
            Save Data
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8}}> Save Data  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>}
          </div>

        }
       
        open={workExperienceModal}
        onCancel={() =>{ 
        resetWorkExperince(applicationData)
          setWorkExperienceModal(false)}}
        width={"80%"}
      >
       
       <Form
      form={form1}
      layout="vertical"
      className="job-overview-edit-modal"
      initialValues={{ workExperience }}
      onValuesChange={handleExperienceValuesChange}
    >
      {workExperience.map((experience, index) => (
        <div key={index} style={{ marginBottom: 20 }}>
          <Row gutter={16}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <div style={{
                display:'flex',
                flexDirection:'row',
                gap:'2px',
                alignItems:'center'

                
              }}>
                <div
                  style={{
                    height: 20,
                    width: 4,
                    backgroundColor: "#2337C6",
                    marginRight: 8,
                  }}
                ></div>
                <div>   Work Experience {index + 1}</div>
             
              </div>
              {index!=0 ?
              <Button
                type="text"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleRemoveExperience(index)}
                disabled={index==0 && (!workExperience[index] || Object.values(workExperience[index]).every(value => value==null||value ==undefined||value==false))} 
              >
                Remove
              </Button>:null}
            </div>
            <Col span={16}>
              <Form.Item
                name={["workExperience", index, "current"]}
                valuePropName="checked"
              >
                <Checkbox disabled={workExperience?.some((e,i)=>e.current && i!=index)}>Currently working in the company</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name={["workExperience", index, "company"]}
                label="Company"
                rules={[{ required: false, message: "Please enter the company" }]}
              >
                <Input placeholder="Enter company" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={["workExperience", index, "jobTitle"]}
                label="Job Title"
                rules={[{ required: false, message: "Please enter job title" }]}
              >
                <Input placeholder="Enter job title" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={["workExperience", index, "location"]}
                label="Location"
                rules={[{ required: false, message: "Please enter location" }]}
              >
                <Input placeholder="Enter location" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={["workExperience", index, "workDuration"]}
                label="Work Duration"
                rules={[
                  {
                    type: "array",
                    required: false,
                    message: "Please select the duration",
                  },
                ]}
              >
                <RangePicker format="YYYY-MM-DD"
            placeholder={['Start Date',experience.current? 'Current':"End Date"]}
             allowEmpty={[false, experience.current]}
             disabled={[false, experience.current]} // Disable endDate if checkbox is checked
             onCalendarChange={(dates) => {
              if (experience.current && dates) {dates[1] = null}
              return 
            }}

                 />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={["workExperience", index, "details"]}
                label="Details"
                rules={[{ required: false, message: "Please enter details" }]}
              >
                <TextArea rows={4} placeholder="Enter details" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ))}

      <Row>
      <span className="add-more" onClick={handleAddExperience} >
          <PlusCircleOutlined /> Add Work Experience
        </span>
      </Row>
    </Form>

   

    
         </Modal>

   {/* education modal */}

   <Modal
        title={<p className="keyword-modal-title">Education</p>}
        centered
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
               setEducationModal(false)
               resetEducation(applicationData)
            }} className="keyword-modal-done">
            Cancel
          </button>
        {!isModalLoding?  <button onClick={()=>{
    
          if(!compareValues(educations,applicationData?.application?.education,'courseDuration')){
            updateEducation()
          }else{
            message.error("Please make changes before updating!")
            return 
          }
        
        
        }} className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none',
          cursor:compareValues(educations,applicationData?.application?.education,'courseDuration')?'not-allowed':'pointer',
          opacity:compareValues(educations,applicationData?.application?.education,'courseDuration')?'0.6':'1'}}>
            Save Data
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8}}> Save Data  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>}
          </div>

        }
       
        open={educationModal}
        onCancel={() => {
          resetEducation(applicationData)
          setEducationModal(false)}}
        width={"80%"}
      >
       
       <Form
      form={form2}
      layout="vertical"
      className="job-overview-edit-modal"
      initialValues={{ educations }}
      onValuesChange={handleEduactionValuesChange}
    >
      {educations.map((education, index) => (
        <div key={index} style={{ marginBottom: 20 }}>
          <Row gutter={16}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <div style={{
                display:'flex',
                flexDirection:'row',
                gap:'2px',
                alignItems:'center'

                
              }}>
                <div
                  style={{
                    height: 20,
                    width: 4,
                    backgroundColor: "#2337C6",
                    marginRight: 8,
                  }}
                ></div>
                <div>   Education {index + 1}</div>
             
              </div>
              {index!=0 ?
              <Button
                type="text"
                icon={<DeleteOutlined />}
                danger
                onClick={() => handleRemoveEducation(index)}
                disabled={index==0 && (!educations[index] || Object.values(educations[index]).every(value =>  value==null||value ==undefined||value==false))} 
              >
                Remove
              </Button>:null}
            </div>
            <Col span={16}>
              <Form.Item
                name={["educations", index, "current"]}
                valuePropName="checked"
              >
                <Checkbox disabled={educations?.some((e,i)=>e.current && i!=index)}>Currently studying in the school/institution</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name={["educations", index, "degree"]}
                label="Degree/Certification"
                rules={[{ required: false, message: "Please enter Degree/Certification " }]}
              >
                <Input placeholder="Enter Degree/Certification" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={["educations", index, "area"]}
                label="Major area of study"
                rules={[{ required: false, message: "Please enter Major area of study" }]}
              >
                <Input placeholder="Enter Major area of study" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={["educations", index, "university"]}
                label="School/Institutions"
                rules={[{ required: false, message: "Enter school/institution" }]}
              >
                <Input placeholder="Enter school/institution" />
              </Form.Item>
            </Col>
            </Row>


            <Row>
            <Col span={12}>
              <Form.Item
                name={["educations", index, "courseDuration"]}
                label="Course Duration"
                rules={[
                  {
                    type: "array",
                    required: false,
                    message: "Please select the course Duration",
                  },
                ]}
              >
                <RangePicker format="YYYY-MM-DD"   
              placeholder={['Start Date',education.current? 'Current':"End Date"]}
             allowEmpty={[false, education.current]}
             disabled={[false, education.current]} // Disable endDate if checkbox is checked
             onCalendarChange={(dates) => {
              if (education.current && dates) {dates[1] = null}
              return 
            }}
 />
              </Form.Item>
            </Col>
          </Row>


        </div>
      ))}

      <Row>
        
           <span  className="add-more"  onClick={handleAddEducation}>
          <PlusCircleOutlined /> Add school/institution
          </span>
      </Row>
    </Form>

   

    
         </Modal>
   

   {/* skill modal */}

   <Modal
        title={<p className="keyword-modal-title">Skills</p>}
        footer={
          <div className="footer-submit">
             <button onClick={()=>{
               setSkillModal(false)
               resetSkills(applicationData)
            }} className="keyword-modal-done">
            Cancel
          </button>
        {!isModalLoding?  <button onClick={()=>{
          if(!compareValues(skills,applicationData?.application?.skills,)){
            updateSkills()
          }else{
            message.error("Please make changes before updating!")
            return 
          }

        }} className="keyword-modal-done" style={{background:'#1890FF',color:'#ffffff',border:'none',
          cursor:compareValues(skills,applicationData?.application?.skills,)?'not-allowed':'pointer',
          opacity:compareValues(skills,applicationData?.application?.skills,)?'0.6':'1'}}>
            Save Data
          </button>:
          <>
            <button className="keyword-modal-done" style={{color:'#ffffff',background:'#1890FF',opacity:0.8}}> Save Data  <Spin indicator={<LoadingOutlined spin />} size="small" /></button>
          </>}
          </div>

        }
       
        open={skillModal}
        onCancel={() => {
          resetSkills(applicationData)
          setSkillModal(false)}}
      >
        <div className="keyword-input-wrapper">
            <div className="keyword-input">
            <Input placeholder="Enter skills"  className="keyword-input-field" value={newSkill} onChange={(e)=>{setNewSkill(e.target.value)}} onPressEnter={addSkills}/>
            <button  onClick={addSkills}><PlusCircleOutlined/> Add</button>
            </div>
            {skills?.length>0?
            <div className="keywords-list">
            {skills?.map(skill=>{
                    return <div>{skill} <CloseOutlined onClick={()=>removeSkills(skill)}/></div>
                  })}
            </div>
           :
            <div className="keywords-nodata" style={{width:'99%',height:380,padding:'0px'}}>
                    <img src={nodata}></img>
                    <h3>No data to display!</h3>
                    <p>This section is currently empty. Add information to see it here.</p>
              
                </div>
            }
        </div>
      

    
      </Modal>


      {/* resume modal */}


      <Modal
        open={isResumeModalOpen}
        title={<p className="keyword-modal-title">{getLastNameFromUrl(applicationData?.application?.resumeLink)}</p>}
        footer
        onCancel={()=>{setResumeModal(false)}}
        width={'max-content'}
        className="resume-view-modal"
       centered
      > 
     
     
        
        {/* PDF Viewer */}
        <div style={{ height: "100%", overflow: "auto",width:'100%' }}>
          <Document
            file={applicationData?.application?.resumeLink}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-document"
          >
            <Page pageNumber={pageNumber} />
          </Document>

          <div className="resume-page-meta">
           
            <Button
            className="prev-btn"
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
            <LeftOutlined/>
            </Button>
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <Button
            className="next-btn"
            disabled={pageNumber >= numPages}
          
              onClick={() => setPageNumber(pageNumber + 1)}
            >
            <RightOutlined/>
            </Button>
          </div>
        </div>
      </Modal>
        </>
    )


}
export default Application