import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Radio } from "antd";
import React, { useState,useRef } from "react";
import Divider from "../../../components/divider/Divider";
import { useVacancyStore } from "../store/store";
const FilterSiderbar=({loading,setPage,setLimit,refPointer})=>{
    const[expandedFilters,setExpandedFilters]=useState([]);
    const {nFilters,setnFilters, jobsResponse,setJobsResponse,jobFilters} = useVacancyStore(state=>state);
    const [expandedOptions, setExpandedOptions] = useState({});

  const toggleShowMore = (filterKey) => {
    setExpandedOptions(prevState => ({
      ...prevState,
      [filterKey]: !prevState[filterKey]
    }));
  };
  const toggleShowAll = (filterKey) => {
    setExpandedFilters(prevState => ({
      ...prevState,
      [filterKey]: !prevState[filterKey]
    }));
  };

    
    // [
    //     {
    //         title:"Recruitment type",
    //         key:"recruitmentType",
    //         options:[{
    //             key:"Lateral recruitment",
    //             value:"Lateral recruitment"
    //         },
    //         {

    //         key:"Campus recruitment",
    //         value:"Campus recruitment"
    //         }]

    //     },
      
   
    // ]

    const handleSelectFilter=(filterKey,filterOption)=>{
      if(nFilters?.[filterKey]?.includes(filterOption)){

       const filterVal=nFilters?.[filterKey]?.filter(item => item !== filterOption)
        if(filterVal?.length>0){
        setnFilters({
          ...nFilters,
          [filterKey]:nFilters?.[filterKey]?.filter(item => item !== filterOption)
        })}
        else{
          delete nFilters?.[filterKey]
          setnFilters({...nFilters})
        }

      }else{
      setnFilters({
        ...nFilters,
        [filterKey]:nFilters?.[filterKey]?[...nFilters?.[filterKey],filterOption]:[filterOption]
      })
    }

      setPage(1);
      setLimit(10);
    }

    const handleClear=()=>{
      if(!loading){
      setnFilters();
      setExpandedFilters([])
      setExpandedOptions({});
      setPage(1);
      setLimit(10);
      }
    }





    return (
    <div className="filter-container" ref={refPointer}>
    
     <div className="filter-header">
        <div className="filter-heading">Filters</div>
        {console.log("nfilter,",nFilters)}
        <div className="filter-clearall" onClick={(e)=>{if(Object?.keys(nFilters||{})?.length!=0){handleClear(e)}}} style={{color:Object?.keys(nFilters||{})?.length==0?'#c8cacb':'#F5222D',cursor:Object?.keys(nFilters||{})?.length==0?'default':'pointer'}}>Clear all</div>
     </div>
     {jobFilters?
     jobFilters?.map((filter,filterIndex)=>{

      filter.options=filter?.options?.filter(option=>(option.key===0 || option.key ) && (option.value===0||option.value))
        return <>
        <Divider margin={'6px 0px'}/>
        <div className="filter-type">
     <div className="filter-type-header">
     <div className="filter-heading">{filter?.title}</div>
     <div>
    { !expandedFilters[filter.title]?
        <UpOutlined  onClick={() => toggleShowAll(filter.title)} />
        : <DownOutlined  onClick={() => toggleShowAll(filter.title)}/>}
     </div>
     </div>
    {!expandedFilters[filter.title]?   
     <div className="filter-type-options" key={filterIndex}>

     {filter?.options?
     <>
          {filter?.options?.slice(0, expandedOptions[filter.title] ? filter.options?.length : 6)?.map((option, optionIndex) => {
            
            return   <div key={optionIndex} className="filter-type-option" >
          {filter?.type=='single'?
          <Radio checked={nFilters?.[filter?.key]?.includes(option?.value)} onClick={()=>{
                        
                  if(nFilters?.[filter?.key]?.split(',')?.includes(option?.value)){
                    setnFilters({
            ...nFilters,
              [filter?.key]:undefined
              })
                  }else{
                  setnFilters({
            ...nFilters,
              [filter?.key]:option?.value
              })
                  }
              return

          }}/>:
          <Checkbox  onClick={()=>{
                         
                         handleSelectFilter(filter?.key,option?.value)
                         
                         }} checked={nFilters?.[filter?.key]?.includes(option?.value)}/>
          
  
          }
              
              <div>{option.key}</div>
            </div>
            
          })}

          {/* Show "+n more" if there are more than 6 options and it's collapsed */}
          {filter?.options?.length > 6 && !expandedOptions[filter.title] && (
            <div className="filter-type-option" onClick={() => toggleShowMore(filter.title)}>
              <span>+{filter.options.length - 6} more</span>
            </div>
          )}

          {/* Show "Show less" if the filter is expanded */}
          {expandedOptions[filter.title] && (
            <div className="filter-type-option" onClick={() => toggleShowMore(filter.title)}>
              <span>Show less</span>
            </div>
          )}
          </>:<div>No data</div>
     }          
        </div>


     :null}
     </div>
        </>
     })
     : 
     <>
     {/* !loading?
     <>
     <Divider/>
     <div className="no-filter">

     </div>
     </>
    : */}
    
    <Divider/>
      <div className="filter-type">
      {[...new Array(5)].map(e=>{
        return <>
     <div className="filter-type-header">
     <h3 className="filter-heading  animated-bg animated-bg-text" style={{width:'90%',height:'15px'}}>   &nbsp;</h3>
     <div className=" animated-bg animated-bg-text" style={{width:'15px',height:'15px',borderRadius:'4px'}} >
     &nbsp;
  
     </div>
     </div>
      {[...new Array(4)].map(e=>{
  
   return  <div className="filter-type-options"  style={{display:'flex',flexDirection:'row',alignItems:'center',}}>

<div  className="filter-type-option animated-bg animated-bg-text"  style={{width:'10px',height:'10px',borderRadius:'3px'}} >
&nbsp;
</div>
<div className=" animated-bg animated-bg-text" style={{width:'70%',height:'10px'}}></div>

</div>
       })}
       <div style={{height:'20px'}}></div>
       </>
     
      })}
     </div>

    </>
    }
    
    <div style={{height:'60px'}}></div>
    </div>
    )
}
   
    export default FilterSiderbar;