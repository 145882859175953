import React from "react";
import { Collapse, Divider } from "antd";
import { usePipelineStore } from "../store/store";
import CandidateCard from "./candidateCard";
import "./hiringStages.css"

const { Panel } = Collapse;

const HiringStagesPipeline = ({stagePosition, title,total,totalQualified, totalUnqualified,totalOnHold,qualifiedCandidates,unQualifiedCandidates, onHoldCandidates,filtersList}) => {
  console.log(stagePosition,title,"checkpipeline")
  const {quickViewVisibility} = usePipelineStore(state=>state);
  const candidates = [
    { name: "Emily Raybon", avatar: "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg", score: "88%", date: "Today", status: "high" },
    { name: "Emily Raybon", avatar: "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg", score: "15%", date: "3 days ago", status: "low" },
    { name: "Emily Raybon", avatar: "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg", score: "88%", date: "12/04/2024", status: "high" },
    { name: "Emily Raybon", avatar: "https://cdn.pixabay.com/photo/2024/05/04/12/12/ai-generated-8738943_1280.jpg", score: "88%", date: "12/04/2024", status: "high" },
  ];


  const renderCustomHeader = (title, color) => (
    <div
     style={{
      display:'flex',
      flexDirection:'row',
      gap:'4px'
     }}
    >
      <div style={{width:'4px', height:'24px', backgroundColor:color}}></div>
      <span style={{ fontWeight: "bold", fontSize: "16px" }}>{title}</span>
     
    </div>
  );
  const statusOptions = [
    { key: "qualified", label: "Qualified", color: "#52C41A", data: qualifiedCandidates },
    { key: "unqualified", label: "Unqualified", color: "#D0D0D0", data: unQualifiedCandidates },
    { key: "onHold", label: "On Hold", color: "#FADB14", data: onHoldCandidates },
  
  ].filter((status) => !(status.key === "onHold" && stagePosition === 1));

  return (
    <div className="hiringstages-container" >
      <div className="hiringstages-heading" >
        <div>
          <div style={{ marginBottom: "8px" }}>{title}</div>
        </div>
        <div>
          ({total})
        </div>
      </div>
      <div className="hiringstages-subheading"style={{ marginBottom: "16px", color: "gray" }}>
        {totalQualified !== undefined ? totalQualified : 0} Qualified
        {stagePosition > 0 && ` • ${totalOnHold !== undefined ? totalOnHold : 0} On-Hold`}
         • {totalUnqualified !== undefined ? totalUnqualified : 0} Unqualified
      </div>

<Collapse
  ghost
  defaultActiveKey={["1"]}
  expandIconPosition="end"
  style={{ border: "8px", backgroundColor: "transparent" }}
>
  {statusOptions.map((status, index) => {
    const shouldRender =
      (filtersList?.all || filtersList?.[status.key] ) ; // Check if the panel should be rendered
    return (
      shouldRender  && (
        <Panel
          header={renderCustomHeader(status.label, status.color)}
          key={index.toString()} // Use the index or status key as the unique key
          style={{
            maxHeight: "300px",
            overflow: "hidden",
            padding: "0px",
          }}
        >
          <div
            style={{
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {status?.data?.map((candidate, candidateIndex) => (
              <CandidateCard
                key={candidateIndex}
                {...candidate}
                title={title} // Optional if `title` applies to all statuses
                color={status.color}
              />
            ))}
          </div>
        </Panel>
      )
    );
  })}
</Collapse>

    </div>
  );
};

export default HiringStagesPipeline;
