import React, { useCallback, useContext, useState } from 'react';
import { Button, Drawer, Modal, Popover, Space,Tooltip } from 'antd';
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Input, Row, Col } from "antd";
import Delete from "../../../../assets/deleteIcon.svg"
import { useVacancyStore } from '../../store/store';
import Add from "../../../../assets/smallAdd.svg"
import Grab from "../../../../assets/grab.svg"
import DownArrow from "../../../../assets/blueDownArrow.svg"
import styles from "./recruit.module.css"
import { updateJobTemplate } from '../../Action/action';
import Notification from '../../../../service/Notification';
import { useParams } from "react-router-dom";
import { ToggleContext } from './recruitmentOverview';
const ManagementTask = ({ openDrawer, onClose, data }) => {
  const setToggleApi = useContext(ToggleContext); 
 
  const { id } = useParams(); 
  const { defaultTemplate, updateDefaultTemplate } = useVacancyStore(state => state);
  const [btnLoader,setBtnLoader]=useState(false)
  const { templateDropdown } = useVacancyStore(state => state);
  const [items, setItems] = useState(data?.templateStages);
  const [open, setOpen] = useState(false)
  const [tempName, setTempName] = useState(data?.templateName)
   const [openModal,setOpenModal]=useState(false)
  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("dragIndex", index);

    e.currentTarget.style.opacity = "0.5";
  };

  const handleDragEnd = (e) => {
    // Reset the opacity when drag ends
    e.currentTarget.style.opacity = "1";
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // Allow dropping by preventing default behavior
  };

  const handleDrop = (e, dropIndex) => {
    const dragIndex = e.dataTransfer.getData("dragIndex");
    if (dragIndex === dropIndex) return;

    const updatedItems = [...items];
    const draggedItem = updatedItems.splice(dragIndex, 1)[0];
    updatedItems.splice(dropIndex, 0, draggedItem);

    setItems(updatedItems);
  };

  const handleInputChange = useCallback((index, field, value) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index][field] = value;
      return newItems;
  });
  },[]);
  const hide = () => {
    setOpen(false);
  };

  return (
    <>

      <Drawer

        title={
          (<div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ padding: 4 }}>Manage</div><div>
            <Space>
            <Button onClick={onClose}>Cancel</Button>
           <Button loading={btnLoader} type="primary" onClick={() => {
          
            if(items.length===0){
              Notification.error("Error","Kindly add stages")
           
              return
            }
            console.log(data)
            setBtnLoader(true)
            const params = {
              templateName: tempName,
              templateId:data?.templateType==="Specific"?data?.templateId:undefined ,
              jobId: id,
              templateStages: items
            }
         
           
            
            updateJobTemplate(params, (res, error) => {
              setBtnLoader(false)
              if (res?.type==="success") {
                // let array = [...defaultTemplate]
                // let index = array.findIndex(ele => ele?.templateId === data?.templateId)
                // array[index].templateStages = items
                // array[index].templateName=tempName
                // updateDefaultTemplate(array)
                onClose()
                setToggleApi((prev)=>!prev)
                
              }
        
            })
          }}>Save</Button></Space></div></div>)}
           onClose={onClose} 
           open={openDrawer} 
           width={740}
           closable={false}
           >
        <div style={{ padding: "12px", backgroundColor: "white", borderRadius: 4 }}>
        <div className={styles.modalTitle} style={{width:"100%",display:"flex",flexDirection:"row",marginBottom:6}}><div style={{height:20,width:4,backgroundColor:"#2337C6",marginRight:4}}></div>  Stages</div>
          {/* <div style={{ paddingBottom: 20, }}>
            <div style={{ marginBottom: 8 }}>Name</div>
            <input onChange={(e) => setTempName(e.target.value)} defaultValue={tempName} style={{ width: "98%", height: "30px" }} />
          </div> */}
          {items?.map((item, index) => (
            <div
              key={item.stageId}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragEnd={handleDragEnd}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={(e) => handleDrop(e, index)}
              className={styles.dragItems}
            >
             
              <Row gutter={16} style={{ width: "100%" }}>
                <Col span={2}>
                  <Button type="link" >
                    <img src={Grab} />
                  </Button>
                </Col>
                <Col span={8}>

                  <Input
                    placeholder="Name"
                    defaultValue={item.stageName}
                    onChange={(e) => handleInputChange(index, "stageName", e.target.value)}
                  />
                </Col>
                <Col span={12}>
                <Tooltip title={item.stageDescription}>
                  <Input
                    placeholder="Value"
                    defaultValue={item.stageDescription}
                    onChange={(e) => handleInputChange(index, "stageDescription", e.target.value)}
                  />
                  </Tooltip>
                </Col>
                <Col span={2}>
                  <Button type="link" danger onClick={() => {
                    console.log(items,item,index)
                     let data = items.filter((ele,indexed) => indexed !== index)
                     console.log(data)
                     setItems(data)
                  }}>
                    <img src={Delete} />
                  </Button>
                </Col>
              </Row>

            </div>

          ))}
          <div
  style={{
    display: "flex",
    flexDirection: "row",
    margin: 8,
    alignItems: "center",
    justifyContent: "center",
  }}
>
 
  <div
    onClick={() => setOpen(true)}
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      cursor: "pointer", // This makes the cursor pointer when hovering
    }}
  >
    <img src={Add} height={"50%"} />
    <div style={{ color: "#1890FF", margin: "0 8px" }}>Add stage</div>
    <img src={DownArrow} height={"20%"} />
  </div>
</div>

        </div>
        <Popover
          content={<>
            <a onClick={hide}>Close</a>
            {templateDropdown?.map((ele,index) => {
              return (
                <div key={ele?.stageKey} onClick={() => {
                  let obj={...ele}
                  // delete(obj?.stageId)
                  const newItems = [...(items || []), obj];
                 console.log(newItems)
                  setItems(newItems)
                }} style={{ margin: 8, cursor: "pointer" }}>{ele?.stageName}</div>
              )
            })}</>}
          title=""
          trigger="click"
          open={open}
          onOpenChange={(newOpen) => setOpen(newOpen)}
        >

        </Popover>
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <Button onClick={()=>{
          setOpenModal(true)
        }} style={{borderColor:"#1890FF",color:"#1890FF",}}>Save as Template</Button>
        </div>
      </Drawer>
      <Modal 
      onCancel={() =>{setOpenModal(false)}}
      onClose={() =>{setOpenModal(false)}}
      onOk={() =>{
        if(items.length===0){
          Notification.error("Error","Kindly add stages")
       
          return
        }
        const params = {
          templateName: tempName,
          // templateId:data?.templateType==="specific"?data?.templateId:undefined ,
          jobId: id,
          templateStages: items,
          savedTemplate:true

        }
        updateJobTemplate(params, (res, error) => {
          if (res?.type==="success") {
            // let array = [...defaultTemplate]
            // let index = array.findIndex(ele => ele?.templateId === data?.templateId)
            // array[index].templateStages = items
            // array[index].templateName=tempName
            // updateDefaultTemplate(array)
            setOpenModal(false)
            onClose()
            
            setToggleApi((prev)=>!prev)
          }
    
        })
      }}
      okText="Save data"
      width={"30%"}
      open={openModal} title="Name your new Template">
        <div style={{ paddingBottom: 20, }}>
            <div style={{ marginBottom: 8 }}>Provide a unique name to easily identify this template later.</div>
            <input onChange={(e) => setTempName(e.target.value)} defaultValue={tempName} className={styles.inputStyle} />
          </div>
      </Modal>
    </>
  );
};

export default ManagementTask;






