import notification from "antd/lib/notification";
import "antd/lib/notification/style/index";

class Notification {
  success(title, text) {
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    notification.success({
       message: capitalizedTitle,
      description: text,
      duration: 3,
      style: {
        borderRadius: 4,
        border: "#95DE64",
        backgroundColor: "#F6FFED",
        color:"#4D4D4D",
      },
    });
  }

  error(title, text) {
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    notification.error({
      message:title==='error'?'Error!':capitalizedTitle,
      description: text,
      duration: 5,
      style: {
        //borderRadius: 11,
        borderRadius: 4,
        border: "#95DE64",
        backgroundColor: "#ff9d9e",
        color:"#4D4D4D",
      },
    });
  }

  warning(title, text) {
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    notification.warning({
      message: capitalizedTitle,
      description: text,
      duration: 5,
      style: {
        borderRadius: 4,
      
      },
    });
  }

  info(title, text) {
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    notification.info({
      message: capitalizedTitle,
      description: text,
      duration: 6,
      style: {
        borderRadius: 11,
      },
    });
  }
}

export default new Notification();
