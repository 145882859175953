import Api from "../../../service/Api"
import { useApplicationManagementStore } from "../store/store"


export const postCandidateApplication = (params, callback) => {
    Api.uploadAndPost('/api/candidate/add-candidate',params,((response, error) => {
        callback(response,error)
       }))
}

export const postBulkCandidateApplications = (params, callback) => {
    Api.uploadAndPost('/api/candidate/add-candidate-in-bulk',params,((response, error) => {
        callback(response,error)
       }))
}
export const getCandidate = (params,callback) => {
  
    Api.get('/api/candidate/get-candidate').params(params)
    .send((response, error) => {
        callback(response,error)
      })
}

export const getApplication = (params,callback) => {
  

    Api.get('/api/candidate/get-application').params(params)
    .send((response, error) => {
     
        callback(response,error)
      })
}

export const updateApplication = (params,callback) => {
    Api.uploadAndPost('/api/candidate/edit-application',params,(response, error) => {
        callback(response,error)
      })
}



export const deleteApplication = (applicationId,callback) => {
    Api.delete(`/api/candidate/deleteApplication?applicationId=${applicationId}`).send((response, error) => {
        callback(response,error)
      })
}


export const getApplications = (params,callback) => {
   
    Api.get('/api/candidate/applications').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}



export const getApplicationsCount = (params,callback) => {
   
    Api.get('/api/candidate/applicationsCount').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}


export const getPipelineView = (params,callback)=>{
    Api.post('/api/hiring/pipeLineView').params(params)
    .send((response,error)=>{
        callback(response,error)
    })
}

export const emailCheck=(params,callback)=>{
    Api.get('/api/candidate/emailCheck').params(params)
    .send((response, error) => {
        callback(response,error)
    })
}
export const getActiveJobs = (params,callback) => {
   
    Api.get('/api/candidate/job-dropdown').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}

export const DeleteDocs = (params,callback) => {
   
        Api.delete('/api/candidate/deleteDocument').params(params)
        .send((response, error) => {
            callback(response,error)}
        )}
export const UpdateDocs = (params,callback) => {
   
            Api.patch('/api/candidate/updateDocument').params(params)
            .send((response, error) => {
                callback(response,error)}
            )}


export const getCandidateNotes = (params,callback)=>{
    Api.get('/api/candidate/notes').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}

export const addCandidateNotes = (params,callback)=>{
    Api.post('/api/candidate/add-note').params(params)
    .send((response,error)=>{
        callback(response,error)
    })
}
export const editCandidateNotes = (params,callback) => {
   
    Api.patch('/api/candidate/edit-note').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}
export const deleteCandidateNotes = (params,callback) => {

    Api.delete(`/api/candidate/delete-note/${params}`)
    .send((response, error) => {
        callback(response,error)}
    )}

export const getExcel=(params,callback)=>{
    Api.get('/api/candidate/getExcel').params(params)
    .send((response,error)=>{
        callback(response,error)
    })
}

export const getCandidateActivities = (params,callback)=>{
    Api.get('/api/candidate/getApplicationActivties').params(params)
    .send((response, error) => {
        callback(response,error)}
    )}

    export const deleteResume = (applicationId,callback) => {
        Api.delete(`/api/candidate/deleteResume?applicationId=${applicationId}`).send((response, error) => {
            callback(response,error)
          })
    }
    
export const getApplicationMetaCount=(params,callback)=>{
    Api.get('/api/candidate/applicationMetaCount').params(params)
    .send((response, error) => {
        callback(response,error)}
    )
}