import React from "react";
import "./applicationView.css"

const ApplicationView = ()=>{

    return (
        <div className="application-container">
            APPLICATION VIEW
        </div>
    );

}

export default ApplicationView