import React, { useState } from 'react';
import './JobIndustrySelector.css'; // You'll define your CSS here
import { useStore } from './store/store';
import { Select, Tooltip } from 'antd';


const JobIndustrySelector = () => {

  const {titleJob,titleIndustry,setTitleJob, setTitleIndustry} = useStore(state=>state)

  return (
    <div className="job-industry-selector">
    <Tooltip title={titleJob}>
      <input
        type="text"
        className="job-title-input"
        value={titleJob}
        onChange={(e) => setTitleJob(e.target.value)}
        placeholder="Add a job title"
      />
      </Tooltip>
      <span className="divider" style={{height:'20px',width:'6px'}}></span>
      {/* <select
        className="industry-select"
        placeholder="Select an Industry"
        value={titleIndustry}
        onChange={(e) => setTitleIndustry(e.target.value)}
      >
        <option value="" disabled selected hidden style={{color:'gray !important'}}  >
          Select an Industry
        </option>
        {industries.map((industry, index) => (
          <option key={index} value={industry}>
            {industry}
          </option>
        ))}
      </select> */}

      <Select
    showSearch
      className="industry-select"
      filterSort={(optionA, optionB) =>
      (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
    }
    allowClear
    value={titleIndustry||undefined}
    placeholder="Select an Industry"
    optionFilterProp="label"
    onChange={(value)=>{setTitleIndustry(value)}}
    // onSearch={onSearch}
    options={ [
  { value: 'agriculture', label: 'Agriculture' },
  { value: 'aerospace', label: 'Aerospace and Defense' },
  { value: 'automotive', label: 'Automotive' },
  { value: 'banking', label: 'Banking and Finance' },
  { value: 'biotechnology', label: 'Biotechnology' },
  { value: 'chemicals', label: 'Chemicals' },
  { value: 'construction', label: 'Construction' },
  { value: 'consumerGoods', label: 'Consumer Goods' },
  { value: 'education', label: 'Education' },
  { value: 'energy', label: 'Energy and Utilities' },
  { value: 'entertainment', label: 'Entertainment and Media' },
  { value: 'fashion', label: 'Fashion' },
  { value: 'foodBeverage', label: 'Food and Beverage' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'hospitality', label: 'Hospitality and Tourism' },
  { value: 'hr', label: 'Human Resources' },
  { value: 'it', label: 'Information Technology' },
  { value: 'insurance', label: 'Insurance' },
  { value: 'legal', label: 'Legal Services' },
  { value: 'manufacturing', label: 'Manufacturing' },
  { value: 'marketing', label: 'Marketing and Advertising' },
  { value: 'mining', label: 'Mining and Metals' },
  { value: 'nonprofit', label: 'Nonprofit' },
  { value: 'pharmaceuticals', label: 'Pharmaceuticals' },
  { value: 'realEstate', label: 'Real Estate' },
  { value: 'retail', label: 'Retail' },
  { value: 'telecommunications', label: 'Telecommunications' },
  { value: 'transportation', label: 'Transportation and Logistics' },
  { value: 'wholesale', label: 'Wholesale and Distribution' },
  { value: 'professionalServices', label: 'Professional Services' },
  { value: 'technology', label: 'Technology' },
  { value: 'sports', label: 'Sports and Recreation' },
  { value: 'government', label: 'Government' },
  { value: 'healthcareTech', label: 'Healthcare Technology' },
  { value: 'consulting', label: 'Consulting' },
  { value: 'artDesign', label: 'Art and Design' },
  { value: 'environmental', label: 'Environmental Services' },
  { value: 'media', label: 'Media and Journalism' },
  { value: 'publicRelations', label: 'Public Relations' },
  { value: 'constructionEngineering', label: 'Construction and Engineering' }
]}
  />
    </div>
  );
};

export default JobIndustrySelector;
