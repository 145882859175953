import React, { useState } from "react";

const DynamicBoxes = () => {
  // Initial state for the boxes
  const [boxes, setBoxes] = useState([1, 2, 3, 4, 5, 6, 7]);

  return (
    <div style={{ display: "flex", justifyContent: "left", gap: "10px", marginTop:'16px', marginBottom:'16px',marginLeft:'0px' }}>
      
      {boxes.map((box, index) => (
        <div
          key={index}
          style={{
            width: "80px",
            height: "32px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: index === 0?"1px solid #13C2C2":"1px solid #D0D0D0",
            backgroundColor: index === 0 ? "#13C2C2" : "transparent",
            color: index === 0 ? "white" : "#D0D0D0",
            borderRadius: "2px",
          }}
        >
          {box}
        </div>
      ))}
    </div>
  );
};

export default DynamicBoxes;
