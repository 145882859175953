import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Button, Modal } from "antd";
import "./candidateNotes.css"
import SearchCompo from "../../vacancies/searchComponent";
import CustomButton from "../../hiring/customButton";
import addIconButton from "../../../assets/addIconButton.svg"
import CardComponent from "./cardComponent";
import Pagination from "../../vacancies/customPagination";
import { getCandidateNotes, addCandidateNotes, editCandidateNotes, deleteCandidateNotes } from "../Action/action";
import no_data from '../../../assets/icons/no-job.svg'
import addNoteHighlighter from "../../../assets/addNoteHighlighter.svg"
import EditorToolbar ,{modules, formats} from "../../../components/EditorToolbar/EditorToolbar"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import parse, { htmlToDOM } from 'html-react-parser';


const CandidateNotes = ({viewMode})=>{
  const [addNotesModal, setAddNotesModal] = useState(false);
  const [cardData, setCardData] = useState([]);
  const [pageMeta, setPageMeta] = useState({});
  const [editorContent, setEditorContent] = useState('');
  const [editNotes, setEditNotes] = useState(false);
  const [editNoteId, setEditNoteId] = useState();
  const [search, setSearch] = useState('');
  const [show, setShow] = useState(false)

  const { id } = useParams();

  const getNotes = ()=>{
    let params={
      "applicationId" :id,
      "page":pageMeta?.currentPage?pageMeta?.currentPage:1,
      "limit":pageMeta?.itemsPerPage?pageMeta?.itemsPerPage:10,
      "search": search!==''?search:null
    }
    getCandidateNotes(params,(res,err)=>{
      if(err){
        console.error("Error notes:", err);   
      }
      else{
        setCardData(res?.notes)
        console.log(parse(res?.notes[0]?.note),"ccccc")
        setPageMeta(res?.meta)
        console.log(pageMeta,"checkingmeta")
      }
    })
  }

  useEffect  (()=>{
    getNotes()
  },[pageMeta?.itemsPerPage, pageMeta?.currentPage,search]);

  const formatQuillJdData=(data)=>{
    return  data?.replace(/<p>\s*(.*?)\s*<\/p>/g, (match, innerContent) => `<p>${innerContent?.trim()}</p>`)
    .replace(/^(<p><br\s*\/?\s*><\/p>\s*)+/, "") 
  .replace(/(\s*<p><br\s*\/?\s*><\/p>)+$/, "");
   }

  const handleEditorChange = (content) => {
    setEditorContent(content); // Keeps the styled HTML content
  };

  // Extract plain text for validation
  const getPlainText = (htmlContent) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    return tempDiv.textContent || tempDiv.innerText || '';
  };

  // Validation: Check if plain text content has meaningful text
  const plainTextContent = getPlainText(editorContent).trim(); // Extract plain text
  console.log(plainTextContent, 'checknotes');
  const isContentValid = plainTextContent.length > 0;
 

  const handlePageChange = (page) => {
    console.log(page,"checkmeta")
    setPageMeta({
      ...pageMeta,   // Spread the previous state to keep other properties
      
        currentPage: page      // Update only the currentPage
      
    });
    console.log(pageMeta,"checkmeta")
    //setPageMeta({currentPage:page})
  
  };
  const handleItemsPerPageChange = (size)=>{
    console.log(size,"checkmeta")
    setPageMeta({
      ...pageMeta,   // Spread the previous state to keep other properties
     
        currentPage: 1 ,     // Update only the currentPage
        itemsPerPage: size,
        totalPage:0,
        totalItems:0,
        itemCount:0 
      
    });
  };


  const handleClick = ()=>{
    console.log("addnotes")
    setAddNotesModal(true)
    setTimeout(()=>{
      setShow(true);
  }, 100);
  }
  const handleOk = ()=>{
    const cleanedValue = editorContent?.replace(/<p>\s*(.*?)\s*<\/p>/g, (match, innerContent) => `<p>${innerContent?.trim()}</p>`)
    .replace(/^(<p><br\s*\/?\s*><\/p>\s*)+/, "") // Remove <p><br/></p> at the beginning
  .replace(/(\s*<p><br\s*\/?\s*><\/p>)+$/, "");
    const isBlank = /<p>\s*<\/p>/g.test(cleanedValue);

    console.log(cleanedValue,"checknotes123")
    if(editNotes){
      let params={
     
        "noteId":editNoteId,
        "note":isBlank?"":cleanedValue
      }
      editCandidateNotes(params,(res,err)=>{
        if(err){
          console.error("Error notes:", err);   
        }
        else{
          getNotes()
        }
      })
      setAddNotesModal(false)
      setShow(false)
      setEditorContent('')
      setEditNoteId()
      setEditNotes(false)
    }
    else{
    let params={
      "applicationId":id,
      "userId":3,
      "note":isBlank?"":cleanedValue
    }
    addCandidateNotes(params,(res,err)=>{
      if(err){
        console.error("Error notes:", err);   
      }
      else{
        getNotes()
      }
    })
    setAddNotesModal(false)
    setShow(false)
    setEditorContent('')
   
    }
  }

  const handleCancel = ()=>{
    setAddNotesModal(false)
    setShow(false)
    setEditorContent('')
    setEditNotes(false)
  }
  const handleDeleteNote = (noteId)=>{
    console.log("Delete Note : ",noteId)
  
    // deleteCandidateNotes(params,(res,err)=>{
    //   if(err){
    //     console.error("Error notes:", err);   
    //   }
    //   else{
    //     getNotes()
    //   }
    // })
      deleteCandidateNotes(noteId,(response,error)=>{
              if (error) {
                console.error("Error deleting notes:", error);
              } else {
                getNotes()
              
              }
            })
  }


  console.log(cardData,"checkcarddata")
    return(
     
            <div style={{display:'flex', flexDirection:'column', margin:'24px'}}>
            
             
                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
                    <SearchCompo placeholder="Search Notes" onSearch={(value)=>{ setSearch(value)}}/>
                    {cardData!==undefined&&cardData.length!==0 &&!viewMode?
                    <Button type="primary" onClick={handleClick} className="button-addnotes">
                    <img src={addIconButton}/>
                    <div style={{color:'#fff'}}>Add Notes</div>
                    </Button>
                    :null}
                </div>
                {cardData!==undefined && cardData.length!==0 ?
                <div style={{overflowY: "scroll", height:'800px', marginTop:'16px'}}>
                    <CardComponent data={cardData} handleEditNotes={(value,noteId,note)=>{
                    
                      if(value==="Edit"){
                        console.log(value,noteId,note,"checkeditnotes")
                        setEditNotes(true)
                        setEditNoteId(noteId)
                        handleEditorChange(note)
                        setAddNotesModal(true)
                        setTimeout(()=>{
                          setShow(true);
                      }, 100);
                      }
                      else if(value==="Delete"){
                        handleDeleteNote(noteId)
                      }
                      
                    }}/>
                </div>
                :
                <div style={{display:'flex', alignItems:'center', flexDirection:'column', border:'1px dashed #D0D0D0', height:'90vh', margin:'16px', borderRadius:'4px'}}>
                  <div style={{marginTop:'40%'}}><img src={no_data}/></div>
                  <div className="no-data-header">No notes to display!</div>
                  <div className="no-data-sub">Add notes to capture important points and stay organized.</div>
                  <Button type="primary" onClick={handleClick} className="button-addnotes">
                    <img src={addIconButton}/>
                    <div style={{color:'#fff'}}>Add Notes</div>
                  </Button>
                </div>
              }
                    {cardData!==undefined?<div>
                      <Pagination  
                        totalPages= {pageMeta?.totalPages}
                        currentPage ={pageMeta?.currentPage}
                        onPageChange ={handlePageChange}
                        itemsPerPage ={pageMeta?.itemsPerPage}
                        onItemsPerPageChange={handleItemsPerPageChange}
                     />
                    </div>:null}
                {(addNotesModal)&&
                (
                <div>
                <Modal
                  title={
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
                      <div>
                        <img src={addNoteHighlighter} alt="Add Note Highlighter" />
                      </div>
                      {!editNotes? <div className="addnotes-header">
                        <div>Add a note</div>
                        <div>Add notes to capture important points and stay organized.</div>
                      </div>:
                      <div className="addnotes-header">
                          <div>Edit Information</div>
                      </div>
                      }
                    </div>
                  }
                  open={addNotesModal}
                  onOk={() => {
                    handleOk(); // Pass editor content to handleOk
                  }}
                  onCancel={handleCancel}
                  zIndex={10000000}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button
                      key="submit"
                      type="primary"
                      onClick={() => {
                       
                          handleOk(); // Add a new note if editNotes is false
                     
                      }}
                      
                      disabled={!isContentValid} // Disable button if content is invalid
                    >
                      {editNotes?"Save data":"Add note"}
                    </Button>,
                  ]}
                  className="addnotes-content"
                >
                 
                {show ?
                  <>
                  <EditorToolbar data={formatQuillJdData(editorContent)}/>
                  <ReactQuill
                    theme="snow"
                    value={editorContent}
                    onChange={handleEditorChange}
                    placeholder="Type your note here..."
                    className="quill-editor"
                    modules={modules}
                    formats={formats}
      
                  />
                  </>
                  :null
                }
                </Modal>
                </div>
                )}
            </div>
    
    );
}
export default CandidateNotes