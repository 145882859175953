import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './customTabs.css'



const CustomTabs = ({tabs,activeTab, onClick=()=>{}}) =>{

    // const [activeTab, setActiveTab] = useState(tabs[0].name);
  //  const {activeTab,setActiveTab} = useVacancyStore(state=>state);
    // useEffect(()=>{
    //  setActiveTab(tabs?.[0]?.name)
    // },[])

    //const contentRefs = useRef([]); // Store refs for tab contents


    // const handleTabClick = (tabName, index) => {
    //     setActiveTab(tabName);
    //     //contentRefs.current[index].scrollIntoView({ behavior: "smooth" });
    //   };

    const renderContent = () => {
        const activeTabContent = tabs.find(tab => tab.name === activeTab);
        return activeTabContent ? activeTabContent.content : null;
    };

    useEffect(()=>{
        renderContent();
    },[activeTab])

    return (
      <>
            <div style={{ display: "flex",flexDirection:'column',padding:'0px 20px'}} >
            <div style={{width:'100%',display:'flex',flexDirection:'row',overflowX:'scroll'}} className="tabs-wrapper">
            {tabs.map((tab,index)=>(
             <Link to={tab.url}>
              <div
              key={tab.name}
              style={{
                padding: "10px 20px",
                cursor: "pointer",
                //backgroundColor: activeTab === tab ? "#007bff" : "#f0f0f0", // Highlight active tab
                color: activeTab === tab.name ? "#1890FF" : "#D0D0D0",
      
                display: "flex",
                padding: 'var(--XS-4XS, 10px) var(--S-3S, 16px)',
                alignItems: 'center',
                gap: 'var(--XS-3XS, 8px)',
            
                width:'max-content',

                flexDirection:'row',
                position:'relative'
              }}
              className="custom-tab"
              onClick={()=>{onClick(tab.name)}}
            >
              {activeTab===tab.name && tab.iconActive?<div style={{width:'19px',height:'19px'}}>{tab.iconActive}</div>:null}
              {activeTab!==tab.name && tab.iconInactive?<div style={{width:'19px',height:'19px'}}>{tab.iconInactive}</div>:null}
              <div style={{display:'flex',gap:'5px',justifyContent:'center',alignItems:'center',fontSize:'16px',fontWeight:activeTab===tab.name?'600':'500'}}>{tab.name}
              {tab?.hasOwnProperty('count')?   <div className="tabCount" style={{    backgroundColor:activeTab===tab.name?'#1890FF' : '#D0D0D0',fontSize:'12px'}}>{tab?.count||'00'}</div>:null }
          
              
              </div>
              {
                activeTab === tab.name?    
                 <div style={{width: '100%',
                          height:'4px', 
                          backgroundColor:'#1890FF', 
                         position:'absolute',
                         bottom:'0',
                         left:'0'
                        }}>
                      
                </div>:null
              }
         
              
            </div>
            </Link>


            ))}
            </div>
          
            
            <div style={{width:'100%', height:'1px', backgroundColor:'#E9E9E9', marginLeft:'1rem'}}></div>
            </div>
           
           
             {/* Active Tab Content */}
          
           
        
      <div style={{width:'100%',height: 'max-content'}} >
        {renderContent()}
      </div>
           
       </>
    );
}

export default CustomTabs;