import React, { useEffect,  useState,useRef } from "react";
import { useApplicationManagementStore } from "../store/store";
import FilterSiderbar from "./FilterSiderbar";
import { Button, Dropdown, message, Tooltip } from "antd";
import { Select } from 'antd';
import { AppstoreOutlined, BarsOutlined, DownloadOutlined, InfoCircleOutlined, PlusCircleOutlined, UploadOutlined, UserOutlined } from "@ant-design/icons";
import Pagination from "../../vacancies/customPagination";
import SearchCompo from "../../vacancies/searchComponent";
import Table, { ActivejobsFilter, ColumnFilter } from "../../../components/Table/Table";
import {  useLocation, useNavigate } from "react-router-dom";
import Divider from "../../../components/divider/Divider";
import no_job from '../../../assets/icons/no-job.svg'
import { getActiveJobs, getApplications, getCandidates, getExcel } from "../Action/action";
import CandidateCard, { ratingColor, StatusActions, timeAgo } from "./candidateCard";
import xlsImg from '../../../assets/icons/excel.svg'
import emailIcon from '../../../assets/icons/emailIcon.svg';
import phoneIcon from '../../../assets/icons/phoneIcon.svg';

import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { adjustColorBrightness, getInitials, stringToColor } from "../../notification/notification";




const Candidate=({status})=>{

  const {nFilters,setnFilters, applicationResponse,setApplicationResponse,
    layoutType,setLayoutType, setApplicationFilters,
    setOpenAddCandidateModal,setOpenBulkUploadModal,refresh} = useApplicationManagementStore(state=>state);
  const [search,setSearch]=useState();
  const [sortBy,setSortBy]=useState('DESC');
  const [loading,setLoading]=useState(true);
  const [page,setPage]=useState(1);
  const [limit,setLimit]=useState(10);
    // State for selected columns
    const [jobOptions,setJobOptions]=useState([ ])
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedJobs,setSelectedJobs]=useState()




useEffect(()=>{
  setLoading(true)
  setnFilters()
  setApplicationResponse({applications:[]})
  setPage(1)
  setLimit(10);
},[status])
  
 const getAllApplications=()=>{
  let params={
    status:status,
    search:search,
    sortBy:sortBy,
    ...nFilters,
    page: page||1,
    limit: limit||10,
    selectedJobs:selectedJobs?.map(e=>e?.value),
  }
  setLoading(true);
  getApplications(params,(response,error)=>{

    if(page>response?.meta?.totalPages && response?.meta?.totalPages>0 ){
      setPage(response?.meta?.totalPages);
      setLoading(false);
      return
     }
    setApplicationResponse(response)
    setApplicationFilters(response?.filterTypes)
    setLoading(false);
    
     
    
  })
}


useEffect(()=>{
  getAllApplications();
},[search,sortBy,status,page,limit,nFilters,refresh,selectedJobs])

useEffect(()=>{
  setPage(1)
  setLimit(10);
},[search])

const handlePageChange = (page) => {
  if(!page){setPage(1);return}
  setPage(page);

};
const handleItemsPerPageChange = (size)=>{
setPage(1);
setLimit(size)
};



const activeCol=[
  {
    meta:{
      title:'Name',
      type:'default',
      styles:{
        width:'200px'
      },
   
    },
   
  },
 
 {
    meta:  {
      title:'Applied Date',
      type:'default',
      styles:{
        width:'250px'
      },
    }
  },
  {
    meta:{
      title:'Current Location',
      type:'default',
      styles:{
        width:'160px'
      },
    }
  },
  {
    meta:{
      title:'Email ID',
      type:'default',
      styles:{
        width:'200px'
      },
    }
  },
  {
    meta:{
      title:'Mobile Number',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Job applied',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Job Code',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Rating',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Stage',
      type:'default',
      styles:{
        width:'250px'
      },
    }
  },
  {
    meta:{
      title:'Action',
      type:'default',
      styles:{
        width:'50px'
      },
    }
  },
]
const inactiveCol=[
  {
    meta:{
      title:'Name',
      type:'default',
      styles:{
        width:'200px'
      },
   
    },
   
  },
 
 {
    meta:  {
      title:'Applied Date',
      type:'default',
      styles:{
        width:'250px'
      },
    }
  },
  {
    meta:{
      title:'Current Location',
      type:'default',
      styles:{
        width:'160px'
      },
    }
  },
  {
    meta:{
      title:'Email ID',
      type:'default',
      styles:{
        width:'160px'
      },
    }
  },
  {
    meta:{
      title:'Mobile Number',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Job applied',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Job Code',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Rating',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Stage',
      type:'default',
      styles:{
        width:'180px'
      },
    }
  },
  {
    meta:{
      title:'Action',
      type:'default',
      styles:{
        width:'50px'
      },
    }
  },
]
const navigate = useNavigate(); 
const location = useLocation();

const activeData=applicationResponse?.applications?.map(data=>{
   return  [
      {renderItem:
           <Tooltip placement="right"  zIndex={1000} title={"Manage application"} >
                <div  onClick={(e)=>{
                 e.stopPropagation();
                 localStorage.setItem('previousUrl',location.pathname)
                 navigate(`/application-management/pipeline/${data?.job?.id}/application/${data?.id}`)
               }} style={{color:'#1890FF',fontSize:'14px',fontWeight:700,cursor:'pointer',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:'10px'}} >
        

                   {
                     data?.candidateDetails?.profileImg?
                     <div className="candidate-profile-icon">
                       <img src={data?.candidateDetails?.profileImg} style={{width:'100%',height:'100%',objectFit:'contain'}}/>
                     </div>:
                     <div className="candidate-profile-icon" 
               style={{
                backgroundColor:adjustColorBrightness(stringToColor(data?.candidateDetails?.name),70),
                color:adjustColorBrightness(stringToColor(data?.candidateDetails?.name),-70),
                width:'50px',}}
               >{getInitials(data?.candidateDetails?.name||'-')}
               </div> 
                    }
               
              <h3 className="candidate-card-title" title={data?.candidateDetails?.name}>{data?.candidateDetails?.name||'-'}</h3></div>
        
             </Tooltip>},
      {renderItem:<div>{timeAgo(data?.createdAt)||'-'}</div>},
      {renderItem:<div title={data?.candidateDetails?.currentLocation}>{data?.candidateDetails?.currentLocation?.trim()||'-'}</div>},
      {renderItem:<div>{data?.candidateDetails?.email||'-'}</div>},
      {renderItem:<div>{data?.candidateDetails?.phone||'-'}</div>},
      {renderItem:  <Tooltip placement="right" zIndex={1000} title={"View pipeline"}><div style={{color:'#1890FF',fontSize:'14px',fontWeight:700,cursor:'pointer',}} title={data?.job?.jobTitle}
       onClick={(e)=>{
         e.stopPropagation();
         localStorage.setItem('previousUrl',location.pathname)
           navigate(`/application-management/pipeline/${data?.job?.id}`)
       }}
       >   {data?.job?.jobTitle??'-'}</div></Tooltip>},
      {renderItem:<div>{data?.job?.jobCode??'-'}</div>},
      {renderItem:<div><div className="rating-hug" style={{border:`1px solid ${ratingColor(data?.ats)?.strokeColor}`,background:ratingColor(data?.ats)?.fillColor}}>  
          <StarIcon  className="rating-star" style={{'--fillColor':ratingColor(data?.ats)?.starColor}}/>
        {data?.ats||'-'}%</div></div>},
      {renderItem:     <div className="application-stage">
       
        <div className="stage-dot"></div>
      
        <span title={data?.stage}>{data?.stage||'-'}</span>
    
      </div>},
      {renderItem:<div>{ <StatusActions data={data} />||'-'}</div>}
    
     ]
  })

  const inaciveData=
  applicationResponse?.applications?.map(data=>{
    return  [
       {renderItem:
            <Tooltip placement="right"  zIndex={1000} title={"Manage application"} >
                 <div  onClick={(e)=>{
                  e.stopPropagation();
                  localStorage.setItem('previousUrl',location.pathname)
                  navigate(`/application-management/pipeline/${data?.job?.id}/application/${data?.id}`)
                }} style={{color:'#1890FF',fontSize:'14px',fontWeight:700,cursor:'pointer',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:'10px'}} >
                {
                     data?.candidateDetails?.profileImg?
                     <div className="candidate-profile-icon">
                       <img src={data?.candidateDetails?.profileImg} style={{width:'100%',height:'100%',objectFit:'contain'}}/>
                     </div>:
                     <div className="candidate-profile-icon" 
               style={{
                backgroundColor:adjustColorBrightness(stringToColor(data?.candidateDetails?.name),70),
                color:adjustColorBrightness(stringToColor(data?.candidateDetails?.name),-70),
                width:'50px',}}
               >{getInitials(data?.candidateDetails?.name||'-')}
               </div> 
                    }
                
               <h3 className="candidate-card-title" title={data?.candidateDetails?.name}>{data?.candidateDetails?.name||'-'}</h3></div>
         
              </Tooltip>},
       {renderItem:<div>{timeAgo(data?.createdAt)||'-'}</div>},
       {renderItem:<div title={data?.candidateDetails?.currentLocation}>{data?.candidateDetails?.currentLocation?.trim()||'-'}</div>},
       {renderItem:<div>{data?.candidateDetails?.email||'-'}</div>},
       {renderItem:<div>{data?.candidateDetails?.phone||'-'}</div>},
       {renderItem:  <Tooltip placement="right" zIndex={1000} title={"View pipeline"}><div style={{color:'#1890FF',fontSize:'14px',fontWeight:700,cursor:'pointer',}} title={data?.job?.jobTitle}
        onClick={(e)=>{
          e.stopPropagation();
          localStorage.setItem('previousUrl',location.pathname)
            navigate(`/application-management/pipeline/${data?.job?.id}`)
        }}
        >   {data?.job?.jobTitle??'-'}</div></Tooltip>},
       {renderItem:<div>{data?.job?.jobCode??'-'}</div>},
       {renderItem:<div><div className="rating-hug" style={{border:`1px solid ${ratingColor(data?.ats)?.strokeColor}`,background:ratingColor(data?.ats)?.fillColor}}>  
           <StarIcon  className="rating-star" style={{'--fillColor':ratingColor(data?.ats)?.starColor}}/>
         {data?.ats||'-'}%</div></div>},
       {renderItem:     <div className="application-stage">
        
         <div className="stage-dot"></div>
       
         <span title={data?.stage}>{data?.stage||'-'}</span>
     
       </div>},
       {renderItem:<div>{ <StatusActions data={data} />||'-'}</div>}
     
      ]
   })

  useEffect(()=>{
    if(status=='active')
    setSelectedColumns(activeCol?.map(col=>col?.meta?.title))
    else if(status=='inactive')
      setSelectedColumns(inactiveCol?.map(col=>col?.meta?.title))

    setSearch();
    setSortBy('DESC')
    setLayoutType('grid')
  },[status])


  useEffect(()=>{
        getActiveJobs({},(res,err)=>{
          if(res?.job){
            console.log("jobs",res)
            setJobOptions(res?.job?.filter((job)=>job?.id&&job?.name)?.map((job)=>{
              return {
                label:job?.name,
                value:job?.id,
                jobCode:job?.jobCode
              }
            }))

          
          }
    
        })

  },[])


  const firstChildRef = useRef(null);
  const secondChildRef = useRef(null);
  const parentRef = useRef(null);

  const adjustFirstChildHeight = () => {
    if (!firstChildRef.current || !secondChildRef.current) return;
    console.log("resize",firstChildRef.current ,secondChildRef.current)
    if (firstChildRef.current && secondChildRef.current) {
      // Match the height of the first child to the second child
      firstChildRef.current.style.height = `${secondChildRef.current.offsetHeight}px`;
    }
  };

  const handleScroll = () => {
    console.log("scroll event")
    if (!parentRef.current || !firstChildRef.current || !secondChildRef.current) return;

    const parentRect = parentRef.current.getBoundingClientRect();
    const firstChildRect = firstChildRef.current.getBoundingClientRect();
    const secondChildRect = secondChildRef.current.getBoundingClientRect();
 console.log("top heigths" ,"p top:=",parentRect.top,"filter height:=",firstChildRef.current.offsetHeight ,"candidates rect:=",secondChildRect,"f recT:",firstChildRect,"windo heigth:", window.innerHeight)
    // Check if the first child should stick
    if (secondChildRect.top<=0 && firstChildRect.height+secondChildRect.top <=window.innerHeight) {
      // Enable fixed position
      firstChildRef.current.style.position='fixed'
      firstChildRef.current.style.bottom = "0"; // Stick to the bottom of the window
      firstChildRef.current.style.top = "unset"; // Reset top
    } else {
      // Reset to relative position
      firstChildRef.current.style.position='relative'
      firstChildRef.current.style.top = "0"; // Reset top
      firstChildRef.current.style.bottom = "unset"; // Reset bottom
    }
  };

  // useEffect(() => {
  //   // Adjust height on mount and window resize
  
  //    const parentScroller=document.getElementById('vacancies-wrapper');
  //    if(parentScroller){
  //     parentScroller.addEventListener('scroll',handleScroll);
      
  //    }
  //    return () =>{
  //     parentScroller.removeEventListener('scroll',handleScroll);
    
  //    }
 
  // },[]);


  useEffect(() => {

        adjustFirstChildHeight();
 
  }, [secondChildRef?.current?.getBoundingClientRect()]);




    return (
      Object.keys(applicationResponse||{}).length>0 ?
        <>
        <div className="candidates-wrapper" ref={parentRef}  onScroll={handleScroll}>
        <div style={{width:'200px'}}>
        <FilterSiderbar loading={loading} setPage={setPage} setLimit={setLimit}  refPointer={firstChildRef}/>
        </div>
        
        
      
        <div className="candidates-container" ref={secondChildRef} >
            <div className="candidates-filter-1">
            {/* <Search placeholder="Search" onSearch={onSearch} enterButton style={{maxWidth:'300px'}}/> */}
            <SearchCompo onSearch={(value)=>{
                  if(!loading)
                 setSearch(value)
              }}  value={search}  placeholder="Search name/job title/job code" /> 
            </div>
            <div className="candidates-filter-2">
            <div className="candidates-count" style={{color:'#7A7A7A',fontSize:'14px',fontWeight:400}}>
            Showing: <span> {applicationResponse?.meta?.totalItems??0} Applications</span> 
            <span className="bullet-dot"></span>
             <span> {applicationResponse?.meta?.candidatesCount??0} Candidates</span>
             {status=='inactive'
             ?
             <Tooltip title='Listed applications are either disqualified, on-hold or the job vacancy is no longer active.'><span><InfoCircleOutlined /></span></Tooltip>:null}
            
            </div>
            <div className="candidates-filter-wrapper">
            <div className="candidates-sort">
      

         <ActivejobsFilter selectedColumns={selectedJobs} setSelectedColumns={setSelectedJobs} columns={jobOptions?.map(e=>{return{key:e?.label,value:e?.value,jobCode:(e?.jobCode||'NA')}})} meta={{selectAll:false,isMultiSelect:true}}/>
         
     

             <Select
                showSearch
                style={{
                width: 200,
                color:'#1F1F1F'
                }}
                placeholder="Select"
                optionFilterProp="label"
                value={sortBy}
                onChange={(value)=>{
                  setSortBy(value)
                }}
             
                disabled={loading}
                options={[
                {
                  value: 'DESC',
                  label: 'Newest to Oldest',
                },
                {
                  value: 'ASC',
                  label: 'Oldest to Newest',
                },
                {
                  value: 'Name-ASC',
                  label: 'Name (A-Z)',
                },
                {
                  value: 'Name-DESC',
                  label: 'Name (Z-A)',
                },
                {
                  value: 'Job-ASC',
                  label: 'Job Title (A-Z)',
                },
                {
                  value: 'Job-DESC',
                  label: 'Job Title (Z-A)',
                },
                {
                  value:'best-match',
                  label:"Best Match First"
                }

                ]}
              />
            </div>
          {  layoutType=='list'?
         <ColumnFilter selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} columns={status=='active'?activeCol:inactiveCol} meta={{selectAll:true,isMultiSelect:true}}/>
         
         :null}
            <div className={`layout-btn ${layoutType=='grid'?'active':''}`} onClick={()=>{
              setLayoutType('grid')
            }}>
            <AppstoreOutlined style={{color:layoutType=='grid'?'white':'black'}} />
            </div>
            <div className={`layout-btn ${layoutType=='list'?'active':''}`} onClick={()=>{
              setLayoutType('list')
            }}>
            <BarsOutlined  style={{color:layoutType=='list'?'white':'black'}}/>
            </div>
            </div>
         
                
            </div>

         
            <>
            {layoutType=='grid'?
              <div className="candidate-card-wrapper">
              {loading?
         
              <>
              {[...new Array(8)].map(e=>{
             return  <div   className="candidate-card-container" >

                  <div className="candidate-card-header">
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'10px'}}>                         
                   <div className=" animated-bg animated-bg-text" style={{width:'30px',height:'30px',borderRadius:'100px',marginRight:'2px'}}></div>
                   <h3 className="candidate-card-title animated-bg animated-bg-text" style={{width:'180px',height:'15px'}}></h3>
                  </div>
                  </div>
                  <div className="candidate-card-header">
                  <div>
                  <h3 className="candidate-card-title animated-bg animated-bg-text" style={{width:'180px',height:'15px'}}></h3>
                  <span className="candidate-card-field animated-bg animated-bg-text" style={{width:'120px',height:'12px',marginTop:'10px'}}></span>
                  </div>


                  </div>


                  <div className="candidate-card-spec" style={{gap:10,padding:12,background:'rgba(245,245,245,0.5)',width:'100%',borderRadius:3,height:'90px',marginBottom:'10px'}}>
                      <div className="candidate-card-exp"  style={{flexDirection:'column',}}>
                      <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                        <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                        <div className="candidate-card-exp" style={{marginTop:'10px'}} >
                          <div className=" animated-bg animated-bg-text" style={{width:'10px',height:'10px',marginRight:'2px'}}></div>
                          <div className=" animated-bg animated-bg-text" style={{width:'60px',height:'10px'}}></div>
                      </div>
                      </div>
                      <div className="candidate-card-exp" >
                          <div className=" animated-bg animated-bg-text" style={{width:'50px',height:'20px',borderRadius:'999px',marginRight:'2px'}}></div>
                   
                      </div>
                  </div>

                 


                  <div className="candidate-card-date">
                  <div className=" animated-bg animated-bg-text" style={{width:'60%',height:'10px'}}></div>
                  </div>


              </div>
            })}
              </>
        
              :(
                applicationResponse?.applications?
                applicationResponse?.applications?.map((application,index)=>{
                
                  return <CandidateCard data={application} status={status} />
                })
                 :
              <>
              <div className="no-job-data">
                 <img src={no_job}/>
                 <h3 >{Object.keys(nFilters||{})?.length>0?'Oops! No results.':'Oops! Nothing matches your search.'}</h3>
                 <p >{Object.keys(nFilters||{})?.length>0?'We couldn\'t find any matches.':'Try adjusting your search terms or use different keywords for better results.'}</p>
       
              </div>
                 </>
              )
              
              }
            </div>:
           
             <Table columns={status=='active'?activeCol:inactiveCol} 
             rowData={status=='active'?activeData:inaciveData} 
             loading={loading} scrollMore={true} selectedColumns={selectedColumns} 
             columnFilterFlag={true}   filterApplied={Object.keys(nFilters||{})?.length>0}/>
            }
           

          
            <div className="candidates-pagination">
            <Pagination
              totalPages={applicationResponse?.meta?.totalPages}
              currentPage={page}
              onPageChange={handlePageChange} 
              itemsPerPage={limit}
              onItemsPerPageChange={handleItemsPerPageChange}
             />
             </div>
           </>
             
            

        </div>
        </div>
           

        </>:
        <>
               <div className="no-candidate-wrapper" >
             <div className="no-candidate-data">
                 <img src={no_job}/>
                 <h3>It's time to find the right talent!</h3>
                 <p>Start building your candidate database quickly by adding profiles in two ways: upload individual resumes, enter candidate data manually, or bulk upload via an Excel file.</p>
                 <div className="add-btns">
                 <button  style={{cursor:'pointer'}} type="button" onClick={()=>{
                  setOpenAddCandidateModal(true);
                 }}  
                      className="add-candidate"> 
                   <PlusCircleOutlined/>
                    <div>Add candidate</div>
                  </button>
                  <button style={{cursor:'pointer'}} type="button" onClick={()=>{
                      setOpenBulkUploadModal(true);
                 }}  
                      className="bulk-upload"> 
                    <UploadOutlined/>
                    <div>Upload in bulk</div>
                  </button>
                 </div>
                 <div className="download-template">
                  <div className="excel-div">
                    <div>
                    <img src={xlsImg}></img> 
                  <span>Excel Template</span>
                  </div>
                    <div>
                    Download and edit attached template as a foundation for a smooth upload process.
                    </div>
                  </div>
                  <div className="template-div"  onClick={()=>{
                    getExcel({},(res,err)=>{
                   
                      if (res.file) {
                        // Create a temporary anchor element
                        const link = document.createElement('a');
                        link.href = res.file; // Create a download URL
                        // Append the anchor to the document, trigger the download, and then remove it
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        message.success('Template downloaded successfully!');
                        // Revoke the object URL to free up memory
                        URL.revokeObjectURL(link.href);
                    } else {
                        console.error("No file found in the response");
                        message.error('Unable to download template!');
                        return
                    }

                    })
                    
                  }}>
                    <DownloadOutlined/>
                    <span>Download Template</span>
                  </div>

                 </div>
              </div>
              </div>
       
             
        </>
    );
}
export default Candidate;