import { create } from "zustand";
export const useStore=create(set=>({
    titleJob:'',
    titleIndustry:'',
    isModalOpen:false,
    jobExperience:'',
    jdData:'',
    progress:0,
    showExperince:"no",
    mandatorySkills:'',
    optionalSkills:'',
    jdEditModal:false,
    setTitleJob:(val)=>set(state=>({titleJob:val})),
    setTitleIndustry:(val)=>set(state=>({titleIndustry:val})),
    setIsModalOpen:(val)=>set(state=>({isModalOpen:val})),
    setJobExperience:(val)=>set(state=>({jobExperience:val})),
    setJdData:(val)=>set(state=>({jdData:val})),
    setProgress:(val)=>set(state=>({progress:val})),
    setShowExperince:(val)=>set(state=>({showExperince:val})),
    setMandatorySkills:(val)=>set(state=>({mandatorySkills:val})),
    setOptionalSkills:(val)=>set(state=>({optionalSkills:val})),
    setJdEditModal:(val)=>set(state=>({jdEditModal:val}))

   
}))

export const useResumeScreeningStore = create(set=>({
    analyseModal:false,
      uploads:[],
    uploadedFile:null,
    jobTitle:"",
    jobDescription:"",
    yearsOfExperience:"",
    qualification:"",
    progress:0,

    setUploads: (val) => set((state) => ({ uploads: val })),
    setUploadedFile: (val)=>set((state)=> ({uploadedFile: val})),
    setJobTitle:  (val) => set((state) => ({ jobTitle: val })),
    setJobDescription: (val)=> set((state)=>({jobDescription: val})),
    setYearsOfExperience: (val)=> set((state)=>({yearsOfExperience:val})),
    setAnalyseModal:(val)=>set(state=>({analyseModal:val})),
    setQualification:(val)=>set(state=>({qualification:val})),
    setProgress:(val)=>set((state)=>({progress: val})),
}))