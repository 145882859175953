import React, { useEffect, useState } from "react";
import addButtonIcon from "../../assets/addButtonIcon.svg"
import { useApplicationManagementStore } from "./store/store.js"
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import './applicationManagement.css'
import { getApplicationsCount, getJobsCount} from "./Action/action.js";
import Candidate from "./listingPage/Candidate.js";
import AddCandidateModal from "./listingPage/addCandidateModal.js";
import BulkUploadModal from "./listingPage/bulkuploadModal.js";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

function ApplicationManagement ({tab})
{
   
   const {  setActiveTab,role,setRole,activeTab,openAddCandidateModal,setOpenAddCandidateModal,openBulkUploadModal,setOpenBulkUploadModal} = useApplicationManagementStore(state=>state);

    const [activeCount,setActiveCount]=useState(0);
    const [inactiveCount,setInactiveCount]=useState(0);
   const [open,setOpen]=useState(false)
    const [choosenOption,setChoosenOption]=useState();

    useEffect(()=>{
        setActiveTab(tab);

        getApplicationsCount({  role:role},(res,err)=>{
          
           setActiveCount(res?.active||0);
          
          
            setInactiveCount(res?.inactive||0);
     
        })
     
    },[tab,role])

    const tabs = [
    
        { 
          name: "Active", 
          content: <Candidate status={"active"} />,
          url:'/application-management',
          count:activeCount,
          style:{
            width:'100px',
            marginLeft:'200px',
          }
        },
        { 
          name: "In-active", 
          content:    <Candidate  status={"inactive"} />,
          url:'/application-management/in-active',
          count:inactiveCount,
          style:{
            width:'100px',
            marginLeft:'300px',
          }
        },
        {
          name: "Analytics & Reports",
          content: <></>,
          url:'/application-management/analytics',
          style:{
            width:'160px',
            marginLeft:'460px',
          }
        },
   
      ];
 

      useEffect(()=>{
        if(!openAddCandidateModal && !openBulkUploadModal){
          setChoosenOption()
        }

      },[openAddCandidateModal ,openBulkUploadModal])

      const handleOpenChange = (nextOpen, info) => {
        if (info.source === 'trigger' || nextOpen) {
          console.log("nnext",nextOpen)
          setOpen(nextOpen);
          if(!openAddCandidateModal && !openBulkUploadModal){
            setChoosenOption();
          
          }
        }
     
      };
      const handleMenuClick = (e) => {
        setChoosenOption(e.key);   
         setOpen(!open);

        if (e.key === '1') {
          setOpenAddCandidateModal(true);
          setOpenBulkUploadModal(false);
      
          
        }else if(e.key=='2'){
          setOpenAddCandidateModal(false);
          setOpenBulkUploadModal(true);
   
      
        }
      };
      
      const items = [
        {
          label: 'Enter manually',
          key: '1',
        },
        {
          label: 'Upload in bulk',
          key: '2',
        },
     
      ];
  

    return (
       
        <div className="vacancies-wrapper">
     
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',padding:'20px'}}>
        <div >
            <h3 style={{margin:'3px 0px',fontSize:'20px',fontWeight:700}}>Application Management</h3>
            <p style={{margin:0,fontSize:'14px',fontWeight:500,color:'#4D4D4D'}}>Find and organize candidate resumes all in one place for streamlined hiring.</p>
        </div>
           
                <div>
                <Dropdown
             menu={{
               items,
               selectable: true,
               onClick: handleMenuClick,
               selectedKeys:[choosenOption],
          
              
              }}
              
               
              overlayStyle={{
                zIndex:100
              }}	
              trigger={['click']}
            onOpenChange={handleOpenChange}
            open={open}
            >
                  <button id="raiseReqForm" style={{cursor:'pointer'}} type="button"  
                      className="dashed-box-button"> 
                    <img src={addButtonIcon} />
                    <div>Add candidate {open?<CaretUpOutlined />:<CaretDownOutlined />}</div>
                  </button>
                  </Dropdown>
                </div>
            </div>
        
              
            {<CustomTabs tabs={tabs} activeTab={tab} onClick={() =>{}}/>}
        
         

           {openAddCandidateModal && <AddCandidateModal />}
              {openBulkUploadModal && <BulkUploadModal  />} 
        
    </div>
       
    );
}

export default ApplicationManagement;
