import React from "react";
import './styles.css'
import Divider from "../../../components/divider/Divider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import location_mark from '../../../assets/icons/location-mark.svg'
import experince from '../../../assets/icons/experince.svg'
import { Dropdown, Tooltip } from "antd";
import { CheckOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { updateVacancyRequests } from "../Action/action";
import { adjustColorBrightness, getInitials, stringToColor } from "../../notification/notification";
      
import emailIcon from '../../../assets/icons/emailIcon.svg';
import phoneIcon from '../../../assets/icons/phoneIcon.svg';

import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import timeIcon from '../../../assets/icons/timeIcon.svg';
export  const statusBtnColor=(status)=>{
     
  switch(status){
      case "Closed":return ["#FFF1F0","#F5222D"];
      case "On-hold":return ["#FFF7E6","#FA8C16"];
      default: return ["#E9E9E9","#7A7A7A"];

  }
}

export function timeAgo(date) {
  try{
  const now = new Date();

  const givenDate = new Date(date);
  
  const diffInSeconds = Math.floor((now - givenDate) / 1000);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if(diffInDays==1){
    return 'Yesterday'
  }

  const options = { 
    month: 'short', 
    day: '2-digit', 
    year: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit', 
    hour12: false 
  };

  return new Intl.DateTimeFormat('en-US', options).format(givenDate);
}
catch(e){
  return ''
}

  // if (diffInDays < 30) {
  //   return `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
  // }

  // const diffInMonths = Math.floor(diffInDays / 30);
  // if (diffInMonths < 12) {
  //   return `${diffInMonths} month${diffInMonths === 1 ? '' : 's'} ago`;
  // }

  // const diffInYears = Math.floor(diffInMonths / 12);
  // return `${diffInYears} year${diffInYears === 1 ? '' : 's'} ago`;

  
}

export function ratingColor(rating){

  if(rating<40){
    return {strokeColor:'#FFCCC7',fillColor:'#FFF1F0',starColor:'#F5222D'}
  }else if(rating<60){
    return {strokeColor:'#FFE7BA',fillColor:'#FFF7E6',starColor:'#FA8C16'}
  }else if(rating<80){
    return {strokeColor:'#FFFFB8',fillColor:'#FEFFE6',starColor:'#FADB14'}
  }else if(rating<=100){
    return {strokeColor:'#D9F7BE',fillColor:'#F6FFED',starColor:'#52C41A'}
  }else{
    return {strokeColor:'rgba(208,208,208,0.7)',fillColor:'rgba(208,208,208,0.3)',starColor:'#D0D0D0'}
  }

}

export const StatusActions=({data,isHorizontal})=>{

   const navigate=useNavigate();

  
  const statusList=[
    {
        key:'1',
        label:"Manage application"
    },
    {
        key:'2',
      
        label: 'View pipeline'
          
      },
    {
        key:'3' ,
       
        label:'View candidate\'s profile' 
    }
  
    ]
 return ( <div 
  onClick={(e) => e.stopPropagation()} >
      <Dropdown
      menu={{
          items:statusList,
      onClick:({key,domEvent})=>{
   
        domEvent.stopPropagation();

        switch(key){
          case '1':navigate(`/application-management/pipeline/${data?.job?.id}/application/${data?.id}`);
                    return ;
          case '2':navigate(`/application-management/pipeline/${data?.job?.id}`);
                   return ;
          case '3':navigate(`/application-management/candidate-profile/${data?.id}_${data?.job?.id}`);
        }
    
      }
      }}
      trigger={['click']}
      placement="bottomRight"
      style={{padding:'0'}}
      className="dropdown-status"
   
 
  
    >
      <MoreOutlined  className="more-btn" style={{rotate:isHorizontal?'90deg':'0deg'}}/>
      </Dropdown>
      </div>);
}


const CandidateCard=({ data})=>{
 
const navigate = useNavigate(); 
const location = useLocation();


return (
    <div  onClick={(e)=>{
      e.stopPropagation();
   
      localStorage.setItem('previousUrl',location.pathname)
            navigate(`/application-management/pipeline/${data?.job?.id}/application/${data?.id}`)
    }}   className="candidate-card-container"  title="Manage application">

        <div className="candidate-card-header">
 
         <Tooltip placement="right" title={"View candidate's profile"} > 
        <div  
        onClick={(e)=>{
         e.stopPropagation();
         localStorage.setItem('previousUrl',location.pathname)
               navigate(`/application-management/candidate-profile/${data?.id}_${data?.job?.id}`)
       }} 
       
       style={{color:'#1890FF',fontSize:'14px',fontWeight:700,cursor:'pointer',display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:'10px'}} >
     {
      data?.candidateDetails?.profileImg?
      <div className="candidate-profile-icon">
        <img src={data?.candidateDetails?.profileImg} style={{width:'100%',height:'100%',objectFit:'contain'}}/>
      </div>:
      <div className="candidate-profile-icon" style={{backgroundColor:adjustColorBrightness(stringToColor(data?.candidateDetails?.name),70),color:adjustColorBrightness(stringToColor(data?.candidateDetails?.name),-70)}}>{getInitials(data?.candidateDetails?.name)}
      </div> 
     }
     
  
       
     <h3 className="candidate-card-title" style={{width:'max-content',maxWidth:'180px'}} title={data?.candidateDetails?.name}>{data?.candidateDetails?.name??'-'}</h3></div>

     </Tooltip>


  
   
        </div>
        <div className="candidate-info">
      
        <span  className="candidate-card-field" style={{maxWidth:'180px',}} title={data?.candidateDetails?.email}><img src={emailIcon}/><span className="ellipsis">{data?.candidateDetails?.email||'-'}</span></span>
     
        <span  className="candidate-card-field" style={{maxWidth:'180px'}} title={data?.candidateDetails?.phone}><img src={phoneIcon}/><span className="ellipsis ">{data?.candidateDetails?.phone||'-'}</span></span>

      
        </div>
        
       
  
    
    
        <div className="application-status">
         
       
        <div className="status-header">
        <div className="applied-job-info"  onClick={(e)=>{
         e.stopPropagation();
         localStorage.setItem('previousUrl',location.pathname)
           navigate(`/application-management/pipeline/${data?.job?.id}`)
       }}>

        <Tooltip placement="right" title={"View pipeline"}>
          <h3 title={data?.job?.jobTitle} >{data?.job?.jobTitle ??'-'}</h3>
          </Tooltip>
          <span title={data?.job?.jobCode}>{data?.job?.jobCode??'-'}</span>
        </div>

          <div className="rating-wrapper">
       
          <div className="rating-hug" style={{border:`1px solid ${ratingColor(data?.ats)?.strokeColor}`,background:ratingColor(data?.ats)?.fillColor}}>   
      
           <StarIcon  className="rating-star" style={{'--fillColor':ratingColor(data?.ats)?.starColor}}/>
          
           {data?.ats||'-'}%</div>

          </div>

        </div>
     
        <div className="application-stage">
       
          <div className="stage-dot"></div>
        
          <span title={data?.stage}>{data?.stage||'-'}</span>
      
        </div>
        <div>
        </div>
        </div>

        <div className="application-footer">
        <div className="appliation-date-wrapper">
      
          <div>
         
          <span  style={{display:'flex',flexDirection:'row',justifyContent:'flex-start',alignItems:'center',gap:'5px',fontSize:'12px',color:'#7A7A7A'}}><img src={timeIcon}/> {timeAgo(data?.createdAt)||'-'}</span>
          </div> 

          </div>
            <StatusActions data={data} isHorizontal={true}/>
        </div>


    </div>

);
}
export default CandidateCard;