import React from "react";
import SemiCircularProgressBar from "./semiCircularProgressBar";
import RenderResults from "./renderResults";
import "./renderResults.css"

const Results = ({results})=>{
    const options = [
        { title: 'Hard Skills', description: 'Specific, measurable abilities required for the job, such as technical expertise. These are often validated by certifications, education, or work experience.', percentage: results?.Hard_Skill_Score?.toFixed(2).toString()+'%' },
        { title: 'Soft Skills', description: 'Interpersonal and communication abilities that influence workplace interactions, such as teamwork, problem-solving, adaptability, and leadership.', percentage: results?.Soft_Skill_Score?.toFixed(2).toString()+'%' },
        { title: 'Experience', description: "The candidate's relevant professional background, including the duration, roles, industries, and accomplishments that align with the job requirements.", percentage: results?.Experience_Score?.toFixed(2).toString()+'%' },
        {title: 'Format Score', description:"An evaluation of how well the resume is structured, including readability, consistency, organization, and adherence to standard formats (e.g., clear headings, proper font usage).", percentage: results.Format_Score?.toFixed(2).toString()+'%'},
        {title: 'Sales Index', description:"A metric specific to sales or customer-focused roles, assessing the candidate's potential to drive revenue, meet targets, or succeed in sales-related tasks based on past performance and skills.", percentage: results.Sales_Index?.toFixed(2).toString()+'%'}
      ];
      
    return (
        <div style={{marginTop:'40px', marginBottom:'40px'}}>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between',alignItems:'center'}}>
                <div style={{textAlign:'left'}}>
                    <div className="results-header">Top Fit</div>
                    <div className="results-desc">Exceeds expectations, demonstrating high relevance and readiness for the role.</div>
                </div>
                {/* <SemiCircularProgressBar percentage={results['ATS Score']}/> */}
                <div>
                <div className="result-percentage">{"ATS SCORE :"}</div>
                <div className="result-percentage" style={{color: results['ATS Score']>50 ?'#52C41A':'#F5222D'}}>{results['ATS Score']+'%'||'0%'}</div>
                </div>
            </div>
            
                <RenderResults options={options} />
        
        </div>
    );

}
export default Results;