import { create } from "zustand";
export const useApplicationManagementStore=create(set=>({
    activeTab:'Active',
    role:'',
    applicationResponse:[],
    applicationFilters:null,
    layoutType:'grid',
    nFilters:{},
    previousUrl:'',
    openAddCandidateModal:false,
    openBulkUploadModal:false,
    refresh:false,
    applicationData:null,
    candidateData:null,
    aplicationLoading:true,
    
    setOpenAddCandidateModal:(val)=>set(({openAddCandidateModal:val})),
    setOpenBulkUploadModal:(val)=>set(({openBulkUploadModal:val})),
    setActiveTab:(val)=>set(state=>({activeTab:val})),
    setnFilters:(val)=>set({nFilters:val}),
    setApplicationResponse:(val)=>set({applicationResponse:val}),
    setApplicationFilters:(val)=>set({applicationFilters:val}),
    setPreviousUrl:(val)=>set({previousUrl:val}),  
     setLayoutType:(val)=>set(state=>({layoutType:val})),
     setRefresh:(val)=>set(state=>({refresh:val})),
     setApplicationData:(val)=>set({applicationData:val}),
     setCandidateData:(val)=>set({candidateData:val}),
     setApplicationLoading:(val)=>set({applicationLoading:val})
}))
export const usePipelineStore=create(set=>({
  quickViewVisibility: false,
  setQuickViewVisibility:(val)=>set(state=>({quickViewVisibility:val})),
}))

