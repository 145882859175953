import React, { useEffect, useState } from 'react';
import rightArrow from "../../assets/rightArrow.svg";
import leftArrow from "../../assets/leftArrow.svg";
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';

// const Pagination = ({ totalPages, currentPage, onPageChange, itemsPerPage, onItemsPerPageChange }) => {

//     const [pageRange, setPageRange] = useState([1, Math.min(5, totalPages)]);
//     const [gotoPage,setGotoPage]=useState();
 
//     useEffect(()=>{
//      setGotoPage('')
//     },[currentPage,totalPages])
//     // Helper function to update the page range when navigating
//     const updatePageRange = (newPage) => {
//         if (newPage > pageRange[1]) {
//             setPageRange([pageRange[1] + 1, Math.min(pageRange[1] + 5, totalPages)]);
//         } else if (newPage < pageRange[0]) {
//             setPageRange([Math.max(1, pageRange[0] - 5), pageRange[0] - 1]);
//         }
//         onPageChange(newPage);
//     };

//     useEffect(()=>{
//         setPageRange([1, Math.min(5, totalPages)]);
//     },[totalPages])
//     // Create an array of page numbers for the current range
//     const pageNumbers = Array.from({ length: pageRange[1] - pageRange[0] + 1 }, (_, i) => pageRange[0] + i);

//     const handleGoto=(e)=>{
//         if(!e.target.value){ setGotoPage();  return }
//         if(e.target.value<=0){
//             setGotoPage(1);
//             return 
//         }
//         setGotoPage(e.target.value)
//     }

//     return (
//         <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
       
//             {/* Left navigation button */}
//             {totalPages?<>
//                 <div style={{display:'flex',flexDirection:'row',gap: '8px',alignItems:'center'}}>
//             <button 
//                 onClick={() => updatePageRange(currentPage - 1)} 
//                 disabled={currentPage == 1}
//                 style={{...buttonStyle,
//                     cursor: totalPages>1 && currentPage!==1?'pointer':'not-allowed'
//                 }}
//             >
//                 {currentPage != 1 ? 
//                     <img src={rightArrow} style={{ transform: 'rotate(180deg)' }} alt="leftArrow" /> : 
//                     <img src={leftArrow} alt="leftArrow" />}
//             </button>

//             {
//                 ( currentPage>5)?
//                 <>
//                 <button
            
//                     onClick={() => updatePageRange(1)}
//                     style={{
//                         ...buttonStyle,
//                         border: currentPage == 1 ? '2px solid #1890FF' : '1px solid #D0D0D0',
//                         color: currentPage == 1 ? '#1890FF' : '#4D4D4D'
//                     }}
//                 >
//                   1
//                 </button>
//                 <button
//                 className='backward-page'
//                     style={{
//                         ...buttonStyle,
//                         border: '1px solid #D0D0D0',
//                         color:  '#4D4D4D'
//                     }}
//                     onClick={()=>{
//                         updatePageRange(currentPage-5)
//                     }}
//                 >
//                   <span className='more'>...</span>
//                   <span className='backward-left'><DoubleLeftOutlined style={{color:'#1890FF'}}/></span>
//                 </button>
             
//                 </>


//                 :null

//             }
//             {/* Page numbers */}
//             {pageNumbers.map((page) => (
//                 <button
//                     key={page}
//                     onClick={() => updatePageRange(page)}
//                     style={{
//                         ...buttonStyle,
//                         border: currentPage == page ? '2px solid #1890FF' : '1px solid #D0D0D0',
//                         color: currentPage == page ? '#1890FF' : '#4D4D4D'
//                     }}
//                 >
                

//                     {page}
//                 </button>
//             ))}

//             {
//                 (totalPages> 5 && pageNumbers.length>=5  )?
//                 <>
//                 <button
//                 className='forward-page'
//                 onClick={() => updatePageRange(currentPage+5)}
//                     style={{
//                         ...buttonStyle,
//                         border: '1px solid #D0D0D0',
//                         color:  '#4D4D4D'
//                     }}
//                 >
//                     <span className='more'>...</span>
//                   <span className='forward-right'><DoubleRightOutlined style={{color:'#1890FF'}}/></span>
//                   </button>
//                 <button
//                     key={totalPages}
//                     onClick={() => updatePageRange(totalPages)}
//                     style={{
//                         ...buttonStyle,
//                         border: currentPage == totalPages ? '2px solid #1890FF' : '1px solid #D0D0D0',
//                         color: currentPage == totalPages ? '#1890FF' : '#4D4D4D'
//                     }}
//                 >
//                     {totalPages}
//                 </button>
//                 </>
//                 :null

//             }

//             {/* Right navigation button */}
//             <button 
//                 onClick={() => updatePageRange(currentPage + 1)} 
//                 disabled={currentPage == totalPages}
//                 style={{...buttonStyle,
//                     cursor: totalPages>1 && currentPage!==totalPages?'pointer':'not-allowed'
//                 }}
//             >
//                 {currentPage != totalPages ? 
//                     <img src={rightArrow} alt="rightArrow" /> : 
//                     <img src={leftArrow} style={{ transform: 'rotate(180deg)' }} alt="leftArrow" />}
//             </button>
         
        
//             </div>
//             <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}> 
//             {/* Items per page dropdown */}
//             <select
//                 value={itemsPerPage}
//                 onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
//                 style={selectStyle}
//                   onmousedown="this.style.outline='none';"
//             >
//                 <option value={10}>10 / page</option>
//                 <option value={1}>20 / page</option>
//                 <option value={30}>30 / page</option>
//                 <option value={40}>40 / page</option>
//             </select>

//             <div style={{marginLeft:'20px',  color: '#000'}}>Go to</div>
//             <div>
//             <form  onSubmit={e=>{e.preventDefault();updatePageRange(+gotoPage); const input=document.getElementById("input-goto");input.value=''; input.blur();  }}>
//             {console.log("goto page",gotoPage)}
//             <input  id='input-goto'  type='number'   min={1}  max={totalPages} onChange={e=>handleGoto(e)} style={gotoStyle} value={gotoPage} />
//             </form>
            
//             </div>
//             </div>
//             </>
//             :
//             <>
//               <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>
//                 <div style={animatedButton} > <img src={leftArrow} alt="leftArrow" /></div>
//                 <div style={animatedButton} ></div>
//                 <div style={animatedButton} ></div>
//                 <div style={animatedButton} ></div>
//                 <div style={animatedButton} ></div>
//                 <div style={animatedButton} ></div>
//                 <div style={animatedButton}  ><img src={leftArrow} alt="rightArrow"  style={{ transform: 'rotate(180deg)' }}/></div>
//                 </div>
//                 <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}> 
//                  <div style={{...animatedButton,width:'100px'}} ></div>
              
//                 <div style={{marginLeft:'20px',  color: '#000'}}>Go to</div>
//                 <div style={animatedButton} ></div>
//                 </div>
                
//             </>
            
//             }
         
         
//         </div>
//     );
// };

const Pagination = ({ totalPages, currentPage, onPageChange, itemsPerPage, onItemsPerPageChange }) => {
    const [pageRange, setPageRange] = useState([1, Math.min(5, totalPages)]);
    const [gotoPage, setGotoPage] = useState("");

    useEffect(() => {
        setPageRange([1, Math.min(5, totalPages)]);
    }, [totalPages]);

    useEffect(() => {
        setGotoPage("");
    }, [currentPage, totalPages]);

    const updatePageRange = (newPage) => {
        if (newPage > totalPages || newPage < 1) return;

        const newRangeStart = Math.max(1, Math.min(newPage - 2, totalPages - 4));
        const newRangeEnd = Math.min(totalPages, newRangeStart + 4);

        setPageRange([newRangeStart, newRangeEnd]);
        onPageChange(newPage);
    };

    const pageNumbers = Array.from(
        { length: pageRange[1] - pageRange[0] + 1 },
        (_, i) => pageRange[0] + i
    );

    const handleGoto = (e) => {
        const value = e.target.value;
        if (!value) {
            setGotoPage("");
            return;
        }

        const numericValue = Math.max(1, Math.min(totalPages, Number(value)));
        setGotoPage(numericValue);
    };

    const handleGotoSubmit = (e) => {
        e.preventDefault();
        if (gotoPage) updatePageRange(Number(gotoPage));
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {totalPages ? (
                <>
                    {/* Left Navigation and Page Numbers */}
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                        {/* <button
                            onClick={() => updatePageRange(currentPage - 1)}
                            disabled={currentPage === 1}
                            style={{
                                ...buttonStyle,
                                cursor: currentPage !== 1 ? 'pointer' : 'not-allowed',
                            }}
                        >
                            Prev
                        </button> */}

                        <button 
                onClick={() => updatePageRange(currentPage - 1)} 
                disabled={currentPage == 1}
                style={{...buttonStyle,
                    cursor: totalPages>1 && currentPage!==1?'pointer':'not-allowed'
                }}
            >
                {currentPage != 1 ? 
                    <img src={rightArrow} style={{ transform: 'rotate(180deg)' }} alt="leftArrow" /> : 
                    <img src={leftArrow} alt="leftArrow" />}
            </button>




                        {/* {currentPage > 5 && (
                            <>
                                <button onClick={() => updatePageRange(1)}>1</button>
                                <button onClick={() => updatePageRange(currentPage - 5)}>...</button>
                            </>
                        )} */}
          {
                ( currentPage>5)?
                <>
                <button
            
                    onClick={() => updatePageRange(1)}
                    style={{
                        ...buttonStyle,
                        border: currentPage == 1 ? '2px solid #1890FF' : '1px solid #D0D0D0',
                        color: currentPage == 1 ? '#1890FF' : '#4D4D4D'
                    }}
                >
                  1
                </button>
                <button
                className='backward-page'
                    style={{
                        ...buttonStyle,
                        border: '1px solid #D0D0D0',
                        color:  '#4D4D4D'
                    }}
                    onClick={()=>{
                        updatePageRange(currentPage-5)
                    }}
                >
                  <span className='more'>...</span>
                  <span className='backward-left'><DoubleLeftOutlined style={{color:'#1890FF'}}/></span>
                </button>
             
                </>

                :null

            }






                        {/* {pageNumbers.map((page) => (
                            <button
                                key={page}
                                onClick={() => updatePageRange(page)}
                                style={{
                                    fontWeight: currentPage === page ? 'bold' : 'normal',
                                }}
                            >
                                {page}
                            </button>
                        ))} */}

                        {pageNumbers.map((page) => (
                <button
                    key={page}
                    onClick={() => updatePageRange(page)}
                    style={{
                        ...buttonStyle,
                        border: currentPage == page ? '2px solid #1890FF' : '1px solid #D0D0D0',
                        color: currentPage == page ? '#1890FF' : '#4D4D4D'
                    }}
                >
                

                    {page}
                </button>
            ))}












                        {/* {totalPages > 5 && pageRange[1] < totalPages && (
                            <>
                                <button onClick={() => updatePageRange(currentPage + 5)}>...</button>
                                <button onClick={() => updatePageRange(totalPages)}>{totalPages}</button>
                            </>
                        )} */}

                        {
                (totalPages-currentPage>= 5 && pageRange[1] < totalPages  )?
                <>
                <button
                className='forward-page'
                onClick={() => updatePageRange(currentPage+5)}
                    style={{
                        ...buttonStyle,
                        border: '1px solid #D0D0D0',
                        color:  '#4D4D4D'
                    }}
                >
                    <span className='more'>...</span>
                  <span className='forward-right'><DoubleRightOutlined style={{color:'#1890FF'}}/></span>
                  </button>
                <button
                    key={totalPages}
                    onClick={() => updatePageRange(totalPages)}
                    style={{
                        ...buttonStyle,
                        border: currentPage == totalPages ? '2px solid #1890FF' : '1px solid #D0D0D0',
                        color: currentPage == totalPages ? '#1890FF' : '#4D4D4D'
                    }}
                >
                    {totalPages}
                </button>
                </>
                :null

            }








                        {/* <button
                            onClick={() => updatePageRange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            style={{
                                cursor: currentPage !== totalPages ? 'pointer' : 'not-allowed',
                            }}
                        >
                            Next
                        </button> */}

                             {/* Right navigation button */}
           <button 
                onClick={() => updatePageRange(currentPage + 1)} 
                disabled={currentPage == totalPages}
                style={{...buttonStyle,
                    cursor: totalPages>1 && currentPage!==totalPages?'pointer':'not-allowed'
                }}
            >
                {currentPage != totalPages ? 
                    <img src={rightArrow} alt="rightArrow" /> : 
                    <img src={leftArrow} style={{ transform: 'rotate(180deg)' }} alt="leftArrow" />}
            </button>

                    </div>

 






                    {/* Items Per Page and Go-To Page */}
                    {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <select
                            value={itemsPerPage}
                            onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
                        >
                            <option value={10}>10 / page</option>
                            <option value={1}>20 / page</option>
                            <option value={30}>30 / page</option>
                            <option value={40}>40 / page</option>
                        </select>

                        <div>Go to</div>
                        <form onSubmit={handleGotoSubmit}>
                            <input
                                type="number"
                                min={1}
                                max={totalPages}
                                value={gotoPage}
                                onChange={handleGoto}
                                style={{ width: '50px' }}
                            />
                        </form>
                    </div> */}
                    <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}> 
            {/* Items per page dropdown */}
            <select
                value={itemsPerPage}
                onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
                style={selectStyle}
                  onmousedown="this.style.outline='none';"
            >
                <option value={10}>10 / page</option>
                <option value={20}>20 / page</option>
                <option value={30}>30 / page</option>
                <option value={40}>40 / page</option>
            </select>

            <div style={{marginLeft:'20px',  color: '#000'}}>Go to</div>
            <div>
            <form  onSubmit={e=>{e.preventDefault();updatePageRange(+gotoPage); const input=document.getElementById("input-goto");input.value=''; input.blur();  }}>
            {console.log("goto page",gotoPage)}
            <input  id='input-goto'  type='number'   min={1}  max={totalPages} onChange={e=>handleGoto(e)} style={gotoStyle} value={gotoPage} />
            </form>
            
            </div>
            </div>
                </>
            ) : (
                <>
              <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}>
                <div style={animatedButton} > <img src={leftArrow} alt="leftArrow" /></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton} ></div>
                <div style={animatedButton}  ><img src={leftArrow} alt="rightArrow"  style={{ transform: 'rotate(180deg)' }}/></div>
                </div>
                <div style={{display:'flex',flexDirection:'row',gap:'10px',alignItems:'center'}}> 
                 <div style={{...animatedButton,width:'100px'}} ></div>
              
                <div style={{marginLeft:'20px',  color: '#000'}}>Go to</div>
                <div style={animatedButton} ></div>
                </div>
                
            </>
            )}
        </div>
    );
};

const buttonStyle = {
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    outline: 'none',
    border: '1px solid #D0D0D0',
};

const selectStyle = {
    padding: '6px 12px',
    backgroundColor: '#f5f5f5',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: 'var(--Radius-XS, 4px)',
    border: '1px solid var(--boarders-lines-neutral-subtle, #D0D0D0)',
    background: 'var(--Backgrounds-white, #FFF)',
    height: '34px',
    outline:'none'
};
const optionsStyle = {
    color: 'var(--Texts-body, #4D4D4D)',
fontFamily: 'Figtree',
fontSize: '14px',
fontStyle: 'normal',
fontWeight: '400',
lineHeight: 'normal',
}

const animatedButton={
    width: '34px',
    height: '34px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    backgroundColor: '#ffffff !important',
    outline: 'none',
    border: '1px solid #D0D0D0',
}

const gotoStyle={
    padding: '6px 12px',
    backgroundColor: '#f5f5f5',
    fontSize: '14px',
    cursor: 'text',
    borderRadius: '4px',
    border: '1px solid  #D0D0D0',
    background: '#FFF',
    height: '34px',
    width:'50px',
    outline:'none'
}
export default Pagination;
